<template>
  <div class="gallery-container">
    <!-- 장르 필터 섹션 -->
    <div class="filter-section">
      <!-- 검색바 -->
      <div class="search-bar">
        <i class="fas fa-search"></i>
        <input v-model="searchQuery" placeholder="디자인 검색" />
      </div>
      
      <!-- 필터 토글 버튼 -->
      <div class="filter-toggle" @click="isFilterOpen = !isFilterOpen">
        <div class="filter-header">
          <div class="filter-buttons">
            <button @click.stop="resetFilters" class="filter-button">
              <i class="fas fa-rotate"></i> 초기화
            </button>
            <button @click.stop="getCurrentLocation" class="filter-button">
              <i class="fas fa-location-dot"></i> 내 위치
            </button>
          </div>
          <i :class="['fas', isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
        </div>
      </div>

      <!-- 필터 섹션 -->
      <div class="filters" :class="{ 'filters-open': isFilterOpen }">
        <!-- 지역 필터 -->
        <div class="filter-section">
          <h3 class="filter-title">지역 필터</h3>
          <div class="filter-group location-filters">
            <select v-model="selectedCity" class="filter-select city-select">
              <option value="all">전체</option>
              <option v-for="city in places" :key="city" :value="city">
                {{ city }}
              </option>
            </select>
            <select 
              v-if="selectedCity && selectedCity !== 'all' && selectedCity !== '내위치'" 
              v-model="selectedDistrict" 
              class="filter-select district-select"
            >
              <option value="all">전체</option>
              <option v-for="district in placedetail[selectedCity]" :key="district" :value="district">
                {{ district }}
              </option>
            </select>
          </div>
        </div>

        <!-- 장르 필터 -->
        <div class="filter-section">
          <h3 class="filter-title">장르 필터</h3>
          <div class="filter-group">
            <select v-model="selectedGenre" class="filter-select genre-select">
              <option value="all">전체 장르</option>
              <option v-for="genre in genres" :key="genre" :value="genre">
                {{ genre }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="masonry-grid">
      <div class="masonry-column">
        <div v-for="design in leftColumnItems" 
             :key="design.id" 
             class="masonry-item"
             @click="showDetail(design)">
          <div class="portfolio-image">
            <img 
              :src="design.imageUrl" 
              :alt="design.description"
              @load="onImageLoad($event)"
            />
          </div>
        </div>
      </div>
      <div class="masonry-column">
        <div v-for="design in rightColumnItems" 
             :key="design.id" 
             class="masonry-item"
             @click="showDetail(design)">
          <div class="portfolio-image">
            <img 
              :src="design.imageUrl" 
              :alt="design.description"
              @load="onImageLoad($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 포트폴리오 상세 모달 -->
    <transition name="modal">
    <div v-if="selectedDesign" class="modal" @click="closeDetail">
      <div class="modal-content" @click.stop>
        <button class="close-btn" @click="closeDetail">
          <i class="fas fa-times"></i>
        </button>

        <div class="modal-body">
          <div class="modal-image-container">
            <img 
              :src="selectedDesign.imageUrl" 
              :alt="selectedDesign.description"
              class="modal-image"
            />
          </div>

          <div class="modal-info">
            <!-- 타투이스트 정보 -->
            <div class="artist-section">
              <div class="artist-profile" @click="navigateToProfile(selectedDesign.userId)">
                <img 
                  :src="selectedDesign.artistProfileUrl || defaultAvatar" 
                  :alt="selectedDesign.artistNickname" 
                  class="artist-avatar"
                />
                <div class="artist-info">
                  <span class="artist-name">{{ selectedDesign.artistNickname }}</span>
                  <span class="artist-role">{{ selectedDesign.detailAddress ? selectedDesign.detailAddress.split(' ').slice(0, 3).join(' ') : '주소 정보가 없습니다.' }}</span>
                </div>
              </div>
            </div>

            <!-- 작품 정보 -->
            <div class="work-info">

              <div class="info-group description">
                <label>작품 설명</label>
                <p>{{ selectedDesign.description || '설명이 없습니다.' }}</p>
              </div>
              
              <div class="info-group">
                <label>작업 시간</label>
                <div class="time-info">
                  <i class="fas fa-clock"></i>
                  {{ selectedDesign.timeSpent }}시간
                </div>
              </div>

              <div class="info-group description">
                <label>작업 비용</label>
                <p>{{ selectedDesign.price.toLocaleString() }}원</p>
              </div>

              <div class="info-group description">
                <label>장르</label>
                <p>{{ selectedDesign.mainGenre }}</p>
              </div>
            </div>

            <!-- 프로필 버튼과 문의하기 버튼 -->
            <div class="action-section">
              <button 
                class="profile-btn"
                @click="navigateToProfile(selectedDesign.userId)"
              >
                <i class="fas fa-user"></i>
                프로필 보기
              </button>
              <button 
                class="inquiry-btn"
                @click="sendDesignInquiry(selectedDesign)"
              >
                <i class="fas fa-comments"></i>
                문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { collection, getDocs, query, orderBy, getDoc, doc, where, addDoc, serverTimestamp} from 'firebase/firestore';
import { db } from '@/firebase';
import defaultAvatar from '@/assets/default-avatar.png';
import { useRouter } from 'vue-router';
import { getAuth } from 'firebase/auth';
import { incrementPageView } from '@/utils/pageView';

export default {
  name: 'DesignGallery',
  setup() {
    const designs = ref([]);
    const shuffledDesigns = ref([]);
    const searchQuery = ref('');
    const selectedDesign = ref(null);
    const selectedGenre = ref('all');
    const selectedCity = ref('all');
    const selectedDistrict = ref('all');
    const router = useRouter();
    const loading = ref(true);

    // 추가: 무한 스크롤을 위한 변수
    const itemsPerLoad = ref(10);
    const loadedItems = ref(0);

    // Additional ref to store the scroll position
    const savedScrollPosition = ref(0);

    // 장르 목록 정의
    const genres = [
      "감성타투", "동양화", "레터링", "라인워크", "블랙워크", "블랙앤그레이",
      "수채화", "뉴스쿨", "미니타투", "올드스쿨", "이레즈미", "일러스트",
      "컬러타투", "커버업", "치카노", "터치업", "트라이벌","애니/만화"
    ];

    // 지역 목록 및 세부 지역 데이터 가져오기
    const places = [ '서울', '경기', '인천', '강원', '충남', '대전', '충북', '세종', '부산', '울산', '대구', '경북', '경남', '전남', '광주', '전북', '제주'];
    const placedetail = {
      서울: ['관악구', '강남구', '강동구', '광진구', '구로구', '금천구', '노원구', '도봉구', '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구', '성북구', '송파구', '양천구', '영등포구', '용산구', '은평구', '종로구', '중구', '중랑구'],
      경기: ['가평군', '고양시', '광명시', '광주시', '구리시', '김포시', '남양주시', '동두천시', '부천시', '성남시', '시흥시', '안산시', '안양시', '양주시', '여주시', '용인시', '이천시', '파주시', '평택시', '하남시', '화성시'],
      인천: ['계양구', '남동구', '동구', '미추홀구', '서구', '중구', '옹진군', '강화군'],
      부산: ['금정구', '동구', '동래구', '부산진구', '사하구', '서구', '수영구', '해운대구', '영도구', '중구', '기장군', '남구', '북구', '울주군', '연제구'],
      대구: ['달서구', '달성군', '동구', '남구', '북구', '서구', '중구', '수성구'],
      광주: ['동구', '남구', '북구', '서구', '광산구'],
      대전: ['대덕구', '동구', '서구', '유성구', '중구'],
      울산: ['남구', '동구', '중구', '북구', '울주군'],
      세종: ['세종시'],
      강원: ['강릉시', '고성군', '동해시', '삼척시', '속초시', '춘천시', '평창군', '영월군', '정선군', '철원군', '횡성군', '양구군', '인제군', '홍천군'],
      충남: ['당진시', '논산시', '보령시', '서산시', '천안시', '홍성군', '아산시', '예산군', '금산군', '서천군', '청양군', '태안군'],
      충북: ['괴산군', '단양군', '보은군', '영동군', '옥천군', '진천군', '청주시', '충주시', '음성군', '증평군'],
      경북: ['경산시', '경주시', '구미시', '문경시', '상주시', '안동시', '영덕군', '영천시', '울진군', '울릉군', '포항시', '칠곡군', '고령군', '성주군', '청도군', '영양군'],
      경남: ['거제시', '김해시', '밀양시', '사천시', '양산시', '진주시', '창원시', '통영시', '하동군', '함안군', '함양군', '산청군', '의령군', '거창군', '창녕군', '합천군'],
      전남: ['곡성군', '광양시', '나주시', '담양군', '목포시', '순천시', '여수시', '영광군', '완도군', '진도군', '해남군', '신안군', '영암군', '무안군', '함평군', '장흥군', '보성군', '강진군'],
      전북: ['군산시', '김제시', '남원시', '무주군', '부안군', '순창군', '완주군', '익산시', '전주시', '정읍시', '장수군', '임실군', '고창군', '진안군'],
      제주: ['제주시', '서귀포시'],
    };

    // Firebase Auth 인스턴스 가져오기
    const auth = getAuth();
    // 현재 사용자 ref 생성
    const currentUser = ref(auth.currentUser);

    // 필터링 로직 수정
    const filteredDesigns = computed(() => {
      let result = [...shuffledDesigns.value];
      
      // 검색어로 필터링
      if (searchQuery.value) {
        const search = searchQuery.value.toLowerCase();
        result = result.filter(design => {
          // 디자인 정보 검색
          const designMatch = 
            design.description?.toLowerCase().includes(search) ||          // 작품 설명
            design.mainGenre?.toLowerCase().includes(search) ||           // 메인 장르
            design.subGenres?.some(genre => 
              genre.toLowerCase().includes(search)
            ) ||                                                            // 서브 장르
            design.timeSpent?.toString().includes(search) ||             // 작업 시간
            design.price?.toString().includes(search);                   // 가격 정보

          // 아티스트 정보 검색
          const artistMatch = 
            design.artistNickname?.toLowerCase().includes(search) ||      // 닉네임
            design.detailAddress?.toLowerCase().includes(search) ||       // 주소
            design.instagramId?.toLowerCase().includes(search) ||         // 인스타그램
            design.phone?.includes(search) ||                            // 전화번호
            design.email?.toLowerCase().includes(search);                 // 이메일

          // 장르 검색
          const genreMatch = genres.some(genre => 
            genre.toLowerCase().includes(search) && 
            (design.mainGenre === genre || design.subGenres?.includes(genre))
          );
          
          // 지역 검색
          const locationMatch = places.some(location => 
            location.toLowerCase().includes(search) && 
            design.detailAddress?.includes(location)
          );

          return designMatch || artistMatch || genreMatch || locationMatch;
        });
      }
      
      // 장르와 지역 필터 동시 적용
      if (selectedGenre.value !== 'all' || selectedCity.value !== 'all' || selectedDistrict.value !== 'all') {
        result = result.filter(design => {
          // mainGenre 확인
          const genreMatch = selectedGenre.value === 'all' || 
            design.mainGenre === selectedGenre.value ||
            design.subGenres?.includes(selectedGenre.value);
          
          // 주소가 없는 경우 처리
          if (!design.detailAddress) return false;

          // 주소를 배열로 분리
          const addressParts = design.detailAddress.split(' ');
          
          // 시/도 확인
          const cityMatch = selectedCity.value === 'all' || 
            addressParts[0].includes(selectedCity.value);

          // 구/군 확인
          const districtMatch = selectedDistrict.value === 'all' || 
            addressParts.some(part => {
              // placedetail에서 현재 선택된 도시의 구/군 목록 가져오기
              const districts = placedetail[selectedCity.value] || [];
              // 현재 선택된 구/군이 실제로 해당 도시에 존재하는지 확인
              if (!districts.includes(selectedDistrict.value)) return false;
              
              // 주소에서 구/군 찾기
              return part.includes(selectedDistrict.value);
            });

          return genreMatch && cityMatch && districtMatch;
        });
      }
      
      return result.slice(0, loadedItems.value);
    });

    // 검색어 자동 완성을 위한 computed 속성 추가
    const searchSuggestions = computed(() => {
      if (!searchQuery.value) return [];
      
      const search = searchQuery.value.toLowerCase();
      const suggestions = new Set();
      
      // 장르 추천
      genres.forEach(genre => {
        if (genre.toLowerCase().includes(search)) {
          suggestions.add(genre);
        }
      });
      
      // 지역 추천
      places.forEach(location => {
        if (location.toLowerCase().includes(search)) {
          suggestions.add(location);
        }
      });
      
      return Array.from(suggestions).slice(0, 5); // 최대 5개까지 추천
    });

    const loadDesigns = async () => {
      try {
        loading.value = true;
        designs.value = []; // 데이터 초기화

        // 유저타입이 nottattooist가 아닌 경우에만 디자인을 불러오기
        const q = query(
          collection(db, "designs"),
          orderBy("uploadTime", "desc")
        );
        const querySnapshot = await getDocs(q);
        
        // Set을 사용하여 이미 처리된 ID를 추적
        const processedIds = new Set();

        // 모든 문서에 대한 Promise 배열 생성
        const promises = querySnapshot.docs.map(async (docSnapshot) => {
          const id = docSnapshot.id;
          
          // 이미 처리된 ID면 건너뛰기
          if (processedIds.has(id)) return null;
          
          const data = docSnapshot.data();
          
          // userId가 없는 경우 필터링
          if (!data.userId) {
            return null;
          }
          
          // 타투이스트 정보 가져오기
          const userDocRef = doc(db, "users", data.userId);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();

          // 유저타입이 nottattooist가 아닌 경우에만 반환
          if (userData?.userType !== 'nottattooist') {
            processedIds.add(id);
            return {
              id: id,
              ...data,
              artistNickname: userData?.nickname || '알 수 없음',
              artistProfileUrl: userData?.profileImageUrl || defaultAvatar,
              detailAddress: userData?.location?.address || '주소 정보가 없습니다.',
              instagramId: userData?.instagramId,
              phone: userData?.phone,
              email: userData?.email,
              subGenres: userData?.subGenres || [],
              price: data.price,
            };
          }
          return null;
        });

        // 모든 Promise를 병렬로 실행
        const results = await Promise.all(promises);
        
        // null이 아닌 결과만 필터링
        const filteredResults = results.filter(result => result !== null);
        
        // 초기 무작위 정렬 수행
        shuffledDesigns.value = [...filteredResults].sort(() => Math.random() - 0.5);
        designs.value = filteredResults;
        
        // 초기 로드된 아이템 수 설정
        loadedItems.value = Math.min(itemsPerLoad.value, designs.value.length);
      } catch (error) {
        console.error("디자인 로드 오류:", error);
      } finally {
        loading.value = false;
      }
    };

    // 추가: 더 많은 포트폴리오를 로드하는 함수
    const loadMore = () => {
      if (loadedItems.value >= designs.value.length) return;
      loadedItems.value = Math.min(loadedItems.value + itemsPerLoad.value, designs.value.length);
    };

    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      const now = new Date();
      const diff = now - date;
      
      if (diff < 24 * 60 * 60 * 1000) {
        const hours = Math.floor(diff / (60 * 60 * 1000));
        return `${hours}시간 전`;
      }
      
      return date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\./g, '').split(' ').join('.');
    };

    const showDetail = (design) => {
      // Save the current scroll position before opening the modal
      savedScrollPosition.value = window.scrollY;

      selectedDesign.value = design;
      window.history.pushState({ modal: true }, '');

      // Add popstate event listener to handle back button
      window.addEventListener('popstate', closeDetail);
    };

    const closeDetail = () => {
      selectedDesign.value = null;

      // Remove the popstate event listener
      window.removeEventListener('popstate', closeDetail);

      // Restore the saved scroll position
      window.scrollTo(0, savedScrollPosition.value);
    };

    const navigateToProfile = (userId) => {
      router.push(`/tattooist/${userId}`);
    };

    const leftColumnItems = computed(() => {
      const filtered = filteredDesigns.value;
      return filtered.filter((_, index) => index % 2 === 0);
    });

    const rightColumnItems = computed(() => {
      const filtered = filteredDesigns.value;
      return filtered.filter((_, index) => index % 2 === 1);
    });

    const onImageLoad = (event) => {
      const img = event.target;
      const naturalRatio = img.naturalHeight / img.naturalWidth;
      const width = img.width;
      const height = width * naturalRatio;
      
      // 이미지 높이에 맞게 컨테이너 조정
      img.parentElement.style.height = `${height}px`;
    };

    const sendDesignInquiry = async (design) => {
      try {
        // 로그인 체크
        if (!currentUser.value) {
          alert('로그인이 필요한 서비스입니다.');
          router.push('/tattooauth');
          return;
        }

        // 자기 자신과의 채팅 방지
        if (currentUser.value.uid === design.userId) {
          alert('자신과는 채팅할 수 없습니다.');
          return;
        }

        // 1. 채팅방 생성 또는 기존 채팅방 찾기
        const chatRoomsRef = collection(db, 'chatRooms');
        const q = query(
          chatRoomsRef,
          where('participants', 'array-contains', currentUser.value.uid),
        );
        const querySnapshot = await getDocs(q);
        
        let roomId;
        let existingRoom = querySnapshot.docs.find(doc => 
          doc.data().participants.includes(design.userId)
        );

        if (existingRoom) {
          roomId = existingRoom.id;
        } else {
          // 새 채팅방 생성
          const newRoomRef = await addDoc(chatRoomsRef, {
            participants: [currentUser.value.uid, design.userId],
            createdAt: serverTimestamp(),
            lastMessage: {
              content: '새로운 대화가 시작되었습니다.',
              timestamp: serverTimestamp()
            }
          });
          roomId = newRoomRef.id;
        }

        // 2. 디자인 이미지를 메시지로 전송
        const messagesRef = collection(db, 'chatRooms', roomId, 'messages');
        await addDoc(messagesRef, {
          type: 'image',
          images: [design.imageUrl],
          content: '디자인 작품에 대해 문의드립니다.',
          senderId: currentUser.value.uid,
          timestamp: serverTimestamp()
        });

        // 3. 채팅방으로 이동
        router.push(`/chat/${roomId}`);
        closeDetail();

      } catch (error) {
        console.error('문의하기 실패:', error);
        alert('문의하기에 실패했습니다. 다시 시도해주세요.');
      }
    };

    // Auth 상태 변경 감지
    onMounted(async () => {
      auth.onAuthStateChanged((user) => {
        currentUser.value = user;
      });

      loadDesigns();

      // 무한 스크롤을 위한 스크롤 이벤트 리스너 추가
      window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !loading.value) {
          loadMore();
        }
      });

      // Handle popstate for modal closure
      window.addEventListener('popstate', () => {
        if (selectedDesign.value) {
          closeDetail();
        }
      });

      // 페이지 뷰 증가
      try {
        await incrementPageView('DesignGallery');
      } catch (error) {
        console.error('페이지뷰 업데이트 실패:', error);
      }
    });

    const isFilterOpen = ref(false);

    const resetFilters = () => {
      selectedCity.value = 'all';
      selectedDistrict.value = 'all';
      selectedGenre.value = 'all';
      searchQuery.value = '';
    };

    // watch 함수를 수정
    const isLocationChange = ref(false);

    watch(selectedCity, () => {
      if (!isLocationChange.value) {
        selectedDistrict.value = 'all';
      }
      isLocationChange.value = false;
    });

    // getCurrentLocation 함수 수정
    const getCurrentLocation = () => {
      if (!navigator.geolocation) {
        alert('위치 정보가 지원되지 않습니다.');
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          try {
            const { latitude, longitude } = position.coords;
            
            naver.maps.Service.reverseGeocode({
              location: new naver.maps.LatLng(latitude, longitude),
            }, (status, response) => {
              if (status !== naver.maps.Service.Status.OK) {
                alert('현재 위치를 가져오는데 실패했습니다.');
                return;
              }

              const result = response.result;
              const items = result.items[0];
              
              // 시/도 추출
              let area1 = items.addrdetail.sido;
              // 구/군 추출
              let area2 = items.addrdetail.sigugun;

              // 시/도 이름 정제
              area1 = area1.replace(/특별시|광역시|특별자치시|특별자치도|도/, '');
              
              // 구/군 이름 정제 (시/구 처리)
              if (area2) {
                if (area2.includes(' ')) {
                  area2 = area2.split(' ')[0];
                }
                area2 = area2.replace(/시$|구$/, '');
                // placedetail에서 일치하는 구/군 찾기
                const matchedDistrict = placedetail[area1]?.find(district => 
                  district.startsWith(area2) || district.replace(/시|구/, '') === area2
                );
                if (matchedDistrict) {
                  area2 = matchedDistrict;
                }
              }

              // 시/도와 구/군을 동시에 설정
              if (places.includes(area1)) {
                isLocationChange.value = true;  // watch 트리거 방지
                selectedCity.value = area1;
                
                if (area2 && placedetail[area1]?.includes(area2)) {
                  selectedDistrict.value = area2;
                } else {
                  selectedDistrict.value = 'all';
                }
              } else {
                console.error('일치하는 시/도를 찾을 수 없습니다:', area1);
              }
            });
          } catch (error) {
            console.error('위치 변환 실패:', error);
            alert('현재 위치를 가져오는데 실패했습니다.');
          }
        },
        (error) => {
          console.error('위치 정보 가져오기 실패:', error);
          alert('위치 정보를 가져올 수 없습니다.');
        }
      );
    };

    return {
      designs,
      searchQuery,
      selectedGenre,
      genres,
      filteredDesigns,
      selectedDesign,
      defaultAvatar,
      loading,
      formatDate,
      showDetail,
      closeDetail,
      navigateToProfile,
      leftColumnItems,
      rightColumnItems,
      onImageLoad,
      selectedCity,
      places,
      searchSuggestions,
      loadedItems,
      loadMore,
      sendDesignInquiry,
      currentUser,
      savedScrollPosition,
      isFilterOpen,
      resetFilters,
      selectedDistrict,
      placedetail,
      getCurrentLocation,
    };
  }
}
</script>

<style scoped>
.gallery-container {
  margin: 0 auto;
  margin-top: 70px;
}

.masonry-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 24px;
}

.masonry-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.masonry-item {
  width: 100%;
  break-inside: avoid;
  cursor: pointer;
}

.portfolio-image {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background: #f5f5f5;
}

.portfolio-image img {
  width: 100%;
  height: auto;
  display: block;
}

.filter-section {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 12px;
  background: white;
  margin-bottom: 10px;
}

.search-bar input {
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
}

.search-bar i {
  color: #666;
}

.filter-toggle {
  user-select: none;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.filter-buttons {
  display: flex;
  gap: 8px;
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  font-size: 14px;
  cursor: pointer;
}

.filter-button:hover {
  background: #f8f9fa;
}

.filters {
  background: white;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 0;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filters-open {
  padding: 0 16px 16px;
  max-height: 500px;
  opacity: 1;
}

.filter-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.filter-group {
  display: flex;
  gap: 12px;
}

.filter-select {
  flex: 1;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 12px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M6 8L1 3h10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  transition: all 0.2s ease;
}

.filter-select:focus {
  border-color: #666;
  outline: none;
}

/* 기존 스타일과 충돌할 수 있는 부분 제거 */
.genre-filter,
.location-filter {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 0;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.modal-body {
  display: flex;
  height: auto;
  width: 99%;
}

.modal-image-container {
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-info {
  width: 400px;
  background: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.artist-section {
  padding: 10px;
  border-bottom: 1px solid #eee;
  background-color: #fefefe;
}

.artist-profile {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.2s;
}

.artist-avatar {
  width: 50px;
  height: 50px;
  border-radius: 25%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.artist-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.artist-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.artist-role {
  font-size: 0.9rem;
  color: #666;
}

.work-info {
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.info-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-group label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.time-info {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
}

.description p {
  margin: 0;
  line-height: 1.6;
  color: #333;
}

.action-section {
  display: flex;
  gap: 10px;
  margin-top: 16px;
}

.profile-btn,
.inquiry-btn {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.profile-btn {
  background: #f5f5f5;
  color: #333;
}

.inquiry-btn {
  background: #1a1a1a;
  color: white;
}

@media (max-width: 768px) {
  .modal-body {
    flex-direction: column;
  }

  .modal-info {
    width: 100%;
    height: 60%;
    overflow-y: auto;
  }
}

/* 지역 필터 스타일 추가 */
.location-filter {
  margin-bottom: 8px;
}

.location-info {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
}

.location-info i {
  color: #666;
}

/* 로딩 스피너 스타일 */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 기존 스타일에 추가 */
.location-filters {
  display: flex;
  gap: 8px;
}

.city-select,
.district-select {
  flex: 1;
}

/* 모달 진입/진출 애니메이션 */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.modal-enter-to,
.modal-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
  
  