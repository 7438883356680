<template>
  <div class="signup-container">
    <div class="signup-card">
      <h1 class="title">회원가입</h1>
      <p class="subtitle">원하는 회원가입 유형을 선택해주세요</p>
      
      <div class="user-type-grid">
        <div 
          class="user-type-card" 
          @click="goToUserSignup"
          :class="{ 'selected': selectedType === 'user' }"
          @mouseover="selectedType = 'user'"
        >
          <div class="icon-wrapper">
            <i class="fas fa-user icon"></i>
          </div>
          <h2>일반 사용자</h2>
          <p>나만의 타투를 찾고 예약하세요</p>
        </div>
        
        <div 
          class="user-type-card" 
          @click="goToArtistSignup"
          :class="{ 'selected': selectedType === 'artist' }"
          @mouseover="selectedType = 'artist'"
        >
          <div class="icon-wrapper">
            <i class="fas fa-palette icon"></i>
          </div>
          <h2>아티스트</h2>
          <p>나의 작품을 공유하고 고객을 만나보세요</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'TattooMapSignUp',
  setup() {
    const router = useRouter();
    const selectedType = ref(null);

    const goToUserSignup = () => {
      router.push('/signupUser');
    };

    const goToArtistSignup = () => {
      router.push('/signupArtist');
    };

    return {
      selectedType,
      goToUserSignup,
      goToArtistSignup
    };
  }
}
</script>

<style scoped>
.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  min-height: 95vh;
  width: 100%;
}

.signup-card {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  padding: 20px;
  text-align: center;
  width: 85%;
  transition: all 0.3s ease;
  margin-top: 50px;
}

.title {
  font-size: 2.5rem;
  color: #212121;
  margin-bottom: 10px;
  font-weight: 700;
}

h1.title {
  margin: 0;
}

.subtitle {
  color: #616161;
  margin-bottom: 30px;
  font-size: 1.1rem;
}

.user-type-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.user-type-card {
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  padding: 20px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon-wrapper {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.icon {
  font-size: 30px;
  color: #424242;
  stroke-width: 1.5;
}

.user-type-card h2 {
  margin: 15px 0 10px;
  font-size: 1.3rem;
  color: #212121;
}

.user-type-card p {
  color: #757575;
  font-size: 0.9rem;
  margin: 0;
}
</style>