import { saveRequest } from './server/analyticsDB.js';

async function sendAnalytics(data) {
    try {
        const response = await fetch('https://www.google-analytics.com/g/collect', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: data,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    } catch (error) {
        console.warn('Analytics 전송 실패, 큐에 저장:', error);
        await saveRequest({
            url: 'https://www.google-analytics.com/g/collect',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: data,
        });
        // 서비스 워커에 동기화 요청 보내기
        if ('serviceWorker' in navigator && 'SyncManager' in window) {
            const registration = await navigator.serviceWorker.ready;
            try {
                await registration.sync.register('sync-analytics');
            } catch (err) {
                console.error('Sync 등록 실패:', err);
            }
        }
    }
}

// 예제 사용법
const analyticsData = 'v=2&tid=G-PJYG13CDGZ&cid=maoacmj-10773133n-7ee3e3f997184734dbe6471e7e712555&sid=1737106688258&sr=390x764&dl=http%3A%2F%2Flocalhost%3A8080%2Fhome&dr=&dt=tattoomap&ul=ko-KR&sct=1&seg=1&_et=100&_p=438668036&_s=2'; // 실제 데이터로 대체
sendAnalytics(analyticsData); 