<template>
  <div class="map-container">
    <!-- 필터 섹션 -->
    <div class="filter-section">
      <div class="filter-container">
        <div class="filter-genre" @click="toggleGenreFilter">
          <p>{{ selectedGenre || '장르 필터' }}</p>
          <span class="material-icons arrow">{{ isGenreOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</span>
        </div>
        <!-- 장르 드롭다운 메뉴 -->
        <div v-if="isGenreOpen" class="filter-dropdown genre-dropdown">
          <div class="dropdown-item" @click="filterByGenre(null)">
            전체
          </div>
          <div 
            v-for="genre in genres" 
            :key="genre" 
            class="dropdown-item"
            @click="filterByGenre(genre)"
          >
            {{ genre }}
          </div>
        </div>
      </div>
      <div class="filter-info" v-if="selectedGenre">
        <p>
          <span class="highlight">{{ filteredArtists.length }}명</span>의 타투이스트가 
          <span class="highlight">{{ selectedGenre }}</span> 작업을 합니다.
        </p>
      </div>
    </div>

    <!-- 지도 영역 -->
    <div class="map-wrapper">
      <div v-if="!isMapLoaded" class="loading-map">
        지도를 불러오는 중...
      </div>
      <div id="map" class="map-area"></div>
      <!-- 현재 위치 버튼 -->
      <button 
        v-if="isMapLoaded"
        @click="moveToCurrentLocation" 
        class="current-location-button"
      >
        <i class="fas fa-crosshairs"></i>
      </button>
    </div>
    
    <div class="location-info">
      <p class="small-text">정확한 위치는 타투이스트에게 문의해주세요.</p>
    </div>

    <!-- 타투이스트 목록 -->
    <div class="artists-list">
      <h3>현재 지도에 표시된 타투이스트</h3>
      <div class="artists-grid">
        <div 
          v-for="artist in visibleArtists" 
          :key="artist.id" 
          class="artist-card"
          @click="navigateToProfile(artist.id)"
        >
          <img :src="artist.profileImageUrl || defaultAvatar" :alt="artist.nickname" class="artist-image">
          <div class="artist-info">
            <h4>{{ artist.nickname }}</h4>
            <p class="genre">{{ artist.mainGenre }}</p>
            <p class="location">{{ artist.location.address.split(' ').slice(0,3).join(' ') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { db } from '@/firebase';
import { collection, getDocs} from 'firebase/firestore';
import { useRouter } from 'vue-router';
import defaultAvatar from '@/assets/default-avatar.png';
import { incrementPageView } from '@/utils/pageView';

export default {
  name: 'TattooMap',
  setup() {
    const map = ref(null);
    const markers = ref([]);
    const artists = ref([]);
    const visibleArtists = ref([]);
    const selectedGenre = ref(null);
    const router = useRouter();
    const isMapLoaded = ref(false);
    const isGenreOpen = ref(false);
    const isFilterOpen = ref(false);

    const genres = [
      "감성타투", "동양화", "레터링", "라인워크", "블랙워크", "블랙앤그레이",
      "수채화", "뉴스쿨", "미니타투", "올드스쿨", "이레즈미", "일러스트",
      "컬러타투", "커버업", "치카노", "터치업", "트라이벌", "애니/만화"
    ];

    // 필터링된 타투이스트 계산
    const filteredArtists = computed(() => {
      if (!selectedGenre.value) return artists.value;
      
      return artists.value.filter(artist => 
        artist.mainGenre === selectedGenre.value || 
        (artist.subGenres && artist.subGenres.includes(selectedGenre.value))
      );
    });

    // 주소를 동까지만 표시하는 함수 추가
    const formatAddress = (address) => {
      if (!address) return '';
      return address.split(' ').slice(0, 3).join(' '); // 시/구/동 까지만 표시
    };

    // 마커 생성 함수 수정
    const createMarker = (artist) => {
      if (!artist.location?.latitude || !artist.location?.longitude) {
        console.warn('위치 정보 없음:', artist.nickname);
        return null;
      }

      const isMainGenre = artist.mainGenre === selectedGenre.value;
      const isSubGenre = artist.subGenres?.includes(selectedGenre.value);
      
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(
          artist.location.latitude,
          artist.location.longitude
        ),
        map: map.value,
        icon: {
          content: `
            <div class="custom-marker ${isMainGenre ? 'main-genre' : isSubGenre ? 'sub-genre' : ''}">
              <img src="${artist.profileImageUrl || defaultAvatar}" alt="${artist.nickname}">
            </div>
          `,
          anchor: new naver.maps.Point(20, 20)
        }
      });

      let currentInfoWindow = null;

      // 마커 클릭 이벤트
      naver.maps.Event.addListener(marker, 'click', () => {
        // 이전 인포윈도우가 있다면 닫기
        if (currentInfoWindow) {
          currentInfoWindow.close();
        }

        const infoWindow = new naver.maps.InfoWindow({
          content: `
            <div class="info-window">
              <div class="info-header">
                <h3>${artist.nickname}</h3>
                <button class="close-button" onclick="this.parentElement.parentElement.parentElement.parentElement.style.display='none'">
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="info-content">
                <div class="artist-profile">
                  <img src="${artist.profileImageUrl || defaultAvatar}" alt="${artist.nickname}" class="artist-thumbnail">
                  <div class="artist-details">
                    <p class="genre-tag">${artist.mainGenre}</p>
                    <p class="address">${formatAddress(artist.location.address)}</p>
                  </div>
                </div>
                <button class="view-profile-button" onclick="window.location.href='/tattooist/${artist.id}'">
                  프로필 보기
                </button>
              </div>
            </div>
          `,
          borderColor: "transparent",
          backgroundColor: "transparent",
          borderWidth: 0,
          disableAnchor: true,
          pixelOffset: new naver.maps.Point(0, -10)
        });

        infoWindow.open(map.value, marker);
        currentInfoWindow = infoWindow;
      });

      return marker;
    };

    // 지도 범위 내 타투이스트 필터링
    const updateVisibleArtists = () => {
      if (!map.value) return;
      
      const bounds = map.value.getBounds();
      visibleArtists.value = filteredArtists.value.filter(artist => {
        if (!artist.location?.latitude || !artist.location?.longitude) return false;
        
        const position = new naver.maps.LatLng(
          artist.location.latitude,
          artist.location.longitude
        );
        return bounds.hasLatLng(position);
      });
    };

    // 장르별 필터링
    const filterByGenre = (genre) => {
      selectedGenre.value = genre;
      isGenreOpen.value = false;
      
      // 기존 마커 제거
      markers.value.forEach(marker => marker.setMap(null));
      
      // 새 마커 생성
      markers.value = filteredArtists.value.map(artist => createMarker(artist));
      
      updateVisibleArtists();
    };

    // 프로필 페이지로 이동
    const navigateToProfile = (artistId) => {
      router.push(`/tattooist/${artistId}`);
    };

    // 타투이스트 데이터 로드
    const loadArtists = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        artists.value = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(user => 
            user.userType === 'tattooist' && 
            user.location && 
            user.location.latitude && 
            user.location.longitude
          );

        // 초기 마커 생성
        markers.value = artists.value.map(artist => {
          return createMarker(artist);
        });
        
        updateVisibleArtists();
      } catch (error) {
        console.error('타투이스트 데이터 로드 실패:', error);
      }
    };

    // 현재 위치로 이동하는 함수
    const moveToCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const currentLocation = new naver.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            
            // 지도 이동
            map.value.setCenter(currentLocation);
            map.value.setZoom(14); // 적절한 줌 레벨로 설정
            
            // 현재 위치 마커 표시 (선택사항)
            new naver.maps.Marker({
              position: currentLocation,
              map: map.value,
              icon: {
                content: `
                  <div class="current-location-marker">
                    <div class="pulse"></div>
                  </div>
                `,
                anchor: new naver.maps.Point(15, 15)
              }
            });
          },
          (error) => {
            console.error('위치 정보를 가져오는데 실패했습니다:', error);
            alert('위치 정보를 가져오는데 실패했습니다. 위치 권한을 확인해주세요.');
          }
        );
      } else {
        alert('이 브라우저에는 위치 정보를 사용할 수 없습니다.');
      }
    };

    const toggleGenreFilter = () => {
      isGenreOpen.value = !isGenreOpen.value;
    };

    const resetFilters = () => {
      selectedGenre.value = null;
      filterByGenre(null);
    };

    onMounted(async () => {
      // 네이버 지도 API가 로드될 때까지 대기
      const waitForNaverMaps = () => {
        return new Promise((resolve) => {
          if (window.naver && window.naver.maps) {
            resolve();
          } else {
            const checkNaverMaps = setInterval(() => {
              if (window.naver && window.naver.maps) {
                clearInterval(checkNaverMaps);
                resolve();
              }
            }, 100);
          }
        });
      };

      try {
        // 네이버 지도 API 로드 대기
        await waitForNaverMaps();
        isMapLoaded.value = true;

        // 네이버 지도 초기화 (서울 중심)
        map.value = new naver.maps.Map('map', {
          center: new naver.maps.LatLng(37.5665, 126.978),
          zoom: 12
        });

        // 지도 이동 이벤트
        naver.maps.Event.addListener(map.value, 'idle', updateVisibleArtists);

        await loadArtists();

        // 페이지 뷰 증가
        try {
          await incrementPageView('TattooMap');
        } catch (error) {
          console.error('페이지뷰 업데이트 실패:', error);
        }
      } catch (error) {
        console.error('지도 초기화 실패:', error);
      }
    });

    return {
      genres,
      selectedGenre,
      visibleArtists,
      filterByGenre,
      navigateToProfile,
      defaultAvatar,
      moveToCurrentLocation,
      filteredArtists,
      formatAddress,
      isMapLoaded,
      isGenreOpen,
      toggleGenreFilter,
      isFilterOpen,
      resetFilters,
    };
  }
};
</script>

<style scoped>
.map-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  margin-top: 60px;

}

.filter-section {
  background: white;
  padding: 5px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.genre-filters-container {
  position: relative;
  margin: 0 auto;
  max-width: 800px;
}

.genre-filters {
  display: flex;
  gap: 8px;
  padding: 5px;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Chrome, Safari 스크롤바 숨기기 */
.genre-filters::-webkit-scrollbar {
  display: none;
}

.genre-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 0.9rem;
  white-space: nowrap;
  flex-shrink: 0;
}

.genre-button.active {
  background: #333;
  color: white;
  border-color: #333;
}

.genre-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.map-area {
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
}

.artists-list {
  background: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.artists-list h3 {
  margin: 0;
}

.artists-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.artist-card {
  display: flex;
  gap: 15px;
  padding: 15px;
  border-radius: 12px;
  background: #f8f9fa;
  cursor: pointer;
  transition: all 0.3s;
}

.artist-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.artist-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.artist-info {
  flex: 1;
}

.artist-info h4 {
  margin: 0 0 5px 0;
  font-size: 1.1rem;
}

.artist-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.genre {
  color: #333;
  font-weight: 500;
}

/* 커스텀 마커 스타일 */
:deep(.custom-marker) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

:deep(.custom-marker.main-genre) {
  border-color: #ff4444;
  transform: scale(1.1);
}

:deep(.custom-marker.sub-genre) {
  border-color: #ff9900;
  transform: scale(1.05);
}

:deep(.custom-marker img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 인포윈도우 스타일 */
:deep(.info-window) {
  padding: 15px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  max-width: 300px;
}

:deep(.info-header) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

:deep(.info-header h3) {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

:deep(.close-button) {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  font-size: 1.1rem;
  transition: color 0.3s;
}

:deep(.close-button:hover) {
  color: #333;
}

:deep(.info-content) {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

:deep(.artist-profile) {
  display: flex;
  gap: 12px;
  align-items: center;
}

:deep(.artist-thumbnail) {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

:deep(.artist-details) {
  flex: 1;
}

:deep(.genre-tag) {
  display: inline-block;
  background: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9rem;
  color: #333;
  margin: 0 0 4px 0;
}

:deep(.address) {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

:deep(.view-profile-button) {
  width: 100%;
  padding: 8px;
  background: #333;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

:deep(.view-profile-button:hover) {
  background: #444;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 300px;
}

.current-location-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: all 0.3s ease;
}

.current-location-button:hover {
  background: #f8f9fa;
  transform: scale(1.05);
}

.current-location-button i {
  font-size: 18px;
  color: #333;
}

/* 현재 위치 마커 스타일 */
:deep(.current-location-marker) {
  position: relative;
  width: 30px;
  height: 30px;
}

:deep(.current-location-marker .pulse) {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #4285f4;
  border-radius: 50%;
  opacity: 0.7;
}

:deep(.current-location-marker .pulse::after) {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background: white;
  border: 2px solid #4285f4;
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  70% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

:deep(.current-location-marker .pulse::before) {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #4285f4;
  border-radius: 50%;
  animation: pulse 2s infinite;
  opacity: 0.4;
}

.filter-info {
  text-align: center;
  padding: 10px;
  color: #666;
  font-size: 0.9rem;
  border-top: 1px solid #eee;
  margin-top: 10px;
}

.highlight {
  color: #333;
  font-weight: bold;
}

.loading-map {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filter-container {
  position: relative;
  padding: 10px;
}

.filter-genre {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-genre:hover {
  border-color: #999;
}

.filter-genre p {
  margin: 0;
  font-size: 0.95rem;
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 12px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.material-icons {
  font-size: 20px;
  color: #666;
}

.arrow {
  transition: transform 0.3s ease;
}

.small-text {
  font-size: 12px; /* 작은 글씨 크기 */
  color: #666; /* 회색으로 텍스트 색상 변경 */
  margin: 0; /* 여백 제거 */
}
</style>
  