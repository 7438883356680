import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

// Firebase 설정 객체 (보안상의 이유로 실제 프로젝트에서는 환경 변수로 관리)
const firebaseConfig = {
    apiKey: "AIzaSyAdpdeB1s5S-ZS9BSyhD5AjTHgN1VvBoms",
    authDomain: "tattoomap3.firebaseapp.com",
    projectId: "tattoomap3",
    storageBucket: "tattoomap3.firebasestorage.app",
    messagingSenderId: "615573376679",
    appId: "1:615573376679:web:fdea18561ca5e89dd69582",
    measurementId: "G-SJL9CQKCGC"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);

// Messaging 인스턴스 가져오기
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };
