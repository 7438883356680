<template>
  <div class="my-page-container">
    <div class="my-page-wrap">
      <div class="header-section">
        <h2>마이페이지</h2>
        <button class="logout-button" @click="handleLogout">
          <span class="material-icons">logout</span>
          로그아웃
        </button>
      </div>
      <div class="user-info">
        <div class="profile-section">
          <div class="image-container">
            <img :src="profileImageUrl || require('@/assets/default-avatar.png')" alt="프로필 이미지" class="avatar" />
          </div>
          <div class="user-details">
            <h3>{{ nickname }}</h3>
            <p>{{ email }}</p>
            <button @click="navigateTo('TattooistEditPage')" class="edit-profile-button">
              <i class="fas fa-edit"></i> 프로필 수정
            </button>
          </div>
        </div>
      </div>
      <div v-if="userType === 'nottattooist'" class="unverified-notice">
        <div class="notice-content">
          <i class="fas fa-exclamation-circle"></i>
          <p>미인증 상태입니다.<br>인증 후 프로필이 노출됩니다.</p>
          <button class="verify-button" @click="changeUserType">
            인증하기
          </button>
        </div>
      </div>
      <div class="settings">
        <h3>프로필</h3>
        <div class="settings-grid">
          <div class="setting-item" @click="viewProfile">
            <i class="fas fa-user"></i>
            <span>내 프로필</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingNotice')">
            <i class="fas fa-bell"></i>
            <span>공지관리</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingMultiLink')">
            <i class="fas fa-link"></i>
            <span>멀티링크 관리</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingAwards')">
            <i class="fas fa-award"></i>
            <span>수상 뱃지 관리</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingWelcomeMessage')">
            <i class="fas fa-comment-dots"></i>
            <span>웰컴 메시지 관리</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingColor')">
            <i class="fas fa-brush"></i>
            <span>프로필 색상 관리</span>
          </div>
        </div>
      </div>
      <div class="settings">
        <h3>작업물</h3>
        <div class="settings-grid">
          <div class="setting-item" @click="navigateTo('PortfolioManager')">
            <i class="fas fa-images"></i>
            <span>포트폴리오 관리</span>
          </div>
          <div class="setting-item" @click="navigateTo('DesignManager')">
            <i class="fas fa-palette"></i>
            <span>디자인 관리</span>
          </div>
        </div>
      </div>
      
      <div class="settings">
        <h3>기타</h3>
        <div class="settings-grid">
          <div class="setting-item" @click="navigateTo('ScheduleManager')">
            <i class="fas fa-calendar"></i>
            <span>일정관리</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingAccount')">
            <i class="fas fa-wallet"></i>
            <span>계좌관리</span>
          </div>
          <div class="setting-item" @click="navigateTo('PageStats')">
            <i class="fas fa-chart-line"></i>
            <span>통계관리</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { db } from "@/firebase";
import { doc, getDoc, updateDoc, query, collection, getDocs, where } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from 'vue-router';

export default {
  name: "MyPage",
  setup() {
    const email = ref("");
    const nickname = ref("");
    const profileImageUrl = ref("");
    const userType = ref("");
    const userId = ref("");
    const auth = getAuth();
    const router = useRouter();

    const loadUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        userId.value = user.uid;
        const userDoc = doc(db, "users", userId.value);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          email.value = userData.email || "";
          nickname.value = userData.nickname || "";
          profileImageUrl.value = userData.profileImageUrl || "";
          userType.value = userData.userType || "";
        }
      } else {
        router.push('/TattooAuth');
      }
    };

    const navigateTo = (routeName) => {
      try {
        if (routeName === 'ScheduleManager') {
          router.push('/schedule-manager');
        } else if (routeName) {
          router.push({ name: routeName });
        }
      } catch (error) {
        console.error('Navigation error:', error);
      }
    };

    const viewProfile = () => {
      const user = auth.currentUser;
      if (user) {
        router.push({
          name: 'TattooistProfile',
          params: { id: user.uid }
        });
      }
    };

    const changeUserType = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userDoc);
        
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          
          // 포트폴리오 개수 확인
          const portfoliosQuery = query(
            collection(db, "portfolios"), 
            where("userId", "==", user.uid)
          );
          const portfoliosSnapshot = await getDocs(portfoliosQuery);
          const portfolioCount = portfoliosSnapshot.size;

          if (userData.introduce && userData.mainGenre && userData.subGenres && userData.location && portfolioCount >= 3) {
            try {
              await updateDoc(userDoc, {
                userType: 'tattooist'
              });
              alert('타투이스트로 전환되었습니다.');
              loadUserData(); // 사용자 데이터 새로고침
            } catch (error) {
              console.error('타입 변경 실패:', error);
              alert('타입 변경에 실패했습니다.');
            }
          } else {
            if (portfolioCount < 3) {
              alert('타투이스트 인증을 위해서는 최소 3개 이상의 포트폴리오가 필요합니다.');
              router.push('/portfoliomanager');
            } else {
              alert('인증을 위해 먼저 프로필을 완성해주세요.');
              router.push('/tattooisteditpage');
            }
          }
        }
      }
    };

    const handleLogout = async () => {
      try {
        await signOut(auth);
        alert('로그아웃되었습니다');
        router.push('/');
      } catch (error) {
        alert('로그아웃 실패: ' + error.message);
      }
    };

    onMounted(() => {
      loadUserData();
    });

    return {
      email,
      nickname,
      profileImageUrl,
      userType,
      navigateTo,
      viewProfile,
      changeUserType,
      handleLogout
    };
  }
};
</script>

<style scoped>

.my-page-container {
 margin-top: 80px;
 position: absolute;
 left: 0;
 top: 0;
 width: 100%;
}

.my-page-wrap {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.profile-section {
 background: white;
 border-radius: 16px;
 padding: 15px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 display: flex;
 align-items: center;
 gap: 32px;
 margin: 0;
 width: 100%;
 box-sizing: border-box;
}

.image-container {
 position: relative;
}

.avatar {
 width: 120px;
 height: 120px;
 border-radius: 50%;
 object-fit: cover;
 border: 4px solid #fff;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 transition: transform 0.3s ease;
}

.user-details {
 display: flex;
 flex-direction: column;
 gap: 8px;
}

.user-details h3 {
 font-size: 28px;
 font-weight: 600;
 color: #2c3e50;
 margin: 0;
}

.user-details p {
 color: #6c757d;
 font-size: 16px;
 margin: 0;
}

.edit-profile-button {
 background: #333;
 color: white;
 border: none;
 padding: 12px;
 border-radius: 12px;
 font-weight: 500;
 cursor: pointer;
}

.unverified-notice {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 16px;
  padding: 20px;
  margin: 20px 0;
}

.notice-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.notice-content i {
  color: #856404;
  font-size: 24px;
}

.notice-content p {
  color: #856404;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.verify-button {
  background: #856404;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
  margin-left: auto;
}

.settings {
 background: white;
 border-radius: 16px;
 padding: 15px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 margin-top: 20px;
}

.settings h3 {
 color: #2c3e50;
 font-size: 24px;
 margin: 0 0 20px 0;
}

.settings-grid {
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
 gap: 16px;
}

.setting-item {
 display: flex;
 align-items: center;
 padding: 16px;
 border-radius: 12px;
 cursor: pointer;
 transition: all 0.3s ease;
 background: #f8f9fa;
 gap: 10px;
}

.setting-item i {
 display: flex;
 justify-content: center;
 width: 20px;
 font-size: 19px;
 color: #333;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-section h2 {
  margin: 0;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.logout-button .material-icons {
  font-size: 14px;
}

</style> 