import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/MainPage.vue'; //메인
import TattooMapAuth from '@/components/auth/TattooMapAuth.vue'; //로그인
import TattooMapSignUp from '@/components/auth/TattooMapSignUp.vue'; //회원가입
import SignUpUser from '@/components/auth/SignUpUser.vue'; //유저 회원가입
import SignUpArtist from '@/components/auth/SignUpArtist.vue'; //타투이스트 회원가입
import MainMyPage from '@/components/tattooistSetting/MyPage.vue'; //타투이스트 마이페이지
import GuestMyPage from '@/components/guestSetting/guestMyPage.vue'; //게스트 마이페이지
import TattooistProfile from '@/components/tattooistSetting/TattooistProfile.vue'; //타투이스트 프로필
import TattooistEditPage from '@/components/tattooistSetting/tattooistEditPage.vue'; //타투이스트 프로필 수정
import PortfolioManager from '@/components/tattooistSetting/PortfolioManager.vue'; //포트폴리오 관리
import DesignManager from '@/components/tattooistSetting/DesignManager.vue'; //디자인 관리
import TattooMap from '@/components/TattooMap.vue'; //타투맵
import TattooGallery from '@/components/TattooGallery.vue'; //타투갤러리
import TattooMapFeed from '@/components/TattooMapFeed.vue'; //타투피드
import TattooRequestTattooist from '@/components/request/TattooRequestTattooist.vue'; //타투이스트 의뢰 요청 확인
import OpenRequestList from '@/components/request/OpenRequestList.vue'; //타투이스트가 공개 의뢰 확인하는 곳
import TattooRequest from '@/components/request/TattooRequest.vue'; //유저 > 타투이스트 의뢰 요청
import GuestTattooRequest from '@/components/request/GuestTattooRequest.vue'; //공개 의뢰 작성
import GuestRequestList from '@/components/request/GuestRequestList.vue'; //유저 의뢰 요청 확인
import GuestRequestDetail from '@/components/request/GuestRequestDetail.vue'; //의뢰 상세
import SettingNotice from '@/components/tattooistSetting/SettingNotice.vue'; //타투이스트 공지사항 관리
import SettingMultiLink from '@/components/tattooistSetting/SettingMultiLink.vue'; //타투이스트 링크 관리
import SettingAccount from '@/components/tattooistSetting/SettingAccount.vue'; //타투이스트 계좌 관리
import SettingWelcomeMessage from '@/components/tattooistSetting/SettingWelcomeMessage.vue'; //타투이스트 웰컴 메시지 관리
import SettingAwards from '@/components/tattooistSetting/SettingAwards.vue'; //타투이스트 수상 뱃지 관리
import ChatList from '@/components/chat/ChatList.vue';
import ChatRoom from '@/components/chat/ChatRoom.vue';
import ScheduleManager from '@/components/tattooistSetting/ScheduleManager.vue';
import DesignGallery from '@/components/DesignGallery.vue';
import GuestFavorite from '@/components/guestSetting/GuestFavorite.vue';
import CreateQuotation from '@/components/request/CreateQuotation.vue';
import Banner1 from '@/components/Banner1Go.vue'
import SettingColor from '@/components/tattooistSetting/SettingColor.vue';
import ConfirmedReservations from '@/components/chat/ConfirmedReservations.vue'
import PageStats from '@/components/PageStats.vue'
import AdminTattooMap from '@/components/admintattoomap.vue'; // 관리자 페이지

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', name:'MainPage', component: MainPage },
  { path: '/tattoomap', name: 'TattooMap', component: TattooMap },
  { path: '/tattoogallery', name: 'TattooGallery', component: TattooGallery },
  { path: '/designgallery', name: 'DesignGallery', component: DesignGallery },
  { path: '/tattooauth', name: 'TattooAuth', component: TattooMapAuth },
  { path: '/tattoofeed', name: 'TattooFeed', component: TattooMapFeed },
  { path: '/guestmypage', name: 'guestMyPage', component: GuestMyPage },
  { path: '/guestfavorite', name: 'GuestFavorite', component: GuestFavorite },
  { path: '/signup', name: 'TattooMapSignUp', component: TattooMapSignUp },
  { path: '/signupuser', name: 'SignUpUser', component: SignUpUser },
  { path: '/signupartist', name: 'SignUpArtist', component: SignUpArtist },
  { path: '/portfoliomanager', name: 'PortfolioManager', component: PortfolioManager },
  { path: '/mypage', name: 'MyPage', component: MainMyPage }, 
  { path: '/tattooist/:id', name: 'TattooistProfile', component: TattooistProfile, props: true },
  { path: '/tattooisteditpage', name: 'TattooistEditPage', component: TattooistEditPage },
  { path: '/designmanager', name: 'DesignManager', component: DesignManager },
  { path: '/tattooist/requests', name: 'TattooRequestTattooist', component: TattooRequestTattooist, meta: { requiresAuth: true, requiresTattooist: true } },
  { path: '/guest-requests', name: 'GuestRequestList', component: GuestRequestList, meta: { requiresAuth: true } },
  { path: '/open-request', name: 'OpenRequest', component: GuestTattooRequest, meta: { requiresAuth: true, requiresGuest: true } },
  { path: '/open-request/:id', name: 'OpenRequestDetail', component: () => import('@/components/request/GuestRequestDetail.vue'), props: true, meta: { requiresAuth: true, requiresGuest: true } },
  { path: '/request/:type/:id', name: 'RequestDetail', component: GuestRequestDetail, meta: { requiresAuth: true } },
  { path: '/open-requests', name: 'OpenRequestList', component: OpenRequestList, meta: { requiresAuth: true, requiresTattooist: true } },
  { path: '/settingnotice', name: 'SettingNotice', component: SettingNotice },
  { path: '/settingmultilink', name: 'SettingMultiLink', component: SettingMultiLink },
  { path: '/settingaccount', name: 'SettingAccount', component: SettingAccount },
  { path: '/settingwelcomemessage', name: 'SettingWelcomeMessage', component: SettingWelcomeMessage },
  { path: '/settingawards', name: 'SettingAwards', component: SettingAwards },
  { path: '/chat', name: 'ChatList', component: ChatList, meta: { requiresAuth: true } },
  { path: '/chat/:id', name: 'ChatRoom', component: ChatRoom, meta: { requiresAuth: true } },
  { path: '/schedule-manager', name: 'ScheduleManager', component: ScheduleManager, meta: { requiresAuth: true, requiresTattooist: true } },
  { path: '/tattoo-request/:tattooistId', name: 'TattooRequest', component: TattooRequest, props: true, meta: { requiresAuth: true } },
  { path: '/request/:requestId', name: 'CreateQuotation', component: CreateQuotation, props: true, meta: { requiresAuth: true } },
  { path: '/banner1', name: 'Banner1', component: Banner1 },
  { path: '/settingcolor', name: 'SettingColor', component: SettingColor },
  {
    path: '/reservations',
    name: 'Reservations',
    component: ConfirmedReservations,
    meta: { requiresAuth: true }
  },
  {
    path: '/page-stats',
    name: 'PageStats',
    component: PageStats,
    meta: {
      requiresAuth: true,  // 인증이 필요한 페이지
      allowedUserTypes: ['tattooist']  // 타투이스트만 접근 가능
    }
  },
  {
    path: '/admin',
    name: 'AdminTattooMap',
    component: AdminTattooMap,
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    // 라우터 이동 시 맨 위로 스크롤
    if (to.hash) {
      // 만약 hash(#)가 있으면 해당 위치로 스크롤
      return { selector: to.hash };
    } else {
      // 기본적으로 페이지가 이동할 때 맨 위로 스크롤
      return { top: 0 };
    }
  },
});

// 네비게이션 가드 추가
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 인증 로직 추가 가능
    next();
  } else {
    next();
  }
});

export default router;