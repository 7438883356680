import { openDB } from 'idb';

export async function openAnalyticsDB() {
    return openDB('analytics-store', 1, {
        upgrade(db) {
            db.createObjectStore('requests', { keyPath: 'id', autoIncrement: true });
        },
    });
}

export async function saveRequest(request) {
    const db = await openAnalyticsDB();
    await db.add('requests', request);
}

export async function getAllRequests() {
    const db = await openAnalyticsDB();
    return db.getAll('requests');
}

export async function deleteRequest(id) {
    const db = await openAnalyticsDB();
    await db.delete('requests', id);
} 