<template>
  <div class="main-page">
    <section v-if="banners.length > 0 || showDefaultBanner" class="banner-section">
      <div class="banner-container">
        <div class="banner-slider" 
             :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
             @touchstart="handleBannerTouchStart"
             @touchmove="handleBannerTouchMove"
             @touchend="handleBannerTouchEnd">
          <div v-if="banners.length > 0" 
               class="banner-slide"
               @click="handleBannerClick(banners[banners.length - 1])">
            <img :src="banners[banners.length - 1].imageUrl" :alt="'배너'" class="banner-image">
          </div>
          
          <div v-if="showDefaultBanner" class="banner-slide">
            <img src="@/assets/banner0.png" alt="기본 배너" class="banner-image" />
          </div>
          <div v-for="(banner, index) in banners" 
               :key="index" 
               class="banner-slide"
               @click="handleBannerClick(banner)">
            <img :src="banner.imageUrl" :alt="`배너 ${index + 1}`" class="banner-image">
          </div>
          
          <div v-if="banners.length > 0" 
               class="banner-slide"
               @click="handleBannerClick(banners[0])">
            <img :src="banners[0].imageUrl" :alt="'배너'" class="banner-image">
          </div>
        </div>
      </div>
    </section>
    <section class="quick-menu">
      <div class="menu-map" @click="gotomenu('/TattooMap')">
        <span class="material-icons-outlined">map</span>
        <p>MAP</p>
      </div>
      <div class="menu-gallery" @click="gotomenu('/tattoogallery')">
        <span class="material-icons-outlined">photo_library</span>
        <p>TATTOO</p>
      </div>
      <div class="menu-feed" @click="gotomenu('/designgallery')">
        <span class="material-icons-outlined">palette</span>
        <p>DESIGN</p>
      </div>
      <div class="menu-feed" @click="handleRequestClick">
        <span class="material-icons-outlined">assignment</span>
        <p>REQUEST</p>
      </div>
    </section>
    <section class="artist">
      <div class="artist-filter">
        <div class="filter-place" @click="filterPlace">
          <span class="material-icons place">place</span>
          <p>{{ selectedDetail || selectedCity || '지역' }}</p>
          <span class="material-icons arrow">{{ isPlaceOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</span>
        </div>
        <div class="filter-genre" @click="filterGenre">
          <p>{{ selectedGenre || '장르' }}</p>
          <span class="material-icons arrow">{{ isGenreOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</span>
        </div>
      </div>
      <div class="filter-list">
        <ul v-if="isPlaceOpen" class="placelist">
          <li v-for="(place, index) in places" :key="index" @click="selectedPlace(place)"><a class="placename">{{ place }}</a></li>
        </ul>
      </div>
      <div class="filter-list">
        <ul v-if="selectedCity" class="citylist">
          <li v-for="(area, index) in placedetail[selectedCity]" :key="index" @click="selectedArea(area)"><a class="placename">{{ area }}</a></li>
        </ul>
      </div>
      <div class="filter-list">
        <ul v-if="isGenreOpen" class="genrelist">
          <li v-for="(genre, index) in genres" :key="index" @click="selecGenre(genre)"><a class="placename">{{ genre }}</a></li>
        </ul>
      </div>
      <div class="artists-section">
        <div v-if="loading" class="loading-container">
          <div class="loading-spinner"></div>
          <span>아티스트 정보를 불러오는 중...</span>
        </div>
        <div v-else class="artists-grid">
          <div v-for="artist in filteredArtists" :key="artist.id" class="card">
            <div class="card-header">
              <div 
                class="portfolio-slider" 
                ref="portfolioSliders"
                @touchstart="handleTouchStart($event, artist.id)"
                @touchmove="handleTouchMove($event, artist.id)"
                @touchend="handleTouchEnd($event, artist.id)"
              >
                <div 
                  v-for="(work, index) in artist.portfolios" 
                  :key="index"
                  :class="['portfolio-slide', getPortfolioClass(index, artist.id)]"
                  :style="{ backgroundImage: `url(${work.imageUrl})` }"
                  @click="handleSlideClick(index, artist.id)"
                ></div>
              </div>
            </div>
            <div class="card-body">
              <div class="profile" @click="gotomenu(`/tattooist/${artist.id}`)">
                <img class="profile-image" :src="artist.profileImageUrl" alt="프로필 이미지">
                <div class="profile-info">
                  <h3 >{{ artist.name }}</h3>
                  <p>{{ extractDong(artist.location) }}</p>
                </div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
              <div class="details">
                <span class="likes"><i class="fa-regular fa-heart"></i> {{ artist.favorites }}</span>
                <span class="price">시간당: {{ artist.hourlyRate === '비용문의' ? '문의' : formatPrice(artist.hourlyRate) }}</span>
              </div>
              <div class="tags">
                <span class="tag main-genre">{{ artist.tags[0] }}</span>
                <span v-for="tag in artist.tags.slice(1)" 
                      :key="tag" 
                      class="tag sub-genre">
                  {{ tag }}
                </span>
              </div>
              <div class="actions">
                <button class="like-button" @click="toggleLike(artist.id)">
                  <i :class="artist.isLiked ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"></i>
                </button>
                <button class="consult-button" @click="requestConsult(artist.id)">문의</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getStorage, ref as storageRef, listAll, getDownloadURL } from 'firebase/storage'
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { collection, getDocs, query, where, doc, deleteDoc, updateDoc, setDoc, getDoc, orderBy, limit } from 'firebase/firestore';
import { db } from '@/firebase';
import { getAuth } from 'firebase/auth';
import { incrementPageView } from '@/utils/pageView';
import { requestNotificationPermission } from '@/firebase';

export default {
  name: 'MainPage',
  data() {
    return {
      currentSlide: 1,
      banners: [],
      showDefaultBanner: true,
      isPlaceOpen: false,
      isGenreOpen: false,
      selectedCity: null,
      selectedDetail: null,
      selectedGenre: null,
      places: ['내위치', '전체', '서울', '경기', '인천', '강원', '충남', '대전', '충북', '세종', '부산', '울산', '대구', '경북', '경남', '전남', '광주', '전북', '제주'],
      placedetail: {
        서울: ['관악구', '강남구', '강동구', '광진구', '구로구', '금천구', '노원구', '도봉구', '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구', '성북구', '송파구', '양천구', '영등포구', '용산구', '은평구', '종로구', '중구', '중랑구'],
        경기: ['가평군', '고양시', '광명시', '광주시', '구리시', '김포시', '남양주시', '동두천시', '부천시', '성남시', '시흥시', '안산시', '안양시', '양주시', '여주시', '용인시', '이천시', '파주시', '평택시', '하남시', '화성시'],
        인천: ['계양구', '남동구', '동구', '미추홀구', '서구', '중구', '옹진군', '강화군'],
        부산: ['금정구', '동구', '동래구', '부산진구', '사하구', '서구', '수영구', '해운대구', '영도구', '중구', '기장군', '남구', '북구', '울주군', '연제구'],
        대구: ['달서구', '달성군', '동구', '남구', '북구', '서구', '중구', '수성구'],
        광주: ['동구', '남구', '북구', '서구', '광산구'],
        대전: ['대덕구', '동구', '서구', '유성구', '중구'],
        울산: ['남구', '동구', '중구', '북구', '울주군'],
        세종: ['세종시'],
        강원: ['강릉시', '고성군', '동해시', '삼척시', '속초시', '춘천시', '평창군', '영월군', '정선군', '철원군', '횡성군', '양구군', '인제군', '홍천군'],
        충남: ['당진시', '논산시', '보령시', '서산시', '천안시', '홍성군', '아산시', '예산군', '금산군', '서천군', '청양군', '태안군'],
        충북: ['괴산군', '단양군', '보은군', '영동군', '옥천군', '진천군', '청주시', '충주시', '음성군', '증평군'],
        경북: ['경산시', '경주시', '구미시', '문경시', '상주시', '안동시', '영덕군', '영천시', '울진군', '울릉군', '포항시', '칠곡군', '고령군', '성주군', '청도군', '영양군'],
        경남: ['거제시', '김해시', '밀양시', '사천시', '양산시', '진주시', '창원시', '통영시', '하동군', '함안군', '함양군', '산청군', '의령군', '거창군', '창녕군', '합천군'],
        전남: ['곡성군', '광양시', '나주시', '담양군', '목포시', '순천시', '여수시', '영광군', '완도군', '진도군', '해남군', '신안군', '영암군', '무안군', '함평군', '장흥군', '보성군', '강진군'],
        전북: ['군산시', '김제시', '남원시', '무주군', '부안군', '순창군', '완주군', '익산시', '전주시', '정읍시', '장수군', '임실군', '고창군', '진안군'],
        제주: ['제주시', '서귀포시'],
      },
      genres: ['전체', '감성타투', '동양화', '레터링', '라인워크', '블랙워크', '블랙앤그레이', '수채화', '뉴스쿨', '미니타투', '올드스쿨', '이레즈미', '일러스트', '컬러타투', '커버업', '치카노', '터치업', '트라이벌','애니/만화'],
      slidesPosition: {},
      isDragging: false,
      startX: 0,
      currentX: 0,
      currentSlideIndex: {},
      slideWidth: 300,
      currentSlides: {},
      touchData: {},
      bannerTouchStart: null,
      bannerTouchEnd: null,
      isTransitioning: false,
    };
  },
  mounted() {
    this.fetchBanners();
  },
  setup() {
    const router = useRouter();
    const auth = getAuth();
    
    const gotomenu = (route) => {
      router.push(route);
    };

    const artists = ref([]);
    const loading = ref(true);

    const itemsPerLoad = ref(10);
    const loadedItems = ref(0);

    const loadArtists = async () => {
      try {
        loading.value = true;
        // users 컬렉션에서 tattooist만 필터링
        const usersRef = collection(db, 'users');
        const tattooistQuery = query(usersRef, where('userType', '==', 'tattooist'));
        const tattooistSnapshot = await getDocs(tattooistQuery);
        
        const artistsData = [];
        const portfolioPromises = [];

        for (const doc of tattooistSnapshot.docs) {
          // 각 타투이스트의 포트폴리오 가져오기 위한 Promise 생성
          const portfoliosRef = collection(db, 'portfolios');
          const portfoliosQuery = query(
            portfoliosRef, 
            where('userId', '==', doc.id),
            orderBy('uploadTime', 'desc'), // 최신순으로 정렬
            limit(10) // 최대 10개로 제한
          );
          portfolioPromises.push(getDocs(portfoliosQuery).then(portfoliosSnapshot => {
            const portfolios = portfoliosSnapshot.docs.map(pdoc => ({
              id: pdoc.id,
              ...pdoc.data()
            }));

            // 사용자 데이터와 포트폴리오 결합
            const userData = doc.data();
            const tags = [userData.mainGenre, ...(userData.subGenres || [])];

            artistsData.push({
              id: doc.id,
              name: userData.nickname,
              location: userData.location.address || '위치 미지정',
              profileImageUrl: userData.profileImageUrl || '',
              favorites: userData.favorites || 0,
              hourlyRate: userData.hourlyRate || 0,
              tags: tags,
              portfolios, // 최대 10개로 제한된 포트폴리오
              isLiked: false
            });
          }));
        }

        // 모든 포트폴리오 데이터를 병렬로 가져오기
        await Promise.all(portfolioPromises);

        // 아티스트 데이터를 무작위로 정렬하여 공정한 초기 순서 보장
        artistsData.sort(() => Math.random() - 0.5);

        artists.value = artistsData;
        // 초기 로드된 아이템 수 설정
        loadedItems.value = Math.min(itemsPerLoad.value, artistsData.length);

        const user = auth.currentUser;
        if (user) {
          const userFavoritesRef = collection(db, 'users', user.uid, 'favorites');
          const userFavoritesSnapshot = await getDocs(userFavoritesRef);
          const favoriteArtistIds = userFavoritesSnapshot.docs.map(doc => doc.id);

          artistsData.forEach(artist => {
            artist.isLiked = favoriteArtistIds.includes(artist.id);
          });
        }
      } catch (error) {
        console.error('타투이스트 정보 로딩 실패:', error);
      } finally {
        loading.value = false;
      }
    };

    const extractDong = (location) => {
      const parts = location.split(' ');
      return parts.length > 2 ? `${parts[0]} ${parts[1]}` : location;
    };

    const formatPrice = (price) => {
      return `${price.toLocaleString()}원`;
    };

    const toggleLike = async (artistId) => {
      const user = auth.currentUser;
      if (!user) {
        // 로그인 창으로 이동
        router.push('/tattooauth');
        return;
      }

      const artist = artists.value.find(a => a.id === artistId);
      if (artist) {
        const userId = user.uid;
        const userFavoritesRef = collection(db, 'users', userId, 'favorites');
        const artistRef = doc(db, 'users', artistId); // users 컬렉션에서 아티스트 문서 참조

        try {
          const artistDoc = await getDoc(artistRef);
          if (!artistDoc.exists()) {
            console.error('아티스트 문서가 존재하지 않습니다.');
            return;
          }

          const currentFavorites = artistDoc.data().favorites || 0;

          if (artist.isLiked) {
            // 즐겨찾기 취소
            await deleteDoc(doc(userFavoritesRef, artistId));
            await updateDoc(artistRef, {
              favorites: currentFavorites - 1
            });
          } else {
            // 즐겨찾기 추가
            await setDoc(doc(userFavoritesRef, artistId), { artistId });
            await updateDoc(artistRef, {
              favorites: currentFavorites + 1
            });
          }

          artist.isLiked = !artist.isLiked;
        } catch (error) {
          console.error('즐겨찾기 처리 중 오류:', error);
        }
      }
    };

    const requestConsult = async (artistId) => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert('로그인이 필요한 서비스입니다.');
        router.push('/tattooauth');
        return;
      }

      // 자기 자신과의 채팅 방지
      if (currentUser.uid === artistId) {
        alert('자신과는 채팅할 수 없습니다.');
        return;
      }

      try {
        // 기존 채팅방 확인
        const chatRoomsRef = collection(db, 'chatRooms');
        const q = query(
          chatRoomsRef,
          where('participants', 'array-contains', currentUser.uid)
        );
        const querySnapshot = await getDocs(q);
        
        let existingChatRoom = null;
        querySnapshot.forEach((doc) => {
          const room = doc.data();
          if (room.participants.includes(artistId)) {
            existingChatRoom = { id: doc.id, ...room };
          }
        });

        if (existingChatRoom) {
          // 기존 채팅방으로 이동
          router.push(`/chat/${existingChatRoom.id}`);
        } else {
          // 새 채팅방 생성
          const newChatRoomRef = doc(collection(db, 'chatRooms'));
          await setDoc(newChatRoomRef, {
            participants: [currentUser.uid, artistId],
            createdAt: new Date(),
            lastMessage: null,
            lastMessageTime: null
          });
          router.push(`/chat/${newChatRoomRef.id}`);
        }
      } catch (error) {
        console.error('채팅방 생성/이동 중 오류:', error);
        alert('채팅 기능 이용 중 오류가 발생했습니다.');
      }
    };

    const loadMore = () => {
      if (loadedItems.value >= artists.value.length) return;
      loadedItems.value = Math.min(loadedItems.value + itemsPerLoad.value, artists.value.length);
    };

    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !loading.value) {
        loadMore();
      }
    });

    onMounted(async () => {
      loadArtists();
      
      // 페이지 뷰 증가
      try {
        await incrementPageView('MainPage');
      } catch (error) {
        console.error('페이지뷰 업데이트 실패:', error);
      }

      if (auth.currentUser) {
        // 로그인 상태일 때만 권한 요청
        setTimeout(() => {
          requestNotificationPermission();
        }, 1000); // 1초 후에 권한 요청
      }
    });

    const handleRequestClick = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        // 로그인되지 않은 경우
        router.push('/tattooauth');
        return;
      }

      try {
        // 사용자 정보 조회
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          console.error('사용자 정보를 찾을 수 없습니다.');
          return;
        }

        const userData = userDoc.data();
        // 사용자 타입에 따라 다른 페이지로 라우팅
        if (userData.userType === 'tattooist') {
          router.push('/open-requests');
        } else if (userData.userType === 'nottattooist') {
          alert('미인증 사용자는 이용할 수 없는 서비스입니다.');
          return;
        } else {
          router.push('/guest-requests');
        }
      } catch (error) {
        console.error('사용자 정보 조회 실패:', error);
      }
    };

    return {
      gotomenu,
      artists,
      loading,
      extractDong,
      formatPrice,
      toggleLike,
      requestConsult,
      loadedItems,
      loadMore,
      handleRequestClick,
    };
  },
  computed: {
    filteredArtists() {
      const filtered = this.artists.filter(artist => {
        const matchesCity = this.selectedCity ? artist.location.includes(this.selectedCity) : true;
        const matchesDetail = this.selectedDetail ? artist.location.includes(this.selectedDetail) : true;
        const matchesGenre = this.selectedGenre ? artist.tags.includes(this.selectedGenre) : true;
        return matchesCity && matchesDetail && matchesGenre;
      });
      return filtered.slice(0, this.loadedItems);
    }
  },
  methods: {
    async fetchBanners() {
      const cachedBanners = localStorage.getItem('banners');
      if (cachedBanners) {
        this.banners = JSON.parse(cachedBanners);
        this.showDefaultBanner = false;
        this.startSlideShow();
        return;
      }

      try {
        const Storage = getStorage();
        const bannerRef = storageRef(Storage, 'banners');
        
        // Firebase Storage와 Firestore의 데이터를 병렬로 가져오기
        const [storageResult, bannersSnapshot] = await Promise.all([
          listAll(bannerRef),
          getDocs(collection(db, 'banners'))
        ]);

        const bannerData = {};
        bannersSnapshot.forEach(doc => {
          const data = doc.data();
          bannerData[data.filename] = data.linkUrl;
        });

        const urls = await Promise.all(
          storageResult.items.map(async (item) => {
            const imageUrl = await getDownloadURL(item);
            const filename = item.name;
            return {
              imageUrl,
              linkUrl: bannerData[filename] || null
            };
          })
        );

        this.banners = urls.sort((a, b) => {
          const nameA = a.imageUrl.split('banners')[1];
          const nameB = b.imageUrl.split('banners')[1];
          return nameA.localeCompare(nameB);
        });

        // 캐싱
        localStorage.setItem('banners', JSON.stringify(this.banners));

        if (this.banners.length > 0) {
          this.showDefaultBanner = false;
          this.startSlideShow();
        }
      } catch (error) {
        console.error('배너 이미지 로드 실패:', error);
      }
    },

    startSlideShow() {
      if (this.banners.length > 1) {
        clearInterval(this.slideInterval);
        this.slideInterval = setInterval(() => {
          if (!this.isTransitioning) {
            this.currentSlide++;
            this.checkBoundary();
          }
        }, 3000);
      }
    },

    stopSlideshow() {
      clearInterval(this.slideInterval);
    },

    filterPlace() {
      this.isPlaceOpen = !this.isPlaceOpen;
      this.selectedCity = null;
      if (this.isPlaceOpen) {
        this.isGenreOpen = false;
      }
    },

    filterGenre() {
      this.isGenreOpen = !this.isGenreOpen;
      this.selectedCity = null;
      if (this.isGenreOpen) {
        this.isPlaceOpen = false;
      }
    },

    async selectedPlace(place) {
      if (place === '전체') {
        this.selectedCity = null;
        this.selectedDetail = null;
        this.isPlaceOpen = false;
        this.isGenreOpen = false;
      } else if (place === '내위치') {
        this.getCurrentLocation();
        this.isPlaceOpen = false;
        this.isGenreOpen = false;
      } else {
        this.selectedCity = place;
        this.selectedDetail = null;
        this.isPlaceOpen = false;
        this.isGenreOpen = false;
      }
    },

    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            console.log('Current Position:', latitude, longitude); // 위치 정보 확인
            this.reverseGeocode(latitude, longitude);
          },
          (error) => {
            console.error('위치 정보를 가져오는 데 실패했습니다:', error);
          }
        );
      } else {
        console.error('이 브라우저는 Geolocation을 지원하지 않습니다.');
      }
    },

    reverseGeocode(latitude, longitude) {
      naver.maps.Service.reverseGeocode({
        coords: new naver.maps.LatLng(latitude, longitude),
      }, (status, response) => {
        if (status === naver.maps.Service.Status.OK) {
          const address = response.v2.address;
          console.log('Reverse Geocode Address:', address); // 주소 정보 확인

          // '경기도 안산시 단원구 선부2동'에서 시와 구 추출
          const jibunAddress = address.jibunAddress || '';
          const parts = jibunAddress.split(' ');
          if (parts.length >= 3) {
            this.selectedDetail = parts[2]; // '단원구'
          } else {
            console.error('주소 형식이 예상과 다릅니다:', jibunAddress);
          }

          this.isPlaceOpen = false; // 필터 창 닫기
        } else {
          console.error('주소 변환 실패:', status);
        }
      });
    },

    selecGenre(genre) {
      if (genre === '전체') {
        this.selectedGenre = null;
      } else {
        this.selectedGenre = genre;
      }
      this.isGenreOpen = false;
    },

    selectedArea(area) {
      this.selectedDetail = area;
      this.selectedCity = null;
    },

    getPortfolioClass(index, artistId) {
      const currentIndex = this.currentSlides[artistId] || 0;
      const slideLength = this.artists.find(a => a.id === artistId)?.portfolios.length || 0;
      
      if (index === currentIndex) return 'current';
      if (index === (currentIndex + 1) % slideLength) return 'next';
      if (index === (currentIndex - 1 + slideLength) % slideLength) return 'prev';
      return '';
    },

    nextSlide(artistId) {
      const artist = this.artists.find(a => a.id === artistId);
      if (!artist) return;
      
      const slideLength = artist.portfolios.length;
      this.currentSlides[artistId] = ((this.currentSlides[artistId] || 0) + 1) % slideLength;
    },

    prevSlide(artistId) {
      const artist = this.artists.find(a => a.id === artistId);
      if (!artist) return;
      
      const slideLength = artist.portfolios.length;
      this.currentSlides[artistId] = ((this.currentSlides[artistId] || 0) - 1 + slideLength) % slideLength;
    },

    handleSlideClick(index, artistId) {
      const currentIndex = this.currentSlides[artistId] || 0;
      const slideLength = this.artists.find(a => a.id === artistId)?.portfolios.length || 0;
      
      // 이전 슬라이드 클릭
      if (index === (currentIndex - 1 + slideLength) % slideLength) {
        this.prevSlide(artistId);
      }
      // 다음 슬라이드 클릭
      else if (index === (currentIndex + 1) % slideLength) {
        this.nextSlide(artistId);
      }
    },

    /**
     * 터치 시작 이벤트 핸들러
     * @param {TouchEvent} event 
     * @param {string} artistId 
     */
    handleTouchStart(event, artistId) {
      const touch = event.touches[0];
      this.touchData[artistId] = {
        startX: touch.clientX,
        startY: touch.clientY,
        endX: 0,
        endY: 0,
      };
    },

    /**
     * 터치 이동 이벤트 핸들러
     * @param {TouchEvent} event 
     * @param {string} artistId 
     */
    handleTouchMove(event, artistId) {
      if (!this.touchData[artistId]) return;

      const touch = event.touches[0];
      this.touchData[artistId].endX = touch.clientX;
      this.touchData[artistId].endY = touch.clientY;
    },

    /**
     * 터치 종료 이벤트 핸들러
     * @param {TouchEvent} event 
     * @param {string} artistId 
     */
    handleTouchEnd(event, artistId) {
      if (!this.touchData[artistId]) return;

      const { startX, startY, endX, endY } = this.touchData[artistId];
      const diffX = endX - startX;
      const diffY = endY - startY;

      // 스와이프가 수평 방향이며 임계값을 초과하는지 확인
      if (Math.abs(diffX) > Math.abs(diffY) && Math.abs(diffX) > 50) {
        if (diffX > 0) {
          // 오른쪽으로 스와이프
          this.prevSlide(artistId);
        } else {
          // 왼쪽으로 스와이프
          this.nextSlide(artistId);
        }
      }

      // 아티스트별 터치 데이터 초기화
      this.touchData[artistId] = null;
    },

    handleBannerClick(banner) {
      if (banner.linkUrl) {
        if (banner.linkUrl.startsWith('http')) {
          window.open(banner.linkUrl, '_blank');
        } else {
          this.$router.push(banner.linkUrl);
        }
      }
    },

    handleBannerTouchStart(event) {
      this.stopSlideshow(); // 터치 시작시 자동 슬라이드 중지
      this.bannerTouchStart = event.touches[0].clientX;
    },

    handleBannerTouchMove(event) {
      if (!this.bannerTouchStart) return;
      
      this.bannerTouchEnd = event.touches[0].clientX;
      const diff = this.bannerTouchStart - this.bannerTouchEnd;
      
      // 터치 중에는 transition 효과 제거
      event.currentTarget.style.transition = 'none';
      event.currentTarget.style.transform = 
        `translateX(${-((this.currentSlide * 100) + (diff / event.currentTarget.offsetWidth * 100))}%)`;
    },

    handleBannerTouchEnd(event) {
      if (!this.bannerTouchStart || !this.bannerTouchEnd) {
        this.startSlideShow();
        return;
      }

      const diff = this.bannerTouchStart - this.bannerTouchEnd;
      const element = event.currentTarget;
      
      element.style.transition = 'transform 0.5s ease-in-out';

      if (Math.abs(diff) > 50) {
        if (diff > 0) {
          this.currentSlide++;
        } else {
          this.currentSlide--;
        }
        this.checkBoundary();
      }

      element.style.transform = `translateX(-${this.currentSlide * 100}%)`;

      this.bannerTouchStart = null;
      this.bannerTouchEnd = null;
      
      this.startSlideShow();
    },

    checkBoundary() {
      const totalSlides = this.banners.length;
      const element = this.$el.querySelector('.banner-slider');
      
      if (this.currentSlide === 0) {
        // 첫 번째 슬라이드 이전으로 갈 때
        this.isTransitioning = true;
        setTimeout(() => {
          element.style.transition = 'none';
          this.currentSlide = totalSlides;
          element.style.transform = `translateX(-${this.currentSlide * 100}%)`;
          setTimeout(() => {
            element.style.transition = 'transform 0.5s ease-in-out';
            this.isTransitioning = false;
          }, 50);
        }, 500);
      } else if (this.currentSlide === totalSlides + 1) {
        // 마지막 슬라이드 다음으로 갈 때
        this.isTransitioning = true;
        setTimeout(() => {
          element.style.transition = 'none';
          this.currentSlide = 1;
          element.style.transform = `translateX(-${this.currentSlide * 100}%)`;
          setTimeout(() => {
            element.style.transition = 'transform 0.5s ease-in-out';
            this.isTransitioning = false;
          }, 50);
        }, 500);
      }
    },
  }
}
</script>

<style scoped>
.main-page {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 60px;
}

/* 배너 CSS */
.banner-section {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.banner-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
}

.banner-slider {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  touch-action: pan-x pinch-zoom;
  cursor: grab;
}

.banner-slider:active {
  cursor: grabbing;
}

.banner-slide {
  min-width: 100%;
  height: 100%;
  cursor: pointer;
}

.banner-image {
  width: 100%;
  height: auto;
  aspect-ratio: 36 / 19;
  object-fit: cover;
}

/* 퀵메뉴 css */
.quick-menu {
  position: relative;
  display: flex; /* 플렉스 박스 사용 */
  justify-content: space-between; /* 양쪽 끝으로 정렬 */
  padding: 0 20px;
  top: -20px;
  height: 100px;
  background-color: #eeeeee;
  border-radius: 20px 20px 0 0;
}

.quick-menu p {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.material-icons-outlined {
  font-size: 32px;
  font-weight: 100;
  padding: 18px 24px 0 24px;
}

.menu-map,
.menu-gallery,
.menu-feed {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column; /* 세로 방향으로 나열 */
  text-decoration: none;
  cursor: pointer;
}

/* 아티스트 영역 */
.artist {
  position: relative;
  top: -20px;
  background-color: #eeeeee;
}

.artist-filter {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  margin: 0 15px;
  z-index: 10;
}

.filter-place {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 50%;
  height: 50px;
  padding: 0 30px 0 15px;
  background-color: #fff;
}

.filter-place .place {
  position: absolute;
  top: 13px;
  left: 15px;
  width: 20px;
  height: 20px;
}

.filter-place p {
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 14px 0 0 30px;
  font-size: 18px;
  font-weight: 600;
}

.filter-place .arrow {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
}

.filter-genre {
  position: relative;
  float: right;
  box-sizing: border-box;
  width: 50%;
  height: 50px;
  padding: 0 30px 0 15px;
  background-color: #fff;
}

.filter-genre::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 2px;
  height: 20px;
  margin-top: -10px;
  background-color: #cccccc;
}

.filter-genre p {
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 14px;
  font-size: 18px;
  font-weight: 600;
}

.filter-genre .arrow {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
}

.filter-list {
  overflow: hidden;
  box-sizing: border-box;
  width: calc(100% - 2px);
  margin: -30px 0 0 0;
  padding-left: 15px;
}

.placelist {
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  width: calc(100% - 15px);
  margin-top: -25px;
  margin-left: 1px;
  padding: 70px 16px 20px;
  border-radius: 0 0 12px 12px;
  background-color: #fff;
  animation: slideUp 0.3s ease-out forwards;
  transform: translateY(100%);
}

@keyframes slideUp {
  from {
    transform: translateY(3%);
  }
  to {
    transform: translateY(0);
  }
}

.placelist li {
  list-style: none;
  float: left;
  width: calc(25% - 4px);
  margin: 4px 0 0 4px;
}

.citylist {
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  width: calc(100% - 15px);
  margin-top: 30px;
  margin-left: 1px;
  padding: 50px 16px 20px;
  border-radius: 0 0 12px 12px;
  background-color: #fff;
  animation: slideUp 0.3s ease-out forwards;
  transform: translateY(100%);
}

.citylist li {
  list-style: none;
  float: left;
  width: calc(50% - 4px);
  margin: 4px 0 0 4px;
}

.genrelist {
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  width: calc(100% - 15px);
  margin-top: 65px;
  margin-left: 1px;
  padding: 40px 16px 20px;
  border-radius: 0 0 12px 12px;
  background-color: #fff;
  animation: slideUp 0.3s ease-out forwards;
  transform: translateY(100%);
}

.genrelist li {
  list-style: none;
  float: left;
  width: calc(50% - 4px);
  margin: 4px 0 0 4px;
}

.placename {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  font-size: 14px;
  text-decoration: none;
}

.artists-section {
  margin-top: 100px;
}

.artists-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 20px;
}

.card {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card-header {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  padding: 0 40px; /* 양쪽에 여백 추가 */
}

.portfolio-slider {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 -40px; /* 양쪽 여백만큼 네거티브 마진 */
  touch-action: pan-y pinch-zoom; /* 터치 이벤트 방지 */
  cursor: grab; /* 사용자 경험 향상 */
}

.portfolio-slider:active {
  cursor: grabbing;
}

.portfolio-slide {
  opacity: 0;
  position: absolute;
  width: calc(100% - 120px); /* 전체 너비에서 양쪽 여백 제외 */
  height: 100%;
  left: 60px; /* 왼쪽 여백 */
  background-size: cover;
  background-position: center;
  transition: all 0.5s ease;
  cursor: pointer;
}

.portfolio-slide.current {
  opacity: 1;
  transform: scale(1) translateX(0);
  z-index: 2;
}

.portfolio-slide.prev {
  opacity: 1;
  transform: translateX(-102%) scale(1);
  z-index: 1;
}

.portfolio-slide.next {
  opacity: 1;
  transform: translateX(102%) scale(1);
  z-index: 1;
}

/* 터치 이벤트 방지 */
.portfolio-slider {
  touch-action: pan-y pinch-zoom;
}

.card-body {
  height: 180px;
  padding: 10px;
}

.profile {
  display: flex;
  align-items: center;
}

.profile i {
  position: relative;
  right: 10px;
  margin-left: auto;
}

.profile-image {
  width: 48px;
  height: 48px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 12px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-info h3 {
  margin: 0 0 5px 0;
  font-size: 18px;
  font-weight: 600;
}

.profile-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

/* ... 나머지 스타일 동일하게 유지 ... */

/* 로딩 스피너 스타일 */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 태그 컨테이너 */
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 16px;
}

/* 기본 태그 스타일 */
.tag {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 400;
}

/* 메인 장르 강조 스타일 */
.main-genre {
  background-color: #333;
  color: white;
  font-weight: 500;
}

/* 서브 장르는 연하게 */
.sub-genre {
  background-color: #f5f5f5;
  color: #666;
}

/* 액션 버튼 컨테이너 */
.actions {
  display: flex;
  gap: 12px;
}

/* 좋아요 버튼 */
.like-button {
  flex: 1;
  padding: 12px 20px;
  border: 1px solid #e0e0e0;
  background-color: white;
  color: #333;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

/* 상담 요청 버튼 */
.consult-button {
  flex: 1;
  padding: 12px 20px;
  border: none;
  background-color: #333;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

/* 좋아요 수와 가격 정보 */
.details {
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  color: #666;
}

.likes {
  display: flex;
  align-items: center;
  gap: 4px;
}

.price {
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid #eee;
  color: #333;
  font-weight: 500;
}

/* 드래그 중일 때 이미지 선택 방지 */
.slider {
  user-select: none;
  -webkit-user-select: none;
}
</style>