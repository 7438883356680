<template>
<div class="mypage-wrap">
  <div class="my-page-container">
    <!-- 내 정보 수정 섹션 -->
    <h2>내정보 수정</h2>
    <form @submit.prevent="saveProfile" class="profile-form">
      <!-- 프로필 이미지 업로드 -->
      <div class="profile-image-wrapper">
        <img v-if="profileImageUrl" :src="profileImageUrl" alt="프로필 이미지" class="profile-image" />
        <input type="file" @change="handleImageUpload" accept="image/*" id="profileImage" ref="profileImage" style="display: none;" />
        <button type="button" @click="triggerFileInput" class="image-upload-button">
          사진 변경
        </button>
      </div>
      <!-- 닉네임 입력 -->
      <div class="input-wrapper">
        <label for="nickname">닉네임</label>
        <input type="text" v-model="nickname" id="nickname" required :class="{'has-value': nickname}" />
      </div>
      <!-- 자기소개 입력 -->
      <div class="input-wrapper">
        <label for="introduce">자기소개</label>
        <textarea 
          v-model="introduce" 
          id="introduce" 
          required 
          :class="{'has-value': introduce}"
          rows="4"
        ></textarea>
      </div>
      <!-- 인스타그램 ID 입력 -->
      <div class="input-wrapper">
        <label for="instagramId">인스타그램 ID</label>
        <input type="text" v-model="instagramId" id="instagramId" :class="{'has-value': instagramId}" />
      </div>
      <!-- 전화번호 입력 -->
      <div class="input-wrapper">
        <label for="phone">전화번호</label>
        <input type="text" v-model="phone" id="phone" required @input="formatPhoneNumber" maxlength="13" :class="{'has-value': phone}" />
      </div>
      <!-- 태그 선택 -->
      <div class="input-wrapper">
        <span>태그</span>
        <div class="genre-wrapper">
          <div class="genre-container">
            <div v-for="tag in service" :key="tag" :class="['genre-item', { selected: selectedServices.includes(tag) }]" @click="toggleService(tag)">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
      <!-- 주요 장르 선택 -->
      <div class="input-wrapper">
        <span>주요 장르</span>
        <div class="genre-wrapper">
          <div class="genre-container">
            <div v-for="genre in genres" :key="genre" :class="['genre-item', { selected: mainGenre === genre }]" @click="selectMainGenre(genre)">
              {{ genre }}
            </div>
          </div>
        </div>
      </div>
      <!-- 서브 장르 선택 -->
      <div class="input-wrapper">
        <span>서브 장르 (최대 2개)</span>
        <div class="genre-container">
          <div v-for="genre in availableSubGenres" :key="genre" :class="['genre-item', { selected: subGenres.includes(genre) }]" @click="toggleSubGenre(genre)">
            {{ genre }}
          </div>
        </div>
        <div v-if="subGenres.length === 2" class="warning-msg">
          서브 장르는 최대 2개까지 선택할 수 있습니다.
        </div>
      </div>
      <!-- 최소 비용 입력 -->
      <div class="input-wrapper">
        <label for="minRate">작업 최소 비용</label>
        <div class="input-price-container">
          <input 
            type="text" 
            :value="minRate === '비용문의' ? minRate : minRate.toLocaleString()" 
            @input="e => minRate = isMinRatePrivate ? '비용문의' : Number(e.target.value.replace(/[^\d]/g, ''))" 
            id="minRate" 
            required 
            :class="{'has-value': minRate}" 
            :disabled="isMinRatePrivate"
          />
          <div class="private-checkbox-wrapper">
            <input 
              type="checkbox" 
              v-model="isMinRatePrivate" 
              @change="toggleMinRatePrivacy"
              :id="'minRatePrivate'"
              class="private-checkbox"
            />
            <label :for="'minRatePrivate'" class="private-label">비공개</label>
          </div>
        </div>
      </div>
      <!-- 시간당 비용 입력 -->
      <div class="input-wrapper">
        <label for="hourlyRate">작업 시간당 비용</label>
        <div class="input-price-container">
          <input 
            type="text" 
            :value="hourlyRate === '비용문의' ? hourlyRate : hourlyRate.toLocaleString()" 
            @input="e => hourlyRate = isHourlyRatePrivate ? '비용문의' : Number(e.target.value.replace(/[^\d]/g, ''))" 
            id="hourlyRate" 
            required 
            :class="{'has-value': hourlyRate}" 
            :disabled="isHourlyRatePrivate"
          />
          <div class="private-checkbox-wrapper">
            <input 
              type="checkbox" 
              v-model="isHourlyRatePrivate" 
              @change="toggleHourlyRatePrivacy"
              :id="'hourlyRatePrivate'"
              class="private-checkbox"
            />
            <label :for="'hourlyRatePrivate'" class="private-label">비공개</label>
          </div>
        </div>
      </div>
      <!-- 위치 설정 -->
      <div class="input-wrapper">
        <label for="location">내 위치</label>
        <input type="text" v-model="location.address" id="location" readonly required :class="{'has-value': location.address}" placeholder="내 위치를 설정해주세요" />
        <button type="button" @click="setCurrentLocation" class="location-button">
          <span class="material-icons">my_location</span>
        </button>
        <div id="map"></div>
      </div>
      <!-- 폼 액션 버튼 -->
      <div class="form-actions">
        <button type="button" @click="cancelEdit" class="cancel-button">취소</button>
        <button type="submit" class="save-button">저장</button>
      </div>
      <!-- 에러 메시지 표시 -->
      <div v-if="errorMessage" class="error-msg">{{ errorMessage }}</div>
    </form>
  </div>
</div>
</template>

<script>
import { ref, computed, onMounted } from "vue"; 
import { db, storage } from "@/firebase"; 
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import router from "@/router";

export default {
  name: "TattooistMyPage",
  setup() {
    const nickname = ref(""); 
    const instagramId = ref("");
    const introduce = ref("");
    const phone = ref("");
    const location = ref({ address: "", latitude: null, longitude: null });
    const map = ref(null); 
    const marker = ref(null); 
    const mainGenre = ref("");
    const subGenres = ref([]);
    const genres = [
      "감성타투", "동양화", "레터링", "라인워크", "블랙워크", "블랙앤그레이",
      "수채화", "뉴스쿨", "미니타투", "올드스쿨", "이레즈미", "일러스트",
      "컬러타투", "커버업", "치카노", "터치업", "트라이벌", "애니/만화"
    ];
    const service = ["카드결제", "주차가능", "분할납부", "여성 타투이스트", "남성 타투이스트", "당일예약", "심야작업", "1인샵", "남/여 화장실 구분", "와이파이", "대기공간", "야간작업", "흡연부스"];
    const minRate = ref(50000);
    const hourlyRate = ref(50000);
    const isMinRatePrivate = ref(false);
    const isHourlyRatePrivate = ref(false);
    const profileImage = ref(null);
    const profileImageUrl = ref("");
    const errorMessage = ref("");
    const userId = ref("");
    const auth = getAuth();
    const selectedServices = ref([]);

    // 계산 속성: 서브 장르 목록에서 메인 장르를 제외
    const availableSubGenres = computed(() => {
      return genres.filter(g => g !== mainGenre.value);
    });

    // 메인 장르 선택 함수
    const selectMainGenre = (genre) => {
      if (mainGenre.value === genre) {
        mainGenre.value = "";
      } else {
        mainGenre.value = genre;
        // 서브 장르에서 선택된 메인 장르 제거
        if (subGenres.value.includes(genre)) {
          subGenres.value = subGenres.value.filter(g => g !== genre);
        }
      }
    };

    // 서브 장르 토글 함수
    const toggleSubGenre = (genre) => {
      // 빈 값이나 중복된 값 방지
      if (!genre || subGenres.value.includes(genre)) {
        subGenres.value = subGenres.value.filter(g => g !== genre);
      } else {
        // 서브 장르 최대 2개 제한
        if (subGenres.value.length < 2) {
          subGenres.value.push(genre);
        } else {
          alert("서브 장르는 최대 2개까지 선택할 수 있습니다.");
        }
      }
    };

    // 전화번호 형식화 함수
    const formatPhoneNumber = () => {
      let formatted = phone.value.replace(/[^0-9]/g, '');
      if (formatted.length > 3 && formatted.length <= 7) {
        formatted = `${formatted.slice(0, 3)}-${formatted.slice(3)}`;
      } else if (formatted.length > 7) {
        formatted = `${formatted.slice(0, 3)}-${formatted.slice(3, 7)}-${formatted.slice(7, 11)}`;
      }
      phone.value = formatted;
    };

    // 편집 모드 활성화 함수
    const enableEditing = () => {
    };

    // 편집 모드 취소 함수
    const cancelEdit = () => {
      window.location.href = '/MyPage';
    };

    // 서비스 토글 함수
    const toggleService = (tag) => {
      if (selectedServices.value.includes(tag)) {
        selectedServices.value = selectedServices.value.filter(t => t !== tag);
      } else {
        selectedServices.value.push(tag);
      }
    };

    // 프로필 저장 함수
    const saveProfile = async () => {
      const userDoc = doc(db, "users", userId.value);
      try {
        await setDoc(userDoc, {
          nickname: nickname.value,
          instagramId: instagramId.value,
          introduce: introduce.value,
          phone: phone.value,
          location: location.value,
          mainGenre: mainGenre.value,
          subGenres: subGenres.value,
          service: selectedServices.value,
          hourlyRate: hourlyRate.value,
          minRate: minRate.value,
          profileImageUrl: profileImageUrl.value,
          updatedAt: serverTimestamp(),
        }, { merge: true });

        errorMessage.value = "";
        alert("프로필이 성공적으로 저장되었습니다.");
        router.push('/MyPage');
      } catch (error) {
        console.error("프로필 저장에 실패했습니다.", error);
        errorMessage.value = "프로필 저장에 실패했습니다.";
      }
    };

    // 프로필 이미지 업로드 함수
    const handleImageUpload = async (event) => {
      const file = event.target.files[0];
      if (file) {
        if (!userId.value) { 
          errorMessage.value = "사용자 ID가 없습니다. 다시 로그인 해주세요.";
          console.error("User ID is undefined.");
          return;
        }
        console.log("Uploading file:", file.name);
        console.log("Storage instance:", storage);
        console.log("User ID:", userId.value);
        
        const storagePath = `profileImages/${userId.value}/${file.name}`;
        const storageReference = storageRef(storage, storagePath); 
        console.log("Storage Reference:", storageReference);
        
        const uploadTask = uploadBytesResumable(storageReference, file);
        
        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error("Upload failed:", error);
            errorMessage.value = "파일 업로드에 실패했습니다.";  
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              profileImageUrl.value = downloadURL;
              await setDoc(doc(db, "users", userId.value), {
                profileImageUrl: downloadURL,
                updatedAt: serverTimestamp(),
              }, { merge: true });
              console.log("File available at", downloadURL);
            } catch (error) {
              console.error("Error getting download URL:", error);
              errorMessage.value = "이미지 URL을 가져오는 데 실패했습니다.";
            }
          }
        );
      }
    };

    // 현재 위치 설정 함수
    const setCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const userLocation = new naver.maps.LatLng(lat, lng);
            map.value.setCenter(userLocation);
            map.value.setZoom(14);
            placeMarker(userLocation);
          },
          () => {
            alert("위치를 가져올 수 없습니다.");
          }
        );
      } else {
        alert("Geolocation을 지원하지 않는 브라우저입니다.");
      }
    };

    // 마커 배치 함수
    const placeMarker = (latLng) => {
      if (marker.value) {
        marker.value.setMap(null);
      }

      marker.value = new naver.maps.Marker({
        position: latLng,
        map: map.value,
      });

      naver.maps.Service.reverseGeocode(
        { location: latLng },
        (status, response) => {
          if (status === naver.maps.Service.Status.OK) {
            const address = response.result.items[0].address;
            location.value.address = address;
            location.value.latitude = latLng.lat();
            location.value.longitude = latLng.lng();
          } else {
            console.error("좌표 변환 실패", status, response);
            errorMessage.value = "주소를 가져오는 데 실패했습니다.";
          }
        }
      );
    };

    // 지도 초기화 함수
    const initMap = () => {
      const mapOptions = {
        center: new naver.maps.LatLng(location.value.latitude || 37.5665, location.value.longitude || 126.978),
        zoom: 10,
      };

      map.value = new naver.maps.Map("map", mapOptions);

      if (location.value.latitude && location.value.longitude) {
        marker.value = new naver.maps.Marker({
          position: new naver.maps.LatLng(location.value.latitude, location.value.longitude),
          map: map.value,
        });
      }

      naver.maps.Event.addListener(map.value, 'click', function(e) {
        const latLng = e.coord;
        placeMarker(latLng);
      });
    };

    const triggerFileInput = () => {
      profileImage.value.click();
    };

    // 사용자 데이터 로드 함수
    const loadUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        userId.value = user.uid;
        const userDoc = doc(db, "users", userId.value);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          nickname.value = userData.nickname || ""; 
          instagramId.value = userData.instagramId || "";
          introduce.value = userData.introduce || "";
          phone.value = userData.phone || "";
          location.value.address = userData.location.address || "";
          location.value.latitude = userData.location.latitude || null;
          location.value.longitude = userData.location.longitude || null;
          mainGenre.value = userData.mainGenre || "";
          subGenres.value = userData.subGenres || [];
          selectedServices.value = userData.service || [];
          
          // 시간당 비용과 최소 비용의 상태 설정
          if (userData.hourlyRate === "비용문의") {
            isHourlyRatePrivate.value = true;
            hourlyRate.value = "비용문의";
          } else {
            isHourlyRatePrivate.value = false;
            hourlyRate.value = userData.hourlyRate || 0;
          }

          if (userData.minRate === "비용문의") {
            isMinRatePrivate.value = true;
            minRate.value = "비용문의";
          } else {
            isMinRatePrivate.value = false;
            minRate.value = userData.minRate || 0;
          }

          profileImageUrl.value = userData.profileImageUrl || "";
          
          initMap();
        } else {
          errorMessage.value = "사용자 정보가 존재하지 않습니다.";
        }
      } else {
        errorMessage.value = "로그인이 필요합니다.";
      }
    };

    const toggleMinRatePrivacy = () => {
      if (isMinRatePrivate.value) {
        minRate.value = "비용문의";
      } else {
        minRate.value = 50000; // 초기화 또는 다른 로직에 따라 설정
      }
    };

    const toggleHourlyRatePrivacy = () => {
      if (isHourlyRatePrivate.value) {
        hourlyRate.value = "비용문의";
      } else {
        hourlyRate.value = 50000; // 초기화 또는 다른 로직에 따라 설정
      }
    };

    onMounted(() => {
      loadUserData();
    });

    return {
      nickname,
      introduce,
      instagramId,
      phone,
      location,
      map,
      marker,
      mainGenre,
      subGenres,
      service,
      genres,
      availableSubGenres,
      hourlyRate,
      minRate,
      profileImageUrl,
      errorMessage,
      enableEditing,
      cancelEdit,
      saveProfile,
      handleImageUpload,
      setCurrentLocation,
      selectMainGenre,
      toggleSubGenre,
      formatPhoneNumber,
      profileImage,
      triggerFileInput,
      selectedServices,
      toggleService,
      isMinRatePrivate,
      isHourlyRatePrivate,
      toggleMinRatePrivacy,
      toggleHourlyRatePrivacy,
    };
  },
};
</script>

<style scoped>
.mypage-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  margin-top: 60px;
  width: 100%;
  padding: 0 0 20px 0;
}
/* 전체 페이지 컨테이너 */
.my-page-container {
  width: 85%;
  margin-top: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.my-page-container h2 {
  margin: 10px 0 0 0;
}

/* 폼 스타일 */
.profile-image-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
}

/* 입력 필드 스타일 */
.input-wrapper {
  display: flex;
  flex-direction: column;
}

.input-wrapper label {
  position: relative;
  top: 35px;
  left: 10px;
  margin-bottom: 5px;
  color: #888;
}

.input-wrapper input {
  padding: 35px 10px 10px 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
}

.input-wrapper input[readonly] {
  background-color: #f5f5f5;
}

input:focus {
  outline: none;
}

/* 장르 선택 스타일 */
.input-wrapper span {
  margin: 20px 0 0 3px;
}

.genre-container {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 10px 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.genre-container::-webkit-scrollbar {
  display: none;
}

.genre-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.genre-item {
  flex: 0 0 auto;
  padding: 10px 12px;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  background: #f8fafc;
  border: 2px solid #eef2f7;
  white-space: nowrap;
  scroll-snap-align: start;
}

.genre-item.selected {
  background: #333;
  color: white;
  border-color: #333;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

/* 스크롤 화살표 추가 (선택사항) */
.genre-wrapper {
  position: relative;
}

.input-wrapper textarea {
  padding: 35px 10px 10px 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
  line-height: 1.5;
}

.input-wrapper textarea:focus {
  outline: none;
}

/* 경고 메시지 */
.warning-msg {
  color: #ff4444;
  font-size: 14px;
  margin-top: 5px;
}

/* 위치 버튼 스타일 */
.location-button {
  position: relative;
  top: 70px;
  left: calc(100% - 60px);
  margin-top: -50px;
  background-color: #333;
  color: #fff;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.location-button span {
  margin: 0;
}

/* 프로필 이미지 스타일 */

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 25%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.image-upload-button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}

/* 에러 메시지 */
.error-msg {
  color: #ff4444;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* 폼 액션 버튼 */
.form-actions {
  display: flex;
  justify-content: right;
  gap: 20px;
  margin: 10px 0;
}

.save-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.cancel-button {
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* 지도 컨테이너 */
#map {
  width: 100%;
  height: 300px;
  margin: 10px 0;
  border-radius: 10px;
  overflow: hidden;
}

.input-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.input-price-container input[type="text"] {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

.private-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: -30px;
  padding-bottom: 30px;
}

.private-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.private-label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  user-select: none;
  display: flex;
  align-items: center;
  margin: 0;
  top: 0;
  transform: none;
  background: none;
}

.private-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: white;
  transition: all 0.2s ease;
}

.private-label:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  opacity: 0;
  transition: all 0.2s ease;
}

.private-checkbox:checked + .private-label:before {
  background-color: #333;
  border-color: #333;
}

.private-checkbox:checked + .private-label:after {
  opacity: 1;
}

.private-checkbox:focus + .private-label:before {
  box-shadow: 0 0 0 2px rgba(51, 51, 51, 0.2);
}

/* 호버 효과 */
.private-label:hover:before {
  border-color: #333;
}

/* 비활성화된 입력 필드 스타일 */
input[type="text"]:disabled {
  background-color: #f5f5f5;
  color: #666;
  cursor: not-allowed;
}
</style>
