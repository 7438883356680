<template>
  <div class="confirmed-reservations">
    <div class="header">
      <button class="back-button" @click="$router.go(-1)">
        <i class="fas fa-arrow-left"></i>
      </button>
      <h2>예약 현황</h2>
    </div>

    <div class="reservations-list" v-if="confirmedReservations.length > 0">
      <div v-for="reservation in confirmedReservations" 
           :key="reservation.id" 
           class="reservation-card">
        <div class="reservation-header">
          <span class="date">{{ formatDate(reservation.date) }}</span>
          <span class="time">{{ reservation.time }}</span>
        </div>
        
        <div class="reservation-details">
          <div class="info-row tattooist-name">
            <i class="fas fa-user-md"></i>
            <span>{{ reservation.senderName }}</span>
          </div>
         
          <div class="info-row">
            <i class="fas fa-clock"></i>
            <span>예상 소요시간: {{ reservation.duration }}시간</span>
          </div>
          <div class="info-row">
            <i class="fas fa-won-sign"></i>
            <span>작업 비용: {{ formatPrice(reservation.price) }}원</span>
          </div>
          <div v-if="reservation.depositAmount" class="info-row deposit">
            <i class="fas fa-wallet"></i>
            <span>예약금: {{ formatPrice(reservation.depositAmount) }}원</span>
          </div>
        </div>

        <div class="reservation-content">
          <p>{{ reservation.content }}</p>
        </div>

        <div v-if="reservation.images?.length" class="reservation-images">
          <div v-for="(image, index) in reservation.images" 
               :key="index" 
               class="image-thumbnail"
               @click="openImageViewer(image)">
            <img :src="image" :alt="`예약 이미지 ${index + 1}`">
          </div>
        </div>

        <div class="reservation-actions">
          <button class="chat-button" @click="goToChat(reservation.chatRoomId)">
            <i class="fas fa-comments"></i>
            채팅방으로 이동
          </button>
          <button 
            v-if="!reservation.hasReview" 
            class="review-button" 
            @click="openReviewModal(reservation)"
          >
            <i class="fas fa-star"></i>
            리뷰 작성
          </button>
          <span v-else class="review-completed">
            <i class="fas fa-check-circle"></i>
            리뷰 작성 완료
          </span>
        </div>
      </div>
    </div>

    <div v-else class="empty-state">
      <i class="fas fa-calendar-times"></i>
      <p>확정된 예약이 없습니다</p>
    </div>

    <!-- 이미지 뷰어 모달 -->
    <div v-if="selectedImage" 
         class="image-viewer" 
         @click="selectedImage = null">
      <img :src="selectedImage" alt="확대된 이미지">
    </div>

    <!-- 리뷰 작성 모달 -->
    <div v-if="showReviewModal" class="modal-overlay" @click="closeReviewModal">
      <div class="modal-content" @click.stop>
        <h3>리뷰 작성</h3>
        <div class="rating-container">
          <div class="stars">
            <i v-for="star in 5" 
               :key="star" 
               class="fas fa-star"
               :class="{ active: star <= reviewForm.rating }"
               @click="reviewForm.rating = star">
            </i>
          </div>
        </div>
        
        <!-- 이미지 업로드 섹션 추가 -->
        <div class="image-upload-section">
          <label class="upload-button">
            <i class="fas fa-camera"></i>
            사진 추가
            <input 
              type="file" 
              accept="image/*" 
              multiple 
              @change="handleImageUpload" 
              ref="fileInput"
            >
          </label>
          <div v-if="reviewForm.images.length > 0" class="preview-images">
            <div v-for="(image, index) in reviewForm.images" 
                 :key="index" 
                 class="preview-image-container">
              <img :src="image.url" :alt="`미리보기 ${index + 1}`">
              <button class="remove-image" @click="removeImage(index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <textarea 
          v-model="reviewForm.content"
          placeholder="리뷰 내용을 작성해주세요"
          rows="4">
        </textarea>
        
        <div class="modal-actions">
          <button class="cancel-button" @click="closeReviewModal">취소</button>
          <button 
            class="submit-button" 
            @click="submitReview"
            :disabled="!isFormValid"
          >
            작성 완료
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { db, auth, storage } from '@/firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useRouter } from 'vue-router';

export default {
  name: 'ConfirmedReservations',
  props: {
    chatRoomId: {
      type: String,
      required: false
    }
  },

  setup() {
    const confirmedReservations = ref([]);
    const selectedImage = ref(null);
    const router = useRouter();
    const showReviewModal = ref(false);
    const selectedReservation = ref(null);
    const reviewForm = ref({
      rating: 0,
      content: '',
      images: []
    });

    const fetchConfirmedReservations = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) return;

        const chatRoomsRef = collection(db, 'chatRooms');
        const chatRoomsQuery = query(
          chatRoomsRef, 
          where('participants', 'array-contains', userId)
        );
        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);

        const allReservations = [];

        for (const chatRoom of chatRoomsSnapshot.docs) {
          const messagesRef = collection(db, 'chatRooms', chatRoom.id, 'messages');
          const q = query(messagesRef, where('type', '==', 'estimate'), where('confirmed', '==', true));
          const snapshot = await getDocs(q);

          for (const doc of snapshot.docs) {
            // 데이터 구조 확인을 위한 로그
            console.log('예약 데이터:', doc.data());
            
            const messageData = doc.data();
            const reservation = {
              id: doc.id,
              chatRoomId: chatRoom.id,
              ...messageData,
              date: messageData.content.availableDate.split(' ')[0],
              time: messageData.content.availableDate.split(' ')[1],
              price: messageData.content.price,
              duration: messageData.content.duration,
              content: messageData.content.description,
              images: messageData.content.images,
              depositAmount: messageData.content.depositAmount,
              // 이름 정보 추가
              senderName: messageData.senderName || '타투이스트',  // senderName으로 변경
              customerName: messageData.customerName || '고객'
            };
            
            // 개별 예약 데이터 확인
            console.log('변환된 예약 데이터:', reservation);
            
            allReservations.push(reservation);
          }
        }

        confirmedReservations.value = allReservations.sort((a, b) => 
          new Date(b.date) - new Date(a.date)
        );
        
        // 최종 예약 목록 확인
        console.log('최종 예약 목록:', confirmedReservations.value);
      } catch (error) {
        console.error('확정된 예약 조회 실패:', error);
      }
    };

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      return date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short'
      });
    };

    const formatPrice = (price) => {
      return Number(price).toLocaleString();
    };

    const goToChat = (chatRoomId) => {
      router.push(`/chat/${chatRoomId}`);
    };

    const openImageViewer = (imageUrl) => {
      selectedImage.value = imageUrl;
    };

    const openReviewModal = (reservation) => {
      selectedReservation.value = reservation;
      showReviewModal.value = true;
    };

    const closeReviewModal = () => {
      showReviewModal.value = false;
      selectedReservation.value = null;
      reviewForm.value = { rating: 0, content: '', images: [] };
    };

    // 이미지 압축 함수
    const compressImage = async (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const MAX_WIDTH = 1200;
            const MAX_HEIGHT = 1200;
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob((blob) => {
              resolve(new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              }));
            }, 'image/jpeg', 0.7); // 품질 70%로 압축
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    };

    // 이미지 업로드 핸들러
    const handleImageUpload = async (event) => {
      const files = Array.from(event.target.files);
      if (files.length + reviewForm.value.images.length > 5) {
        alert('최대 5장까지만 업로드할 수 있습니다.');
        return;
      }

      for (const file of files) {
        const compressedFile = await compressImage(file);
        const url = URL.createObjectURL(compressedFile);
        reviewForm.value.images.push({
          file: compressedFile,
          url: url
        });
      }
    };

    // 이미지 제거
    const removeImage = (index) => {
      URL.revokeObjectURL(reviewForm.value.images[index].url);
      reviewForm.value.images.splice(index, 1);
    };

    // 폼 유효성 검사
    const isFormValid = computed(() => {
      return reviewForm.value.rating > 0 && 
             reviewForm.value.content.trim() !== '' &&
             reviewForm.value.images.length > 0;
    });

    const submitReview = async () => {
      if (!isFormValid.value) {
        alert('별점, 리뷰 내용, 사진은 필수입니다.');
        return;
      }

      try {
        // 이미지 업로드
        const uploadedImageUrls = [];
        for (const image of reviewForm.value.images) {
          const imageRef = storageRef(storage, `reviews/${Date.now()}-${image.file.name}`);
          await uploadBytes(imageRef, image.file);
          const imageUrl = await getDownloadURL(imageRef);
          uploadedImageUrls.push(imageUrl);
        }

        // 리뷰 데이터 생성
        const reviewData = {
          tattooistId: selectedReservation.value.senderId,
          customerId: auth.currentUser.uid,
          reservationId: selectedReservation.value.id,
          rating: reviewForm.value.rating,
          content: reviewForm.value.content,
          images: uploadedImageUrls,
          customerName: auth.currentUser.displayName,
          tattooistName: selectedReservation.value.senderName,
          createdAt: new Date(),
        };

        await addDoc(collection(db, 'reviews'), reviewData);
        await updateDoc(doc(db, 'chatRooms', selectedReservation.value.chatRoomId, 
                          'messages', selectedReservation.value.id), 
                          { hasReview: true });

        closeReviewModal();
        await fetchConfirmedReservations();
      } catch (error) {
        console.error('리뷰 작성 실패:', error);
        alert('리뷰 작성에 실패했습니다. 다시 시도해주세요.');
      }
    };

    onMounted(fetchConfirmedReservations);

    return {
      confirmedReservations,
      selectedImage,
      openImageViewer,
      goToChat,
      formatDate,
      formatPrice,
      showReviewModal,
      reviewForm,
      openReviewModal,
      closeReviewModal,
      submitReview,
      handleImageUpload,
      removeImage,
      isFormValid
    };
  }
};
</script>

<style scoped>
.confirmed-reservations {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
  overflow-y: auto;
  padding-bottom: env(safe-area-inset-bottom);
}

.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: white;
  border-bottom: 1px solid #eee;
  z-index: 1;
}

.back-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.reservations-list {
  padding: 1rem;
}

.reservation-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.reservation-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-weight: bold;
}

.info-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.info-row i {
  width: 20px;
  color: #666;
}

.deposit {
  color: #e74c3c;
}

.reservation-content {
  margin: 1rem 0;
  padding: 1rem;
  background: white;
  border-radius: 8px;
}

.reservation-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 0.5rem;
  margin-top: 1rem;
}

.image-thumbnail {
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: #666;
}

.empty-state i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.image-viewer img {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

.reservation-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.chat-button, .review-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 0.5rem;
}

.chat-button {
  background-color: #2563eb;
  color: white;
}

.review-button {
  background-color: #10b981;
  color: white;
}

.review-completed {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #10b981;
  font-weight: 500;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
}

.rating-container {
  margin: 1rem 0;
  text-align: center;
}

.stars {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.stars i {
  font-size: 2rem;
  color: #d1d5db;
  cursor: pointer;
  transition: color 0.2s;
}

.stars i.active {
  color: #fbbf24;
}

textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  resize: vertical;
  margin: 1rem 0;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.cancel-button, .submit-button {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.cancel-button {
  background: #e5e7eb;
  border: none;
}

.submit-button {
  background: #10b981;
  color: white;
  border: none;
}

.image-upload-section {
  margin: 1rem 0;
}

.upload-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #4b5563;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upload-button:hover {
  background-color: #374151;
}

.upload-button input {
  display: none;
}

.preview-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.preview-image-container {
  position: relative;
  aspect-ratio: 1;
}

.preview-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.remove-image {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #ef4444;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.submit-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

@media (prefers-color-scheme: dark) {
  .confirmed-reservations {
    background: #1a1a1a;
  }

  .header {
    background: #1a1a1a;
    border-bottom-color: #333;
  }

  .reservation-card {
    background: #2a2a2a;
  }

  .reservation-content {
    background: #1a1a1a;
  }

  .reservation-actions {
    border-top-color: #3d3d3d;
  }

  .chat-button {
    background-color: #3b82f6;
  }

  .chat-button:hover {
    background-color: #2563eb;
  }

  .image-viewer {
    background: rgba(0,0,0,0.95);
  }

  .modal-content {
    background: #2a2a2a;
    color: white;
  }

  textarea {
    background: #1a1a1a;
    border-color: #3d3d3d;
    color: white;
  }

  .cancel-button {
    background: #374151;
    color: white;
  }

  .upload-button {
    background-color: #4b5563;
  }

  .upload-button:hover {
    background-color: #374151;
  }
}
</style> 