//(유저) 타투 직접 의뢰 작성

<template>
  <div class="tattoo-request">
    <h2 class="title"></h2>

    <!-- 타투이스트 프로필 섹션 -->
    <div v-if="tattooist" class="tattooist-profile">
      <div class="profile-header">
        <div class="profile-image-wrapper">
          <img 
            :src="tattooist.profileImageUrl || '/default-profile.png'" 
            :alt="tattooist.nickname" 
            class="profile-image"
          />
          <div class="profilename">
            <!-- 타투이스트 닉네임 표시 -->
            <h3 class="artist-name">{{ tattooist.nickname }}</h3>
            <!-- 타투이스트 위치 정보 표시 -->
            <p class="artist-location">
              <i class="fas fa-map-marker-alt"></i>
              {{ formatLocation(tattooist?.location?.address) }}
            </p>
          </div>
        </div>
        <div class="profile-info">
          <!-- 타투이스트 장르 정보 표시 -->
          <div class="artist-genres">
            <span class="main-genre">{{ tattooist.mainGenre }}</span>
            <div class="sub-genres">
              <span 
                v-for="genre in tattooist.subGenres" 
                :key="genre" 
                class="sub-genre"
              >
                {{ genre }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 진행 상태 표시 -->
    <div class="progress-bar">
      <div class="progress" :style="{ width: `${(currentStep / totalSteps) * 100}%` }"></div>
    </div>

    <!-- 스텝별 질문 폼 -->
    <div class="step-container">
      <!-- 장르 선택 스텝 -->
      <div v-if="currentStep === 1" class="step">
        <h3>타투 스타일을 선택해 주세요</h3>
        <div class="genre-buttons">
          <button
            v-for="genre in genres"
            :key="genre"
            type="button"
            :class="['genre-button', { active: form.genre === genre }]"
            @click="toggleGenre(genre)"
          >
            {{ genre }}
          </button>
        </div>
      </div>

      <!-- 사이즈 선택 스텝 -->
      <div v-if="currentStep === 2" class="step">
        <h3>타투 사이즈를 선택해 주세요</h3>
        <div class="size-buttons">
          <button
            v-for="size in ['신용카드크기', '주먹크기', '손바닥크기', '반팔크기', '그이상']"
            :key="size"
            type="button"
            :class="['size-button', { active: form.size === size }]"
            @click="selectSize(size)"
          >
            {{ size }}
          </button>
        </div>
      </div>

      <!-- 신체 부위 선택 스텝 -->
      <div v-if="currentStep === 3" class="step">
        <h3>작업 부위를 선택해 주세요</h3>
        <div class="body-map">
          <div class="body-tabs">
            <button 
              :class="['tab-button', { active: activeTab === 'front' }]"
              @click="activeTab = 'front'"
            >
              앞면
            </button>
            <button 
              :class="['tab-button', { active: activeTab === 'back' }]"
              @click="activeTab = 'back'"
            >
              뒷면
            </button>
          </div>
          <div class="body-image">
            <!-- 앞면 -->
            <div v-show="activeTab === 'front'" class="body-front">
              <svg viewBox="0 0 226 790" xmlns="http://www.w3.org/2000/svg">
                <path @click="selectBodyPart('얼굴')" :data-selected="selectedBodyParts.includes('얼굴')"
                d="M137.63,93.19c7.08-5.27,7.81-20.71,7.81-20.71,0,0,.55,2.72,3.27.91,2.72-1.81,3.64-20.16,2.91-21.8s-3.82-1.09-3.82-1.09c0,0,4.35-23.98-3.09-36.33C137.26,1.81,119.64,0,112.73,0s-24.52,1.81-31.97,14.17c-7.44,12.36-3.09,36.33-3.09,36.33,0,0-3.09-.55-3.82,1.09-.73,1.63.18,19.98,2.91,21.8,2.72,1.81,3.27-.91,3.27-.91,0,0,.73,15.45,7.81,20.71,0,0,1.4,17.65-.72,25.51h51.22c-2.12-7.87-.72-25.51-.72-25.51h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('쇄골')" :data-selected="selectedBodyParts.includes('쇄골')"
                d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 팔뚝')" :data-selected="selectedBodyParts.includes('오른쪽 팔뚝')"
                d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 팔목')" :data-selected="selectedBodyParts.includes('오른쪽 팔목')"
                d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 손')" :data-selected="selectedBodyParts.includes('오른쪽 손')"
                d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 팔뚝')" :data-selected="selectedBodyParts.includes('왼쪽 팔뚝')"
                d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽  팔목')" :data-selected="selectedBodyParts.includes('왼쪽  팔목')"
                d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 손')" :data-selected="selectedBodyParts.includes('왼쪽 손')"
                d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z"
                class="body-part" />

                <path @click="selectBodyPart('가슴')" :data-selected="selectedBodyParts.includes('가슴')"
                d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('배')" :data-selected="selectedBodyParts.includes('배')"
                d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 허벅지')" :data-selected="selectedBodyParts.includes('왼쪽 허벅지')"
                d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 종아리')" :data-selected="selectedBodyParts.includes('왼쪽 종아리')"
                d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 발')" :data-selected="selectedBodyParts.includes('왼쪽 발')"
                d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 허벅지')" :data-selected="selectedBodyParts.includes('오른쪽 허벅지')"
                d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 종아리')" :data-selected="selectedBodyParts.includes('오른쪽 종아리')"
                d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 발')" :data-selected="selectedBodyParts.includes('오른쪽 발')"
                d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z"
                class="body-part" />
              </svg>
            </div>
            <!-- 뒷면 -->
            <div v-show="activeTab === 'back'" class="body-back">
              <svg viewBox="0 0 226 790" xmlns="http://www.w3.org/2000/svg">
                <path @click="selectBodyPart('(뒤)어깨')" :data-selected="selectedBodyParts.includes('(뒤)어깨')"
                d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 팔뚝')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 팔뚝')"
                d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 팔목')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 팔목')"
                d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 손')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 손')"
                d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 팔뚝')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 팔뚝')"
                d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 팔목')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 팔목')"
                d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 손')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 손')"
                d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)윗등')" :data-selected="selectedBodyParts.includes('(뒤)윗등')"
                d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)아랫등')" :data-selected="selectedBodyParts.includes('(뒤)아랫등')"
                d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 허벅지')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 허벅지')"
                d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 종아리')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 종아리')"
                d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 발')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 발')"
                d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 허벅지')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 허벅지')"
                d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 종아리')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 종아리')"
                d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 발')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 발')"
                d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z"
                class="body-part" />
              </svg>
            </div>
          </div>
          <p class="selected-part">선택된 부위: {{ selectedBodyParts.join(', ') || '선택되지 않음' }}</p>
        </div>
      </div>

      <!-- 작업 지역 및 희망일시 선택 스텝 -->
      <div v-if="currentStep === 4" class="step">
        <h3>희망 일시를 선택해 주세요</h3>
        <div class="region-selects">          
          <input 
            type="date" 
            v-model="form.preferredDate"
            class="input-field"
            :min="minDate"
          />
          
          <select v-model="form.preferredTime" class="input-field">
            <option value="">희망 시간을 선택해주세요</option>
            <option value="새벽(00:00 ~ 06:00)">새벽 (00:00 ~ 06:00)</option>
            <option value="아침(06:00 ~ 09:00) ">아침 (06:00 ~ 09:00)</option>
            <option value="오전(09:00 ~ 12:00)">오전 (09:00 ~ 12:00)</option>
            <option value="이른 오후(12:00 ~ 15:00)">이른 오후 (12:00 ~ 15:00)</option>
            <option value="늦은 오후(15:00 ~ 18:00)">늦은 오후 (15:00 ~ 18:00)</option>
            <option value="저녁(18:00 ~ 21:00)">저녁 (18:00 ~ 21:00)</option>
            <option value="밤(21:00 ~ 24:00)">밤 (21:00 ~ 24:00)</option>
          </select>
        </div>
      </div>

      <!-- 상세 설명 스텝 -->
      <div v-if="currentStep === 5" class="step">
        <h3>원하시는 타투에 대해 자세히 설명해 주세요</h3>
        <textarea 
          v-model="form.description" 
          placeholder="타투에 대한 상세한 설명을 입력해주세요" 
          rows="4" 
          class="textarea-field"
        ></textarea>
      </div>

      <!-- 연락처 동의 스텝 -->
      <div v-if="currentStep === 6" class="step">
        <h3>연락처 정보 제공 동의</h3>
        <div class="phone-consent-container">
          <p class="phone-info">
            현재 등록된 연락처: {{ userPhone ? userPhone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') : '등록된 연락처가 없습니다' }}
          </p>
          <div class="consent-checkbox">
          <input 
              type="checkbox"
              v-model="form.phoneConsent"
              id="phoneConsent"
            />
            <label for="phoneConsent">
              타투이스트에게 연락처 정보를 제공하는 것에 동의합니다
            </label>
          </div>
        </div>
      </div>

      <!-- 이미지 업로드 스텝 -->
      <div v-if="currentStep === 7" class="step">
        <h3>참고 이미지가 있다면 업로드해 주세요</h3>
        <div class="file-upload-container">
          <input type="file" ref="fileInput" @change="handleFileUpload" multiple accept="image/*" class="file-input" />
          <div class="upload-icon">
            <i class="fas fa-cloud-upload-alt"></i>
          </div>
        </div>
        <div v-if="uploadedImages.length > 0" class="uploaded-images">
          <div v-for="(image, index) in uploadedImages" :key="index" class="image-preview-container">
            <div class="image-wrapper">
            <img :src="image.url" alt="참고 이미지" class="uploaded-image" />
            <button @click="removeImage(index)" class="remove-image-btn">×</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 최종 확인 스텝 -->
      <div v-if="currentStep === 8" class="step">
        <h3>입력하신 내용을 확인해 주세요</h3>
        <div class="summary">
          <p><strong>타투 스타일:</strong> {{ form.genre }}</p>
          <p><strong>사이즈:</strong> {{ form.size }}</p>
          <p><strong>신체 부위:</strong> {{ form.location }}</p>
          <p><strong>작업 희망 일자:</strong> {{ form.preferredDate }}</p>
          <p><strong>작업 희망 시간:</strong> {{ form.preferredTime }}</p>
          <p><strong>연락처:</strong> {{ userPhone ? userPhone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') : '등록된 연락처가 없습니다' }}</p>
          
          <!-- 업로드된 이미지 미리보기 섹션 -->
          <div v-if="uploadedImages.length > 0" class="uploaded-images-summary">
            <p><strong>참고 이미지:</strong></p>
            <div class="images-grid">
              <div v-for="(image, index) in uploadedImages" 
                   :key="index" 
                   class="image-preview">
                <img :src="image.url" :alt="`참고 이미지 ${index + 1}`" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 네비게이션 버튼 -->
    <div class="navigation-buttons">
      <button 
        v-if="currentStep === 1" 
        @click="gotoBack" 
        class="nav-button prev"
      >
        처음
      </button>
      <button 
        v-if="currentStep > 1" 
        @click="prevStep" 
        class="nav-button prev"
      >
        이전
      </button>
      <button 
        v-if="currentStep < totalSteps" 
        @click="nextStep" 
        :class="['nav-button next', { disabled: !canProceed }]"
        :disabled="!canProceed"
      >
        다음
      </button>
      <button 
        v-if="currentStep === totalSteps" 
        @click="submitRequest" 
        class="nav-button submit"
      >
        의뢰 요청
      </button>
    </div>

    <!-- 성공 메시지 모달 -->
    <div v-if="isSuccessModalOpen" class="modal-overlay" @click.self="closeSuccessModal">
      <div class="modal success-modal">
        <h3>문의서 작성 완료!</h3>
        <button @click="closeSuccessModal" class="modal-button success">확인</button>
      </div>
    </div>

    <!-- 에러 메시지 모달 -->
    <div v-if="isErrorModalOpen" class="modal-overlay" @click.self="closeErrorModal">
      <div class="modal error-modal">
        <div class="modal-icon">!</div>
        <h3>오류 발생</h3>
        <p>{{ errorMessage }}</p>
        <button @click="closeErrorModal" class="modal-button error">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { db, auth, storage } from '@/firebase';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore'; // setDoc 제거
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { createOrGetChatRoom } from '@/utils/chat';

export default {
  name: 'TattooRequest',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentUser = auth.currentUser; // currentUser 정의

    // 사용자 인증 확인
    if (!currentUser) {
      router.push('/TattooAuth');
      return;
    }

    const tattooist = ref(null);
    const isSuccessModalOpen = ref(false);
    const isErrorModalOpen = ref(false);
    const errorMessage = ref('');
    const createdRoomId = ref(null);
    
    // 스텝 관련 상태 수정
    const currentStep = ref(1);
    const totalSteps = ref(8); // 9단계로 수정
    const activeTab = ref('front');
    const selectedBodyParts = ref([]);
    const phoneError = ref(false);

    // form 데이터 수정
    const form = ref({
      genre: '',
      size: '',
      location: '',
      budget: tattooist.value?.minRate || 50000,
      city: '',
      district: '',
      preferredDate: '',
      preferredTime: '',
      description: '',
      phoneConsent: false, // 연락처 동의 추가
    });

    // 업로드된 이미지 배열
    const uploadedImages = ref([]);

    // 사용자 연락처 정보 가져오기
    const userPhone = ref('');
    const getUserPhone = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          userPhone.value = userDoc.data().phone || '';
        }
      } catch (error) {
        console.error('연락처 정보 가져오기 실패:', error);
      }

    };

    onMounted(() => {
      getUserPhone();
    });

    const gotoBack = () => {
      router.go(-1);
    };

    // 다음 스텝으로 이동하는 함수 수정
    const nextStep = () => {      
      if (currentStep.value < totalSteps.value) {
        if (canProceed.value) {
          currentStep.value++;
          console.log('Moving to step:', currentStep.value); // 디버깅용
        } else {
          console.log('Cannot proceed - validation failed'); // 디버깅용
        }
      }
    };

    // 이전 스텝으로 이동하는 함수 수정
    const prevStep = () => {
      if (currentStep.value > 1) {
        currentStep.value--;
      }
    };

    // canProceed computed 속성 수정
    const canProceed = computed(() => {
      switch (currentStep.value) {
        case 1:
          return form.value.genre !== '';
        case 2:
          return form.value.size !== '';
        case 3:
          return selectedBodyParts.value.length > 0;
        case 4:
          return form.value.preferredTime !== '';
        case 5:
          return form.value.description.length >= 2;
        case 6:
          return form.value.phoneConsent && userPhone.value;
        case 7:
          return true; // 이미지는 선택사항
        case 8:
          return true; // 최종 확인
        default:
          return false;
      }
    });

    // 문의서 메시지 포맷팅 함수 수정
    const formatConsultationMessage = async (requestData) => {
      const message = `
📋 타투 문의서

🎨 스타일: ${requestData.genre}
📏 사이즈: ${requestData.size}
👉 선택 부위: ${selectedBodyParts.value.join(', ')}
📅 희망 일시: ${requestData.preferredDate} ${requestData.preferredTime}
📞 연락처: ${userPhone.value}
${requestData.description ? `✍️ 상세 설명: ${requestData.description}` : ''}
`.trim();

      return {
        text: message,
        images: requestData.images || [],
        selectedBodyParts: selectedBodyParts.value,
        activeTab: activeTab.value
      };
    };

    const checkUserAccess = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          alert('로그인 이후 이용 가능한 서비스입니다.');
          router.push('/TattooAuth');
          return;
        }

        const userDoc = await getDoc(doc(db, 'users', user.uid));
        console.log('User data:', userDoc.data()); // 디버깅을 위한 로그
        
        if (!userDoc.exists()) {
          alert('사용자 정보를 찾을 수 없습니다.');
          router.push('/');
          return;
        }

        const userType = userDoc.data().userType; // userType 필드로 수정
        console.log('User type:', userType); // 디버깅을 위한 로그

        if (userType !== 'guest') {
          alert('게스트 회원만 이용 가능한 서비스입니다.');
          router.push('/');
          return;
        }
      } catch (error) {
        console.error('사용자 확인 실패:', error);
        alert('사용자 확인 중 오류가 발생했습니다.');
        router.push('/');
      }
    };

    const fetchTattooist = async () => {
      try {
        const tattooistId = route.params.tattooistId;
        
        if (!tattooistId) {
          throw new Error('타투이스트 ID가 없습니다');
        }
        
        const docRef = doc(db, 'users', tattooistId);
        const tattooistDoc = await getDoc(docRef);
        
        if (tattooistDoc.exists()) {
          tattooist.value = {
            id: tattooistDoc.id,
            ...tattooistDoc.data()
          };
        }
      } catch (error) {
        console.error('타투이스트 정보 가져오기 실패:', error);
      }
    };

    onMounted(async () => {
      await checkUserAccess();
      await fetchTattooist();
    });

    // submitRequest 함수 수정
    const submitRequest = async () => {
      try {
        if (!form.value.phoneConsent) {
          alert('연락처 제공에 동의해주셔야 합니다.');
          return;
        }

        if (!userPhone.value) {
          alert('마이페이지에서 연락처를 등록해주세요.');
          return;
        }

        const requestData = {
          userId: currentUser.uid,
          tattooistId: route.params.tattooistId,
          genre: form.value.genre,
          size: form.value.size,
          location: form.value.location,
          preferredDate: form.value.preferredDate,
          preferredTime: form.value.preferredTime,
          phone: userPhone.value,
          status: 'pending',
          createdAt: new Date(),
          images: uploadedImages.value.map(img => img.url),
          description: form.value.description  // 이 줄 추가
        };

        // 문의서 저장
        const requestDoc = await addDoc(collection(db, 'consultationRequests'), requestData);

        // 채팅방 생성 또는 가져오기
        const roomId = await createOrGetChatRoom(currentUser.uid, route.params.tattooistId);
        createdRoomId.value = roomId;

        // 문의서 내용을 채팅 메시지로 전송
        const messageContent = await formatConsultationMessage(requestData);
        await addDoc(collection(db, 'chatRooms', roomId, 'messages'), {
          content: messageContent.text,
          images: messageContent.images,
          senderId: currentUser.uid,
          timestamp: new Date(),
          type: 'consultation',
          consultationId: requestDoc.id
        });

        isSuccessModalOpen.value = true;
      } catch (error) {
        console.error('요청 제출 실패:', error);
        errorMessage.value = '요청 제출에 실패했습니다.';
        isErrorModalOpen.value = true;
      }
    };

    const closeSuccessModal = () => {
      isSuccessModalOpen.value = false;
      // 채팅방으로 이동
      if (createdRoomId.value) {
        router.push(`/chat/${createdRoomId.value}`);
      }
    };

    // 이미지 압축 함수
    const compressImage = async (file) => {
      return new Promise((resolve, reject) => {
        const maxWidth = 1024;
        const maxHeight = 1024;
        const maxSizeMB = 1;
        
        const reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          
          img.onload = () => {
            let width = img.width;
            let height = img.height;
            
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
            
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
            
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            
            let quality = 0.7;
            let dataUrl;
            
            do {
              dataUrl = canvas.toDataURL('image/jpeg', quality);
              quality -= 0.1;
            } while (
              quality > 0.1 && 
              dataUrl.length > maxSizeMB * 1024 * 1024 * 1.33
            );
            
            fetch(dataUrl)
              .then(res => res.blob())
              .then(blob => {
                const compressedFile = new File([blob], file.name, {
                  type: 'image/jpeg',
                  lastModified: Date.now()
                });
                resolve(compressedFile);
              })
              .catch(reject);
          };
          
          img.onerror = reject;
        };
        
        reader.onerror = reject;
      });
    };

    // 파일 업로드 핸들러
    const handleFileUpload = async (event) => {
      const files = event.target.files;
      
      for (let file of files) {
        try {
          if (!file.type.startsWith('image/')) {
            alert('이미지 파일만 업로드 가능합니다.');
            continue;
          }

          const compressedFile = await compressImage(file);
          const storageReference = storageRef(storage, `referenceImages/${Date.now()}_${file.name}`);
          const snapshot = await uploadBytes(storageReference, compressedFile);
          const downloadURL = await getDownloadURL(snapshot.ref);

          uploadedImages.value.push({
            url: downloadURL,
            name: file.name
          });
        } catch (error) {
          console.error('이미지 업로드 실패:', error);
          alert('이미지 업로드에 실패했습니다.');
        }
      }
    };

    // 이미지 제거 함수
    const removeImage = (index) => {
      URL.revokeObjectURL(uploadedImages.value[index].url);
      uploadedImages.value.splice(index, 1);
    };

    // 주소 포맷팅 함수 추가
    const formatLocation = (address) => {
      if (!address) return '위치 정보 없음';
      
      // 주소를 공백으로 분리
      const parts = address.split(' ');
      
      // 처음 두 단어만 반환
      return parts.slice(0, 2).join(' ');
    };

    // 가격 포맷팅 함수 추가
    const formatPrice = (price) => {
      if (!price) return '가격 정보 없음';
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW'
      }).format(price).replace('₩', '') + '원';
    };

    // 장르 선택 토글 함수
    const toggleGenre = (genre) => {
      form.value.genre = genre
    };

    // 신체 부위 선택 토글 함수
    const toggleBodyPart = (part) => {
      const index = selectedBodyParts.value.indexOf(part)
      if (index === -1) {
        selectedBodyParts.value = [part]
        form.value.location = part
      } else {
        selectedBodyParts.value.splice(index, 1)
        form.value.location = ''
      }
    };

    // 사이즈 선택 함수
    const selectSize = (size) => {
      form.value.size = size;
    };

    // 신체 부위 선택 함수 수정
    const selectBodyPart = (part) => {
      const index = selectedBodyParts.value.indexOf(part)
      if (index === -1) {
        selectedBodyParts.value.push(part)
      } else {
        selectedBodyParts.value.splice(index, 1)
      }
      form.value.location = selectedBodyParts.value.join(', ')
    }

    return {
      tattooist,
      isSuccessModalOpen,
      isErrorModalOpen,
      errorMessage,
      createdRoomId,
      submitRequest,
      closeSuccessModal,
      form,
      uploadedImages,
      userPhone,
      // 새로 추가된 반환값들
      currentStep,
      totalSteps,
      activeTab,
      selectedBodyParts,
      phoneError,
      canProceed,
      gotoBack,
      nextStep,
      prevStep,
      genres: ['이레즈미', '치카노', '라인워크', '블랙앤그레이', '올드스쿨', '뉴스쿨', '블랙워크', '트라이벌', '컬러타투', '일러스트', '커버업', '수채화', '미니타투', '감성타투', '레터링', '동양화', '터치업', '만화/애니'],
      toggleGenre,
      toggleBodyPart,
      selectSize,
      formatLocation,
      formatPrice,
      handleFileUpload,
      removeImage,
      currentUser, // currentUser 반환 추가
      selectBodyPart,
    };
  },
};
</script>

<style scoped>
.tattoo-request {
  margin: 32px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
}

.title {
  font-size: 38px;
  color: #111111;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 800;
  letter-spacing: -0.03em;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background: #f1f3f5;
  border-radius: 100px;
  margin-bottom: 48px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #2d3436 0%, #636e72 100%);
  transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.step-container {
  min-height: 320px;
  margin-bottom: 40px;
}

.step {
  animation: fadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.budget-slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 100px;
  background: #f1f3f5;
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
  margin-bottom: 20px;
}

.budget-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(135deg, #2d3436 0%, #636e72 100%);
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
}

.budget-slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(135deg, #2d3436 0%, #636e72 100%);
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
  border: none;
}

.budget-slider:hover {
  opacity: 1;
}

.budget-slider::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
}

.budget-slider::-moz-range-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
}

.step h3 {
  font-size: 26px;
  margin-bottom: 32px;
  color: #111111;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 19px;
  margin-top: 48px;
}

.nav-button {
  padding: 19px 40px;
  border-radius: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  font-size: 16px;
}

.nav-button.prev {
  background: #f1f3f5;
  color: #111111;
}

.nav-button.next {
  background: linear-gradient(90deg, #2d3436 0%, #636e72 100%);
  color: white;
}

.nav-button.submit {
  background: linear-gradient(90deg, #2f9e44 0%, #37b24d 100%);
  color: white;
  width: 50%;
}

.nav-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.nav-button:not(:disabled):hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.summary {
  background: #f8f9fa;
  padding: 32px;
  border-radius: 16px;
  margin-top: 24px;
  border: 1px solid #e9ecef;
}

.summary p {
  margin-bottom: 13px;
  font-size: 17px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.genre-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 16px;
  margin-top: 24px;
}

.genre-button {
  padding: 14px 10px;
  border: 2px solid #e9ecef;
  border-radius: 14px;
  background: white;
  color: #495057;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.genre-button:hover {
  border-color: #2d3436;
  color: #2d3436;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.genre-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.genre-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f8f9fa;
}

.size-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 16px;
  margin-top: 24px;
}

.size-button {
  padding: 14px 10px;
  border: 2px solid #e9ecef;
  border-radius: 16px;
  background: white;
  color: #495057;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-button:hover {
  border-color: #2d3436;
  color: #2d3436;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.size-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.body-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}

.body-tabs {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  width: 100%;
  max-width: 300px;
}

.tab-button {
  flex: 1;
  padding: 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  background: white;
  color: #495057;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tab-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.body-image {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  position: relative;
  aspect-ratio: 1/2;
  background: #f8f9fa;
  border-radius: 16px;
  overflow: hidden;
}

.body-front,
.body-back {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body-front svg,
.body-back svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
  background: #f8f9fa;
}

.body-part {
  fill: #e0e0e0;
  cursor: pointer;
  transition: fill 0.3s ease;
}

.body-part:hover {
  fill: #a0a0a0;
}

.body-part[data-selected="true"] {
  fill: #1a1a1a;
}

.selected-part {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  color: #2d3436;
  white-space: pre-wrap;
}

.input-field-day {
  width: calc(100% - 40px);
  padding: 16px 19px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 17px;
  margin-top: 19px;
  transition: border-color 0.2s ease;
}

.textarea-field {
  width: calc(100% - 40px);
  padding: 16px 19px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 17px;
  margin-top: 19px;
  resize: vertical;
  min-height: 140px;
  transition: border-color 0.2s ease;
}

.textarea-field:focus {
  border-color: #2d3436;
  outline: none;
}

.file-upload-container {
  border: 2px dashed #e9ecef;
  border-radius: 16px;
  padding: 40px;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.file-upload-container:hover {
  border-color: #2d3436;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  max-width: 90%;
  width: 60%;
  animation: fadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
}

.uploaded-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 19px;
  margin-top: 24px;
}

.modal-button {
  padding: 8px 32px;
  color: white;
  background-color: #444444;
  border-radius: 16px;
  border: none;
  cursor: pointer;
}

.tattooist-profile {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.profilename {
  margin-left: 20px;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artist-name {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.artist-location {
  color: #666;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.artist-genres {
  margin: 12px;
}

.main-genre {
  background: #2d3436;
  color: white;
  padding: 5px 13px;
  border-radius: 20px;
  font-size: 14px;
  margin-right: 8px;
}

.sub-genres {
  display: inline-flex;
  gap: 8px;
  margin-top: 8px;
}

.sub-genre {
  background: #f1f2f6;
  color: #2d3436;
  padding: 5px 13px;
  border-radius: 20px;
  font-size: 14px;
}

.phone-input-container {
  margin: 16px 0;
}

.error-message {
  color: #ff4757;
  font-size: 14px;
  margin-top: 8px;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  color: #495057;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
}

.input-field:focus {
  border-color: #2d3436;
  outline: none;
}

.input-field.error {
  border-color: #ff4757;
}

.uploaded-images-summary {
  margin-top: 32px;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.image-preview {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.image-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.summary {
  background-color: #f8f9fa;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.summary p {
  margin: 13px 0;
  line-height: 1.6;
}

.summary strong {
  color: #2d3436;
  margin-right: 8px;
}

.budget-info {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.min-budget {
  color: #666;
  font-size: 14px;
}

.selected-budget {
  font-weight: 600;
  color: #2d3436;
}

.nav-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.description-input {
  width: 100%;
  padding: 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 17px;
  margin-top: 19px;
  transition: border-color 0.2s ease;
}

.description-input:focus {
  border-color: #2d3436;
  outline: none;
}

.region-selects {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
}

.location-input-group {
  display: contents;
  position: relative;
  width: 100%;
}

.location-button {
  background: #2d3436;
  color: white;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: center;
}

.location-button i {
  font-size: 13px;
}
</style>
