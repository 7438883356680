<template>
  <div class="chat-list">
    <div class="chat-header">
      <h2>채팅</h2>
    </div>
    
    <div class="chat-rooms">
      <div 
        v-for="room in chatRooms" 
        :key="room.id" 
        class="chat-room-item"
        @click="openChat(room.id)"
      >
        <div class="profile-image">
          <img 
            :src="room.otherUser.profileImageUrl || require('@/assets/default-avatar.png')" 
            :alt="room.otherUser.nickname || room.otherUser.name"
          >
        </div>
        <div class="chat-info">
          <div class="chat-top">
            <span class="nickname">{{ room.otherUser.nickname || room.otherUser.name }}</span>
            <span class="time">{{ formatTime(room.lastMessage?.timestamp) }}</span>
          </div>
          <div class="chat-bottom">
            <p class="last-message">{{ room.lastMessage?.content || '새로운 채팅방' }}</p>
            <div v-if="room.unreadCount" class="unread-count">
              {{ room.unreadCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { db, auth } from '@/firebase';
import { collection, query, where, onSnapshot, orderBy, getDoc, doc } from 'firebase/firestore';
import { useRouter } from 'vue-router';

export default {
  name: 'ChatList',
  setup() {
    const router = useRouter();
    const chatRooms = ref([]);
    let unsubscribe = null;

    const formatTime = (timestamp) => {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      const now = new Date();
      const diff = now - date;
      
      if (diff < 24 * 60 * 60 * 1000) {
        return date.toLocaleTimeString('ko-KR', { 
          hour: '2-digit', 
          minute: '2-digit',
          hour12: false 
        });
      }
      return date.toLocaleDateString('ko-KR', { 
        month: 'short', 
        day: 'numeric' 
      });
    };

    const openChat = (roomId) => {
      router.push(`/chat/${roomId}`);
    };

    onMounted(async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const chatRoomsRef = collection(db, 'chatRooms');
      const q = query(
        chatRoomsRef,
        where('participants', 'array-contains', currentUser.uid),
        orderBy('lastMessage.timestamp', 'desc')
      );

      unsubscribe = onSnapshot(q, async (snapshot) => {
        const rooms = [];
        for (const docSnapshot of snapshot.docs) {
          const roomData = docSnapshot.data();
          const otherUserId = roomData.participants.find(id => id !== currentUser.uid);
          
          // 상대방 정보 가져오기
          const userDoc = await getDoc(doc(db, 'users', otherUserId));
          const otherUserData = userDoc.data();

          rooms.push({
            id: docSnapshot.id,
            ...roomData,
            otherUser: {
              id: otherUserId,
              ...otherUserData
            }
          });
        }
        chatRooms.value = rooms;
      });
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    return {
      chatRooms,
      formatTime,
      openChat
    };
  }
};
</script>

<style scoped>
.chat-list {
  max-width: 768px;
  margin: 0 auto;
  background: white;
  height: 100vh;
}

.chat-header {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.chat-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.chat-room-item {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-room-item:hover {
  background-color: #f8f9fa;
}

.profile-image {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.chat-info {
  flex: 1;
}

.chat-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}

.nickname {
  font-weight: 600;
  font-size: 1rem;
}

.time {
  font-size: 0.8rem;
  color: #868e96;
}

.chat-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-message {
  margin: 0;
  font-size: 0.9rem;
  color: #495057;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
}

.unread-count {
  background: #ff6b6b;
  color: white;
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  min-width: 1.5rem;
  text-align: center;
}
</style> 