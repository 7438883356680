<template>
  <div class="signup-wrap">
    <div class="signup-container">
      <h1 class="signup-title">아티스트 회원가입</h1>
      
      <form @submit.prevent="handleSignUp" class="signup-form">
        <!-- 전화번호 입력 -->
        <div class="input-wrapper">
          <input 
            type="text" 
            v-model="phone" 
            id="phone" 
            required 
            :class="{'has-value': phone}"
            @input="formatPhoneNumber"
            maxlength="13"
            :disabled="isVerified"
          />
          <label for="phone">전화번호</label>
        </div>

        <!-- 인증번호 입력 (조건부 렌더링) -->
        <div v-if="isCodeSent" class="input-wrapper">
          <input 
            type="text" 
            v-model="verificationCode" 
            id="verificationCode" 
            required 
            :class="{'has-value': verificationCode}"
            :disabled="isVerified"
          />
          <label for="verificationCode">인증번호</label>
        </div>

        <!-- 인증번호 전송/확인 버튼 -->
        <div v-if="!isCodeSent" class="button-group">
          <button 
            type="button" 
            @click="sendVerificationCode" 
            class="send-code-button"
            :disabled="isLoading"
          >
            {{ isLoading ? '처리 중...' : '인증번호 전송' }}
          </button>
        </div>
        <div v-else class="button-group">
          <button type="button" @click="verifyCode" class="verify-code-button" :disabled="isVerified">인증번호 확인</button>
        </div>

        <!-- 나머지 입력 필드들은 인증 완료 후에만 표시 -->
        <template v-if="isVerified">
        <!-- 닉네임 입력 -->
          <div class="input-wrapper">
            <input 
              type="text" 
              v-model="nickname" 
              id="nickname" 
              required 
              :class="{'has-value': nickname}"
            />
            <label for="nickname">닉네임</label>
          </div>
          <!-- 대표이미지 업로드 -->
          <div class="input-wrapper image-upload-wrapper">
            <div class="setprofile">
              <input 
                type="file" 
                @change="uploadProfileImage" 
                ref="profileImage"
                id="profileImage" 
                required 
                style="display: none;"
                accept="image/*"
              />
              <div class="image-preview-container">
                <input 
                  type="text" 
                  v-model="profileImageName" 
                  readonly 
                  placeholder="프로필 이미지를 업로드해 주세요"
                />
                <div v-if="profileImageUrl" class="image-preview">
                  <img :src="profileImageUrl" alt="프로필 이미지 미리보기" />
                </div>
              </div>
              <button 
                type="button" 
                @click="triggerFileInput" 
                class="image-upload-button"
              >
                <span class="material-icons">upload</span>
              </button>
            </div>
          </div>

          <!-- 인스타그램 아이디 입력 -->
          <div class="input-wrapper">
            <input 
              type="text" 
              v-model="instagramId" 
              id="instagramId" 
              required 
              :class="{'has-value': instagramId}"
            />
            <label for="instagramId">인스타그램 아이디</label>
          </div>

          <!-- 시간당 작업비용 입력 -->
          <div class="input-wrapper">
            <input 
              type="text" 
              :value="hourlyRate === '비용문의' ? hourlyRate : hourlyRate.toLocaleString()" 
              @input="e => hourlyRate = isHourlyRatePrivate ? '비용문의' : Number(e.target.value.replace(/[^\d]/g, ''))" 
              id="hourlyRate" 
              required 
              :class="{'has-value': hourlyRate}" 
              :disabled="isHourlyRatePrivate"
            />
            <label for="hourlyRate">시간당 작업비용</label>
          </div>
          <div class="private-checkbox-wrapper">
            <input 
              type="checkbox" 
              v-model="isHourlyRatePrivate" 
              @change="toggleHourlyRatePrivacy"
              :id="'hourlyRatePrivate'"
              class="private-checkbox"
            />
            <label :for="'hourlyRatePrivate'" class="private-label">비공개</label>
          </div>

          <!-- 최소 작업비용 입력 -->
          <div class="input-wrapper">
            <input 
              type="text" 
              :value="minRate === '비용문의' ? minRate : minRate.toLocaleString()" 
              @input="e => minRate = isMinRatePrivate ? '비용문의' : Number(e.target.value.replace(/[^\d]/g, ''))" 
              id="minRate" 
              required 
              :class="{'has-value': minRate}" 
              :disabled="isMinRatePrivate"
            />
            <label for="minRate">최소 작업비용</label>
          </div>
          <div class="private-checkbox-wrapper">
            <input 
              type="checkbox" 
              v-model="isMinRatePrivate" 
              @change="toggleMinRatePrivacy"
              :id="'minRatePrivate'"
              class="private-checkbox"
            />
            <label :for="'minRatePrivate'" class="private-label">비공개</label>
          </div>
        </template>

        <!-- 에러 메시지 -->
        <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>

        <!-- 제출 버튼 (인증 완료 후에만 활성화) -->
        <button 
          type="submit" 
          class="submit-button" 
          :disabled="!isVerified"
        >
          회원가입
        </button>
      </form>

      <!-- reCAPTCHA 컨테이너 -->
      <div id="recaptcha-container" class="recaptcha-container"></div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, watch, nextTick } from "vue";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db } from "@/firebase";
import router from "@/router";

export default {
  name: "SignUpArtist",
  setup() {
    // 기본 정보
    const nickname = ref("");
    const phone = ref("");
    const verificationCode = ref("");
    const isCodeSent = ref(false);
    const isVerified = ref(false);
    const isLoading = ref(false);
    const retryTimeoutId = ref(null);
    const retryCount = ref(0);
    let confirmationResult = null;

    // 아티스트 추가 정보
    const userType = ref("nottattooist");
    const instagramId = ref("");
    const profileImageUrl = ref("");
    const location = ref({ address: "" });
    const mainGenre = ref("");
    const hourlyRate = ref("");
    const minRate = ref("");
    const errorMessage = ref("");
    const map = ref(null);
    const marker = ref(null);
    const profileImage = ref(null);
    const profileImageName = ref("");
    const isMapLoaded = ref(false);
    const isHourlyRatePrivate = ref(false);
    const isMinRatePrivate = ref(false);

    // 장르 목록
    const genres = [
      "감성타투", "동양화", "레터링", "라인워크", "블랙워크", "블랙앤그레이", 
      "수채화", "뉴스쿨", "미니타투", "올드스쿨", "이레즈", "일러스트", 
      "컬러타투", "커버업", "치카노", "터치업", "트라이벌"
    ];

    const uploadProfileImage = async (event) => {
      const file = event.target.files[0];
      if (file) {
        // 파일 이름 저장
        profileImageName.value = file.name;
        
        // 이미지 용량 압축
        const compressedFile = await compressImage(file);
        if (compressedFile) {
          const storage = getStorage();
          const uniqueId = Date.now() + '_' + Math.random().toString(36).substring(2, 9);
          const imageRef = storageRef(storage, `profileImages/${uniqueId}`);
          await uploadBytes(imageRef, compressedFile);
          const downloadURL = await getDownloadURL(imageRef);
          profileImageUrl.value = downloadURL; // URL은 백엔드 저장용으로만 사용
        }
      }
    };

    const triggerFileInput = () => {
      profileImage.value.click();
    };

    // 이미지 압축 함수
    const compressImage = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();
        
        reader.onload = () => {
          img.src = reader.result;
        };
        
        reader.readAsDataURL(file);

        img.onload = () => {
          const MAX_WIDTH = 800;  // 최대 너비
          const MAX_HEIGHT = 800; // 최대 높이

          let width = img.width;
          let height = img.height;

          // 비율에 맞게 최대 크기로 조정
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          // canvas에 이미지를 그려서 압축
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // 압축된 이미지를 data URL로 변환
          canvas.toBlob(
            (blob) => {
              if (blob) {
                // 압축된 이미지 파일 생성
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject("이미지 압축에 실패했습니다.");
              }
            },
            file.type,
            0.7 // 압축 품질 (0~1 사이)
          );
        };

        img.onerror = (error) => reject("이미지 로드 실패: " + error);
      });
    };

    const initializeMap = () => {
      // 네이버 지도 API가 로드될 때까지 대기
      const waitForNaverMaps = () => {
        return new Promise((resolve) => {
          if (window.naver && window.naver.maps) {
            resolve();
          } else {
            const checkNaverMaps = setInterval(() => {
              if (window.naver && window.naver.maps) {
                clearInterval(checkNaverMaps);
                resolve();
              }
            }, 100);
          }
        });
      };

      waitForNaverMaps().then(() => {
        const mapContainer = document.getElementById("map");
        if (!mapContainer) {
          console.error("지도 컨테이너가 존재하지 않습니다.");
          return;
        }

        // 서울 중심 좌표
        const defaultCenter = new naver.maps.LatLng(37.5665, 126.978);

        // 지도 생성
        map.value = new naver.maps.Map(mapContainer, {
          center: defaultCenter,
          zoom: 12
        });

        // 지도 로드 완료 표시
        isMapLoaded.value = true;

        // 지도 클릭 이벤트
        naver.maps.Event.addListener(map.value, 'click', function(e) {
          placeMarker(e.coord);
        });
      }).catch(error => {
        console.error("지도 초기화 실패:", error);
        errorMessage.value = "지도를 불러오는데 실패했습니다.";
      });
    };

    const placeMarker = (position) => {
      // 기존 마커가 있다면 제거
      if (marker.value) {
        marker.value.setMap(null);
      }

      // 새 마커 생성
      marker.value = new naver.maps.Marker({
        position: position,
        map: map.value
      });

      // 좌표를 주소로 변환
      naver.maps.Service.reverseGeocode({
        coords: position,
        orders: [
          naver.maps.Service.OrderType.ADDR,
          naver.maps.Service.OrderType.ROAD_ADDR
        ].join(',')
      }, function(status, response) {
        if (status === naver.maps.Service.Status.OK) {
          const result = response.v2.address;
          location.value = {
            address: result.jibunAddress || result.roadAddress,
            latitude: position.lat(),
            longitude: position.lng()
          };
        }
      });
    };

    const setCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const userLocation = new naver.maps.LatLng(lat, lng);
            
            if (map.value) {
              map.value.setCenter(userLocation);
              map.value.setZoom(14);
              placeMarker(userLocation);
            }
          },
          (error) => {
            console.error("위치 정보 가져오기 실패:", error);
            alert("위치 정보를 가져올 수 없습니다. 위치 권한을 확인해주세요.");
          }
        );
      } else {
        alert("이 브라우저에서는 위치 정보를 사용할 수 없습니다.");
      }
    };

    // 전화번호 인증 관련 함수들 추가
    const initializeRecaptcha = () => {
      try {
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'normal',
            'callback': () => {
              sendVerificationCodeInternal();
            },
            'expired-callback': () => {
              errorMessage.value = "reCAPTCHA가 만료되었습니다. 다시 시도해주세요.";
              if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
                window.recaptchaVerifier = null;
              }
            }
          });
        }
      } catch (error) {
        console.error("RecaptchaVerifier 초기화 실패:", error);
        errorMessage.value = "인증 초기화에 실패했습니다.";
      }
    };

    const sendVerificationCodeInternal = async () => {
      if (isLoading.value) return;
      
      try {
        isLoading.value = true;
        let phoneNumber = phone.value.replace(/[^0-9]/g, '');
        if (phoneNumber.startsWith('0')) {
          phoneNumber = phoneNumber.substring(1);
        }
        phoneNumber = `+82${phoneNumber}`;

        confirmationResult = await signInWithPhoneNumber(
          auth, 
          phoneNumber, 
          window.recaptchaVerifier
        );
        
        isCodeSent.value = true;
        alert("인증번호가 전송되었습니다.");
        retryCount.value = 0;
      } catch (error) {
        console.error("인증번호 전송 실패:", error);
        handleVerificationError(error);
      } finally {
        isLoading.value = false;
      }
    };

    const sendVerificationCode = async () => {
      try {
        errorMessage.value = "";
        if (!phone.value) {
          errorMessage.value = "전화번호를 입력해주세요.";
          return;
        }

        if (window.recaptchaVerifier) {
          await window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        }

        initializeRecaptcha();
        if (window.recaptchaVerifier) {
          await window.recaptchaVerifier.render();
        }
      } catch (error) {
        console.error("reCAPTCHA 초기화/렌더링 실패:", error);
        errorMessage.value = "인증 초기화에 실패했습니다. 다시 시도해주세요.";
      }
    };

    const verifyCode = async () => {
      try {
        if (!verificationCode.value) {
          errorMessage.value = "인증번호를 입력해주세요.";
          return;
        }

        await confirmationResult.confirm(verificationCode.value);
        isVerified.value = true;
        alert("전화번호 인증이 완료되었습니다.");

        // 인증 완료 후 지도 초기화
        nextTick(() => {
          initializeMap();
        });
      } catch (error) {
        console.error("인증번호 확인 실패:", error);
        errorMessage.value = "인증번호가 올바르지 않습니다.";
      }
    };

    const handleSignUp = async () => {
      try {
        if (!isVerified.value) {
          errorMessage.value = "전화번호 인증이 필요합니다.";
          return;
        }

        const userInfo = {
          nickname: nickname.value,
          phone: phone.value,
          userType: userType.value,
          instagramId: instagramId.value,
          profileImageUrl: profileImageUrl.value,
          location: location.value,
          mainGenre: mainGenre.value,
          hourlyRate: hourlyRate.value,
          minRate: minRate.value,
        };

        // 현재 인증된 사용자의 UID로 문서 생성
        const user = auth.currentUser;
        if (!user) throw new Error("사용자 인증 정보가 없습니다.");

        await setDoc(doc(db, "users", user.uid), userInfo);
        alert("회원가입이 완료되었습니다!");
        router.push('/');
      } catch (error) {
        console.error("회원가입 실패:", error);
        errorMessage.value = "회원가입에 실패했습니다. 다시 시도해주세요.";
      }
    };

    const formatPhoneNumber = () => {
      let formattedPhone = phone.value.replace(/[^0-9]/g, ''); // 숫자만 추출
      if (formattedPhone.length > 3 && formattedPhone.length <= 7) {
        formattedPhone = formattedPhone.replace(/(\d{3})(\d{1,4})/, '$1-$2');
      } else if (formattedPhone.length > 7) {
        formattedPhone = formattedPhone.replace(/(\d{3})(\d{4})(\d{1,4})/, '$1-$2-$3');
      }
      phone.value = formattedPhone;
    };

    const handleVerificationError = (error) => {
      if (error.code === 'auth/too-many-requests') {
        errorMessage.value = "너무 많은 요청이 발생했습니다. 잠시 후 다시 시도해주세요.";
        const waitTime = Math.min(30000 * (retryCount.value + 1), 300000);
        retryCount.value++;
        
        if (retryTimeoutId.value) {
          clearTimeout(retryTimeoutId.value);
        }
        
        retryTimeoutId.value = setTimeout(() => {
          errorMessage.value = "";
        }, waitTime);
      } else if (error.code === 'auth/invalid-phone-number') {
        errorMessage.value = "올바른 전화번호 형식이 아닙니다.";
      } else {
        errorMessage.value = "인증번호 전송에 실패했습니다. 다시 시도해주세요.";
      }

      if (window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        } catch (e) {
          console.error("reCAPTCHA clear 실패:", e);
        }
      }
    };

    const toggleHourlyRatePrivacy = () => {
      if (isHourlyRatePrivate.value) {
        hourlyRate.value = "비용문의";
      } else {
        hourlyRate.value = ""; // 초기화 또는 다른 로직에 따라 설정
      }
    };

    const toggleMinRatePrivacy = () => {
      if (isMinRatePrivate.value) {
        minRate.value = "비용문의";
      } else {
        minRate.value = ""; // 초기화 또는 다른 로직에 따라 설정
      }
    };

    onUnmounted(() => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
      if (retryTimeoutId.value) {
        clearTimeout(retryTimeoutId.value);
      }
    });

    // 인증이 완료된 후에만 지도 초기화
    watch(() => isVerified.value, (newValue) => {
      if (newValue) {
        nextTick(() => {
          initializeMap();
        });
      }
    });

    return {
      nickname,
      phone,
      verificationCode,
      isCodeSent,
      isVerified,
      isLoading,
      sendVerificationCode,
      verifyCode,
      handleSignUp,
      genres,
      errorMessage,
      uploadProfileImage,
      initializeMap,
      setCurrentLocation,
      formatPhoneNumber,
      triggerFileInput,
      profileImage,
      profileImageName,
      userType,
      instagramId,
      profileImageUrl,
      location,
      mainGenre,
      hourlyRate,
      minRate,
      isMapLoaded,
      isHourlyRatePrivate,
      isMinRatePrivate,
      toggleHourlyRatePrivacy,
      toggleMinRatePrivacy,
    };
  },
};
</script>

<style scoped>
.signup-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 60px;
  width: 100%;
  padding: 0 0 20px 0;
}

.signup-container {
  width: 85%;
  margin-top: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.signup-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input {
  width: calc(100% - 30px);
  height: 25px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.input-wrapper label {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  transition: all 0.3s;
  pointer-events: none;
}

.input-wrapper input:focus {
  border-color: #333;
}

.input-wrapper input:focus + label,
.input-wrapper input.has-value + label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  background-color: white;
  padding: 0 5px;
  color: #333;
}

.map-container {
  width: 100%;
  height: 250px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.map-container::before {
  content: "지도를 불러오는 중...";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 4px;
  z-index: 1;
  display: block;
}

.map-container.loaded::before {
  display: none;
}

.setlocation, .setprofile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.setlocation input {
  width: 100%;
}

.location-button, .image-upload-button {
  width: 67px;
  height: 57px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.material-icons {
  font-size: 28px;
}

.image-preview-container {
  position: relative;
  flex-grow: 1;
}

.image-preview {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  margin-bottom: 10px;
}

.image-preview img {
  max-width: 200px;
  max-height: 200px;
  display: block;
}

/* 호버 시 이미지 미리보기 표시 */
.image-preview-container:hover .image-preview {
  display: block;
}

/* 미리보기 이미지 아래에 화살표 추가 */
.image-preview::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: white transparent transparent;
}

.submit-button,
.send-code-button,
.verify-code-button {
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.error-msg {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.recaptcha-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  min-height: 65px;
}

.send-code-button:disabled,
.verify-code-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.private-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: -15px;
}

.private-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.private-label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  user-select: none;
  display: flex;
  align-items: center;
  margin: 0;
  top: 0;
  transform: none;
  background: none;
}

.private-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: white;
  transition: all 0.2s ease;
}

.private-label:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  opacity: 0;
  transition: all 0.2s ease;
}

.private-checkbox:checked + .private-label:before {
  background-color: #333;
  border-color: #333;
}

.private-checkbox:checked + .private-label:after {
  opacity: 1;
}

.private-checkbox:focus + .private-label:before {
  box-shadow: 0 0 0 2px rgba(51, 51, 51, 0.2);
}

/* 호버 효과 */
.private-label:hover:before {
  border-color: #333;
}

/* 비활성화된 입력 필드 스타일 */
input[type="text"]:disabled,
input[type="number"]:disabled {
  background-color: #f5f5f5;
  color: #666;
  cursor: not-allowed;
}
</style>