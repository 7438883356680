<template>
    <div>
      <h2>피드</h2>
      <input v-model="newPost" placeholder="새 게시글 작성..." />
      <button @click="addPost">게시</button>
  
      <div v-for="post in posts" :key="post.id">
        <p>{{ post.text }}</p>
        <input v-model="commentText[post.id]" placeholder="댓글 작성..." />
        <button @click="addComment(post.id)">댓글</button>
  
        <div v-for="comment in post.comments" :key="comment.id">
          <p>ㄴ {{ comment.text }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from "vue";
  import { db } from "@/firebase";
  import { collection, addDoc, onSnapshot, updateDoc, doc } from "firebase/firestore";
  
  const posts = ref([]);
  const newPost = ref("");
  const commentText = ref({});
  
  const fetchPosts = () => {
    onSnapshot(collection(db, "posts"), (snapshot) => {
      posts.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    });
  };
  
  const addPost = async () => {
    await addDoc(collection(db, "posts"), { text: newPost.value, comments: [] });
    newPost.value = "";
  };
  
  const addComment = async (postId) => {
    const postRef = doc(db, "posts", postId);
    const post = posts.value.find((p) => p.id === postId);
    const updatedComments = [
      ...post.comments,
      { text: commentText.value[postId] || "" },
    ];
  
    await updateDoc(postRef, { comments: updatedComments });
    commentText.value[postId] = "";
  };
  
  onMounted(fetchPosts);
  </script>
  