import { db } from '@/firebase';
import { collection, doc, setDoc, query, where, getDocs } from 'firebase/firestore';

export async function createOrGetChatRoom(currentUserId, otherUserId) {
  try {
    // 이미 존재하는 채팅방 확인
    const chatRoomsRef = collection(db, 'chatRooms');
    const q = query(
      chatRoomsRef,
      where('participants', 'array-contains', currentUserId)
    );
    
    const querySnapshot = await getDocs(q);
    const existingRoom = querySnapshot.docs.find(doc => 
      doc.data().participants.includes(otherUserId)
    );

    if (existingRoom) {
      return existingRoom.id;
    }

    // 새 채팅방 생성
    const newRoomRef = doc(collection(db, 'chatRooms'));
    await setDoc(newRoomRef, {
      participants: [currentUserId, otherUserId],
      createdAt: new Date(),
      lastMessage: {
        content: '',
        timestamp: new Date()
      }
    });

    return newRoomRef.id;
  } catch (error) {
    console.error('채팅방 생성 실패:', error);
    throw error;
  }
} 