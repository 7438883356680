import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { 
  getFirestore, 
  doc, 
  updateDoc 
} from "firebase/firestore";
import { 
  getMessaging, 
  getToken, 
  onMessage
} from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";

// Firebase 설정 정보
const firebaseConfig = {
    apiKey: "AIzaSyAdpdeB1s5S-ZS9BSyhD5AjTHgN1VvBoms",
    authDomain: "tattoomap3.firebaseapp.com",
    projectId: "tattoomap3",
    storageBucket: "tattoomap3.firebasestorage.app",
    messagingSenderId: "615573376679",
    appId: "1:615573376679:web:fdea18561ca5e89dd69582",
    measurementId: "G-SJL9CQKCGC"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);

// Firebase 서비스 초기화
const auth = getAuth(app);
auth.languageCode = 'ko';

const isDevelopment = window.location.hostname === 'localhost' || 
                     window.location.hostname === '127.0.0.1';

if (isDevelopment) {
    auth.settings.appVerificationDisabledForTesting = true;
}

const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

// 초기 FCM 토큰 설정
const initializeFCM = async () => {
    // 로그인하지 않은 상태면 리턴
    if (!auth.currentUser) return;

    try {
        const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                     window.navigator.standalone || 
                     document.referrer.includes('android-app://');
        
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const userRef = doc(db, "users", auth.currentUser.uid);

        // 로그인 직후에는 알림 권한 요청하지 않고 DB만 업데이트
        await updateDoc(userRef, {
            tokenLastUpdated: new Date(),
            platform: isPWA ? 'pwa' : (isMobile ? 'mobile' : 'desktop'),
            notificationBlocked: true
        });

        // 알림 권한이 이미 허용된 경우에만 토큰 요청
        if (Notification.permission === 'granted') {
            try {
                const registration = await navigator.serviceWorker.ready;
                const currentToken = await getToken(messaging, {
                    vapidKey: 'BOva3icccl2C8tCfNFgXvsUiCFCBxVscb1SNbtxaFySTjlEZc0A9DE7gxSLpPTbC05kMU2F8h3qy7Y_pDwr-87c',
                    serviceWorkerRegistration: registration
                });

                if (currentToken) {
                    await updateDoc(userRef, {
                        fcmToken: currentToken,
                        notificationBlocked: false
                    });
                    console.log('FCM 토큰이 업데이트되었습니다.');
                }
            } catch (error) {
                console.log('토큰 가져오기 실패:', error);
            }
        }
    } catch (error) {
        console.error('FCM 초기화 실패:', error);
    }
};

// FCM 메시지 수신 처리 (권한이 있을 때만)
if (Notification.permission === 'granted') {
    onMessage(messaging, (payload) => {
        console.log('Message received:', payload);
    });
}

// 사용자 로그인 상태 변경 감지
onAuthStateChanged(auth, async (user) => {
    if (user) {
        // 로그인 상태일 때만 FCM 초기화
        try {
            await initializeFCM();
        } catch (error) {
            console.error('FCM 초기화 실패:', error);
        }
    }
});

// FCM 권한 요청 함수 추가
const requestNotificationPermission = async () => {
    if (!auth.currentUser) return;

    try {
        const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                     window.navigator.standalone || 
                     document.referrer.includes('android-app://');
        
        const userRef = doc(db, "users", auth.currentUser.uid);
        let permission = Notification.permission;

        // default 상태일 때만 권한 요청
        if (permission === 'default') {
            try {
                permission = await Notification.requestPermission();
            } catch (error) {
                console.log('알림 권한 요청 실패:', error);
                return;
            }
        }

        if (permission === 'granted') {
            try {
                const registration = await navigator.serviceWorker.ready;
                const currentToken = await getToken(messaging, {
                    vapidKey: 'BOva3icccl2C8tCfNFgXvsUiCFCBxVscb1SNbtxaFySTjlEZc0A9DE7gxSLpPTbC05kMU2F8h3qy7Y_pDwr-87c',
                    serviceWorkerRegistration: registration
                });

                if (currentToken) {
                    await updateDoc(userRef, {
                        fcmToken: currentToken,
                        notificationBlocked: false,
                        tokenLastUpdated: new Date()
                    });
                    console.log('FCM 토큰이 업데이트되었습니다.');
                }
            } catch (error) {
                console.log('토큰 가져오기 실패:', error);
            }
        } else if (permission === 'denied') {
            // 권한이 거부된 경우 안내 메시지
            let message = '';
            if (isPWA) {
                if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
                    message = '알림 수신을 위해 설정이 필요합니다.\n\n설정 방법:\n1. 아이폰 설정 앱 실행\n2. 타투맵 앱 선택\n3. 알림 허용으로 변경';
                } else if (/Android/.test(navigator.userAgent)) {
                    message = '알림 수신을 위해 설정이 필요합니다.\n\n설정 방법:\n1. 휴대폰 설정 앱 실행\n2. 앱 및 알림 선택\n3. 타투맵 앱 선택\n4. 알림 허용으로 변경';
                }
            } else {
                let settingsUrl = '';
                if (navigator.userAgent.includes('Chrome')) {
                    settingsUrl = 'chrome://settings/content/notifications';
                } else if (navigator.userAgent.includes('Firefox')) {
                    settingsUrl = 'about:preferences#privacy';
                } else if (navigator.userAgent.includes('Safari')) {
                    settingsUrl = 'safari://settings/websites';
                }
                message = `알림 수신을 위해 브라우저 설정에서 알림을 허용해주세요.\n\n설정 위치: ${settingsUrl}`;
            }
            alert(message);
        }
    } catch (error) {
        console.error('알림 권한 요청 실패:', error);
    }
};

export { 
    auth, 
    db, 
    storage, 
    messaging, 
    getToken,
    requestNotificationPermission  // 새로운 함수 export
};
