<template>
  <div class="design-manager-container">
    <div class="header-section">
      <h2 class="main-title">디자인 관리</h2>
      <div class="edit-toggle">
        <button v-if="!isEditing" @click="enableEditing" class="edit-button">
          <i class="fas fa-plus"></i> 새 디자인 추가
        </button>
        <button v-else @click="cancelEdit" class="cancel-button">
          <i class="fas fa-times"></i> 취소
        </button>
      </div>
    </div>

    <div v-if="errorMessage" class="error-banner">
      <i class="fas fa-exclamation-circle"></i>
      {{ errorMessage }}
    </div>

    <!-- 디자인 업로드/수정 폼 -->
    <transition name="slide-fade">
      <form v-if="isEditing" @submit.prevent="handleSubmit" class="design-form">
        <div class="form-header">
          <h3>{{ editDesignId ? '디자인 수정' : '새 디자인 등록' }}</h3>
        </div>

        <div class="form-grid">
          <div class="form-left">
            <div class="form-group image-upload">
              <label for="designImage">
                <div class="upload-placeholder" v-if="!previewImage">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <span>이미지를 선택하세요</span>
                  <p class="upload-guide">
                    권장 사항:<br>
                    - 이미지 형식: JPG, PNG<br>
                    - 최대 파일 크기: 10MB<br>
                    - 권장 해상도: 1920x1920 이하
                  </p>
                </div>
                <img v-else :src="previewImage" alt="미리보기" class="preview-image" />
              </label>
              <input
                type="file"
                id="designImage"
                @change="handleImageChange"
                accept="image/*"
                hidden
              />
            </div>
          </div>

          <div class="form-right">
            <div class="form-group">
              <label for="description">디자인 설명</label>
              <textarea
                id="description"
                v-model="description"
                required
                placeholder="디자인에 대해 설명해주세요"
              ></textarea>
            </div>
            
            <div class="form-group">
              <label for="price">예상 비용</label>
              <div class="price-input-group">
                <input
                  type="number"
                  id="price"
                  v-model="price"
                  :required="!isPrivatePrice"
                  :disabled="isPrivatePrice"
                  placeholder="예상 비용을 입력해주세요"
                />
                <div class="private-toggle">
                  <input 
                    type="checkbox" 
                    id="privatePrice" 
                    v-model="isPrivatePrice"
                  />
                  <label for="privatePrice">비용문의</label>
                </div>
              </div>
              <small class="price-hint" v-if="isPrivatePrice">
                비용문의로 표시됩니다
              </small>
            </div>
            
            <div class="form-group">
              <label for="timeSpent">예상 소요시간</label>
              <input
                type="number"
                id="timeSpent"
                v-model="timeSpent"
                required
                placeholder="예상 소요시간을 입력해주세요"
              />
            </div>

            <div class="form-group">
              <label>장르</label>
              <div class="genre-container">
                <div 
                  v-for="genre in genres" 
                  :key="genre" 
                  :class="['genre-item', { selected: mainGenre === genre }]" 
                  @click="selectMainGenre(genre)"
                >
                  {{ genre }}
                </div>
              </div>
            </div>

            <div class="form-actions">
              <button type="submit" class="save-button">
                <i class="fas fa-check"></i>
                {{ editDesignId ? '수정 완료' : '등록하기' }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </transition>

    <!-- 디자인 목록 -->
    <div class="design-section">
      <div class="section-header">
        <h3>내 디자인 목록</h3>
        <div class="design-stats">
          <span>총 {{ designs.length }}개의 디자인</span>
        </div>
      </div>

      <div v-if="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <span>디자인을 불러오는 중...</span>
      </div>

      <div v-else-if="designs.length === 0" class="empty-state">
        <i class="fas fa-paint-brush"></i>
        <p>등록된 디자인이 없습니다</p>
        <button @click="enableEditing" class="add-first-button">
          첫 디자인 등록하기
        </button>
      </div>

      <div v-else class="designs-grid">
        <div v-for="design in designs" :key="design.id" class="design-item">
          <div class="image-container">
            <img :src="design.imageUrl" alt="디자인 이미지" class="design-image" />
            <div class="overlay">
              <div class="overlay-content">
                <div class="design-info">
                  <div class="info-row">
                    <i class="fas fa-pen"></i>
                    <p>{{ design.description }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-clock"></i>
                    <p>{{ design.timeSpent }}시간</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-coins"></i>
                    <p>{{ design.price ? '비용문의' : `${design.price?.toLocaleString()}원` }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-tag"></i>
                    <p>{{ design.mainGenre }}</p>
                  </div>
                </div>
                <div class="action-buttons">
                  <button @click="editDesign(design)" class="edit-button">
                    <i class="fas fa-edit"></i> 수정
                  </button>
                  <button @click="deleteDesign(design.id)" class="delete-button">
                    <i class="fas fa-trash-alt"></i> 삭제
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Compressor from "compressorjs";
import { db, storage, auth } from "@/firebase";
import { 
  collection, 
  addDoc, 
  getDocs,
  getDoc,
  query, 
  where, 
  doc, 
  updateDoc, 
  deleteDoc, 
  serverTimestamp 
} from "firebase/firestore";
import { 
  ref as storageRef, 
  uploadBytesResumable, 
  getDownloadURL, 
  deleteObject 
} from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";

export default {
  name: "DesignManager",
  setup() {
    const isEditing = ref(false);
    const editDesignId = ref(null);
    const description = ref("");
    const timeSpent = ref(0);
    const price = ref(0);
    const isPrivatePrice = ref(false);
    const genres = [
      "감성타투", "동양화", "레터링", "라인워크", "블랙워크", "블랙앤그레이",
      "수채화", "뉴스쿨", "미니타투", "올드스쿨", "이레즈미", "일러스트",
      "컬러타투", "커버업", "치카노", "터치업", "트라이벌","애니/만화"
    ];
    const mainGenre = ref("");

    const designImage = ref(null);
    const previewImage = ref("");
    const designs = ref([]);
    const loading = ref(false);
    const errorMessage = ref("");
    const userId = ref("");

    // 디자인 로드 함수
    const loadDesigns = async () => {
      loading.value = true;
      errorMessage.value = "";
      try {
        if (!userId.value) {
          errorMessage.value = "로그인이 필요합니다.";
          designs.value = [];
          return;
        }

        const q = query(collection(db, "designs"), where("userId", "==", userId.value));
        const querySnapshot = await getDocs(q);
        designs.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("디자인 로드 오류:", error);
        errorMessage.value = "디자인을 불러오는 데 실패했습니다.";
      } finally {
        loading.value = false;
      }
    };

    // Firebase 인증 상태 변경 시 UID 설정 및 디자인 로드
    const checkAuth = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          userId.value = user.uid;
          loadDesigns();
        } else {
          errorMessage.value = "로그인이 필요합니다.";
          designs.value = [];
        }
      });
    };

    // 이미지 변경 처리
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        new Compressor(file, {
          quality: 0.6,
          success(compressedResult) {
            designImage.value = compressedResult;
            const reader = new FileReader();
            reader.readAsDataURL(compressedResult);
            reader.onload = () => {
              previewImage.value = reader.result;
            };
          },
          error(err) {
            console.error("이미지 압축 오류:", err);
            errorMessage.value = "이미지 압축에 실패했습니다.";
          },
        });
      }
    };

    // Storage 경로 추출 함수 추가
    const getStoragePathFromUrl = (url) => {
      try {
        const urlObj = new URL(url);
        const path = urlObj.pathname.split('/o/')[1];
        if (!path) return null;
        return decodeURIComponent(path.split('?')[0]);
      } catch (error) {
        console.error("Storage 경로 추출 오류:", error);
        return null;
      }
    };

    // 디자인 제출 처리
    const handleSubmit = async () => {
      if (!designImage.value && !editDesignId.value) {
        alert("이미지를 선택해주세요.");
        return;
      }

      if (!mainGenre.value) {
        alert("주요 장르를 선택해주세요.");
        return;
      }

      if (loading.value === true) {
        alert("이미 작업 중입니다. 잠시만 기다려주세요.");
        return;
      }

      loading.value = true;
      errorMessage.value = "";

      try {
        let downloadURL = previewImage.value;

        if (designImage.value) {
          const timestamp = Date.now();
          const imageName = `${timestamp}_${designImage.value.name}`;
          const imageStorageRef = storageRef(storage, `designs/${userId.value}/${imageName}`);
          const uploadTask = uploadBytesResumable(imageStorageRef, designImage.value);

          // 업로드 진행률 표시 추가
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }
          );

          await new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              null,
              (error) => reject(error),
              () => resolve()
            );
          });

          downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        }

        const designData = {
          description: description.value,
          timeSpent: timeSpent.value,
          price: isPrivatePrice.value ? "비용문의" : price.value,
          mainGenre: mainGenre.value,
          userId: userId.value,
          uploadTime: serverTimestamp(),
          imageUrl: downloadURL,
        };

        if (editDesignId.value) {
          const designDoc = doc(db, "designs", editDesignId.value);
          await updateDoc(designDoc, designData);
        } else {
          await addDoc(collection(db, "designs"), designData);
        }

        resetForm();
        await loadDesigns();
      } catch (error) {
        console.error("디자인 저장 오류:", error);
        errorMessage.value = "디자인을 저장하는 데 실패했습니다. 다시 시도해 주세요.";
      } finally {
        loading.value = false;
        isEditing.value = false;
      }
    };

    // 디자인 수정
    const editDesign = (design) => {
      isEditing.value = true;
      editDesignId.value = design.id;
      description.value = design.description;
      timeSpent.value = design.timeSpent;
      price.value = design.price || 0;
      mainGenre.value = design.mainGenre;
      previewImage.value = design.imageUrl;
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // 디자인 삭제
    const deleteDesign = async (designId) => {
      const userConfirmed = confirm(
        "이 디자인을 삭제하시겠습니까?\n" +
        "삭제된 디자인은 복구할 수 없습니다."
      );
      if (!userConfirmed) return;

      if (loading.value === true) {
        alert("이미 작업 중입니다. 잠시만 기다려주세요.");
        return;
      }

      loading.value = true;
      errorMessage.value = "";

      try {
        const designDoc = doc(db, "designs", designId);
        const designSnapshot = await getDoc(designDoc);
        
        if (designSnapshot.exists()) {
          const designData = designSnapshot.data();
          if (designData.imageUrl) {
            const storagePath = getStoragePathFromUrl(designData.imageUrl);
            if (storagePath) {
              const imageRef = storageRef(storage, storagePath);
              try {
                await deleteObject(imageRef);
                console.log("이미지 삭제 성공:", storagePath);
              } catch (err) {
                console.warn("이미지 삭제 실패:", err);
              }
            }
          }
          await deleteDoc(designDoc);
          await loadDesigns();
          alert("디자인이 성공적으로 삭제되었습니다.");
        }
      } catch (error) {
        console.error("디자인 삭제 오류:", error);
        errorMessage.value = "디자인을 삭제하는 데 실패했습니다. 다시 시도해 주세요.";
      } finally {
        loading.value = false;
      }
    };

    // 편집 모드 활성화
    const enableEditing = () => {
      isEditing.value = true;
      editDesignId.value = null;
      resetForm();
    };

    // 편집 취소
    const cancelEdit = () => {
      isEditing.value = false;
      editDesignId.value = null;
      resetForm();
    };

    // 폼 초기화
    const resetForm = () => {
      description.value = "";
      timeSpent.value = "";
      price.value = "";
      isPrivatePrice.value = false;
      mainGenre.value = "";
      designImage.value = null;
      previewImage.value = "";
      errorMessage.value = "";
    };

    // 메인 장르 선택
    const selectMainGenre = (genre) => {
      if (!isEditing.value) return;
      mainGenre.value = mainGenre.value === genre ? "" : genre;
    };

    // 타임스탬프 형식 변환
    const formatTimestamp = (timestamp) => {
      if (!timestamp) return "";
      const date = timestamp.toDate();
      return date.toLocaleString();
    };

    onMounted(() => {
      checkAuth();
    });

    return {
      isEditing,
      editDesignId,
      description,
      timeSpent,
      price,
      isPrivatePrice,
      genres,
      mainGenre,
      designImage,
      previewImage,
      designs,
      loading,
      errorMessage,
      enableEditing,
      cancelEdit,
      handleImageChange,
      handleSubmit,
      editDesign,
      deleteDesign,
      selectMainGenre,
      formatTimestamp,
    };
  },
};
</script>

<style scoped>
.design-manager-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px;
  margin-top: 60px;
}

/* 헤더 섹션 스타일 */
.header-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 20px;
}

.main-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0;
}

/* 버튼 스타일 */
.edit-toggle {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-button, 
.save-button {
  background: #333;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button,
.delete-button {
  background: #666;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 30px;
}

/* 폼 스타일 */
.design-form {
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.form-header {
  margin-bottom: 30px;
}

.form-header h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin: 0;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.image-upload {
  width: 100%;
  aspect-ratio: 1;
  border: 2px dashed #ddd;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #666;
  padding: 20px;
  text-align: center;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 입력 필드 스타일 */
input[type="text"],
input[type="number"],
textarea {
  width: 90%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

textarea {
  width: calc(100% - 30px);
  min-height: 120px;
  padding: 12px;
  border: 2px solid #e5e7eb;
  border-radius: 12px;
  resize: vertical;
  transition: border-color 0.3s ease;
}

/* 장르 선택 스타일 */
.genre-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.genre-item {
  padding: 8px 16px;
  border: 2px solid #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.genre-item.selected {
  background: #333;
  color: white;
  border-color: #333;
}

/* 디자인 그리드 스타일 */
.designs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

.design-item {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
}

.design-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  padding: 20px;
}

.design-item:hover .overlay {
  opacity: 1;
}

.overlay-content {
  color: white;
  text-align: center;
}

.info-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
}

.info-row i {
  width: 20px;
  color: #fff;
}

.info-row p {
  margin: 0;
  color: #fff;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.design-section {
  margin-top: 40px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.design-stats {
  color: #6b7280;
  font-size: 0.9rem;
}

.empty-state {
  text-align: center;
  padding: 60px 20px;
  background: #f9fafb;
  border-radius: 16px;
}

.empty-state i {
  font-size: 3rem;
  color: #9ca3af;
  margin-bottom: 20px;
}

.add-first-button {
  margin-top: 20px;
  background: #333;
  color: white;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 로딩 및 에러 스타일 */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-banner {
  background: #ff4444;
  color: white;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 트랜지션 애니메이션 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

/* 안내 텍스트 스타일 추가 */
.upload-guide {
  font-size: 0.8rem;
  color: #666;
  margin-top: 10px;
  line-height: 1.4;
}

/* 가격 입력 그룹 스타일 추가 */
.price-input-group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.private-toggle {
  display: flex;
  align-items: center;
  gap: 6px;
}

.private-toggle input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.private-toggle label {
  font-size: 0.9rem;
  color: #666;
  cursor: pointer;
}

/* 비활성화된 입력 필드 스타일 */
input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.price-hint {
  display: block;
  margin-top: 4px;
  font-size: 0.8rem;
  color: #666;
}
</style> 