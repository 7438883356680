//(아티스트) 받은 의뢰 목록

<template>
  <div class="tattoo-requests">
    <h2 class="title">받은 타투 상담 요청</h2>
    </div> 
    <!-- 요청 목록 -->
    <div class="requests-list">
      <div v-for="request in requests" 
           :key="request.id" 
           class="request-card"
           @click="openRequestModal(request)">
        <div class="request-header">
          <span class="request-date">{{ formatDate(request.createdAt) }}</span>
          <span :class="['status-badge', request.status]">
            {{ request.status === 'unread' ? '미확인' : '확인함' }}
          </span>
        </div>
        <div class="request-summary">
          <p class="style">{{ request.tattooType }}</p>
          <p class="size">{{ request.size }}</p>
          <p class="budget">{{ formatPrice(request.budget) }}</p>
        </div>
      </div>
    </div>

    <!-- 모달 -->
    <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="closeModal">&times;</button>
        
        <div v-if="selectedRequest" class="request-details">
          <h3>상담 요청 상세 내용</h3>
          <div class="details-content">
            <div class="detail-row">
              <span class="label">요청 일시</span>
              <span class="value">{{ formatDate(selectedRequest.createdAt) }}</span>
            </div>
            <div class="detail-row">
              <span class="label">타투 스타일</span>
              <span class="value">{{ selectedRequest.tattooType }}</span>
            </div>
            <div class="detail-row">
              <span class="label">사이즈</span>
              <span class="value">{{ selectedRequest.size }}</span>
            </div>
            <div class="detail-row">
              <span class="label">부위</span>
              <div class="value body-parts">
                <!-- 앞면 부위 -->
                <div v-if="getFrontParts.length > 0" class="parts-section">
                  <h4>앞면</h4>
                  <span>{{ getFrontParts.join(', ') }}</span>
                </div>
                <!-- 뒷면 부위 -->
                <div v-if="getBackParts.length > 0" class="parts-section">
                  <h4>뒷면</h4>
                  <span>{{ getBackParts.join(', ') }}</span>
                </div>
              </div>
            </div>
            <div class="detail-row">
              <span class="label">예산</span>
              <span class="value">{{ formatPrice(selectedRequest.budget) }}</span>
            </div>
            <div class="detail-row">
              <span class="label">희망 지역</span>
              <span class="value">{{ selectedRequest.city }} {{ selectedRequest.district }}</span>
            </div>
            <div class="detail-row">
              <span class="label">희망 일시</span>
              <span class="value">{{ selectedRequest.preferredDate }} {{ selectedRequest.preferredTime }}</span>
            </div>
            <div class="detail-row">
              <span class="label">연락처</span>
              <span class="value">{{ selectedRequest.phone }}</span>
            </div>
            <div class="detail-row description">
              <span class="label">상세 설명</span>
              <p class="value">{{ selectedRequest.description }}</p>
            </div>

            <!-- 참고 이미지 -->
            <div v-if="selectedRequest?.referenceImages?.length" class="reference-images">
              <span class="label">참고 이미지</span>
              <div class="images-grid">
                <div v-for="(imageUrl, index) in selectedRequest.referenceImages" 
                     :key="index" 
                     class="image-preview"
                     @click="openImageViewer(imageUrl)">
                  <img :src="imageUrl" :alt="`참고 이미지 ${index + 1}`" />
                </div>
              </div>
            </div>

            <!-- 신체 부위 시각화 추가 -->
            <div class="body-visualization">
              <h4>선택된 부위 시각화</h4>
              <div class="body-map">
                <div class="body-tabs">
                  <button 
                    :class="['tab-button', { active: activeTab === 'front' }]"
                    @click="activeTab = 'front'"
                  >
                    앞면
                  </button>
                  <button 
                    :class="['tab-button', { active: activeTab === 'back' }]"
                    @click="activeTab = 'back'"
                  >
                    뒷면
                  </button>
                </div>
                <div class="body-image">
                  <!-- 앞면 -->
                  <div v-show="activeTab === 'front'" class="body-front">
                    <svg viewBox="0 0 226 790" xmlns="http://www.w3.org/2000/svg">
                      <path :data-selected="isPartSelected('얼굴')" class="body-part" d="M137.63,93.19c7.08-5.27,7.81-20.71,7.81-20.71,0,0,.55,2.72,3.27.91,2.72-1.81,3.64-20.16,2.91-21.8s-3.82-1.09-3.82-1.09c0,0,4.35-23.98-3.09-36.33C137.26,1.81,119.64,0,112.73,0s-24.52,1.81-31.97,14.17c-7.44,12.36-3.09,36.33-3.09,36.33,0,0-3.09-.55-3.82,1.09-.73,1.63.18,19.98,2.91,21.8,2.72,1.81,3.27-.91,3.27-.91,0,0,.73,15.45,7.81,20.71,0,0,1.4,17.65-.72,25.51h51.22c-2.12-7.87-.72-25.51-.72-25.51h.01Z" />
                      <path :data-selected="isPartSelected('쇄골')" class="body-part" d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z" />
                      <path :data-selected="isPartSelected('가슴')" class="body-part" d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z" />
                      <path :data-selected="isPartSelected('배')" class="body-part" d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 팔뚝', false)" class="body-part" d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z" />
                      <path :data-selected="isPartSelected('오른쪽 팔목', false)" class="body-part" d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 손', false)" class="body-part" d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 팔뚝', false)" class="body-part" d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 팔목', false)" class="body-part" d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z" />
                      <path :data-selected="isPartSelected('왼쪽 손', false)" class="body-part" d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z" />
                      <path :data-selected="isPartSelected('왼쪽 허벅지', false)" class="body-part" d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 종아리', false)" class="body-part" d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 발', false)" class="body-part" d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 허벅지', false)" class="body-part" d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 종아리', false)" class="body-part" d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z" />
                      <path :data-selected="isPartSelected('오른쪽 발', false)" class="body-part" d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z" />
                    </svg>
                  </div>
                  <!-- 뒷면 -->
                  <div v-show="activeTab === 'back'" class="body-back">
                    <svg viewBox="-85 0 395 790" xmlns="http://www.w3.org/2000/svg">
                      <path :data-selected="isPartSelected('어깨', true)" class="body-part" d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z" />
                      <path :data-selected="isPartSelected('윗등', true)" class="body-part" d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z" />
                      <path :data-selected="isPartSelected('아랫등', true)" class="body-part" d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 팔뚝', true)" class="body-part" d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z" />
                      <path :data-selected="isPartSelected('오른쪽 팔목', true)" class="body-part" d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 손', true)" class="body-part" d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 팔뚝', true)" class="body-part" d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 팔목', true)" class="body-part" d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z" />
                      <path :data-selected="isPartSelected('왼쪽 손', true)" class="body-part" d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z" />
                      <path :data-selected="isPartSelected('왼쪽 허벅지', true)" class="body-part" d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 종아리', true)" class="body-part" d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z" />
                      <path :data-selected="isPartSelected('왼쪽 발', true)" class="body-part" d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 허벅지', true)" class="body-part" d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z" />
                      <path :data-selected="isPartSelected('오른쪽 종아리', true)" class="body-part" d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z" />
                      <path :data-selected="isPartSelected('오른쪽 발', true)" class="body-part" d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z" />
                    </svg>
                  </div>
                <p class="selected-part">선택된 부위: {{ selectedRequest.location || '선택되지 않음' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 이미지 뷰어 모달 -->
    <div v-if="selectedImage" class="image-viewer-modal" @click="closeImageViewer">
      <img :src="selectedImage" alt="확대된 이미지" @click.stop>
      <button class="close-button" @click="closeImageViewer">&times;</button>
    </div>
  </div>
</template>

<script>
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '@/firebase';

export default {
  name: 'TattooRequestTattooist',
  data() {
    return {
      requests: [],
      selectedRequest: null,
      isModalOpen: false,
      selectedImage: null,
      activeTab: 'front',
      selectedBodyParts: [],
      bodyParts: {
        front: [
          { name: '얼굴', path: 'M137.63,93.19c7.08-5.27,7.81-20.71,7.81-20.71,0,0,.55,2.72,3.27.91,2.72-1.81,3.64-20.16,2.91-21.8s-3.82-1.09-3.82-1.09c0,0,4.35-23.98-3.09-36.33C137.26,1.81,119.64,0,112.73,0s-24.52,1.81-31.97,14.17c-7.44,12.36-3.09,36.33-3.09,36.33,0,0-3.09-.55-3.82,1.09-.73,1.63.18,19.98,2.91,21.8,2.72,1.81,3.27-.91,3.27-.91,0,0,.73,15.45,7.81,20.71,0,0,1.4,17.65-.72,25.51h51.22c-2.12-7.87-.72-25.51-.72-25.51h.01Z' },
          { name: '쇄골', path: 'M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z' },
          { name: '가슴', path: 'M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z' },
          { name: '배', path: 'M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z' },
          { name: '오른쪽 팔뚝', path: 'M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z' },
          { name: '오른쪽 팔목', path: 'M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z' },
          { name: '오른쪽 손', path: 'M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z' },
          { name: '왼쪽 팔뚝', path: 'M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z' },
          { name: '왼쪽 팔목', path: 'M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z' },
          { name: '왼쪽 손', path: 'M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z' },
          { name: '오른쪽 허벅지', path: 'M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z' },
          { name: '오른쪽 종아리', path: 'M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z' },
          { name: '오른쪽 발', path: 'M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z' },
          { name: '왼쪽 허벅지', path: 'M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z' },
          { name: '왼쪽 종아리', path: 'M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z' },
          { name: '왼쪽 발', path: 'M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z' }
        ],
        back: [
          { name: '(뒤)어깨', path: 'M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z' },
          { name: '(뒤)윗등', path: 'M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z' },
          { name: '(뒤)아랫등', path: 'M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z' },
          { name: '(뒤)오른쪽 팔뚝', path: 'M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z' },
          { name: '(뒤)오른쪽 팔목', path: 'M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z' },
          { name: '(뒤)오른쪽 손', path: 'M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z' },
          { name: '(뒤)왼쪽 팔뚝', path: 'M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z' },
          { name: '(뒤)왼쪽 팔목', path: 'M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z' },
          { name: '(뒤)왼쪽 손', path: 'M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z' },
          { name: '(뒤)왼쪽 허벅지', path: 'M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z' },
          { name: '(뒤)왼쪽 종아리', path: 'M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z' },
          { name: '(뒤)왼쪽 발', path: 'M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z' },
          { name: '(뒤)오른쪽 허벅지', path: 'M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z' },
          { name: '(뒤)오른쪽 종아리', path: 'M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z' },
          { name: '(뒤)오른쪽 발', path: 'M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z' }
        ]
      }
    };
  },
  async created() {
    await this.checkUserAccess();
    await this.fetchRequests();
  },
  methods: {
    async checkUserAccess() {
      const user = auth.currentUser;
      if (!user) {
        alert('로그인이 필요한 서비스입니다.');
        this.$router.push('/TattooAuth');
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists() || userDoc.data().userType !== 'tattooist') {
        alert('타투이스트만 접근 가능한 페이지입니다.');
        this.$router.push('/');
        return;
      }
    },

    async fetchRequests() {
      try {
        const user = auth.currentUser;
        const q = query(
          collection(db, 'consultationRequests'),
          where('tattooistId', '==', user.uid)
        );
        
        const querySnapshot = await getDocs(q);
        this.requests = querySnapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Request data:', data);
          console.log('Reference Images:', data.referenceImages);
          return {
            id: doc.id,
            ...data,
            referenceImages: data.referenceImages || [],
            status: data.status || 'unread'
          };
        });
      } catch (error) {
        console.error('요청 목록 조회 실패:', error);
        alert('요청 목록을 불러오는데 실패했습니다.');
      }
    },

    openRequestModal(request) {
      this.selectedRequest = request;
      this.isModalOpen = true;
      if (request.status === 'unread') {
        this.updateRequestStatus(request.id, 'read');
      }
    },

    closeModal() {
      this.isModalOpen = false;
      this.selectedRequest = null;
    },

    openImageViewer(imageUrl) {
      console.log('Opening image URL:', imageUrl);
      this.selectedImage = imageUrl;
    },

    closeImageViewer() {
      this.selectedImage = null;
    },

    async updateRequestStatus(requestId, newStatus) {
      try {
        await updateDoc(doc(db, 'consultationRequests', requestId), {
          status: newStatus
        });
        
        // 로컬 상태 업데이트
        const request = this.requests.find(r => r.id === requestId);
        if (request) {
          request.status = newStatus;
        }
      } catch (error) {
        console.error('상태 업데이트 실패:', error);
      }
    },

    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      return new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    },

    formatPrice(price) {
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW'
      }).format(price);
    },

    isPartSelected(partName, isBack = false) {
      if (!this.selectedRequest?.location) return false;
      const locations = this.selectedRequest.location.split(', ');
      
      if (isBack) {
        return locations.includes(`(뒤)${partName}`);
      } else {
        return locations.includes(partName) && !locations.includes(`(뒤)${partName}`);
      }
    },
    
    // selectedRequest가 변경될 때 호출되는 메서드
    updateSelectedBodyParts() {
      if (this.selectedRequest?.location) {
        this.selectedBodyParts = this.selectedRequest.location.split(', ');
      } else {
        this.selectedBodyParts = [];
      }
    }
  },
  watch: {
    selectedRequest: {
      handler(newRequest) {
        if (newRequest) {
          this.updateSelectedBodyParts();
        }
      },
      immediate: true
    }
  },
  computed: {
    getFrontParts() {
      if (!this.selectedRequest?.location) return [];
      const parts = this.selectedRequest.location.split(', ');
      return parts.filter(part => !part.includes('(뒤)'));
    },
    getBackParts() {
      if (!this.selectedRequest?.location) return [];
      const parts = this.selectedRequest.location.split(', ');
      return parts
        .filter(part => part.includes('(뒤)'))
        .map(part => part.replace('(뒤)', '')); // (뒤) 텍스트 제거
    }
  }
};
</script>

<style scoped>
.tattoo-requests {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #2d3436;
}

.requests-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.request-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.request-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.request-date {
  color: #868e96;
  font-size: 0.9rem;
}

.status-badge {
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
}

.status-badge.unread {
  background-color: #fff3bf;
  color: #e67700;
}

.status-badge.read {
  background-color: #e9ecef;
  color: #495057;
}

.request-summary {
  display: grid;
  gap: 0.5rem;
}

.request-summary p {
  margin: 0;
  font-size: 1rem;
  color: #495057;
}

/* 모달 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #868e96;
}

/* 상세 내용 스타일 */
.request-details {
  margin-top: 1rem;
}

.details-content {
  display: grid;
  gap: 1.2rem;
  margin-top: 1.5rem;
}

.detail-row {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 1rem;
  align-items: start;
}

.detail-row.description {
  grid-template-columns: 120px 1fr;
}

.label {
  color: #868e96;
  font-size: 0.95rem;
}

.value {
  color: #495057;
  font-size: 1rem;
}

/* 이미지 관련 스타일 */
.reference-images {
  margin-top: 2rem;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.image-preview {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.image-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 이미지 뷰어 모달 */
.image-viewer-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.image-viewer-modal img {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

@media (max-width: 768px) {
  .requests-list {
    grid-template-columns: 1fr;
  }

  .detail-row {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .detail-row.description {
    grid-template-columns: 1fr;
  }

  .images-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}

.body-map {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #e9ecef;
  border-radius: 12px;
}

.body-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tab-button {
  padding: 0.8rem 2rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  background: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

.tab-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.body-part {
  fill: #f1f3f5;
  stroke: #dee2e6;
  stroke-width: 1px;
  transition: all 0.2s ease;
}

.body-part[data-selected="true"] {
  fill: #2d3436;
}

.selected-part {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1.1rem;
  color: #495057;
}

.body-visualization {
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 12px;
}

.body-visualization h4 {
  margin-bottom: 1.5rem;
  color: #495057;
  font-size: 1.1rem;
}

.body-map {
  max-width: 300px;
  margin: 0 auto;
}

.body-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tab-button {
  padding: 0.6rem 1.5rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

.tab-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.body-part {
  fill: #f1f3f5;
  stroke: #dee2e6;
  stroke-width: 1px;
  transition: all 0.2s ease;
}

.body-part[data-selected="true"] {
  fill: #2d3436;
}

/* 모달 내부에서의 반응형 조정 */
@media (max-width: 768px) {
  .body-map {
    max-width: 250px;
  }
  
  .tab-button {
    padding: 0.5rem 1.2rem;
    font-size: 0.85rem;
  }
}

.body-parts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.parts-section {
  margin-bottom: 0.5rem;
}

.parts-section h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.25rem;
}

.parts-section span {
  color: #2d3436;
  font-weight: 500;
}
</style>
