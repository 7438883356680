<template>
    <div class="setting-notice">
      <div class="notice-header">
        <h2>링크 관리</h2>
        <p class="subtitle">프로필에 보여질 링크를 관리하세요</p>
      </div>
  
      <div class="notice-form">
        <form @submit.prevent="addLink">
          <div class="input-group">
            <input 
              v-model="newLink.title" 
              placeholder="링크 제목을 입력해주세요" 
              required 
            />
            <input 
              v-model="newLink.url" 
              placeholder="URL을 입력해주세요 (예: https://...)" 
              required 
              type="url"
              pattern="https?://.*"
            />
            <button type="submit">
              <i class="fas fa-plus"></i>
              링크 추가하기
            </button>
          </div>
        </form>
      </div>
  
      <div class="notice-list">
        <transition-group name="list">
          <div 
            v-for="link in links" 
            :key="link.id" 
            class="notice-item"
          >
            <div class="notice-content">
              <i class="fas fa-link notice-icon"></i>
              <div class="link-info">
                <span class="link-title">{{ link.title }}</span>
                <a :href="link.url" target="_blank" class="link-url" :title="link.url">
                  {{ truncateUrl(link.url) }}
                </a>
              </div>
            </div>
            <button @click="deleteLink(link.id)" class="delete-btn">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </transition-group>
        
        <div v-if="links.length === 0" class="empty-state">
          <i class="fas fa-link empty-icon"></i>
          <p>등록된 링크가 없습니다</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { db } from '@/firebase';
  import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  
  export default {
    name: 'SettingMultiLink',
    setup() {
      const auth = getAuth();
      const userId = ref('');
      const newLink = ref({
        title: '',
        url: ''
      });
      const links = ref([]);
  
      onMounted(() => {
        if (auth.currentUser) {
          userId.value = auth.currentUser.uid;
          loadLinks();
        } else {
          auth.onAuthStateChanged((user) => {
            if (user) {
              userId.value = user.uid;
              loadLinks();
            }
          });
        }
      });
  
      const loadLinks = async () => {
        if (!userId.value) return;
        
        try {
          const linksRef = collection(db, 'users', userId.value, 'MultiLinks');
          const snapshot = await getDocs(linksRef);
          links.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
          console.error('링크 로드 실패:', error);
        }
      };
  
      const addLink = async () => {
        if (!userId.value) return;
        
        if (!isValidUrl(newLink.value.url)) {
          alert('올바른 URL 형식을 입력해주세요');
          return;
        }
  
        try {
          const linksRef = collection(db, 'users', userId.value, 'MultiLinks');
          await addDoc(linksRef, { 
            title: newLink.value.title,
            url: formatUrl(newLink.value.url),
            createdAt: new Date().toISOString()
          });
          newLink.value = { title: '', url: '' };
          loadLinks();
        } catch (error) {
          console.error('링크 추가 실패:', error);
        }
      };
  
      const deleteLink = async (linkId) => {
        if (!userId.value) return;
        
        try {
          const linkDoc = doc(db, 'users', userId.value, 'MultiLinks', linkId);
          await deleteDoc(linkDoc);
          loadLinks();
        } catch (error) {
          console.error('링크 삭제 실패:', error);
        }
      };
  
      const isValidUrl = (url) => {
        try {
          new URL(url);
          return true;
        } catch {
          return false;
        }
      };
  
      const formatUrl = (url) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return `https://${url}`;
        }
        return url;
      };
  
      const truncateUrl = (url) => {
        return url.length > 30 ? url.substring(0, 30) + '…' : url;
      };
  
      return {
        newLink,
        links,
        addLink,
        deleteLink,
        truncateUrl,
      };
    },
  };
  </script>
  
  <style scoped>
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  }
  
  .input-group input {
    flex: 1;
    border: none;
    padding: 13px 16px;
    font-size: 16px;
    background: #f8f9fa;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .input-group input:focus {
    outline: none;
    background: #fff;
    box-shadow: 0 0 0 2px #4371ff20;
  }
  
  .input-group button {
    width: 100%;
    padding: 13px 16px;
    background: #1a1a1a;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .input-group button:active {
    transform: translateY(0);
  }
  
  .input-group button i {
    font-size: 18px;
  }
  
  .input-group input::placeholder {
    color: #adb5bd;
  }
  
  .link-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .link-title {
    font-weight: 600;
    color: #1a1a1a;
  }
  
  .link-url {
    font-size: 14px;
    color: #1a1a1a;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    display: inline-block;
  }
  
  .setting-notice {
    margin: 0 auto;
    padding: 20px;
    margin-top: 60px;
  }
  
  .notice-header {
    text-align: center;
    margin-bottom: 48px;
  }
  
  .notice-header h2 {
    font-size: 32px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 8px;
  }
  
  .subtitle {
    color: #666;
    font-size: 16px;
  }
  
  .notice-form {
    margin-bottom: 32px;
  }
  
  .notice-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .notice-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .notice-content {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .notice-icon {
    color: #1a1a1a;
    font-size: 19px;
  }
  
  .delete-btn {
    background: none;
    border: none;
    color: #dc3545;
    padding: 8px;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
    font-size: 19px;
  }
  
  .delete-btn:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  
  .empty-state {
    text-align: center;
    padding: 48px;
    color: #666;
  }
  
  .empty-icon {
    font-size: 48px;
    margin-bottom: 16px;
    color: #ddd;
  }
  </style>
  