<template>
  <div class="stats-container">
    <!-- 내 프로필 방문자 통계 -->
    <section class="my-stats">
      <h2>내 프로필 페이지 방문자 통계</h2>
      <div v-if="myStats" class="stats-grid">
        <div class="stat-card">
          <h3>전체</h3>
          <p class="stat-number">{{ formattedStats.total }}</p>
        </div>
        <div class="stat-card">
          <h3>이번 달</h3>
          <p class="stat-number">{{ formattedStats.month }}</p>
        </div>
        <div class="stat-card">
          <h3>오늘</h3>
          <p class="stat-number">{{ myStats.today }}</p>
        </div>
      </div>
      <div v-else>
        <p>방문자 통계 데이터가 없습니다.</p>
      </div>
    </section>

    <!-- 전체 페이지 방문자 통계 -->
    <section class="global-stats">
      <h2>타투맵 방문자 통계</h2>
      <div class="chart-container">
        <Bar :data="globalChartData" :options="chartOptions" />
      </div>
    </section>

    <!-- 평균 방문자 통계 섹션 수정 -->
    <section class="comparison-stats">
      <h2>타투이스트 프로필 방문자 수 비교</h2>
      <div class="chart-container">
        <Bar :data="comparisonChartData" :options="comparisonChartOptions" />
      </div>
      <div class="comparison-info" v-if="myStats">
        <div class="info-card" :class="{ 'above-average': isAboveAverage }">
          <h3>평균 대비</h3>
          <p class="comparison-text">
            {{ getDifferenceText }}
          </p>
          <p class="percentage">
            {{ getDifferencePercentage }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
);

import { db, auth } from '@/firebase'; // Firebase 초기화 파일에서 auth를 불러옴
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

export default {
  name: 'PageStats',
  components: {
    Bar
  },
  setup() {
    const myStats = ref(null);
    const globalStats = ref([]);
    const missingUsers = ref([]);
    const averageVisitors = ref(0);

    // 일반 페이지에 대한 매핑 정의
    const pageDisplayNames = {
      "MainPage": "메인 페이지",
      "TattooMap": "타투 맵",
      "TattooGallery": "타투 갤러리",
      "DesignGallery": "디자인 갤러리",
      // 필요한 다른 페이지들도 추가하세요
    };

    // 페이지 이름을 결정하는 함수
    const getPageName = (stat) => {
      if (pageDisplayNames[stat.pageId]) {
        return pageDisplayNames[stat.pageId];
      } else {
        return stat.pageId; // 매핑에 없는 경우 pageId를 그대로 표시
      }
    };

    // 내 프로필 방문자 통계 가져오기
    const fetchMyStats = async (userId) => {
      if (!userId) {
        console.error('사용자 ID가 없습니다.');
        return;
      }

      try {
        const pageViewDocRef = doc(db, 'pageViews', userId);
        const pageViewSnap = await getDoc(pageViewDocRef);

        if (pageViewSnap.exists()) {
          const pageViewData = pageViewSnap.data();

          // 오늘 날짜와 이번 달을 포맷팅
          const today = new Date();
          const formattedDate = today.toISOString().split('T')[0]; // YYYY-MM-DD
          const formattedMonth = today.toISOString().slice(0, 7); // YYYY-MM

          // 일별 방문자 수
          const dailyRef = doc(db, 'pageViews', userId, 'daily', formattedDate);
          const dailySnap = await getDoc(dailyRef);
          const todayCount = dailySnap.exists() ? dailySnap.data().count : 0;

          // 월별 방문자 수
          const monthlyRef = doc(db, 'pageViews', userId, 'monthly', formattedMonth);
          const monthlySnap = await getDoc(monthlyRef);
          const monthCount = monthlySnap.exists() ? monthlySnap.data().count : 0;

          // 총 방문자 수
          const totalCount = pageViewData.totalCount || 0;

          myStats.value = {
            totalCount,
            today: todayCount,
            month: monthCount
          };
        } else {
          console.warn('pageViews 문서가 존재하지 않습니다.');
        }
      } catch (error) {
        console.error('방문자 통계 가져오기 실패:', error);
      }
    };

    // 전체 페이지 방문자 통계 가져오기
    const fetchGlobalStats = async () => {
      try {
        const pageViewsSnapshot = await getDocs(collection(db, 'pageViews'));
        const data = [];
        const generalPages = Object.keys(pageDisplayNames);

        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]; // YYYY-MM-DD
        const formattedMonth = today.toISOString().slice(0, 7); // YYYY-MM

        for (const pageDoc of pageViewsSnapshot.docs) {
          const pageId = pageDoc.id;

          // 일반 페이지만 필터링
          if (generalPages.includes(pageId)) {
            const pageData = pageDoc.data();
            const total = pageData.totalCount || 0;

            // 오늘 방문자 수
            const dailyRef = doc(db, 'pageViews', pageId, 'daily', formattedDate);
            const dailySnap = await getDoc(dailyRef);
            const todayCount = dailySnap.exists() ? dailySnap.data().count : 0;

            // 이번 달 방문자 수
            const monthlyRef = doc(db, 'pageViews', pageId, 'monthly', formattedMonth);
            const monthlySnap = await getDoc(monthlyRef);
            const monthCount = monthlySnap.exists() ? monthlySnap.data().count : 0;

            data.push({
              pageId: pageId,
              today: todayCount,
              month: monthCount,
              total: total
            });
          }
        }

        globalStats.value = data;
      } catch (error) {
        console.error('전체 방문자 통계 가져오기 실패:', error);
      }
    };

    const fetchAverageVisitors = async () => {
      try {
        const pageViewsSnapshot = await getDocs(collection(db, 'pageViews'));
        let totalVisitors = 0;
        let count = 0;

        for (const pageDoc of pageViewsSnapshot.docs) {
          const pageData = pageDoc.data();
          if (pageData.totalCount) {
            totalVisitors += pageData.totalCount;
            count++;
          }
        }

        averageVisitors.value = count > 0 ? (totalVisitors / count) : 0;
      } catch (error) {
        console.error('평균 방문자 수 가져오기 실패:', error);
      }
    };

    // 로그인 상태 변경 시 사용자 정보 설정 및 통계 가져오기
    onMounted(() => {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          fetchMyStats(currentUser.uid);
        } else {
          console.warn('로그인되지 않았습니다.');
        }

        // 전체 페이지 통계는 모든 인증된 사용자에게 열려있다고 가정
        fetchGlobalStats();
        fetchAverageVisitors();
      });
    });

    // 차트 옵션
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
      },
    };

    const doughnutOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
      },
    };

    // 내 통계 차트 데이터
    const myChartData = computed(() => ({
      labels: ['오늘', '이번 달', '전체'],
      datasets: [{
        label: '방문자 수',
        data: myStats.value ? [
          myStats.value.today,
          myStats.value.month,
          myStats.value.totalCount
        ] : [],
        backgroundColor: [
          'rgba(54, 162, 235, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1
      }]
    }));

    // 전체 통계 차트 데이터
    const globalChartData = computed(() => ({
      labels: globalStats.value.map(stat => getPageName(stat)),
      datasets: [{
        label: '오늘 방문자',
        data: globalStats.value.map(stat => stat.today),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      }]
    }));

    // 평균 방문자 도넛 차트 데이터
    const averageChartData = computed(() => ({
      labels: ['평균 방문자 수'],
      datasets: [{
        data: [averageVisitors.value],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1
      }]
    }));

    // 템플릿에서 사용할 수 있도록 포맷팅된 숫자 계산
    const formattedStats = computed(() => ({
      month: new Intl.NumberFormat('ko-KR').format(myStats.value?.month || 0),
      total: new Intl.NumberFormat('ko-KR').format(myStats.value?.totalCount || 0)
    }));

    // 평균과의 차이 계산
    const isAboveAverage = computed(() => {
      return myStats.value?.totalCount > averageVisitors.value;
    });

    const getDifferenceText = computed(() => {
      if (!myStats.value) return '';
      const diff = Math.abs(myStats.value.totalCount - averageVisitors.value);
      return isAboveAverage.value 
        ? `평균보다 ${formatNumber(diff)}명 많은 방문자`
        : `평균보다 ${formatNumber(diff)}명 적은 방문자`;
    });
    

    const getDifferencePercentage = computed(() => {
      if (!myStats.value || !averageVisitors.value) return '';
      const percentage = ((myStats.value.totalCount - averageVisitors.value) / averageVisitors.value * 100).toFixed(1);
      return `${percentage}%`;
    });

    // 비교 차트 데이터
    const comparisonChartData = computed(() => ({
      labels: ['방문자 수 비교'],
      datasets: [
        {
          label: '내 프로필',
          data: [myStats.value?.totalCount || 0],
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        },
        {
          label: '타투이스트 평균',
          data: [averageVisitors.value],
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1
        }
      ]
    }));

    // 비교 차트 옵션
    const comparisonChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y', // 가로 막대 차트
      plugins: {
        legend: {
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += new Intl.NumberFormat('ko-KR').format(context.parsed.x);
              return label;
            }
          }
        }
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            callback: function(value) {
              return formatNumber(value);
            }
          }
        }
      }
    };

    // formatNumber 함수 추가
    const formatNumber = (num) => {
      if (!num) return '0';
      
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
      } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
      }
      return new Intl.NumberFormat('ko-KR').format(num);
    };

    return {
      myStats,
      globalStats,
      missingUsers,
      getPageName,
      averageVisitors,
      myChartData,
      globalChartData,
      averageChartData,
      chartOptions,
      doughnutOptions,
      formattedStats,
      comparisonChartData,
      comparisonChartOptions,
      isAboveAverage,
      getDifferenceText,
      getDifferencePercentage,
      formatNumber
    };
  }
};
</script>

<style scoped>
.stats-container {
  padding: 10px;
  max-width: 100%;
  margin-top: 60px;
}

section {
  margin-bottom: 24px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  padding: 24px;
}

.chart-container {
  height: 300px;
  position: relative;
  margin-top: 20px;
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.my-stats {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
}

.global-stats {
  background: linear-gradient(135deg, #ffffff 0%, #e9ecef 100%);
}

.average-stats {
  background: linear-gradient(135deg, #ffffff 0%, #e3f2fd 100%);
}

.stats-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
  margin-top: 20px;
}

.stat-card {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.stat-card h3 {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 8px;
  margin-top: 0;
}

.stat-number {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2196f3;
  margin: 0;
}

.comparison-stats {
  background: linear-gradient(135deg, #ffffff 0%, #f1f8ff 100%);
}

.comparison-info {
  margin-top: 20px;
  text-align: center;
}

.info-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  display: inline-block;
  min-width: 250px;
}

.info-card h3 {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.comparison-text {
  font-size: 1rem;
  color: #333;
  margin: 8px 0;
}

.percentage {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 8px 0;
}

.above-average .percentage {
  color: #2196f3;
}

.above-average .comparison-text {
  color: #2196f3;
}

.info-card:not(.above-average) .percentage {
  color: #ff5252;
}

.info-card:not(.above-average) .comparison-text {
  color: #ff5252;
}
</style>
