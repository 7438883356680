<template>
  <div class="welcome-message-container">
    <div class="welcome-message-wrap">
      <div class="header-section">
        <h2>웰컴 메시지 관리</h2>
        <button class="back-button" @click="$router.go(-1)">
          <i class="fas fa-arrow-left"></i>
          돌아가기
        </button>
      </div>

      <div class="message-section">
        <div class="message-box">
          <h3>프로필의 환영 메시지를 작성해주세요.</h3>
          <textarea 
            v-model="welcomeMessage" 
            placeholder="예) 원하시는 타투가 있으신가요?"
            rows="5"
          ></textarea>
          <div class="button-container">
            <button @click="saveWelcomeMessage" class="save-button">
              <i class="fas fa-save"></i> 저장하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from '@/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'vue-router';

export default {
  name: 'SettingWelcomeMessage',
  setup() {
    const welcomeMessage = ref('');
    const auth = getAuth();
    const router = useRouter();

    const loadWelcomeMessage = async () => {
      const userId = auth.currentUser.uid;
      const userDoc = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userDoc);
      
      if (userSnapshot.exists()) {
        welcomeMessage.value = userSnapshot.data().welcome || '';
      }
    };

    const saveWelcomeMessage = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDoc = doc(db, 'users', userId);
        await updateDoc(userDoc, {
          welcome: welcomeMessage.value
        });
        alert('웰컴 메시지가 저장되었습니다.');
        router.push('/mypage');
      } catch (error) {
        console.error('웰컴 메시지 저장 실패:', error);
        alert('저장에 실패했습니다.');
      }
    };

    onMounted(() => {
      loadWelcomeMessage();
    });

    return {
      welcomeMessage,
      saveWelcomeMessage
    };
  }
};
</script>

<style scoped>
.welcome-message-container {
  margin-top: 60px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.welcome-message-wrap {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.header-section h2 {
  margin: 0;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
}

.message-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.message-box {
  background: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: vertical;
  font-size: 14px;
  min-height: 120px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
