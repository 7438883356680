<template>
  <div class="banner-page">
    <!-- Header -->
    <header class="banner-header">
      <button class="back-button" @click="goBack">
        <i class="fas fa-arrow-left"></i>
      </button>
      <h1 class="title">플래시 이벤트</h1>
    </header>

    <!-- Swiper Container -->
    <Swiper
      class="reels-container"
      :modules="swiperModules"
      direction="vertical"
      slides-per-view="1"
      spaceBetween={0}
      virtual
      @slideChange="handleSlideChange"
    >
      <SwiperSlide
        v-for="event in flashEvents"
        :key="event.id"
        class="reels-card"
        @click="navigateToEvent(event)"
      >
        <div class="image-wrapper">
          <img
            :src="event.imageUrl"
            :alt="event.title"
            class="reels-image"
            loading="lazy"
          />
        </div>
        <div class="reels-info">
          <div class="artist-info">
            <img
              :src="event.artistAvatar"
              :alt="event.artistName"
              class="artist-avatar"
            />
            <span class="artist-name">{{ event.artistName }}</span>
          </div>
          <div class="event-details">
            <h2 class="event-title">{{ event.title }}</h2>
            <div class="price-info">
              <span class="original-price">{{ formatCurrency(event.originalPrice) }}</span>
              <span class="discount-price">{{ formatCurrency(event.eventPrice) }}</span>
            </div>
            <p class="event-period">
              {{ formatDate(event.startDate) }} - {{ formatDate(event.endDate) }}
            </p>
            <p class="event-location">{{ event.location }}</p>
            <button
              v-if="!isTattoist"
              class="consult-button"
              @click.stop="openConsultModal(event)"
            >
              상담 신청
            </button>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <!-- Upload Button (Visible to Tattooists) -->
    <button
      v-if="isTattoist"
      class="upload-button"
      @click="toggleUploadModal"
      aria-label="이벤트 업로드"
    >
      <i class="fas fa-plus"></i>
    </button>

    <!-- Upload Modal -->
    <Transition name="modal">
      <div v-if="showUploadModal" class="modal-overlay" @click.self="toggleUploadModal">
        <div class="modal-content">
          <header class="modal-header">
            <h2>플래시 이벤트 등록</h2>
            <button class="close-button" @click="toggleUploadModal" aria-label="닫기">
              <i class="fas fa-times"></i>
            </button>
          </header>
          <form @submit.prevent="submitUpload" class="modal-form">
            <div class="form-group">
              <label for="imageUpload">이미지 업로드</label>
              <div class="image-upload" @click="triggerFileInput">
                <input
                  type="file"
                  id="imageUpload"
                  ref="fileInput"
                  accept="image/*"
                  @change="handleImageUpload"
                  hidden
                />
                <div v-if="!previewImage" class="upload-placeholder">
                  <i class="fas fa-camera"></i>
                  <span>이미지를 선택하세요</span>
                </div>
                <img v-else :src="previewImage" alt="미리보기" class="preview-image" />
              </div>
            </div>
            <div class="form-group">
              <label for="originalPrice">기존 가격 (원)</label>
              <input
                id="originalPrice"
                type="number"
                v-model.number="uploadForm.originalPrice"
                placeholder="예: 150000"
                required
              />
            </div>
            <div class="form-group">
              <label for="eventPrice">이벤트 가격 (원)</label>
              <input
                id="eventPrice"
                type="number"
                v-model.number="uploadForm.eventPrice"
                placeholder="예: 100000"
                required
              />
            </div>
            <div class="form-group date-group">
              <label>이벤트 기간</label>
              <div class="date-inputs">
                <input
                  type="date"
                  v-model="uploadForm.startDate"
                  required
                />
                <span>~</span>
                <input
                  type="date"
                  v-model="uploadForm.endDate"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <label for="size">사이즈</label>
              <input
                id="size"
                type="text"
                v-model="uploadForm.size"
                placeholder="예: 5x5cm"
                required
              />
            </div>
            <div class="form-group">
              <label for="location">작업 위치</label>
              <input
                id="location"
                type="text"
                v-model="uploadForm.location"
                placeholder="작업 위치를 입력하세요"
                required
              />
            </div>
            <div class="form-group">
              <label for="description">이벤트 설명</label>
              <textarea
                id="description"
                v-model="uploadForm.description"
                placeholder="이벤트에 대한 상세 설명을 입력하세요"
                rows="4"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              class="submit-button"
              :disabled="!isUploadFormValid || isUploading"
            >
              {{ isUploading ? '업로드 중...' : '이벤트 등록' }}
            </button>
          </form>
        </div>
      </div>
    </Transition>

    <!-- Consultation Modal -->
    <Transition name="modal">
      <div v-if="showConsultModal" class="modal-overlay" @click.self="closeConsultModal">
        <div class="modal-content">
          <header class="modal-header">
            <h2>상담 신청</h2>
            <button class="close-button" @click="closeConsultModal" aria-label="닫기">
              <i class="fas fa-times"></i>
            </button>
          </header>
          <form @submit.prevent="submitConsultation" class="modal-form">
            <div class="form-group">
              <label for="consultName">이름</label>
              <input
                id="consultName"
                type="text"
                v-model="consultForm.name"
                placeholder="이름을 입력하세요"
                required
              />
            </div>
            <div class="form-group">
              <label for="consultContact">연락처</label>
              <input
                id="consultContact"
                type="tel"
                v-model="consultForm.contact"
                placeholder="연락처를 입력하세요"
                required
              />
            </div>
            <div class="form-group">
              <label for="consultMessage">메시지</label>
              <textarea
                id="consultMessage"
                v-model="consultForm.message"
                placeholder="상담 내용을 입력하세요"
                rows="4"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              class="submit-button"
              :disabled="!isConsultFormValid || isConsulting"
            >
              {{ isConsulting ? '상담 신청 중...' : '상담 신청' }}
            </button>
          </form>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, onMounted, computed, Transition } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
  where
} from 'firebase/firestore';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/virtual';

export default {
  name: 'Banner1Go',
  components: {
    Swiper,
    SwiperSlide,
    Transition
  },
  setup() {
    const router = useRouter();
    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    // State variables
    const isTattoist = ref(false);
    const flashEvents = ref([]);
    const showUploadModal = ref(false);
    const showConsultModal = ref(false);
    const selectedEvent = ref(null);
    const fileInput = ref(null);
    const previewImage = ref(null);
    const isUploading = ref(false);
    const isConsulting = ref(false);

    // Forms
    const uploadForm = ref({
      originalPrice: null,
      eventPrice: null,
      startDate: '',
      endDate: '',
      size: '',
      location: '',
      description: '',
      imageFile: null
    });

    const consultForm = ref({
      name: '',
      contact: '',
      message: ''
    });

    // Swiper Modules
    const swiperModules = [Virtual];

    // Computed properties
    const isUploadFormValid = computed(() => {
      return (
        uploadForm.value.originalPrice &&
        uploadForm.value.eventPrice &&
        uploadForm.value.startDate &&
        uploadForm.value.endDate &&
        uploadForm.value.size &&
        uploadForm.value.location &&
        uploadForm.value.imageFile
      );
    });

    const isConsultFormValid = computed(() => {
      return (
        consultForm.value.name &&
        consultForm.value.contact &&
        consultForm.value.message
      );
    });

    // Methods
    const goBack = () => {
      router.push('/home');
    };

    const handleSlideChange = () => {
      console.log('슬라이드가 변경되었습니다.');
    };

    const formatDate = (date) => {
      if (!date) return '';
      const options = { month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('ko-KR', options);
    };

    const formatCurrency = (value) => {
      return `${value.toLocaleString()}원`;
    };

    const navigateToEvent = (event) => {
      router.push(`/flash-event/${event.id}`);
    };

    const toggleUploadModal = () => {
      showUploadModal.value = !showUploadModal.value;
    };

    const openConsultModal = (event) => {
      selectedEvent.value = event;
      showConsultModal.value = true;
    };

    const closeConsultModal = () => {
      showConsultModal.value = false;
      consultForm.value = { name: '', contact: '', message: '' };
      selectedEvent.value = null;
    };

    const triggerFileInput = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        uploadForm.value.imageFile = file;
        previewImage.value = URL.createObjectURL(file);
      }
    };

    const submitUpload = async () => {
      if (!isUploadFormValid.value) return;
      try {
        isUploading.value = true;
        
        // Upload Image
        const imageRef = storageRef(storage, `flash-events/${Date.now()}-${uploadForm.value.imageFile.name}`);
        await uploadBytes(imageRef, uploadForm.value.imageFile);
        const imageUrl = await getDownloadURL(imageRef);

        // Save to Firestore
        await addDoc(collection(db, 'flashEvents'), {
          originalPrice: uploadForm.value.originalPrice,
          eventPrice: uploadForm.value.eventPrice,
          startDate: new Date(uploadForm.value.startDate),
          endDate: new Date(uploadForm.value.endDate),
          size: uploadForm.value.size,
          location: uploadForm.value.location,
          description: uploadForm.value.description,
          imageUrl,
          artistId: auth.currentUser.uid,
          artistName: auth.currentUser.displayName,
          artistAvatar: auth.currentUser.photoURL,
          createdAt: serverTimestamp()
        });

        // Reset Form
        uploadForm.value = {
          originalPrice: null,
          eventPrice: null,
          startDate: '',
          endDate: '',
          size: '',
          location: '',
          description: '',
          imageFile: null
        };
        previewImage.value = null;
        showUploadModal.value = false;
        await loadFlashEvents();
        alert('이벤트가 성공적으로 등록되었습니다.');
      } catch (error) {
        console.error('이벤트 업로드 오류:', error);
        alert('이벤트 업로드 중 오류가 발생했습니다.');
      } finally {
        isUploading.value = false;
      }
    };

    const submitConsultation = async () => {
      if (!isConsultFormValid.value) return;
      try {
        isConsulting.value = true;
        await addDoc(collection(db, 'consultations'), {
          eventId: selectedEvent.value.id,
          eventTitle: selectedEvent.value.title,
          userId: auth.currentUser.uid,
          userName: consultForm.value.name,
          contact: consultForm.value.contact,
          message: consultForm.value.message,
          status: 'pending',
          createdAt: serverTimestamp()
        });
        alert('상담 신청이 완료되었습니다.');
        closeConsultModal();
      } catch (error) {
        console.error('상담 신청 오류:', error);
        alert('상담 신청 중 오류가 발생했습니다.');
      } finally {
        isConsulting.value = false;
      }
    };

    const loadFlashEvents = async () => {
      try {
        const eventsRef = collection(db, 'flashEvents');
        const q = query(
          eventsRef,
          where('endDate', '>=', new Date()),
          orderBy('endDate', 'asc'),
          orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        flashEvents.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error('플래시 이벤트 로드 오류:', error);
      }
    };

    const checkUserStatus = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            isTattoist.value = userData.userType === 'tattooist';
          }
        } catch (error) {
          console.error('사용자 상태 확인 오류:', error);
        }
      }
    };

    onMounted(() => {
      checkUserStatus();
      loadFlashEvents();
    });

    return {
      // Components
      swiperModules,

      // State
      isTattoist,
      flashEvents,
      showUploadModal,
      showConsultModal,
      selectedEvent,
      fileInput,
      previewImage,
      isUploading,
      isConsulting,

      // Forms
      uploadForm,
      consultForm,

      // Computed
      isUploadFormValid,
      isConsultFormValid,

      // Methods
      goBack,
      handleSlideChange,
      formatDate,
      formatCurrency,
      navigateToEvent,
      toggleUploadModal,
      openConsultModal,
      closeConsultModal,
      triggerFileInput,
      handleImageUpload,
      submitUpload,
      submitConsultation
    };
  }
};
</script>

<style scoped>
.banner-page {
  background-color: #121212;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.banner-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background-color: rgba(18, 18, 18, 0.98);
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 100;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.back-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  margin-right: 8px;
  -webkit-tap-highlight-color: transparent;
}

.back-button:hover {
  transform: scale(1.1);
}

.title {
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
}

.reels-container {
  width: 100vw;
  height: 100vh !important;
  position: fixed;
  top: 0;
  left: 0;
}

.swiper-slide {
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reels-card {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 56px 0 0 0;
  border-radius: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.image-wrapper {
  width: 100%;
  height: 70vh;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.reels-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translateZ(0);
  will-change: transform;
}

.reels-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 2;
}

.artist-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.artist-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin-right: 12px;
}

.artist-name {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.event-details {
  padding: 0 0 env(safe-area-inset-bottom, 16px) 0;
}

.event-title {
  color: #ffcc00;
  font-size: 1.2rem;
  margin-bottom: 8px;
  font-weight: 600;
}

.price-info {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 4px;
}

.original-price {
  color: #aaaaaa;
  text-decoration: line-through;
  font-size: 0.9rem;
}

.discount-price {
  color: #ff5722;
  font-size: 1.2rem;
  font-weight: bold;
}

.event-period,
.event-location {
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 4px;
}

.consult-button {
  width: 100%;
  padding: 16px;
  background-color: #2196f3;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 16px;
  -webkit-tap-highlight-color: transparent;
}

.upload-button {
  position: fixed;
  bottom: max(16px, env(safe-area-inset-bottom));
  right: 16px;
  width: 56px;
  height: 56px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  width: 100%;
  max-height: 90vh;
  background-color: #1e1e1e;
  border-radius: 24px 24px 0 0;
  padding: 24px 16px max(16px, env(safe-area-inset-bottom));
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-header {
  position: sticky;
  top: 0;
  background-color: #2c2c2c;
  padding: 8px 0;
  margin-bottom: 12px;
}

.modal-header h2 {
  font-size: 1.5rem;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.close-button:hover {
  transform: rotate(90deg);
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 6px;
  font-size: 0.95rem;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #2196f3;
}

.image-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border: 2px dashed #555;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s;
}

.image-upload:hover {
  background-color: #3a3a3a;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #bbb;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.date-group .date-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.submit-button {
  padding: 12px;
  background-color: #2196f3;
  color: #ffffff;
  border: none;
  border-radius: 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #1976d2;
}

.submit-button:disabled {
  background-color: #555555;
  cursor: not-allowed;
}

.modal-enter-active,
.modal-leave-active {
  transition: transform 0.3s ease-out;
}

.modal-enter-from,
.modal-leave-to {
  transform: translateY(100%);
}

@supports (padding: max(0px)) {
  .banner-page {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .banner-header {
    padding-top: env(safe-area-inset-top);
    height: calc(56px + env(safe-area-inset-top));
  }

  .reels-card {
    padding-top: calc(56px + env(safe-area-inset-top));
  }
}

@media (min-width: 768px) {
  .image-wrapper {
    max-width: 500px;
    height: 60vh;
  }

  .reels-info {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (orientation: landscape) {
  .image-wrapper {
    height: 80vh;
  }

  .reels-info {
    background: rgba(0, 0, 0, 0.8);
  }
}

@media (prefers-color-scheme: dark) {
  .modal-content {
    background-color: #1a1a1a;
  }
}
</style>
