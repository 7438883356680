<template>
  <div class="color-setting-container">
    <div class="title-section">
      <h2 class="title">색상 설정</h2>
      <button type="button" @click="resetColors" class="reset-button">
        <span class="material-icons">restart_alt</span>
        초기화
      </button>
    </div>
    
    <div class="color-selection-section">
      <!-- 배경 색상 -->
      <div class="color-select-item">
        <h3>배경 색상</h3>
        <div class="color-picker">
          <input type="color" v-model="colors.background">
        </div>
      </div>

      <!-- 닉네임 색상 -->
      <div class="color-select-item">
        <h3>닉네임 색상</h3>
        <div class="color-picker">
          <input type="color" v-model="colors.nickname">
        </div>
      </div>

      <!-- 인스타그램 ID 색상 -->
      <div class="color-select-item">
        <h3>인스타그램 ID 색상</h3>
        <div class="color-picker">
          <input type="color" v-model="colors.instagramId">
        </div>
      </div>

      <!-- 상세 정보 색상 -->
      <div class="color-select-item">
        <h3>상세 정보 색상</h3>
        <div class="color-picker">
          <input type="color" v-model="colors.infoDetail">
        </div>
      </div>

      <!-- 링크 색상 -->
      <div class="color-select-item">
        <h3>링크 색상</h3>
        <div class="color-picker">
          <input type="color" v-model="colors.link">
        </div>
      </div>

      <!-- 자기소개 색상 -->
      <div class="color-select-item">
        <h3>자기소개 색상</h3>
        <div class="color-picker">
          <input type="color" v-model="colors.introduce">
        </div>
      </div>

      <!-- 공지사항 색상 -->
      <div class="color-select-item">
        <h3>공지사항 색상</h3>
        <div class="color-picker">
            <input type="color" v-model="colors.noticebox">
            <input type="color" v-model="colors.noticetext">
        </div>
      </div>

      <!-- 태그 색상 -->
      <div class="color-select-item">
        <h3>태그 색상</h3>
        <div class="color-picker">
            <input type="color" v-model="colors.tagbox">
            <input type="color" v-model="colors.tagtext">
        </div>
      </div>

      <!-- 갤러리 버튼 색상 -->
      <div class="color-select-item">
        <h3>갤러리 버튼 색상</h3>
        <div class="color-picker">
          <input type="color" v-model="colors.galleryButton">
          <input type="color" v-model="colors.galleryButtonText">
        </div>
      </div>
    </div>

    <!-- 폼로필 미리보기 섹션 -->
    <div class="preview-section">
      <h3>프로필 미리보기</h3>
      <div class="profile-preview" :style="{ backgroundColor: colors.background }">
        <!-- 프로필 헤더 -->
        <div class="preview-header">
          <div class="preview-profile">
            <div class="preview-avatar"></div>
            <h2 :style="{ color: colors.nickname }">타투이스트 닉네임</h2>
            <p :style="{ color: colors.instagramId }">@instagram_id</p>
          </div>
          
          <div class="preview-info" :style="{ color: colors.infoDetail }">
            <p>서울 • 홍대</p>
            <p>010-1234-5678</p>
          </div>
        </div>

        <!-- 소셜 링크 -->
        <div class="preview-social-links">
          <a href="#" class="preview-social-link" :style="{ 
            color: colors.link,
            border: `1px solid ${colors.link}`
          }">
            <i class="fas fa-link"></i>
            링크 1
          </a>
          <a href="#" class="preview-social-link" :style="{ 
            color: colors.link,
            border: `1px solid ${colors.link}`
          }">
            <i class="fas fa-link"></i>
            링크 2
          </a>
        </div>

        <!-- 자기소개 -->
        <div class="preview-introduce" :style="{ color: colors.introduce }">
          안녕하세요. 타투이스트입니다.
        </div>

        <!-- 공지사항 -->
        <div class="preview-notice" 
          :style="{ 
            border: `1px solid ${colors.noticebox}`,
            color: colors.noticetext 
          }">
          공지사항 예시입니다.
        </div>

        <!-- 태그 -->
        <div class="preview-tags">
          <span class="preview-tag" 
            :style="{ 
              backgroundColor: colors.tagbox,
              color: colors.tagtext 
            }">
            #미니타투
          </span>
          <span class="preview-tag" 
            :style="{ 
              backgroundColor: colors.tagbox,
              color: colors.tagtext 
            }">
            #레터링
          </span>
        </div>
      </div>
    </div>

    <!-- 폼 액션 버튼 -->
    <div class="form-actions">
      <button type="button" @click="cancelEdit" class="cancel-button">취소</button>
      <button type="button" @click="saveColors" class="save-button">저장</button>
    </div>
    
    <div v-if="errorMessage" class="error-msg">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '@/firebase'
import { getAuth } from 'firebase/auth'
import router from '@/router'

export default {
  name: 'SettingColor',
  setup() {
    const auth = getAuth()
    const userId = ref('')
    const errorMessage = ref('')
    
    const defaultColors = {
      background: '#000000',
      nickname: '#ffffff',
      instagramId: '#cccccc',
      infoDetail: '#cccccc',
      link: '#ffffff',
      introduce: '#ffffff',
      noticebox: '#ffffff',
      noticetext: '#cccccc',
      tagbox: '#ffffff',
      tagtext: '#000000',
      galleryButton: '#ffffff',
      galleryButtonText: '#000000'
    }

    const colors = ref({ ...defaultColors })

    // 저장된 색상 불러오기
    const loadColors = async () => {
      const user = auth.currentUser
      if (!user) {
        errorMessage.value = '로그인이 필요합니다.'
        return
      }

      userId.value = user.uid
      try {
        const colorDoc = doc(db, 'users', userId.value, 'colors', 'theme')
        const colorSnapshot = await getDoc(colorDoc)

        if (colorSnapshot.exists()) {
          const colorData = colorSnapshot.data()
          Object.keys(colors.value).forEach(key => {
            colors.value[key] = colorData[key] || colors.value[key]
          })
        }
      } catch (error) {
        console.error('색상 불러오기 실패:', error)
        errorMessage.value = '색상을 불러오는데 실패했습니다.'
      }
    }

    // 색상 저장하기
    const saveColors = async () => {
      if (!userId.value) {
        errorMessage.value = '로그인이 필요합니다.'
        return
      }
      
      try {
        const colorDoc = doc(db, 'users', userId.value, 'colors', 'theme')
        await setDoc(colorDoc, {
          ...colors.value,
          updatedAt: new Date()
        })

        alert('색상이 저장되었습니다.')
        router.push('/MyPage')
      } catch (error) {
        console.error('색상 저장 실패:', error)
        errorMessage.value = '색상 저장에 실패했습니다.'
      }
    }

    // 취소
    const cancelEdit = () => {
      router.push('/MyPage')
    }

    // 색상 초기화 함수
    const resetColors = () => {
      if (confirm('색상을 초기값으로 되돌리시겠습니까?')) {
        colors.value = { ...defaultColors }
      }
    }

    onMounted(() => {
      loadColors()
    })

    return {
      colors,
      errorMessage,
      saveColors,
      cancelEdit,
      resetColors
    }
  }
}
</script>

<style scoped>
.color-setting-container {
  width: 85%;
  margin: 80px auto 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.reset-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.reset-button:hover {
  background-color: #e5e7eb;
}

.reset-button .material-icons {
  font-size: 18px;
}

.title {
  margin: 0;
}

.color-selection-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.color-select-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.color-select-item h3 {
  margin: 0;
}

.color-picker {
  display: flex;
  align-items: center;
}

.color-preview {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 2px solid #ddd;
}

input[type="color"] {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.preview-section {
  margin: 30px 0;
  padding: 20px;
  border-radius: 10px;
  background: #f8f9fa;
}

.profile-preview {
  padding: 20px;
  border-radius: 10px;
  min-height: 400px;
  transition: all 0.3s ease;
}

.preview-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.preview-profile {
  text-align: center;
}

.preview-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ddd;
  margin: 0 auto 10px;
}

.preview-profile p {
  margin: 0;
}

.preview-profile h2 {
  margin: 5px 0;
  font-size: 1.5rem;
}

.preview-info {
  text-align: center;
  font-size: 0.9rem;
}

.preview-social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin: 20px 0;
}

.preview-social-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 20px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.preview-social-link i {
  font-size: 14px;
}


.preview-introduce {
  text-align: center;
  margin: 20px 0;
  padding: 10px;
  font-size: 0.95rem;
}

.preview-notice {
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
  text-align: center;
}

.preview-tags {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 15px 0;
  flex-wrap: wrap;
}

.preview-tag {
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 0.8rem;
}

.preview-gallery-btn {
  display: block;
  margin: 20px auto;
  padding: 10px 25px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .profile-preview {
    padding: 15px;
  }

  .preview-avatar {
    width: 80px;
    height: 80px;
  }

  .preview-profile h2 {
    font-size: 1.2rem;
  }
}

.form-actions {
  display: flex;
  justify-content: right;
  gap: 20px;
  margin: 20px 0;
}

.save-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.cancel-button {
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.error-msg {
  color: #ff4444;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}
</style> 