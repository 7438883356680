<template>
  <div class="signup-wrap">
    <div class="signup-container">
      <h1 class="signup-title">일반 사용자 회원가입</h1>
      
      <form @submit.prevent="handleSignUp" class="signup-form">
        <!-- 이름 입력 -->
        <div class="input-wrapper">
          <input 
            type="text" 
            v-model="name" 
            id="name" 
            required 
            :class="{'has-value': name}"
            :disabled="isVerified"
          />
          <label for="name">이름</label>
        </div>

        <!-- 전화번호 입력 -->
        <div class="input-wrapper">
          <input 
            type="text" 
            v-model="phone" 
            id="phone" 
            required 
            :class="{'has-value': phone}"
            @input="formatPhoneNumber"
            maxlength="13"
            :disabled="isVerified"
          />
          <label for="phone">전화번호</label>
        </div>

        <!-- 인증번호 입력 (조건부 렌더링) -->
        <div v-if="isCodeSent" class="input-wrapper">
          <input 
            type="text" 
            v-model="verificationCode" 
            id="verificationCode" 
            required 
            :class="{'has-value': verificationCode}"
            :disabled="isVerified"
          />
          <label for="verificationCode">인증번호</label>
        </div>

        <!-- 재전송 및 인증번호 전송 버튼 -->
        <div v-if="!isCodeSent" class="button-group">
          <button 
            type="button" 
            @click="sendVerificationCode" 
            class="send-code-button"
            :disabled="isLoading"
          >
            {{ isLoading ? '처리 중...' : '인증번호 전송' }}
          </button>
        </div>
        <div v-else class="button-group">
          <button type="button" @click="verifyCode" class="verify-code-button" :disabled="isVerified">인증번호 확인</button>
        </div>

        <!-- 에러 메시지 -->
        <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>

        <!-- 제출 버튼 (인증 완료 후 활성화) -->
        <button 
          type="submit" 
          class="submit-button" 
          :disabled="!isVerified"
        >
          회원가입
        </button>
      </form>

      <!-- reCAPTCHA 컨테이너 -->
      <div id="recaptcha-container" class="recaptcha-container"></div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from "vue";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth, db } from "@/firebase";
import { setDoc, doc } from "firebase/firestore";
import router from "@/router";

export default {
  name: "SignUpUser",
  setup() {
    const name = ref("");
    const phone = ref("");
    const verificationCode = ref("");
    const errorMessage = ref("");
    const isCodeSent = ref(false);
    const isVerified = ref(false);
    let confirmationResult = null;
    const isLoading = ref(false);
    const retryTimeoutId = ref(null);
    const retryCount = ref(0);

    const initializeRecaptcha = () => {
      try {
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'normal',
            'callback': () => {
              sendVerificationCodeInternal();
            },
            'expired-callback': () => {
              errorMessage.value = "reCAPTCHA가 만료되었습니다. 다시 시도해주세요.";
              if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
                window.recaptchaVerifier = null;
              }
            }
          });
        }
      } catch (error) {
        console.error("RecaptchaVerifier 초기화 실패:", error);
        errorMessage.value = "인증 초기화에 실패했습니다.";
      }
    };

    const sendVerificationCodeInternal = async () => {
      if (isLoading.value) return;
      
      try {
        isLoading.value = true;
        let phoneNumber = phone.value.replace(/[^0-9]/g, '');
        if (phoneNumber.startsWith('0')) {
          phoneNumber = phoneNumber.substring(1);
        }
        phoneNumber = `+82${phoneNumber}`;

        confirmationResult = await signInWithPhoneNumber(
          auth, 
          phoneNumber, 
          window.recaptchaVerifier
        );
        
        isCodeSent.value = true;
        alert("인증번호가 전송되었습니다.");
        retryCount.value = 0;
        await window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      } catch (error) {
        console.error("인증번호 전송 실패:", error);
        
        if (error.code === 'auth/too-many-requests') {
          const waitMinutes = Math.ceil((retryCount.value + 1) * 0.5); // 대기 시간을 분 단위로 계산
          errorMessage.value = `너무 많은 요청이 발생했습니다. ${waitMinutes}분 후에 다시 시도해주세요.`;
          
          const waitTime = waitMinutes * 60 * 1000; // 밀리초 단위로 변환
          retryCount.value++;
          
          if (retryTimeoutId.value) {
            clearTimeout(retryTimeoutId.value);
          }
          
          // 남은 시간 표시를 위한 카운트다운
          let remainingTime = waitTime / 1000;
          const countdownInterval = setInterval(() => {
            remainingTime--;
            if (remainingTime > 0) {
              errorMessage.value = `너무 많은 요청이 발생했습니다. ${Math.ceil(remainingTime / 60)}분 ${remainingTime % 60}초 후에 다시 시도해주세요.`;
            } else {
              clearInterval(countdownInterval);
              errorMessage.value = "";
            }
          }, 1000);
          
          retryTimeoutId.value = setTimeout(() => {
            clearInterval(countdownInterval);
            errorMessage.value = "";
          }, waitTime);
        } else if (error.code === 'auth/invalid-phone-number') {
          errorMessage.value = "올바른 전화번호 형식이 아닙니다.";
        } else {
          errorMessage.value = "인증번호 전송에 실패했습니다. 다시 시도해주세요.";
        }

        if (window.recaptchaVerifier) {
          try {
            await window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
          } catch (e) {
            console.error("reCAPTCHA clear 실패:", e);
          }
        }
      } finally {
        isLoading.value = false;
      }
    };

    const sendVerificationCode = async () => {
      try {
        errorMessage.value = "";
        if (!phone.value) {
          errorMessage.value = "전화번호를 입력해주세요.";
          return;
        }

        // 이전 reCAPTCHA 인스턴스가 있다면 제거
        if (window.recaptchaVerifier) {
          await window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        }

        // reCAPTCHA 초기화 및 렌더링
        initializeRecaptcha();
        if (window.recaptchaVerifier) {
          await window.recaptchaVerifier.render();
        }
      } catch (error) {
        console.error("reCAPTCHA 초기화/렌더링 실패:", error);
        errorMessage.value = "인증 초기화에 실패했습니다. 다시 시도해주세요.";
      }
    };

    // 컴포넌트가 제거될 때 reCAPTCHA 정리
    onUnmounted(() => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
      if (retryTimeoutId.value) {
        clearTimeout(retryTimeoutId.value);
      }
    });

    const verifyCode = () => {
      errorMessage.value = "";
      if (!verificationCode.value) {
        errorMessage.value = "인증번호를 입력해주세요.";
        return;
      }

      confirmationResult.confirm(verificationCode.value)
        .then(() => {
          isVerified.value = true;
          alert("인증이 완료되었습니다.");
        })
        .catch((error) => {
          console.error("인증번호 확인 실패", error);
          errorMessage.value = "인증번호가 올바르지 않습니다.";
        });
    };

    const handleSignUp = async () => {
      if (!isVerified.value) {
        errorMessage.value = "전화번호 인증을 완료해주세요.";
        return;
      }

      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error("사용자 정보가 없습니다.");
        }

        const userInfo = {
          name: name.value,
          phone: phone.value,
          userType: "guest"
        };

        await setDoc(doc(db, "users", user.uid), userInfo);
        alert("회원가입이 완료되었습니다!");
        router.push('/');
      } catch (error) {
        console.error("회원가입 실패", error);
        errorMessage.value = "회원가입에 실패했습니다. 다시 시도해주세요.";
      }
    };

    const formatPhoneNumber = () => {
      let formattedPhone = phone.value.replace(/[^0-9]/g, ''); // 숫자만 추출
      if (formattedPhone.length > 3 && formattedPhone.length <= 7) {
        formattedPhone = formattedPhone.replace(/(\d{3})(\d{1,4})/, '$1-$2');
      } else if (formattedPhone.length > 7) {
        formattedPhone = formattedPhone.replace(/(\d{3})(\d{4})(\d{1,4})/, '$1-$2-$3');
      }
      phone.value = formattedPhone;
    };

    return {
      name,
      phone,
      verificationCode,
      errorMessage,
      sendVerificationCode,
      verifyCode,
      handleSignUp,
      formatPhoneNumber,
      isCodeSent,
      isVerified,
      isLoading,
    };
  }
};
</script>

<style scoped>
.signup-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 60px;
  width: 100%;
}

.signup-container {
  width: 85%;
  margin-top: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.signup-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input {
  width: calc(100% - 30px);
  height: 25px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.input-wrapper label {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  transition: all 0.3s;
  pointer-events: none;
}

.input-wrapper input:focus,
.input-wrapper input.has-value {
  border-color: #333;
}

.input-wrapper input:focus + label,
.input-wrapper input.has-value + label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  background-color: white;
  padding: 0 5px;
  color: #333;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.send-code-button,
.verify-code-button {
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-code-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.submit-button {
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: black;
}

.error-msg {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.recaptcha-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  min-height: 65px;
}

.send-code-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}
</style>