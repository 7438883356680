// src/utils/pageView.js
import { db } from '@/firebase';
import { doc, setDoc, updateDoc, increment, getDoc } from 'firebase/firestore';

/**
 * 특정 페이지의 방문자 수를 증가시키는 함수
 * - totalCount: 전체 방문자 수
 * - daily/YYYY-MM-DD: 오늘 방문자 수
 * - monthly/YYYY-MM: 이번 달 방문자 수
 * 
 * @param {string} pageName - 페이지 이름 또는 아티스트의 userId
 */
export const incrementPageView = async (pageName) => {
  if (!pageName) {
    console.error('페이지 이름 또는 userId가 필요합니다.')
    return
  }

  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0]; // YYYY-MM-DD
  const formattedMonth = today.toISOString().slice(0,7); // YYYY-MM

  try {
    // 총 합계 증가
    const totalRef = doc(db, 'pageViews', pageName);
    const totalSnap = await getDoc(totalRef);
    if (totalSnap.exists()) {
      await updateDoc(totalRef, {
        totalCount: increment(1)
      });
    } else {
      await setDoc(totalRef, {
        totalCount: 1
      });
    }

    // 일별 방문자 수 증가
    const dailyRef = doc(db, 'pageViews', pageName, 'daily', formattedDate);
    const dailySnap = await getDoc(dailyRef);
    if (dailySnap.exists()) {
      await updateDoc(dailyRef, {
        count: increment(1)
      });
    } else {
      await setDoc(dailyRef, {
        count: 1
      });
    }

    // 월별 방문자 수 증가
    const monthlyRef = doc(db, 'pageViews', pageName, 'monthly', formattedMonth);
    const monthlySnap = await getDoc(monthlyRef);
    if (monthlySnap.exists()) {
      await updateDoc(monthlyRef, {
        count: increment(1)
      });
    } else {
      await setDoc(monthlyRef, {
        count: 1
      });
    }

  } catch (error) {
    console.error(`방문자 수 업데이트 실패 (${pageName}):`, error);
  }
};