import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/fontstyle.css'
import './chunk-common.js'

import { messaging } from './firebase-messaging.js';
import { getToken, onMessage } from 'firebase/messaging';

const app = createApp(App)

app.use(router)
app.mount('#app')

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(registration => {
        console.log('ServiceWorker 등록 성공:', registration)
      })
      .catch(error => {
        console.log('ServiceWorker 등록 실패:', error)
      })
  })
}

// 푸시 알림 권한 요청 및 토큰 가져오기
async function requestPermission() {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log('Notification permission granted.');

            const currentToken = await getToken(messaging, { vapidKey: 'BOva3icccl2C8tCfNFgXvsUiCFCBxVscb1SNbtxaFySTjlEZc0A9DE7gxSLpPTbC05kMU2F8h3qy7Y_pDwr-87c' });
            if (currentToken) {
                console.log('FCM 토큰:', currentToken);
                // 서버에 토큰 저장 로직 추가
                await sendTokenToServer(currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        } else {
            console.log('Unable to get permission to notify.');
        }
    } catch (error) {
        console.error('Error requesting permission:', error);
    }
}

async function sendTokenToServer(token) {
    // 서버와의 통신 로직을 추가하여 토큰을 저장합니다.
    try {
        const response = await fetch('/api/save-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });
        const data = await response.json();
        console.log('Token saved successfully:', data);
    } catch (error) {
        console.error('Error saving token:', error);
    }
}

requestPermission();

// 포그라운드 메시지 처리
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // 사용자에게 알림 표시 로직 추가
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: '/icons/icon-192x192.png'
    };

    new Notification(notificationTitle, notificationOptions);
}); 
