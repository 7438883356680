<template>
  <div v-if="isAdmin" class="user-management">
    <!-- 대시보드 섹션 -->
    <div class="header" @click="toggleDashboard">
      <div class="header-content">
        <h2>대시보드</h2>
        <span class="material-icons toggle-icon" :class="{ 'rotated': isDashboardOpen }">
          expand_more
        </span>
      </div>
    </div>

    <div class="management-content" :class="{ 'open': isDashboardOpen }">
      <div class="dashboard-container">
        <div class="chart-filters">
          <select v-model="selectedPage">
            <option value="">전체 페이지</option>
            <option v-for="page in pages" :key="page.id" :value="page.id">
              {{ page.nickname || page.id }}
            </option>
          </select>
          <select v-model="viewType">
            <option value="daily">일별 통계</option>
            <option value="monthly">월별 통계</option>
          </select>
        </div>

        <div class="chart-container">
          <Line
            v-if="chartData"
            :data="chartData"
            :options="chartOptions"
          />
        </div>

        <div class="stats-summary">
          <div class="stat-card">
            <h3>총 방문자 수</h3>
            <p>{{ totalViews }}</p>
          </div>
          <div class="stat-card">
            <h3>{{ viewType === 'daily' ? '오늘' : '이번 달' }} 방문자 수</h3>
            <p>{{ currentPeriodViews }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 기존 회원관리 섹션 -->
    <div class="header" @click="toggleManagement">
      <div class="header-content">
        <h2>회원 관리</h2>
        <span class="material-icons toggle-icon" :class="{ 'rotated': isOpen }">
          expand_more
        </span>
      </div>
      <p v-if="isOpen" class="total-users">전체 회원: {{ filteredUsers.length }}명</p>
    </div>

    <div class="management-content" :class="{ 'open': isOpen }">
      <div class="search-filter-container">
        <!-- 검색 바 -->
        <div class="search-bar">
          <span class="material-icons">search</span>
          <input 
            type="text" 
            v-model="searchQuery" 
            placeholder="이름, 전화번호로 검색"
          >
        </div>

        <!-- 필터 옵션 -->
        <div class="filter-options">
          <select v-model="userTypeFilter">
            <option value="">전체 회원</option>
            <option value="guest">일반 회원</option>
            <option value="tattooist">타투이스트</option>
            <option value="nottattooist">타투이스트 신청</option>
            <option value="admin">관리자</option>
          </select>

          <select v-model="sortOption">
            <option value="newest">최신 가입순</option>
            <option value="oldest">오래된 가입순</option>
            <option value="name">이름순</option>
          </select>
        </div>
      </div>

      <div class="user-list">
        <div v-for="user in filteredUsers" :key="user.id" class="user-card">
          <div class="user-header">
            <div class="user-avatar">
              <span class="material-icons">account_circle</span>
            </div>
            <div class="user-primary-info">
              <h3>{{ user.name || user.nickname || '미설정' }}</h3>
              <span class="user-type" :class="user.userType">
                {{ getUserTypeLabel(user.userType) }}
              </span>
            </div>
          </div>

          <div class="user-details">
            <div class="detail-item">
              <span class="material-icons">phone</span>
              <span>{{ user.phone || '미설정' }}</span>
            </div>
            <div class="detail-item">
              <span class="material-icons">calendar_today</span>
              <span>{{ formatDate(user.createdAt || user.updatedAt) }}</span>
            </div>
          </div>

          <div class="action-buttons">
            <button 
              v-if="user.userType === 'tattooist'" 
              class="profile-button ripple" 
              @click="goToProfile(user.id)"
            >
              <span class="material-icons">person</span>
              <span>프로필</span>
            </button>
            
            <button class="delete-button ripple" @click="confirmDelete(user)">
              <span class="material-icons">delete_outline</span>
              <span>삭제</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 뱃지 승인 관리 섹션 -->
    <div class="header" @click="toggleAwardManagement">
      <div class="header-content">
        <h2>뱃지 승인 관리</h2>
        <span class="material-icons toggle-icon" :class="{ 'rotated': isAwardOpen }">
          expand_more
        </span>
      </div>
      <p v-if="isAwardOpen" class="total-awards">대기 중: {{ pendingAwards.length }}건</p>
    </div>

    <div class="management-content" :class="{ 'open': isAwardOpen }">
      <div class="awards-list">
        <div v-for="award in pendingAwards" :key="award.id" class="award-card">
          <div class="award-header">
            <div class="award-icon">
              <img :src="require(`@/assets/award${award.icon}.png`)" :alt="award.name">
            </div>
            <div class="award-info">
              <h3>{{ award.name }}</h3>
              <p class="applicant">신청자: {{ award.userName || '이름 없음' }}</p>
            </div>
          </div>

          <div class="award-proof" @click="showProofImage(award.proofImage)">
            <img :src="award.proofImage" alt="증빙자료">
            <span class="view-proof">증빙자료 보기</span>
          </div>

          <div class="award-actions">
            <button class="approve-btn" @click="approveAward(award)">
              <span class="material-icons">check_circle</span>
              승인
            </button>
            <button class="reject-btn" @click="rejectAward(award)">
              <span class="material-icons">cancel</span>
              거절
            </button>
          </div>
        </div>

        <div v-if="pendingAwards.length === 0" class="no-awards">
          대기 중인 뱃지 신청이 없습니다.
        </div>
      </div>
    </div>

    <!-- 증빙자료 모달 -->
    <div v-if="selectedProofImage" class="modal-overlay" @click="selectedProofImage = null">
      <div class="modal-content proof-modal">
        <img :src="selectedProofImage" alt="증빙자료">
        <button class="close-modal" @click="selectedProofImage = null">닫기</button>
      </div>
    </div>

    <!-- 삭제 확인 모달 -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-content">
        <h3>회원 삭제</h3>
        <p>정말 <strong>{{ selectedUser?.name || selectedUser?.nickname || '이 사용자' }}</strong>를 삭제하시겠습니까?</p>
        <p class="warning-text">※ 이 작업은 되돌릴 수 없습니다.</p>
        <div class="modal-buttons">
          <button class="cancel-button ripple" @click="showDeleteModal = false">
            취소
          </button>
          <button class="confirm-delete-button ripple" @click="executeDelete">
            삭제
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="access-denied">
    <div class="access-denied-content">
      <span class="material-icons">lock</span>
      <h2>접근 권한이 없습니다</h2>
      <p>관리자만 접근할 수 있는 페이지입니다.</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onUnmounted } from "vue";
import { useRouter } from 'vue-router';
import { db, auth, storage } from "@/firebase";
import { collection, getDocs, deleteDoc, doc, getDoc, query, orderBy, limit, where, updateDoc, serverTimestamp } from "firebase/firestore";
import { deleteUser as deleteAuthUser } from "firebase/auth";
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { onAuthStateChanged } from 'firebase/auth';
import { deleteObject, ref as storageRef } from 'firebase/storage';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default {
  name: "UserManagement",
  components: { Line },
  setup() {
    const router = useRouter();
    const users = ref([]);
    const searchQuery = ref("");
    const userTypeFilter = ref("");
    const sortOption = ref("newest");
    const showDeleteModal = ref(false);
    const selectedUser = ref(null);
    const isOpen = ref(false);
    const isDashboardOpen = ref(false);
    const selectedPage = ref('');
    const viewType = ref('daily');
    const pages = ref([]);
    const viewData = ref({});
    const totalViews = ref(0);
    const currentPeriodViews = ref(0);
    const isAdmin = ref(false);
    const isAwardOpen = ref(false);
    const pendingAwards = ref([]);
    const selectedProofImage = ref(null);

    const getUserTypeLabel = (type) => {
      const types = {
        guest: '일반 회원',
        tattooist: '타투이스트',
        nottattooist: '타투이스트 신청',
        admin: '관리자',
        '': '미설정'
      };
      return types[type] || type;
    };

    const getTimestamp = (date) => {
      if (!date) return 0;
      if (date.toDate) return date.toDate().getTime();
      if (date.seconds) return date.seconds * 1000;
      return new Date(date).getTime();
    };

    const filteredUsers = computed(() => {
      let result = [...users.value];

      // 검색어 필터링
      if (searchQuery.value) {
        const query = searchQuery.value.toLowerCase();
        result = result.filter(user => 
          (user.name?.toLowerCase().includes(query) ||
           user.nickname?.toLowerCase().includes(query) ||
           user.phone?.includes(query))
        );
      }

      // 유저 타입 필터링
      if (userTypeFilter.value) {
        result = result.filter(user => user.userType === userTypeFilter.value);
      }

      // 정렬
      result.sort((a, b) => {
        const aTime = getTimestamp(a.createdAt || a.updatedAt);
        const bTime = getTimestamp(b.createdAt || b.updatedAt);

        switch (sortOption.value) {
          case 'newest':
            return bTime - aTime; // 최신순
          case 'oldest':
            return aTime - bTime; // 오래된순
          case 'name':
            return (a.name || a.nickname || '').localeCompare(b.name || b.nickname || '');
          default:
            return 0;
        }
      });

      return result;
    });

    const formatDate = (timestamp) => {
      if (!timestamp) return '날짜 없음';
      
      try {
        // Firestore Timestamp인 경우
        if (timestamp.toDate) {
          const date = timestamp.toDate();
          return new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }).format(date);
        }
        // 일반 Date 객체나 timestamp인 경우
        else if (timestamp.seconds) {
          const date = new Date(timestamp.seconds * 1000);
          return new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }).format(date);
        }
        // 다른 형식의 날짜인 경우
        else {
          const date = new Date(timestamp);
          return new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }).format(date);
        }
      } catch (error) {
        console.error('날짜 형식 변환 오류:', error, timestamp);
        return '날짜 형식 오류';
      }
    };

    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        users.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error("사용자 데이터를 가져오는 데 실패했습니다:", error);
        alert("사용자 데이터 로딩 실패");
      }
    };

    const confirmDelete = (user) => {
      selectedUser.value = user;
      showDeleteModal.value = true;
    };

    const executeDelete = async () => {
      if (!selectedUser.value) return;
      
      try {
        // Firestore 문서 삭제
        await deleteDoc(doc(db, "users", selectedUser.value.id));
        
        // Auth 사용자 삭제 시도
        try {
          const userAuth = auth.currentUser;
          if (userAuth && userAuth.uid === selectedUser.value.id) {
            await deleteAuthUser(userAuth);
          }
        } catch (authError) {
          console.error("Auth 사용자 삭제 실패:", authError);
        }

        // UI 업데이트
        users.value = users.value.filter(user => user.id !== selectedUser.value.id);
        alert("사용자가 성공적으로 삭제되었습니다.");
      } catch (error) {
        console.error("사용자 삭제 실패:", error);
        alert("사용자 삭제 중 오류가 발생했습니다.");
      } finally {
        showDeleteModal.value = false;
        selectedUser.value = null;
      }
    };

    const goToProfile = (userId) => {
      router.push(`/tattooist/${userId}`);
    };

    const toggleManagement = () => {
      isOpen.value = !isOpen.value;
    };

    const toggleDashboard = () => {
      isDashboardOpen.value = !isDashboardOpen.value;
      if (isDashboardOpen.value && !viewData.value[selectedPage.value]) {
        fetchPageViews();
      }
    };

    const chartData = computed(() => {
      if (!viewData.value[selectedPage.value]) return null;

      const data = viewData.value[selectedPage.value][viewType.value] || {};
      const labels = Object.keys(data).sort();
      const values = labels.map(label => data[label]);

      return {
        labels,
        datasets: [{
          label: '방문자 수',
          data: values,
          borderColor: '#2196f3',
          backgroundColor: 'rgba(33, 150, 243, 0.1)',
          tension: 0.4
        }]
      };
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true
        },
        tooltip: {
          mode: 'index',
          intersect: false
        }
      },
      scales: {
        y: {
          beginAtZero: true
        }
      }
    };

    const fetchPageViews = async () => {
      try {
        // 페이지 목록과 유저 정보 가져오기
        const pagesSnapshot = await getDocs(collection(db, 'pageViews'));
        const pageIds = pagesSnapshot.docs.map(doc => doc.id);
        
        // 각 페이지의 유저 정보 가져오기
        const pagesWithNicknames = await Promise.all(
          pageIds.map(async (pageId) => {
            try {
              const userDoc = await getDoc(doc(db, 'users', pageId));
              if (userDoc.exists()) {
                return {
                  id: pageId,
                  nickname: userDoc.data().nickname || userDoc.data().name
                };
              }
              return { id: pageId, nickname: null };
            } catch (error) {
              console.error(`유저 정보 로딩 실패 (${pageId}):`, error);
              return { id: pageId, nickname: null };
            }
          })
        );

        // 닉네임 순으로 정렬
        pages.value = pagesWithNicknames.sort((a, b) => {
          const nicknameA = a.nickname || a.id;
          const nicknameB = b.nickname || b.id;
          return nicknameA.localeCompare(nicknameB);
        });

        // 선택된 페이지의 통계 가져오기
        if (selectedPage.value) {
          const pageRef = doc(db, 'pageViews', selectedPage.value);
          const totalSnapshot = await getDoc(pageRef);
          
          if (totalSnapshot.exists()) {
            totalViews.value = totalSnapshot.data().totalCount || 0;
          }

          // 일별/월별 데이터 가져오기
          const periodRef = collection(db, 'pageViews', selectedPage.value, viewType.value);
          const periodQuery = query(periodRef, orderBy('count', 'desc'), limit(30));
          const periodSnapshot = await getDocs(periodQuery);

          const periodData = {};
          periodSnapshot.forEach(doc => {
            periodData[doc.id] = doc.data().count;
          });

          viewData.value = {
            ...viewData.value,
            [selectedPage.value]: {
              ...viewData.value[selectedPage.value],
              [viewType.value]: periodData
            }
          };

          // 현재 기간 조회수 계산
          const today = new Date().toISOString().split('T')[0];
          const currentMonth = new Date().toISOString().slice(0,7);
          currentPeriodViews.value = periodData[viewType.value === 'daily' ? today : currentMonth] || 0;
        }
      } catch (error) {
        console.error('통계 데이터 로딩 실패:', error);
      }
    };

    watch([selectedPage, viewType], () => {
      if (isDashboardOpen.value) {
        fetchPageViews();
      }
    });

    // 권한 체크 함수
    const checkAdminAccess = async (user) => {
      if (!user) {
        isAdmin.value = false;
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists() && userDoc.data().role === 'inkers112') {
          isAdmin.value = true;
        } else {
          isAdmin.value = false;
        }
      } catch (error) {
        console.error('권한 확인 실패:', error);
        isAdmin.value = false;
      }
    };

    // 인증 상태 감시
    onMounted(() => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        await checkAdminAccess(user);
        if (!isAdmin.value) {
          // 옵션: 권한이 없는 경우 다른 페이지로 리다이렉트
          // router.push('/');
        }
      });

      // 컴포넌트 언마운트 시 구독 해제
      onUnmounted(() => unsubscribe());
    });

    onMounted(() => {
      fetchUsers();
    });

    const toggleAwardManagement = () => {
      isAwardOpen.value = !isAwardOpen.value;
      if (isAwardOpen.value) {
        fetchPendingAwards();
      }
    };

    const fetchPendingAwards = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const awards = [];
        
        // 각 사용자의 awards 서브컬렉션을 확인
        for (const userDoc of usersSnapshot.docs) {
          const awardsSnapshot = await getDocs(
            query(
              collection(db, "users", userDoc.id, "awards"), 
              where("type", "==", "pending")
            )
          );
          
          // pending 상태인 뱃지들을 배열에 추가
          awardsSnapshot.forEach(doc => {
            awards.push({
              id: doc.id,
              userId: userDoc.id,
              userName: userDoc.data().name || userDoc.data().nickname,
              ...doc.data()
            });
          });
        }
        
        pendingAwards.value = awards;
      } catch (error) {
        console.error("Error fetching pending awards:", error);
      }
    };

    const approveAward = async (award) => {
      try {
        // Firestore 문서 업데이트
        await updateDoc(doc(db, "users", award.userId, "awards", award.id), {
          on: true,
          type: "approved",
          approvedAt: serverTimestamp()
        });
        
        // 승인 후에도 증빙자료는 보관
        await fetchPendingAwards();
      } catch (error) {
        console.error("Error approving award:", error);
        alert("뱃지 승인 중 오류가 발생했습니다.");
      }
    };

    const rejectAward = async (award) => {
      if (!confirm("정말 이 뱃지 신청을 거절하시겠습니까?")) return;
      
      try {
        // Storage에서 이미지 삭제
        if (award.proofImage) {
          try {
            // URL에서 storage 경로 추출
            const imagePath = decodeURIComponent(award.proofImage)
              .split('?')[0]  // 쿼리 파라미터 제거
              .split('/o/')[1]; // 경로 부분만 추출
            
            const imageRef = storageRef(storage, imagePath);
            await deleteObject(imageRef);
          } catch (storageError) {
            console.error("Error deleting image from storage:", storageError);
            // 이미지 삭제 실패해도 문서는 삭제 진행
          }
        }

        // Firestore 문서 삭제
        await deleteDoc(doc(db, "users", award.userId, "awards", award.id));
        await fetchPendingAwards();
        
        alert("뱃지 신청이 거절되었습니다.");
      } catch (error) {
        console.error("Error rejecting award:", error);
        alert("뱃지 거절 중 오류가 발생했습니다.");
      }
    };

    const showProofImage = (imageUrl) => {
      selectedProofImage.value = imageUrl;
    };

    return {
      users,
      searchQuery,
      userTypeFilter,
      sortOption,
      filteredUsers,
      getUserTypeLabel,
      formatDate,
      confirmDelete,
      executeDelete,
      showDeleteModal,
      selectedUser,
      goToProfile,
      isOpen,
      toggleManagement,
      isDashboardOpen,
      toggleDashboard,
      selectedPage,
      viewType,
      pages,
      chartData,
      chartOptions,
      totalViews,
      currentPeriodViews,
      isAdmin,
      isAwardOpen,
      pendingAwards,
      selectedProofImage,
      toggleAwardManagement,
      approveAward,
      rejectAward,
      showProofImage
    };
  },
};
</script>

<style scoped>
.user-management {
  padding: 16px;
  max-width: 800px;
  margin: 60px auto 0;
  background-color: #f8f9fa;
  min-height: calc(100vh - 60px);
}

.header {
  background: white;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: all 0.3s ease;
}

.header:hover {
  background: #f8f9fa;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-icon {
  transition: transform 0.3s ease;
}

.toggle-icon.rotated {
  transform: rotate(180deg);
}

.management-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.management-content.open {
  max-height: 100vh; /* 뷰포트 높이만큼 설정 */
  opacity: 1;
  overflow-y: auto; /* 스크롤 가능하도록 설정 */
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

/* 스크롤바 스타일링 */
.management-content::-webkit-scrollbar {
  width: 8px;
}

.management-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.management-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.management-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* 다크 모드에서의 스크롤바 */
@media (prefers-color-scheme: dark) {
  .management-content::-webkit-scrollbar-track {
    background: #2d2d2d;
  }

  .management-content::-webkit-scrollbar-thumb {
    background: #666;
  }

  .management-content::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

.search-filter-container {
  margin-top: 20px;
  border-radius: 16px;
  transition: all 0.3s ease;
}

.header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
}

.total-users {
  margin: 8px 0 0;
  color: #666;
  font-size: 14px;
}

.user-list {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.user-card {
  background: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.user-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-avatar .material-icons {
  font-size: 32px;
  color: #666;
}

.user-primary-info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.user-type {
  display: inline-block;
  padding: 4px 8px;
  background: #e8f5e9;
  color: #2e7d32;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 4px;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
}

.detail-item .material-icons {
  font-size: 20px;
  color: #999;
}

.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.delete-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #fff;
  color: #dc3545;
  border: 1px solid #dc3545;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background-color: #dc3545;
  color: white;
}

.delete-button .material-icons {
  font-size: 20px;
}

/* 리플 효과 */
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple:active::after {
  transform: scale(0, 0);
  opacity: .2;
  transition: 0s;
}

@media (max-width: 600px) {
  .user-management {
    padding: 12px;
  }

  .header {
    padding: 16px;
    border-radius: 12px;
  }

  .user-list {
    grid-template-columns: 1fr;
  }

  .user-card {
    padding: 16px;
    border-radius: 12px;
  }

  .user-header {
    gap: 12px;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
  }

  .user-avatar .material-icons {
    font-size: 28px;
  }

  .user-primary-info h3 {
    font-size: 16px;
  }

  .detail-item {
    font-size: 13px;
  }

  .delete-button {
    padding: 6px 12px;
    font-size: 13px;
  }
}

/* 다크 모드 지원 */
@media (prefers-color-scheme: dark) {
  .user-management {
    background-color: #121212;
  }

  .header, .user-card {
    background-color: #1e1e1e;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  }

  .header h2, .user-primary-info h3 {
    color: #fff;
  }

  .total-users, .detail-item {
    color: #999;
  }

  .user-avatar {
    background: #2d2d2d;
  }

  .user-type {
    background: #1b5e20;
    color: #fff;
  }

  .user-details {
    border-color: #333;
  }

  .delete-button {
    background-color: transparent;
    border-color: #dc3545;
  }

  .delete-button:hover {
    background-color: #dc3545;
  }
}

.search-filter-container {
  background: white;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f5f5f5;
  padding: 8px 16px;
  border-radius: 8px;
}

.search-bar input {
  flex: 1;
  border: none;
  background: none;
  padding: 8px;
  font-size: 14px;
  outline: none;
}

.search-bar .material-icons {
  color: #666;
}

.filter-options {
  display: flex;
  gap: 12px;
}

.filter-options select {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.user-type {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
}

.user-type.guest {
  background: #e3f2fd;
  color: #1976d2;
}

.user-type.tattooist {
  background: #e8f5e9;
  color: #2e7d32;
}

.user-type.admin {
  background: #fce4ec;
  color: #c2185b;
}

@media (max-width: 600px) {
  .search-filter-container {
    padding: 12px;
  }

  .filter-options {
    flex-direction: column;
  }

  .search-bar {
    padding: 6px 12px;
  }

  .filter-options select {
    width: 100%;
  }
}

/* 다크 모드 추가 스타일 */
@media (prefers-color-scheme: dark) {
  .search-filter-container {
    background-color: #1e1e1e;
  }

  .search-bar {
    background: #2d2d2d;
  }

  .search-bar input {
    color: #fff;
  }

  .filter-options select {
    background: #2d2d2d;
    border-color: #333;
    color: #fff;
  }

  .user-type.guest {
    background: #1a237e;
    color: #90caf9;
  }

  .user-type.tattooist {
    background: #1b5e20;
    color: #a5d6a7;
  }

  .user-type.admin {
    background: #880e4f;
    color: #f48fb1;
  }
}

.user-type.nottattooist {
  background: #fff3e0;
  color: #e65100;
}

/* 다크 모드에서의 스타일 */
@media (prefers-color-scheme: dark) {
  .user-type.nottattooist {
    background: #3e2723;
    color: #ffb74d;
  }
}

.profile-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #2196f3;
  background-color: transparent;
  color: #2196f3;
  cursor: pointer;
  transition: all 0.3s ease;
}

.profile-button:hover {
  background-color: #2196f3;
  color: white;
}

/* 다크 모드에서의 프로필 버튼 스타일 */
@media (prefers-color-scheme: dark) {
  .profile-button {
    border-color: #90caf9;
    color: #90caf9;
  }

  .profile-button:hover {
    background-color: #90caf9;
    color: #1a1a1a;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 16px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin: 0 0 16px 0;
  color: #333;
  font-size: 20px;
}

.modal-content p {
  margin: 8px 0;
  color: #666;
}

.warning-text {
  color: #d32f2f;
  font-size: 14px;
  margin-top: 16px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.cancel-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background: white;
  color: #666;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-button:hover {
  background: #f5f5f5;
}

.confirm-delete-button {
  padding: 8px 16px;
  border: none;
  background: #d32f2f;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.confirm-delete-button:hover {
  background: #b71c1c;
}

/* 다크 모드 스타일 */
@media (prefers-color-scheme: dark) {
  .modal-content {
    background: #1e1e1e;
  }

  .modal-content h3 {
    color: #fff;
  }

  .modal-content p {
    color: #999;
  }

  .warning-text {
    color: #ff5252;
  }

  .cancel-button {
    background: #333;
    border-color: #444;
    color: #fff;
  }

  .cancel-button:hover {
    background: #444;
  }

  .confirm-delete-button {
    background: #c62828;
  }

  .confirm-delete-button:hover {
    background: #b71c1c;
  }
}

/* 모바일 대응 */
@media (max-width: 600px) {
  .header {
    padding: 16px;
    border-radius: 12px;
  }

  .header h2 {
    font-size: 20px;
  }

  .toggle-icon {
    font-size: 24px;
  }
}

.dashboard-container {
  padding: 20px;
  background: white;
  border-radius: 16px;
  margin-top: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.chart-filters {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.chart-filters select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
  font-size: 14px;
}

.chart-container {
  height: 400px;
  margin-bottom: 20px;
}

.stats-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.stat-card {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 12px;
  text-align: center;
}

.stat-card h3 {
  margin: 0 0 8px;
  font-size: 16px;
  color: #666;
}

.stat-card p {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #2196f3;
}

/* 다크 모드 */
@media (prefers-color-scheme: dark) {
  .dashboard-container {
    background: #1e1e1e;
  }

  .chart-filters select {
    background: #2d2d2d;
    border-color: #444;
    color: #fff;
  }

  .stat-card {
    background: #2d2d2d;
  }

  .stat-card h3 {
    color: #999;
  }

  .stat-card p {
    color: #90caf9;
  }
}

/* 모바일 대응 */
@media (max-width: 600px) {
  .chart-filters {
    flex-direction: column;
  }

  .chart-container {
    height: 300px;
  }

  .stat-card {
    padding: 12px;
  }
}

.access-denied {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  padding: 20px;
}

.access-denied-content {
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.access-denied-content .material-icons {
  font-size: 48px;
  color: #dc3545;
  margin-bottom: 16px;
}

.access-denied-content h2 {
  margin: 0 0 8px;
  color: #333;
  font-size: 24px;
}

.access-denied-content p {
  margin: 0;
  color: #666;
  font-size: 16px;
}

/* 다크 모드 */
@media (prefers-color-scheme: dark) {
  .access-denied-content {
    background: #1e1e1e;
  }

  .access-denied-content h2 {
    color: #fff;
  }

  .access-denied-content p {
    color: #999;
  }
}

.award-card {
  background: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.award-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.award-icon {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.award-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.award-info h3 {
  margin: 0 0 5px 0;
  font-size: 16px;
}

.applicant {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.award-proof {
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
}

.award-proof img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.view-proof {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}

.award-actions {
  display: flex;
  gap: 10px;
}

.approve-btn, .reject-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.approve-btn {
  background-color: #10b981;
  color: white;
}

.reject-btn {
  background-color: #ef4444;
  color: white;
}

.no-awards {
  text-align: center;
  padding: 30px;
  color: #666;
}

.proof-modal {
  max-width: 90%;
  max-height: 90vh;
  overflow: auto;
}

.proof-modal img {
  width: 100%;
  height: auto;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0,0,0,0.7);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
