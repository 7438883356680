<template>
<div class="awards-setting">
    <h2>수상 뱃지 신청</h2>
    
    <div class="awards-grid">
    <div v-for="i in 6" 
            :key="i"
            class="award-item"
            :class="{ 'award-active': selectedAward === i }"
            @click="selectAward(i)">
        <img :src="require(`@/assets/award${i}.png`)" :alt="`뱃지 ${i}`">
        <span>뱃지 {{ i }}</span>
    </div>
    </div>

    <button 
    class="apply-btn"
    :disabled="!selectedAward"
    @click="showForm = true"
    >
    신청하기
    </button>

    <div class="award-form" v-if="showForm && selectedAward !== null">
    <h3>뱃지 신청하기</h3>
    <div class="form-group">
        <label>수상 내용</label>
        <p>(연도/국가,지역/대회명/수상분야/성적)</p>
        <p>(ex: 2024 한국문신전 이레즈미 1등)</p>
        <input 
        type="text" 
        v-model="awardName"
        placeholder="수상 내용을 입력해주세요"
        >
    </div>

    <div class="form-group">
        <label>증빙자료 첨부</label>
        <div class="upload-area" 
            @click="triggerFileInput"
            @dragover.prevent
            @drop.prevent="handleFileDrop">
        <input 
            type="file"
            ref="fileInput"
            @change="handleFileSelect"
            accept="image/*"
            style="display: none"
        >
        <div v-if="!previewImage" class="upload-placeholder">
            <i class="fas fa-cloud-upload-alt"></i>
            <p>이미지를 드래그하거나 클릭하여 업로드</p>
            <p class="upload-hint">JPG, PNG 파일 (최대 5MB)</p>
        </div>
        <img v-else :src="previewImage" class="preview-image" alt="Preview">
        </div>
        <button v-if="previewImage" 
                @click="removeImage" 
                class="remove-image-btn">
        이미지 삭제
        </button>
    </div>

    <button 
        class="submit-btn"
        :disabled="!isFormValid || loading"
        @click="submitAward"
    >
        {{ loading ? '신청 중...' : '신청하기' }}
    </button>
    </div>

    <div class="current-awards">
    <h3>나의 뱃지 목록</h3>
    <p class="badge-hint">승인된 뱃지를 클릭하여 아이콘을 변경할 수 있습니다.</p>
    <div class="awards-list">
        <div v-for="award in userAwards" 
            :key="award.id" 
            class="award-list-item"
            :class="{'award-pending': award.type === 'pending'}">
        <img 
            v-if="award.type === 'approved'" 
            :src="require(`@/assets/award${award.icon}.png`)" 
            :alt="award.name"
            class="award-icon"
            @click="editAwardIcon(award)"
        >
        <img 
            v-else 
            :src="require(`@/assets/award${award.icon}.png`)" 
            :alt="award.name"
        >
        <div class="award-info">
            <span class="award-name">{{ award.name }}</span>
            <span class="award-status">{{ award.type === 'pending' ? '심사중' : '승인됨' }}</span>
        </div>
        <div class="award-controls">
            <label class="switch">
            <input type="checkbox" 
                    :checked="award.on"
                    @change="toggleAward(award)"
                    :disabled="award.type === 'pending'">
            <span class="slider"></span>
            </label>
            <button class="delete-btn" @click="deleteAward(award.id)">삭제</button>
        </div>
        </div>
    </div>
    </div>

    <!-- 아이콘 변경 모달 -->
    <div v-if="showIconModal" class="icon-modal">
    <div class="modal-content">
        <h3>뱃지 아이콘 변경</h3>
        <div class="icons-grid">
        <div v-for="i in 6" 
                :key="i"
                class="icon-item"
                :class="{ 'icon-active': selectedIcon === i }"
                @click="selectedIcon = i">
            <img :src="require(`@/assets/award${i}.png`)" :alt="`뱃지 ${i}`">
        </div>
        </div>
        <div class="modal-actions">
        <button @click="showIconModal = false">취소</button>
        <button @click="updateAwardIcon" :disabled="!selectedIcon">변경</button>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { db, storage } from '@/firebase'
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

export default {
name: 'SettingAwards',
setup() {
    const auth = getAuth()
    const selectedAward = ref(null)
    const awardName = ref('')
    const loading = ref(false)
    const userAwards = ref([])
    const fileInput = ref(null)
    const previewImage = ref(null)
    const selectedFile = ref(null)
    const showForm = ref(false)
    const showIconModal = ref(false)
    const selectedIcon = ref(null)
    const editingAward = ref(null)

    const isFormValid = computed(() => {
    return selectedAward.value && 
            awardName.value.trim() && 
            selectedFile.value
    })

    const fetchUserAwards = async () => {
    const awardsRef = collection(db, `users/${auth.currentUser.uid}/awards`)
    const snapshot = await getDocs(awardsRef)
    userAwards.value = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }))
    }

    const triggerFileInput = () => {
    fileInput.value.click()
    }

    const handleFileSelect = (event) => {
    const file = event.target.files[0]
    handleFile(file)
    }

    const handleFileDrop = (event) => {
    const file = event.dataTransfer.files[0]
    handleFile(file)
    }

    const handleFile = (file) => {
    if (!file) return
    
    if (!file.type.match('image.*')) {
        alert('이미지 파일만 업로드 가능합니다.')
        return
    }

    if (file.size > 5 * 1024 * 1024) {
        alert('파일 크기는 5MB를 초과할 수 없습니다.')
        return
    }

    selectedFile.value = file
    const reader = new FileReader()
    reader.onload = e => previewImage.value = e.target.result
    reader.readAsDataURL(file)
    }

    const removeImage = () => {
    selectedFile.value = null
    previewImage.value = null
    if (fileInput.value) {
        fileInput.value.value = ''
    }
    }

    const uploadImage = async (file) => {
    const fileRef = storageRef(storage, `awards/${auth.currentUser.uid}/${Date.now()}_${file.name}`)
    await uploadBytes(fileRef, file)
    return await getDownloadURL(fileRef)
    }

    const submitAward = async () => {
    if (!isFormValid.value || loading.value) return

    loading.value = true
    try {
        const imageUrl = await uploadImage(selectedFile.value)
        const awardsRef = collection(db, `users/${auth.currentUser.uid}/awards`)
        await addDoc(awardsRef, {
        icon: selectedAward.value,
        name: awardName.value,
        type: 'pending',
        on: false,
        proofImage: imageUrl
        })
        
        awardName.value = ''
        selectedAward.value = null
        removeImage()
        await fetchUserAwards()
    } catch (error) {
        console.error('Error submitting award:', error)
        alert('뱃지 신청 중 오류가 발생했습니다.')
    } finally {
        loading.value = false
    }
    }

    const toggleAward = async (award) => {
    if (award.type === 'pending') {
        alert('심사중인 뱃지는 ON/OFF를 변경할 수 없습니다.')
        return
    }

    try {
        const awardRef = doc(db, `users/${auth.currentUser.uid}/awards/${award.id}`)
        await updateDoc(awardRef, {
        on: !award.on
        })
        await fetchUserAwards()
    } catch (error) {
        console.error('Error toggling award:', error)
    }
    }

    const deleteAward = async (awardId) => {
    if (!confirm('정말 삭제하시겠습니까?')) return
    
    try {
        const awardRef = doc(db, `users/${auth.currentUser.uid}/awards/${awardId}`)
        await deleteDoc(awardRef)
        await fetchUserAwards()
    } catch (error) {
        console.error('Error deleting award:', error)
    }
    }

    const selectAward = (index) => {
    selectedAward.value = index
    showForm.value = false
    awardName.value = ''
    }

    const editAwardIcon = (award) => {
    editingAward.value = award
    selectedIcon.value = award.icon
    showIconModal.value = true
    }

    const updateAwardIcon = async () => {
    if (!editingAward.value || !selectedIcon.value) return

    try {
        const awardRef = doc(db, `users/${auth.currentUser.uid}/awards/${editingAward.value.id}`)
        await updateDoc(awardRef, {
        icon: selectedIcon.value
        })
        await fetchUserAwards()
        showIconModal.value = false
        editingAward.value = null
        selectedIcon.value = null
    } catch (error) {
        console.error('Error updating award icon:', error)
    }
    }

    onMounted(() => {
    fetchUserAwards()
    })

    return {
    selectedAward,
    awardName,
    loading,
    userAwards,
    fileInput,
    previewImage,
    isFormValid,
    selectAward,
    submitAward,
    toggleAward,
    deleteAward,
    triggerFileInput,
    handleFileSelect,
    handleFileDrop,
    removeImage,
    showForm,
    showIconModal,
    selectedIcon,
    editAwardIcon,
    updateAwardIcon
    }
}
}
</script>

<style scoped>
.awards-setting {
padding: 20px;
margin-top: 60px;
}

.awards-grid {
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 20px;
max-width: 600px;
margin: 20px auto;
}

.award-item {
display: flex;
flex-direction: column;
align-items: center;
padding: 15px;
border: 2px solid #eee;
border-radius: 8px;
cursor: pointer;
transition: all 0.3s ease;
}

.award-item:hover {
border-color: #8B5CF6;
}

.award-active {
border-color: #8B5CF6;
background-color: #f3f0ff;
}

.award-item img {
width: 60px;
height: 60px;
object-fit: contain;
margin-bottom: 8px;
}

.award-form {
margin: 20px 0;
padding: 20px;
border: 1px solid #eee;
border-radius: 8px;
}

.form-group {
margin-bottom: 15px;
}

.form-group label {
display: block;
margin-bottom: 5px;
}

.form-group input {
width: 100%;
padding: 8px;
border: 1px solid #ddd;
border-radius: 4px;
box-sizing: border-box;
}

.form-group p {
margin: 0 0 10px;
font-size: 14px;
color: #666666;
}

.submit-btn {
background-color: #1a1a1a;
color: white;
border: none;
padding: 10px 20px;
border-radius: 4px;
cursor: pointer;
}

.submit-btn:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.badge-hint {
font-size: 14px;
color: #666;
margin: 10px 0;
padding: 8px 12px;
background-color: #f3f4f6;
border-radius: 4px;
border-left: 4px solid #8B5CF6;
}

.awards-list {
margin-top: 20px;
}

.award-list-item {
display: flex;
align-items: center;
padding: 15px;
border: 1px solid #eee;
border-radius: 8px;
margin-bottom: 10px;
background: white;
}

.award-list-item img {
width: 40px;
height: 40px;
object-fit: contain;
margin-right: 15px;
}

.award-info {
flex: 1;
margin-left: 15px;
}

.award-name {
display: block;
font-weight: 500;
margin-bottom: 4px;
}

.award-status {
font-size: 0.9em;
color: #666;
}

.award-pending {
background-color: #fdf6b2;
}

.award-controls {
display: flex;
align-items: center;
gap: 10px;
}

.switch {
position: relative;
display: inline-block;
width: 50px;
height: 24px;
}

.switch input {
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
transition: .4s;
border-radius: 24px;
}

.slider:before {
position: absolute;
content: "";
height: 16px;
width: 16px;
left: 4px;
bottom: 4px;
background-color: white;
transition: .4s;
border-radius: 50%;
}

input:checked + .slider {
background-color: #8B5CF6;
}

input:disabled + .slider {
background-color: #ccc;
cursor: not-allowed;
}

input:checked + .slider:before {
transform: translateX(26px);
}

.delete-btn {
padding: 5px 10px;
background-color: #ef4444;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.delete-btn:hover {
background-color: #dc2626;
}

.upload-area {
border: 2px dashed #ddd;
border-radius: 8px;
padding: 20px;
text-align: center;
cursor: pointer;
transition: all 0.3s ease;
}

.upload-area:hover {
border-color: #8B5CF6;
background-color: #f3f0ff;
}

.upload-placeholder {
color: #666;
}

.upload-placeholder i {
font-size: 2em;
margin-bottom: 10px;
color: #8B5CF6;
}

.upload-hint {
font-size: 0.8em;
color: #999;
margin-top: 5px;
}

.preview-image {
max-width: 100%;
max-height: 200px;
object-fit: contain;
}

.remove-image-btn {
margin-top: 10px;
padding: 5px 10px;
background-color: #ef4444;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.remove-image-btn:hover {
background-color: #dc2626;
}

.apply-btn {
display: block;
width: 200px;
margin: 20px auto;
padding: 12px 0;
background-color: #8B5CF6;
color: white;
border: none;
border-radius: 8px;
font-size: 16px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.apply-btn:hover {
background-color: #7c3aed;
}

.apply-btn:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.award-icon {
width: 40px;
height: 40px;
object-fit: contain;
cursor: pointer;
transition: transform 0.2s ease;
}

.award-icon:active {
transform: scale(0.95);
}

.award-list-item {
display: flex;
align-items: center;
padding: 15px;
border: 1px solid #eee;
border-radius: 8px;
margin-bottom: 10px;
background: white;
}

.award-list-item img {
width: 40px;
height: 40px;
object-fit: contain;
}

.edit-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.3);
display: flex;
align-items: center;
justify-content: center;
}

.edit-overlay i {
color: white;
font-size: 14px;
}

.icon-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
}

.modal-content {
background: white;
padding: 20px;
border-radius: 8px;
width: 90%;
max-width: 500px;
}

.icons-grid {
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 15px;
margin: 20px 0;
}

.icon-item {
padding: 10px;
border: 2px solid #eee;
border-radius: 8px;
cursor: pointer;
display: flex;
justify-content: center;
}

.icon-item img {
width: 40px;
height: 40px;
object-fit: contain;
}

.icon-active {
border-color: #8B5CF6;
background-color: #f3f0ff;
}

.modal-actions {
display: flex;
justify-content: flex-end;
gap: 10px;
margin-top: 20px;
}

.modal-actions button {
padding: 8px 16px;
border-radius: 4px;
border: none;
cursor: pointer;
}

.modal-actions button:first-child {
background-color: #e5e7eb;
}

.modal-actions button:last-child {
background-color: #8B5CF6;
color: white;
}

.modal-actions button:disabled {
background-color: #ccc;
cursor: not-allowed;
}
</style>