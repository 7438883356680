<template>
  <div class="request-detail">
    <div class="header">
      <button @click="goBack" class="back-button">
        <i class="fas fa-arrow-left"></i>
      </button>
      <h2>견적서 상세</h2>
      <button @click="confirmDelete" class="delete-button">
        <i class="fas fa-trash"></i>
      </button>
    </div>
    
    <div v-if="loading" class="loading">
      <div class="loading-spinner"></div>
    </div>
    
    <div v-else-if="request">
      <!-- 타투이스트 프로필 (직접 의뢰인 경우) -->
      <div v-if="request.type === 'direct' && tattooist" class="profile-section">
        <router-link :to="`/tattooist/${request.tattooistId}`" class="profile-link">
          <div class="profile-left">
            <img 
              :src="tattooist.profileImageUrl || '/default-profile.png'" 
              :alt="tattooist.nickname"
              class="profile-image"
            />
            <div class="profile-info">
              <strong>{{ tattooist.nickname }}</strong>
              <p>{{ formatAddress(tattooist.address) }}</p>
            </div>
          </div>
          <i class="fas fa-chevron-right"></i>
        </router-link>
      </div>

      <!-- 의뢰 정보 -->
      <div class="info-section">
        <div class="info-header">
          <h3 class="request-title">{{ `${request.city} ${request.district}` }}</h3>
          <div class="date">{{ formatDate(request.createdAt) }}</div>
        </div>

        <div class="info-list">
          <div class="info-item">
            <label>예산</label>
            <p class="price">{{ formatPrice(request.budget) }}</p>
          </div>
          <div class="info-item">
            <label>장르</label>
            <p>{{ request.tattooStyle || request.tattooType }}</p>
          </div>
          <div class="info-item">
            <label>사이즈</label>
            <p>{{ request.size }}</p>
          </div>
          <div class="info-item">
            <label>타투 부위</label>
            <p>{{ request.location }}</p>
          </div>
          <div class="info-item">
            <label>희망 지역</label>
            <p>{{ request.city }} {{ request.district }}</p>
          </div>
          <div class="info-item">
            <label>희망 날짜</label>
            <p>{{ request.preferredDate }}</p>
          </div>
          <div class="info-item">
            <label>희망 시간대</label>
            <p>{{ request.preferredTime }}</p>
          </div>
          <div class="info-item">
            <label>연락처</label>
            <p>{{ request.phone }}</p>
          </div>
        </div>

        <div class="description">
          <h4>상세 설명</h4>
          <p>{{ request.description }}</p>
        </div>

        <!-- 이미지 갤러리를 상세 설명 아래로 이동 -->
        <div v-if="request.referenceImages?.length" class="image-section">
          <h4>레퍼런스 이미지</h4>
          <img 
            :src="request.referenceImages[currentImageIndex]" 
            :alt="`레퍼런스 이미지 ${currentImageIndex + 1}`" 
            class="main-image"
          />
          <div class="image-thumbnails" v-if="request.referenceImages.length > 1">
            <button 
              v-for="(image, index) in request.referenceImages" 
              :key="index"
              :class="['thumbnail', { active: index === currentImageIndex }]"
              @click="currentImageIndex = index"
            >
              <img :src="image" :alt="`썸네일 ${index + 1}`" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="error-state">
      견적서를 찾을 수 없습니다.
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'GuestRequestDetail',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const request = ref(null);
    const tattooist = ref(null);
    const loading = ref(true);
    const currentImageIndex = ref(0);

    const formatAddress = (address) => {
      if (!address) return '프로필 이동하기';
      return address.split(' ').slice(0, 3).join(' ');
    };

    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      return new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    };

    const formatPrice = (price) => {
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW'
      }).format(price);
    };

    const goBack = () => {
      router.push('/guest-requests');
    };

    const fetchRequest = async () => {
      try {
        const { type, id } = route.params;
        const collection = type === 'tattoo' ? 'tattooRequests' : 'openRequests';
        const docRef = doc(db, collection, id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          request.value = {
            id: docSnap.id,
            type,
            ...docSnap.data(),
            images: docSnap.data().referenceImages || []
          };

          // 직접 의뢰인 경우 타투이스트 정보 조회
          if (type === 'direct' && request.value.tattooistId) {
            const tattooistDoc = await getDoc(doc(db, 'users', request.value.tattooistId));
            if (tattooistDoc.exists()) {
              tattooist.value = tattooistDoc.data();
            }
          }
        }
      } catch (error) {
        console.error('견적서 조회 실패:', error);
      } finally {
        loading.value = false;
      }
    };

    const confirmDelete = async () => {
      if (!confirm('정말로 이 견적서를 삭제하시겠습니까?')) {
        return;
      }

      try {
        const { type, id } = route.params;
        const collection = type === 'tattoo' ? 'tattooRequests' : 'openRequests';
        await deleteDoc(doc(db, collection, id));
        alert('견적서가 삭제되었습니다.');
        router.push('/guest-requests');
      } catch (error) {
        console.error('견적서 삭제 실패:', error);
        alert('견적서 삭제 중 오류가 발생했습니다.');
      }
    };

    onMounted(fetchRequest);

    return {
      request,
      tattooist,
      loading,
      currentImageIndex,
      formatPrice,
      formatAddress,
      formatDate,
      goBack,
      confirmDelete
    };
  }
}
</script>

<style scoped>
.request-detail {
  background: #f8f9fa;
  min-height: 100vh;
  margin-top: 60px;
}

.header {
  z-index: 100;
  background: white;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
}

.header h2 {
  font-size: 18px;
  margin: 0;
  flex: 1;
  text-align: center;
}

.back-button, .delete-button {
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}

.delete-button {
  color: #dc3545;
  font-size: 18px;
}

.image-section {
  width: 100%;
  margin: 16px 0 24px;
  border-radius: 8px;
  overflow: hidden;
  background: white;
}

.main-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: contain;
  display: block;
  background: white;
}

.image-thumbnails {
  display: flex;
  gap: 8px;
  padding: 12px;
  background: white;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.image-thumbnails::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.thumbnail {
  width: 64px;
  height: 64px;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 0;
  background: none;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}

.thumbnail.active {
  border-color: #ff6f0f;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-section {
  padding: 20px;
  background: white;
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.request-title {
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  margin: 0;
}

.date {
  color: #868e96;
  font-size: 14px;
}

.info-list {
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #f1f3f5;
}

.info-item label {
  color: #868e96;
  font-size: 14px;
}

.info-item p {
  color: #495057;
  font-size: 15px;
  margin: 0;
  text-align: right;
}

.info-item .price {
  color: #ff6f0f;
  font-weight: 600;
  font-size: 16px;
}

.description {
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.description h4 {
  font-size: 16px;
  margin-bottom: 8px;
}

.description p {
  font-size: 15px;
  line-height: 1.6;
  color: #495057;
  white-space: pre-wrap;
}

.profile-section {
  background: white;
  padding: 16px;
  margin-bottom: 8px;
}

.profile-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
}

.profile-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-info strong {
  font-size: 16px;
  color: #212529;
  margin-bottom: 4px;
}

.profile-info p {
  font-size: 14px;
  color: #868e96;
  margin: 0;
}

.fa-chevron-right {
  color: #adb5bd;
  font-size: 14px;
}
</style> 