//(유저) 의뢰 목록

<template>
  <div class="request-list">
    <div v-if="loading" class="loading">
      <div class="loading-spinner"></div>
    </div>

    <div v-else>
      <!-- 통합된 의뢰 목록 -->
      <div class="request-cards">
        <div v-if="allRequests.length === 0" class="empty-state">
          <p>아직 작성한 견적서가 없어요</p>
          <p>견적서를 작성해서 맞춤 타투를 받아보세요</p>
        </div>

        <div v-else>
          <div v-for="request in allRequests" :key="request.id" class="request-card">
            <router-link :to="`/request/tattoo/${request.id}`" class="request-content-link">
              <div class="request-image" v-if="request.referenceImages?.length">
                <img :src="request.referenceImages[0]" alt="참고 이미지">
              </div>
              <div class="request-content">
                <h3 class="request-title">{{ formatDate(request.createdAt) }}</h3>
                <div class="request-info">
                  <span class="info-item">{{ request.selectedGenres?.[0] || request.tattooStyle || '스타일 미지정' }}</span>
                  <span class="info-divider">•</span>
                  <span class="info-item">{{ request.size || '사이즈 미지정' }}</span>
                </div>
                <div class="request-footer">
                  <span class="request-price">{{ formatPrice(request.budget) }}</span>
                </div>
                <p class="request-date">{{ `${request.city} ${request.district}` }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- 플로팅 메뉴 -->
    <div class="floating-menu">
      <router-link to="/open-request" class="floating-button open">견적요청</router-link>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '@/firebase';

export default {
  name: 'GuestRequestList',
  setup() {
    const router = useRouter();
    const loading = ref(true);
    const openRequests = ref([]);
    const tattooRequests = ref([]);

    // 의뢰 목록을 시간순으로 정렬하여 반환하는 computed 속성
    const allRequests = computed(() => {
      return [...openRequests.value, ...tattooRequests.value].sort((a, b) => {
        const dateA = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const dateB = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return dateB - dateA;
      });
    });

    // 공개 의뢰 목록 조회
    const fetchRequests = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) return;

        // 타투 의뢰만 조회하도록 수정
        const tattooQuery = query(
          collection(db, 'tattooRequests'),
          where('userId', '==', userId)
        );
        
        const tattooSnapshot = await getDocs(tattooQuery);

        tattooRequests.value = tattooSnapshot.docs.map(doc => ({
          id: doc.id,
          type: 'tattoo',
          ...doc.data()
        }));

        console.log('Fetched requests:', tattooRequests.value); // 데이터 확인용 로그

      } catch (error) {
        console.error('견적 목록 조회 실패:', error);
      }
    };

    onMounted(async () => {
      try {
        if (!auth.currentUser) {
          router.push('/TattooAuth');
          return;
        }
        await fetchRequests();
      } catch (error) {
        console.error('견적 목록 조회 실패:', error);
      } finally {
        loading.value = false;
      }
    });

    const formatDate = (date) => {
      if (!date) return '';
      
      // Firebase Timestamp 객체인 경우
      if (date.toDate && typeof date.toDate === 'function') {
        date = date.toDate();
      }
      // 문자열인 경우
      else if (typeof date === 'string') {
        date = new Date(date);
      }
      // 이미 Date 객체인 경우는 그대로 사용

      return new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    };

    const formatPrice = (price) => {
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW'
      }).format(price);
    };

    return {
      loading,
      allRequests,
      formatDate,
      formatPrice,
    };
  }
};
</script>

<style scoped>
.request-list {
  margin: 0 auto;
  padding: 0;
  margin-top: 60px;
}

.request-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.request-card {
  display: flex;
  align-items: center;
  padding: 10px;
  background: white;
  border-bottom: 1px solid #f2f2f2;
}

.request-content-link {
  display: flex;
  gap: 16px;
  flex: 1;
  text-decoration: none;
  color: inherit;
}

.request-image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #f8f9fa;
}

.request-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.request-content {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.request-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #212529;
}

.request-info {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #868e96;
  font-size: 14px;
}

.info-divider {
  color: #dee2e6;
}

.request-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-price {
  font-size: 18px;
  font-weight: 700;
  color: #ff6f0f;
}

.request-date {
  font-size: 14px;
  color: #adb5bd;
  margin: 0;
}

.empty-state {
  text-align: center;
  padding: 48px 16px;
}

.empty-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
  opacity: 0.5;
}

.floating-menu {
  position: fixed;
  bottom: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.floating-button {
  width: auto;
  height: auto;
  padding: 12px 24px;
  border-radius: 28px;
  background: #1a1a1a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 32px;
}

.loading-spinner {
  width: 32px;
  height: 32px;
  border: 3px solid #f2f2f2;
  border-top-color: #ff6f0f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style> 