//(아티스트) 공개의뢰 목록

<template>
  <div class="open-request-list">
    <h2>공개의뢰 목록</h2>
    <p>모든 아티스트에게 열린 요청입니다.</p>
    <!-- 필터 토글 버튼 -->
    <div class="filter-toggle" @click="isFilterOpen = !isFilterOpen">
      <div class="filter-header">
        <div class="filter-buttons">
          <button @click.stop="resetFilters" class="filter-button">
            <i class="fas fa-rotate"></i> 초기화
          </button>
          <button @click.stop="getCurrentLocation" class="filter-button">
            <i class="fas fa-location-dot"></i> 내 위치
          </button>
        </div>
        <i :class="['fas', isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
      </div>
    </div>

    <!-- 필터 섹션 -->
    <div class="filters" :class="{ 'filters-open': isFilterOpen }">
      <!-- 지역 필터 -->
      <div class="filter-section">
        <h3 class="filter-title">지역 필터</h3>
        <div class="filter-group location-filters">
          <select v-model="selectedCity" class="filter-select city-select">
            <option value="">전체</option>
            <option v-for="city in Object.keys(districts)" :key="city" :value="city">
              {{ city }}
            </option>
          </select>
          <select 
            v-if="selectedCity" 
            v-model="selectedDistrict" 
            class="filter-select district-select"
          >
            <option value="">전체</option>
            <option v-for="district in districts[selectedCity]" :key="district" :value="district">
              {{ district }}
            </option>
          </select>
        </div>
      </div>

      <!-- 장르 필터 -->
      <div class="filter-section">
        <h3 class="filter-title">장르 필터</h3>
        <div class="filter-group">
          <select v-model="selectedGenre" class="filter-select genre-select">
            <option value="">전체</option>
            <option v-for="genre in genres" :key="genre" :value="genre">
              {{ genre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- 의뢰서 목록 -->
    <div v-if="loading" class="loading">
      <span>로딩 중...</span>
    </div>
    <div v-else class="request-cards">
      <div v-if="filteredRequests.length === 0" class="empty-state">
        <p>해당하는 의뢰서가 없습니다.</p>
      </div>

      <div v-else v-for="request in filteredRequests" :key="request.id" class="request-card">
        <div class="request-info">
          <div class="request-header">
            <span class="request-type">{{ request.city }} {{ request.district }}</span>
            <span class="request-date">{{ DateLate(request.createdAt) }}</span>
          </div>
          <div class="request-details">
            <p><strong>희망일:</strong> {{ formatDate(request.preferredDate) }}</p>
            <p><strong>사이즈:</strong> {{ request.size }}</p>
            <p><strong>부위:</strong> {{ request.location }}</p>
            <p><strong>예산:</strong> {{ formatPrice(request.budget) }}</p>
          </div>
          <div class="reference-images" v-if="request.referenceImages?.length">
            <img 
              v-for="(image, index) in request.referenceImages.slice(0, 3)" 
              :key="index" 
              :src="image" 
              alt="참고 이미지"
            />
            <div v-if="request.referenceImages.length > 3" class="more-images">
              +{{ request.referenceImages.length - 3 }}
            </div>
          </div>
        </div>
        <div class="request-actions">
          <button @click="showRequestDetail(request)" class="detail-button">
            상세 보기
          </button>
          <button @click="createQuotation(request)" class="quote-button">
            견적서 작성
          </button>
        </div>
      </div>
    </div>

    <!-- 상세 보기 모달 -->
    <div v-if="selectedRequest" class="modal">
      <div class="modal-content">
        <h3>의뢰서 상세 정보</h3>
        <div class="request-full-details">
          <p><strong>스타일:</strong> {{ selectedRequest.tattooStyle }}</p>
          <p><strong>사이즈:</strong> {{ selectedRequest.size }}</p>
          <p><strong>부위:</strong> {{ selectedRequest.location }}</p>
          <p><strong>예산:</strong> {{ formatPrice(selectedRequest.budget) }}</p>
          <p><strong>희망 지역:</strong> {{ selectedRequest.city }} {{ selectedRequest.district }}</p>
          <p><strong>희망 날짜:</strong> {{ formatDate(selectedRequest.preferredDate) }}</p>
          <p><strong>희망 시간:</strong> {{ selectedRequest.preferredTime }}</p>
          <p><strong>상세 설명:</strong> {{ selectedRequest.description }}</p>
          
          <div class="reference-images-full" v-if="selectedRequest.referenceImages?.length">
            <h4>참고 이미지</h4>
            <div class="images-grid">
              <div v-for="(image, index) in selectedRequest.referenceImages" 
                   :key="index" 
                   class="image-container">
                <img :src="image" alt="참고 이미지" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-actions">
          <button @click="selectedRequest = null" class="cancel-button">닫기</button>
          <button @click="createQuotation(selectedRequest)" class="quote-button">
            견적서 작성
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { collection, query, getDocs, orderBy } from 'firebase/firestore'
import { db } from '@/firebase'

export default {
  name: 'OpenRequestList',
  setup() {
    const router = useRouter()
    const loading = ref(true)
    const requests = ref([])
    const selectedRequest = ref(null)
    const selectedCity = ref('')
    const selectedDistrict = ref('')
    const selectedGenre = ref('')
    const isFilterOpen = ref(false)

    const districts = ref({
      서울: ['강남구', '강동구', '강북구', '강서구', '관악구', '광진구', '구로구', '금천구', 
             '노원구', '도봉구', '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구',
             '성북구', '송파구', '양천구', '영등포구', '용산구', '은평구', '종로구', '중구', '중랑구'],
      부산: ['강서구', '금정구', '남구', '동구', '동래구', '부산진구', '북구', '사상구',
             '사하구', '서구', '수영구', '연제구', '영도구', '중구', '해운대구', '기장군'],
      대구: ['남구', '달서구', '동구', '북구', '서구', '수성구', '중구', '달성군'],
      인천: ['계양구', '남동구', '동구', '미추홀구', '부평구', '서구', '연수구', '중구', '강화군', '옹진군'],
      광주: ['광산구', '남구', '동구', '북구', '서구'],
      대전: ['대덕구', '동구', '서구', '유성구', '중구'],
      울산: ['남구', '동구', '북구', '중구', '울주군'],
      세종: ['세종시'],
      경기: ['고양시', '과천시', '광명시', '광주시', '구리시', '군포시', '김포시', '남양주시',
             '동두천시', '부천시', '성남시', '수원시', '시흥시', '안산시', '안성시', '안양시',
             '양주시', '여주시', '오산시', '용인시', '의왕시', '의정부시', '이천시', '파주시',
             '평택시', '포천시', '하남시', '화성시', '가평군', '양평군', '연천군'],
      강원: ['강릉시', '동해시', '삼척시', '속초시', '원주시', '춘천시', '태백시', '고성군',
             '양구군', '양양군', '영월군', '인제군', '정선군', '철원군', '평창군', '홍천군',
             '화천군', '횡성군'],
      충북: ['제천시', '청주시', '충주시', '괴산군', '단양군', '보은군', '영동군', '옥천군',
             '음성군', '증평군', '진천군'],
      충남: ['계룡시', '공주시', '논산시', '당진시', '보령시', '서산시', '아산시', '천안시',
             '금산군', '부여군', '서천군', '예산군', '청양군', '태안군', '홍성군'],
      전북: ['군산시', '김제시', '남원시', '익산시', '전주시', '정읍시', '고창군', '무주군',
             '부안군', '순창군', '완주군', '임실군', '장수군', '진안군'],
      전남: ['광양시', '나주시', '목포시', '순천시', '여수시', '강진군', '고흥군', '곡성군',
             '구례군', '담양군', '무안군', '보성군', '신안군', '영광군', '영암군', '완도군',
             '장성군', '장흥군', '진도군', '함평군', '해남군', '화순군'],
      경북: ['포항시', '경주시', '김천시', '안동시', '구미시', '영주시', '영천시', '상주시',
            '문경시', '경산시', '군위군', '의성군', '청송군', '영양군', '영덕군', '청도군',
            '고령군', '성주군', '칠곡군', '예천군', '봉화군', '울진군', '울릉군'],
      경남: ['창원시', '진주시', '통영시', '사천시', '김해시', '밀양시', '거제시', '양산시',
            '의령군', '함안군', '창녕군', '고성군', '남해군', '하동군', '산청군', '함양군',
            '거창군', '합천군'],
    })

    const genres = [
      '이레즈미', '치카노', '라인워크', '블랙앤그레이', '올드스쿨',
      '뉴스쿨', '블랙워크', '트라이벌', '컬러타투', '일러스트',
      '커버업', '수채화', '미니타투', '감성타투', '레터링', '동양화', '터치업'
    ]

    // 필터링된 의뢰서 목록
    const filteredRequests = computed(() => {
      return requests.value.filter(request => {
        const matchesCity = !selectedCity.value || request.city === selectedCity.value
        const matchesDistrict = !selectedDistrict.value || request.district === selectedDistrict.value
        const matchesGenre = !selectedGenre.value || 
          request.selectedGenres?.includes(selectedGenre.value) || 
          request.tattooStyle === selectedGenre.value
        return matchesCity && matchesDistrict && matchesGenre
      })
    })

    // 의뢰서 목록 조회
    const fetchRequests = async () => {
      try {
        loading.value = true
        const q = query(
          collection(db, 'tattooRequests'),
          orderBy('createdAt', 'desc')
        )
        const querySnapshot = await getDocs(q)
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        
        requests.value = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(request => {
            const createdAt = request.createdAt?.toDate();
            return createdAt && createdAt > threeDaysAgo;
          });
      } catch (error) {
        console.error('의뢰서 조회 실패:', error)
      } finally {
        loading.value = false
      }
    }

    // 견적서 작성 페이지로 이동
    const createQuotation = (request) => {
      router.push({
        name: 'CreateQuotation',
        params: { requestId: request.id }
      })
    }

    // 상세 보기 모달 표시
    const showRequestDetail = (request) => {
      selectedRequest.value = request
    }

    // 날짜 포맷팅
    const formatDate = (date) => {
      if (!date) return ''
      if (date.toDate) date = date.toDate()
      return new Date(date).toLocaleDateString('ko-KR')
    }

    const DateLate = (date) => {
      if (!date) return '';
      if (date.toDate) date = date.toDate();
      
      const now = new Date();
      const targetDate = new Date(date);
      const diff = now - targetDate;
      
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      
      if (days > 0) return `${days}일전`;
      if (hours > 0) return `${hours}시간전`;
      if (minutes > 0) return `${minutes}분전`;
      if (seconds > 0) return `${seconds}초전`;
      
      return '방금전';
    }

    // 가격 포맷팅
    const formatPrice = (price) => {
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW'
      }).format(price)
    }

    // watch 수정
    watch(selectedCity, (newCity) => {
      if (!newCity) {
        // 시/도가 '전체'로 선택되면 구/군도 초기화
        selectedDistrict.value = '';
      } else if (districts.value[newCity]) {
        // 현재 선택된 구/군이 새로운 시/도의 districts에 없는 경우에만 초기화
        if (!districts.value[newCity].includes(selectedDistrict.value)) {
          selectedDistrict.value = '';
        }
      }
    });

    // 현재 위치 가져오기
    const getCurrentLocation = () => {
      if (!navigator.geolocation) {
        alert('위치 서비스가 지원되지 않는 브라우저입니다.');
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            console.log('현재 위치:', latitude, longitude);

            naver.maps.Service.reverseGeocode({
              coords: new naver.maps.LatLng(latitude, longitude),
              orders: [
                naver.maps.Service.OrderType.ADDR,
                naver.maps.Service.OrderType.ROAD_ADDR
              ].join(',')
            }, (status, response) => {
              if (status === naver.maps.Service.Status.ERROR) {
                console.error('네이버 API 에러');
                alert('현재 위치를 가져오는데 실패했습니다.');
                return;
              }

              if (response.v2.results && response.v2.results.length > 0) {
                const result = response.v2.results[0];
                
                // 시/도 이름 처리 ('경기도' -> '경기')
                let area1 = result.region.area1.name;
                area1 = area1.replace('도', '').replace('시', '').replace('특별', '').replace('광역', '');
                
                // 구/군 이름 처리
                let area2 = result.region.area2.name;
                // 경기도의 경우 area2(시 단위)만 사용
                if (area1 === '경기') {
                  // 구 이름 제거하고 시 이름만 사용
                  area2 = area2.split(' ')[0];  // "안산시 단원구" -> "안산시"
                  area2 = area2.endsWith('시') ? area2 : area2 + '시';
                }

                console.log('정제된 지역:', area1, area2);

                // 지역 선택 (watch 트리거 방지를 위해 순서 변경)
                if (Object.keys(districts.value).includes(area1) && districts.value[area1]?.includes(area2)) {
                  // selectedDistrict를 먼저 설정하고
                  selectedDistrict.value = area2;
                  // 그 다음 selectedCity 설정
                  selectedCity.value = area1;
                  
                  console.log('선택된 시/도:', selectedCity.value);
                  console.log('선택된 구/군:', selectedDistrict.value);
                }
              }
            });
          } catch (error) {
            console.error('위치 변환 실패:', error);
            alert('현재 위치를 가져오는데 실패했습니다.');
          }
        },
        (error) => {
          console.error('위치 정보 가져오기 실패:', error);
          alert('위치 정보를 가져올 수 없습니다.');
        }
      );
    };

    // 필터 초기화 함수
    const resetFilters = () => {
      selectedCity.value = '';
      selectedDistrict.value = '';
      selectedGenre.value = '';
    };

    onMounted(() => {
      fetchRequests()
    })

    return {
      loading,
      requests,
      selectedRequest,
      selectedCity,
      selectedDistrict,
      selectedGenre,
      districts,
      genres,
      filteredRequests,
      showRequestDetail,
      createQuotation,
      formatDate,
      formatPrice,
      getCurrentLocation,
      resetFilters,
      isFilterOpen,
      DateLate,
    }
  }
}
</script>

<style scoped>
.open-request-list {
  margin-top: 50px;
  padding: 10px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 16px 0;
  color: #1a1a1a;
}

/* 필터 섹션 스타일링 */
.filter-toggle {
  user-select: none;
  background: white;
  border-bottom: 1px solid #eee;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.filters {
  background: white;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 0;
}

.filters-open {
  padding: 16px;
  max-height: 500px;
  opacity: 1;
  border-bottom: 1px solid #eee;
}

.filter-buttons {
  display: flex;
  gap: 8px;
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  font-size: 14px;
  cursor: pointer;
}

.filter-button:hover {
  background: #f8f9fa;
}

.filter-section {
  margin-bottom: 16px;
}

.filter-group {
  display: flex;
  gap: 12px;
}

.filter-select {
  flex: 1;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 12px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M6 8L1 3h10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  transition: all 0.2s ease;
}

.filter-select:focus {
  border-color: #666;
  outline: none;
}

/* 의뢰서 카드 스타일링 */
.request-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
}

.request-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.request-type {
  font-weight: 600;
  color: #1a1a1a;
}

.request-date {
  font-size: 13px;
  color: #999;
}

.request-details p {
  margin: 8px 0;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
}

.request-details strong {
  color: #1a1a1a;
  min-width: 64px;
}

.reference-images {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.reference-images::-webkit-scrollbar {
  display: none;
}

.reference-images img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 12px;
  flex-shrink: 0;
}

.more-images {
  width: 64px;
  height: 64px;
  background: #f5f5f5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 14px;
  flex-shrink: 0;
}

.request-actions {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}

.detail-button,
.cancel-button,
.quote-button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.detail-button,
.cancel-button {
  background: #f5f5f5;
  color: #666;
}

.quote-button {
  background: #1a1a1a;
  color: #ffffff;
}

/* 모달 스타일링 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 16px;
}

.modal-content {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1a1a1a;
}

.request-full-details p {
  margin: 12px 0;
  font-size: 15px;
  color: #666;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 12px;
  margin-top: 16px;
}

.image-container img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 12px;
}

.modal-actions {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}
</style> 