<template>
  <div class="portfolio-manager-container">
    <div class="header-section">
      <h2 class="main-title">포트폴리오 관리</h2>
      <div class="edit-toggle">
        <button v-if="!isEditing" @click="enableEditing" class="edit-button">
          <i class="fas fa-plus"></i> 새 작품 추가
        </button>
        <button v-else @click="cancelEdit" class="cancel-button">
          <i class="fas fa-times"></i> 취소
        </button>
      </div>
    </div>

    <div v-if="errorMessage" class="error-banner">
      <i class="fas fa-exclamation-circle"></i>
      {{ errorMessage }}
    </div>

    <!-- 포트폴리오 업로드/수정 폼 -->
    <transition name="slide-fade">
      <form v-if="isEditing" @submit.prevent="handleSubmit" class="portfolio-form">
        <div class="form-header">
          <h3>{{ editPortfolioId ? '작품 수정' : '새 작품 등록' }}</h3>
          <p class="image-guidelines">
            권장 이미지 형식: JPG, PNG<br>
            최대 파일 크기: 10MB<br>
            권장 이미지 해상도: 1920x1920 이하
          </p>
        </div>

        <div class="form-grid">
          <div class="form-left">
            <div class="form-group image-upload">
              <label for="portfolioImage">
                <div class="upload-placeholder" v-if="!previewImage">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <span>이미지를 선택하세요</span>
                </div>
                <img v-else :src="previewImage" alt="미리보기" class="preview-image" />
              </label>
              <input
                type="file"
                id="portfolioImage"
                @change="handleImageChange"
                accept="image/*"
                hidden
              />
            </div>
          </div>

          <div class="form-right">
            <div class="form-group">
              <label for="description">작품 소개</label>
              <textarea
                id="description"
                v-model="description"
                required
                placeholder="작품에 대해 설명해주세요"
              ></textarea>
            </div>
            
            <div class="form-group">
              <label for="price">비용</label>
              <input
                type="number"
                id="price"
                v-model="price"
                :disabled="isPricePrivate"
                required
                placeholder="비용을 입력해주세요"
              />
              <div class="private-checkbox">
                <input
                  type="checkbox"
                  id="pricePrivate"
                  v-model="isPricePrivate"
                />
                <label for="pricePrivate">비용 비공개</label>
              </div>
            </div>

            <div class="form-group">
              <label for="timeSpent">소요 시간</label>
              <div class="time-input">
                <input
                  type="number"
                  id="timeSpent"
                  v-model.number="timeSpent"
                  required
                  placeholder="소요시간을 입력해주세요"
                />
              </div>
            </div>

            <div class="form-group">
              <label>장르</label>
              <div class="genre-container">
                <div 
                  v-for="genre in genres" 
                  :key="genre" 
                  :class="['genre-item', { selected: mainGenre === genre }]" 
                  @click="selectMainGenre(genre)"
                >
                  {{ genre }}
                </div>
              </div>
            </div>

            <div class="form-actions">
              <button type="submit" class="save-button">
                <i class="fas fa-check"></i>
                {{ editPortfolioId ? '수정 완료' : '등록하기' }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </transition>

    <!-- 포트폴리오 목록 -->
    <div class="portfolio-section">
      <div class="section-header">
        <h3>내 포트폴리오 목록</h3>
        <div class="portfolio-stats">
          <span>총 {{ portfolios.length }}개의 작품</span>
        </div>
      </div>

      <div v-if="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <span>작품을 불러오는 중...</span>
      </div>

      <div v-else-if="portfolios.length === 0" class="empty-state">
        <i class="fas fa-images"></i>
        <p>등록된 작품이 없습니다</p>
        <button @click="enableEditing" class="add-first-button">
          첫 작품 등록하기
        </button>
      </div>

      <div v-else class="portfolios-grid">
        <div v-for="portfolio in portfolios" :key="portfolio.id" class="portfolio-item">
          <div class="image-container">
            <img :src="portfolio.imageUrl" alt="포트폴리오 이미지" class="portfolio-image" />
            <div class="overlay">
              <div class="overlay-content">
                <div class="portfolio-info">
                  <div class="info-row">
                    <i class="fas fa-pen"></i>
                    <p>{{ portfolio.description }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-clock"></i>
                    <p>{{ portfolio.timeSpent }}시간</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-clock"></i>
                    <p>{{ portfolio.price === "비용문의" ? portfolio.price : `${portfolio.price}원` }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-tag"></i>
                    <p>{{ portfolio.mainGenre }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-calendar-alt"></i>
                    <p>{{ formatTimestamp(portfolio.uploadTime) }}</p>
                  </div>
                </div>
                <div class="action-buttons">
                  <button @click="editPortfolio(portfolio)" class="edit-button">
                    <i class="fas fa-edit"></i> 수정
                  </button>
                  <button @click="deletePortfolio(portfolio.id)" class="delete-button">
                    <i class="fas fa-trash-alt"></i> 삭제
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Compressor from "compressorjs";
import { db, storage, auth } from "@/firebase";
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where, 
  doc, 
  updateDoc, 
  deleteDoc, 
  serverTimestamp, 
  getDoc 
} from "firebase/firestore";
import { 
  ref as storageRef, 
  uploadBytesResumable, 
  getDownloadURL, 
  deleteObject 
} from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";

export default {
  name: "PortfolioManager",
  setup() {
    const isEditing = ref(false);
    const editPortfolioId = ref(null);
    const description = ref("");
    const timeSpent = ref(0);
    const price = ref(0);
    const isPricePrivate = ref(false);
    const genres = [
      "감성타투", "동양화", "레터링", "라인워크", "블랙워크", "블랙앤그레이",
      "수채화", "뉴스쿨", "미니타투", "올드스쿨", "이레즈미", "일러스트",
      "컬러타투", "커버업", "치카노", "터치업", "트라이벌","애니/만화"
    ];
    const mainGenre = ref("");

    const portfolioImage = ref(null);
    const previewImage = ref("");
    const portfolios = ref([]);
    const loading = ref(false);
    const errorMessage = ref("");
    const userId = ref("");

    // 포트폴리오 로드 함수
    const loadPortfolios = async () => {
      loading.value = true;
      errorMessage.value = "";
      try {
        if (!userId.value) {
          errorMessage.value = "로그인이 필요합니다.";
          portfolios.value = [];
          return;
        }

        const q = query(collection(db, "portfolios"), where("userId", "==", userId.value));
        const querySnapshot = await getDocs(q);
        portfolios.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("포트폴리오 로드 오류:", error);
        errorMessage.value = "포트폴리오를 불러오는 데 실패했습니다.";
      } finally {
        loading.value = false;
      }
    };

    // Firebase 인증 상태 변경 시 UID 설정 및 포트폴리오 로드
    const checkAuth = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          userId.value = user.uid;
          loadPortfolios();
        } else {
          errorMessage.value = "로그인이 필요합니다.";
          portfolios.value = [];
        }
      });
    };

    // 이미지 변경 처리
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (!file) return;

      // 파일 타입 검증
      if (!file.type.startsWith('image/')) {
        errorMessage.value = "이미지 파일만 업로드 가능합니다.";
        return;
      }

      // 파일 크기 검증 (예: 10MB)
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > maxSize) {
        errorMessage.value = "파일 크기가 10MB를 초과할 수 없습니다.";
        return;
      }

      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1920, // 최대 너비 제한
        maxHeight: 1920, // 최대 높이 제한
        success(compressedResult) {
          console.log("이미지 압축 성공");
          portfolioImage.value = compressedResult;
          // 미리보기 설정
          const reader = new FileReader();
          reader.readAsDataURL(compressedResult);
          reader.onload = () => {
            previewImage.value = reader.result;
          };
          reader.onerror = () => {
            errorMessage.value = "이미지 미리보기 생성에 실패했습니다.";
          };
        },
        error(err) {
          console.error("이미지 압축 오류:", err);
          errorMessage.value = "이미지 압축에 실패했습니다. 다른 이미지를 시도해주세요.";
          // 폼 리셋
          event.target.value = '';
          portfolioImage.value = null;
          previewImage.value = "";
        },
      });
    };

    // 파일명 추출 함수를 완전히 새로 작성
    const getStoragePathFromUrl = (url) => {
      try {
        // Firebase Storage URL 형식: https://firebasestorage.googleapis.com/v0/b/[bucket]/o/[path]?[token]
        const urlObj = new URL(url);
        const path = urlObj.pathname.split('/o/')[1];
        if (!path) return null;
        
        // URL 디코딩 및 쿼리 파라미터 제거
        return decodeURIComponent(path.split('?')[0]);
      } catch (error) {
        console.error("Storage 경로 추출 오류:", error);
        return null;
      }
    };

    // 포트폴리오 제출 처리
    const handleSubmit = async () => {
      if (!portfolioImage.value && !editPortfolioId.value) {
        alert("이미지를 선택해주세요.");
        return;
      }

      // 주요 장르가 선택되지 않았을 때 경고 메시지 표시
      if (!mainGenre.value) {
        alert("주요 장르를 선택해주세요.");
        return;
      }

      if (loading.value === true) {
        alert("이미 작업 중입니다. 잠시만 기다려주세요.");
        return;
      }

      loading.value = true;
      errorMessage.value = "";

      try {
        let downloadURL = previewImage.value;

        if (portfolioImage.value) {
          const timestamp = Date.now();
          const imageName = `${timestamp}_${portfolioImage.value.name}`;
          // 경로 중복을 피하기 위해 수정
          const imageStorageRef = storageRef(storage, `portfolios/${userId.value}/${imageName}`);
          const uploadTask = uploadBytesResumable(imageStorageRef, portfolioImage.value);

          await new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              null,
              (error) => {
                reject(error);
              },
              () => {
                resolve();
              }
            );
          });

          downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        }

        const portfolioData = {
          description: description.value,
          timeSpent: timeSpent.value,
          price: isPricePrivate.value ? "비용문의" : price.value,
          mainGenre: mainGenre.value,
          userId: userId.value,
          uploadTime: serverTimestamp(),
          imageUrl: downloadURL,
        };

        if (editPortfolioId.value) {
          // 수정
          const portfolioDoc = doc(db, "portfolios", editPortfolioId.value);
          await updateDoc(portfolioDoc, portfolioData);
        } else {
          await addDoc(collection(db, "portfolios"), portfolioData);
        }

        // 폼 초기화
        resetForm();
        // 포트폴리오 목록 다시 로드
        await loadPortfolios();
      } catch (error) {
        errorMessage.value = "포트폴리오를 저장하는 데 실패했습니다.";
      } finally {
        loading.value = false;
        isEditing.value = false;
      }
    };

    // 포트폴리오 수정
    const editPortfolio = (portfolio) => {
      isEditing.value = true;
      editPortfolioId.value = portfolio.id;
      description.value = portfolio.description;
      timeSpent.value = portfolio.timeSpent;
      price.value = portfolio.price;
      mainGenre.value = portfolio.mainGenre;
      previewImage.value = portfolio.imageUrl;
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // 포트폴리오 삭제
    const deletePortfolio = async (portfolioId) => {
      const userConfirmed = confirm("이 포트폴리오를 삭제하시겠습니까?");
      if (!userConfirmed) return;

      if (loading.value === true) {
        alert("이미 작업 중입니다. 잠시만 기다려주세요.");
        return;
      }

      loading.value = true;
      errorMessage.value = "";

      try {
        const portfolioDoc = doc(db, "portfolios", portfolioId);
        const portfolioSnapshot = await getDoc(portfolioDoc);
        
        if (portfolioSnapshot.exists()) {
          const portfolioData = portfolioSnapshot.data();
          if (portfolioData.imageUrl) {
            const storagePath = getStoragePathFromUrl(portfolioData.imageUrl);
            if (storagePath) {
              const imageRef = storageRef(storage, storagePath);
              try {
                await deleteObject(imageRef);
                console.log("이미지 삭제 성공:", storagePath);
              } catch (err) {
                console.warn("이미지 삭제 실패:", err);
                // 이미지 삭제 실패해도 문서는 삭제 진행
              }
            }
          }
          await deleteDoc(portfolioDoc);
          await loadPortfolios();
        }
      } catch (error) {
        console.error("포트폴리오 삭제 오류:", error);
        errorMessage.value = "포트폴리오를 삭제하는 데 실패했습니다.";
      } finally {
        loading.value = false;
      }
    };

    // 편집 모드 활성화
    const enableEditing = () => {
      isEditing.value = true;
      editPortfolioId.value = null;
      resetForm();
    };

    // 편집 취소
    const cancelEdit = () => {
      isEditing.value = false;
      editPortfolioId.value = null;
      resetForm();
    };

    // 폼 초기화
    const resetForm = () => {
      description.value = "";
      timeSpent.value = "";
      price.value = "";
      mainGenre.value = "";
      portfolioImage.value = null;
      previewImage.value = "";
      errorMessage.value = "";
    };

    // 메인 장르 선택
    const selectMainGenre = (genre) => {
      if (!isEditing.value) return;
      if (mainGenre.value === genre) {
        mainGenre.value = "";
      } else {
        mainGenre.value = genre;
      }
    };

    // 타임스탬프 형식 변환
    const formatTimestamp = (timestamp) => {
      if (!timestamp) return "";
      const date = timestamp.toDate();
      return date.toLocaleString();
    };

    onMounted(() => {
      checkAuth();
    });

    return {
      isEditing,
      editPortfolioId,
      description,
      timeSpent,
      price,
      isPricePrivate,
      genres,
      mainGenre,
      portfolioImage,
      previewImage,
      portfolios,
      loading,
      errorMessage,
      enableEditing,
      cancelEdit,
      handleImageChange,
      handleSubmit,
      editPortfolio,
      deletePortfolio,
      selectMainGenre,
      formatTimestamp,
    };
  },
};
</script>

<style scoped>
.portfolio-manager-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px;
  margin-top: 60px;
}

.header-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 20px;
}

.main-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0;
}

/* 버튼 스타일 */
.edit-toggle {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-button, 
.save-button {
  background: #333;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  justify-content: center;
}

.cancel-button {
  background: #ef4444;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

/* 폼 요소 스타일 */
.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #374151;
}

.form-group textarea {
  width: calc(100% - 30px);
  min-height: 120px;
  padding: 12px;
  border: 2px solid #e5e7eb;
  border-radius: 12px;
  resize: vertical;
  transition: border-color 0.3s ease;
}

.form-group textarea:focus {
  outline: none;
  border-color: #333;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.time-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-input input {
  width: 100%;
  padding: 8px 12px;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.time-unit {
  color: #6b7280;
}

.genre-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.genre-item {
  padding: 8px 16px;
  border: 2px solid #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.genre-item.selected {
  background: #333;
  color: white;
  border-color: #333;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 30px;
}

/* 이미지 관련 스타일 */
.preview-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.portfolio-image {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  height: 285px;
}

.portfolios-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px 0;
}

.portfolio-item {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow: hidden;
}

.portfolio-item:hover .overlay {
  opacity: 1;
}

.portfolio-info {
  margin-bottom: 15px;
}

.info-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.info-row i {
  width: 20px;
  color: #fff;
}

.info-row p {
  margin: 10px 0;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.action-buttons button {
  flex: 1;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.3s ease;
}

.action-buttons .edit-button {
  background: #666;
}

.action-buttons .delete-button {
  background: #333;
}

.action-buttons button:hover {
  transform: translateY(-2px);
}

.error-banner {
  background: #fee2e2;
  color: #dc2626;
  padding: 12px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* 폼 스타일 */
.portfolio-form {
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.form-header {
  margin-bottom: 30px;
}

.form-header h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin: 0;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.image-upload {
  border: 2px dashed #e5e7eb;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.image-upload:hover {
  border-color: #333;
}

.upload-placeholder {
  padding: 40px 20px;
  color: #6b7280;
}

.upload-placeholder i {
  font-size: 3rem;
  margin-bottom: 15px;
}

/* 포트폴리오 섹션 스타일 */
.portfolio-section {
  margin-top: 40px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.portfolio-stats {
  color: #6b7280;
  font-size: 0.9rem;
}

.empty-state {
  text-align: center;
  padding: 60px 20px;
  background: #f9fafb;
  border-radius: 16px;
}

.empty-state i {
  font-size: 3rem;
  color: #9ca3af;
  margin-bottom: 20px;
}

.add-first-button {
  margin-top: 20px;
  background: #333;
  color: white;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 애니메이션 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

/* 로딩 스피너 */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.private-checkbox {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
}

.private-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 27px;
  border: 2px solid #e5e7eb;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.private-checkbox input[type="checkbox"]:checked {
  background-color: #333;
  border-color: #333;
}

.private-checkbox input[type="checkbox"]:checked::after {
  content: '✓';
  color: white;
  font-size: 14px;
}

.private-checkbox input[type="checkbox"]:hover {
  border-color: #333;
}

.private-checkbox label {
  font-size: 14px;
  color: #666;
  cursor: pointer;
  user-select: none;
  margin: 0;
}

/* 비활성화된 가격 입력란 스타일 */
input[type="number"]:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
