<template>
    <div class="account-manager-container">
      <div class="header-section">
        <h2>계좌 관리</h2>
      </div>
  
      <div class="account-form" v-if="!loading">
        <div class="form-group">
          <label>은행명</label>
          <select v-model="bankName" class="input-field">
            <option value="">은행을 선택하세요</option>
            <option v-for="bank in bankList" :key="bank" :value="bank">
              {{ bank }}
            </option>
          </select>
        </div>
  
        <div class="form-group">
          <label>계좌번호</label>
          <input 
            type="text" 
            v-model="accountNumber"
            class="input-field"
            placeholder="'-' 없이 입력해주세요"
          />
        </div>
  
        <div class="form-group">
          <label>예금주</label>
          <input 
            type="text" 
            v-model="accountHolder"
            class="input-field"
            placeholder="예금주명을 입력해주세요"
          />
        </div>
  
        <div class="form-group">
          <label>예약금</label>
          <input 
            type="number" 
            v-model="reservationFee"
            class="input-field"
            placeholder="예약금을 입력해주세요"
          />
        </div>
  
        <button class="save-button" @click="saveAccountInfo">
          <i class="fas fa-save"></i> 저장하기
        </button>
      </div>
  
      <div v-else class="loading">
        <p>로딩중...</p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { db } from '@/firebase'
  import { doc, getDoc, updateDoc } from 'firebase/firestore'
  import { getAuth } from 'firebase/auth'
  import { useRouter } from 'vue-router'

  export default {
    name: 'SettingAccount',
    setup() {
      const auth = getAuth()
      const loading = ref(true)
      const bankName = ref('')
      const accountNumber = ref('')
      const accountHolder = ref('')
      const reservationFee = ref('')
      const router = useRouter();

      const bankList = [
        'NH농협',
        '카카오뱅크',
        'KB국민',
        '토스뱅크',
        '신한은행',
        '우리은행',
        'IBK기업',
        '하나은행',
        '새마을금고',
        '케이뱅크',
        '신협',
        '우체국'
      ]
  
      const loadAccountInfo = async () => {
        try {
          const user = auth.currentUser
          if (!user) return
  
          const userDoc = await getDoc(doc(db, 'users', user.uid))
          if (userDoc.exists()) {
            const data = userDoc.data()
            bankName.value = data.bankName || ''
            accountNumber.value = data.accountNumber || ''
            accountHolder.value = data.accountHolder || ''
            reservationFee.value = data.reservationFee || ''
          }
        } catch (error) {
          console.error('계좌 정보 로딩 실패:', error)
          alert('계좌 정보를 불러오는데 실패했습니다.')
        } finally {
          loading.value = false
        }
      }
  
      const saveAccountInfo = async () => {
        if (!bankName.value || !accountNumber.value || !accountHolder.value || !reservationFee.value) {
          alert('모든 필드를 입력해주세요.')
          return
        }
  
        try {
          const user = auth.currentUser
          if (!user) {
            alert('로그인이 필요합니다.')
            return
          }
  
          await updateDoc(doc(db, 'users', user.uid), {
            bankName: bankName.value,
            accountNumber: accountNumber.value,
            accountHolder: accountHolder.value,
            reservationFee: reservationFee.value,
            updatedAt: new Date()
          })
  
          alert('계좌 정보가 성공적으로 저장되었습니다.')
          router.push('/mypage');
        } catch (error) {
          console.error('계좌 정보 저장 실패:', error)
          alert('계좌 정보 저장에 실패했습니다.')
        }
      }
  
      onMounted(() => {
        loadAccountInfo()
      })
  
      return {
        loading,
        bankName,
        accountNumber,
        accountHolder,
        reservationFee,
        bankList,
        saveAccountInfo
      }
    }
  }
  </script>
  
  <style scoped>
  .account-manager-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .header-section {
    margin-bottom: 30px;
  }
  
  .header-section h2 {
    font-size: 24px;
    color: #333;
    margin: 0;
  }
  
  .account-form {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #666;
  }
  
  select.input-field {
    background-color: white;
  }
  
  .save-button {
    width: 100%;
    padding: 12px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s;
  }
  
  .save-button:hover {
    background-color: #444;
  }
  
  .loading {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  </style>