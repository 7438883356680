<template>
  <div class="chat-room">
    <div class="chat-header">
      <button class="back-button" @click="goBack">
        <i class="fas fa-arrow-left"></i>
      </button>
      <div 
        class="user-info clickable"
        @click="goToUserProfile"
      >
        <img 
          :src="otherUser.profileImageUrl || require('@/assets/default-avatar.png')" 
          :alt="otherUser.nickname || otherUser.name"
        >
        <span>{{ otherUser.nickname || otherUser.name }}</span>
      </div>
    </div>

    <div class="chat-messages" ref="messageContainer">
      <div 
        v-for="message in messages" 
        :key="message.id"
        :class="['message', message.senderId === currentUser.uid ? 'sent' : 'received']"
      >
        <!-- 상대방 메시지일 경우 프로필 이미지 표시 -->
        <div v-if="message.senderId !== currentUser.uid" class="profile-image">
          <img :src="otherUser.profileImageUrl || require('@/assets/default-avatar.png')" :alt="otherUser.nickname || otherUser.name">
        </div>
        <!-- 문의서 작성 요청 버튼 메시지 -->
        <div v-if="message.type === 'consultationButton'">
          <div v-if="message.senderId === currentUser.uid" class="message-content">
            타투 문의서 작성 요청을 보냈습니다
          </div>
          <div v-else class="consultation-button">
            <router-link :to="`/tattoo-request/${message.tattooistId}`">
              타투 문의서 작성
            </router-link>
          </div>
        </div>
        <!-- 문의서 내용 메시지 -->
        <div v-else-if="message.type === 'consultation'" class="consultation-message">
          <pre class="consultation-content">{{ message.content }}</pre>
          <!-- 선택된 타투 부위 표시 -->
          <div v-if="message.selectedBodyParts && message.selectedBodyParts.length > 0" class="selected-parts">
            <strong>선택된 부위:</strong> 
            {{ message.selectedBodyParts.join(', ') }}
          </div>
          <!-- 참고 이미지 그리드 표시 -->
          <div v-if="message.images && message.images.length > 0" class="reference-images">
            <h4>📎 참고 이미지</h4>
            <div class="images-grid">
              <div v-for="(imageUrl, index) in message.images" 
                   :key="index" 
                   class="image-preview"
                   @click="openImageViewer(imageUrl)">
                <img :src="imageUrl" :alt="`참고 이미지 ${index + 1}`" />
              </div>
            </div>
          </div>
          <!-- 타투이스트인 경우 견적 보내기 버튼 표시 -->
          <div v-if="currentUserData?.userType === 'tattooist' && message.senderId !== currentUser.uid" class="estimate-button-container">
            <button class="estimate-button" @click="sendEstimate">
              예약서 작성
            </button>
          </div>
        </div>
        <!-- 견적서 메시지 -->
        <template v-if="message.type === 'estimate'">
          <div class="estimate-message">            
            <div class="estimate-header">
              <h3>타투 견적서</h3>
            </div>            
            <div class="estimate-content">              
              <div class="estimate-section">
                <div class="estimate-row">
                  <span class="label">작업 비용</span>
                  <span class="value price">{{ formatPrice(message.content?.price || 0) }}원</span>
                </div>
                <div class="estimate-row">
                  <span class="label">예상 소요시간</span>
                  <span class="value">{{ message.content?.duration || 0 }}시간</span>
                </div>
                <div class="estimate-row">
                  <span class="label">작업 가능일</span>
                  <span class="value">{{ message.content?.availableDate || '-' }}</span>
                </div>
                <div class="estimate-row" v-if="message.content?.depositAmount">
                  <span class="label">예약금</span>
                  <span class="value">{{ message.content.depositAmount.toLocaleString() + '원' }}</span>
                </div>
                <div class="estimate-row" v-if="message.content?.depositAmount">
                  <span class="label">은행</span>
                  <span class="value">{{ senderBankInfo.bankName }}</span>
                </div>
                <div class="estimate-row" v-if="message.content?.depositAmount">
                  <span class="label">계좌번호</span>
                  <span class="value">{{ senderBankInfo.accountNumber }}</span>
                </div>
                <div class="estimate-row" v-if="message.content?.depositAmount">
                  <span class="label">예금주</span>
                  <span class="value">{{ senderBankInfo.accountHolder }}</span>
                </div>
                <div class="estimate-row" v-if="message.content?.depositAmount">
                  <span class="label">예약금</span>
                  <span class="value">{{ senderBankInfo.reservationFee }}</span>
                </div>
                <button class="copy-button" v-if="message.content?.depositAmount" @click="copyAccountInfo">계좌 복사</button>
              </div>

              <div class="estimate-section description">
                <h4>상세 설명</h4>
                <p>{{ message.content?.description || '설명이 없습니다.' }}</p>
              </div>

              <div v-if="message.content?.images?.length" class="estimate-section">
                <h4>작업 예시 이미지</h4>
                <div class="estimate-images">
                  <div v-for="(image, index) in message.content.images" 
                       :key="index" 
                       class="estimate-image"
                       @click="openImageViewer(image)">
                    <img :src="image" alt="작업 예시" />
                  </div>
                </div>
              </div>
            </div>

            <!-- 예약 확정 섹션 추가 -->
            <div class="estimate-footer">
              <div v-if="!message.confirmed" class="confirmation-status">
                <span>예약 상태: {{ getConfirmationStatus(message) }}</span>
                <button v-if="!isUserConfirmed(message)" 
                        @click="confirmReservation(message)" 
                        class="confirm-button">
                  예약 확정하기
                </button>
              </div>
              <div v-else class="confirmation-complete">
                <i class="fas fa-check-circle"></i>
                예약이 확정되었습니다
              </div>
            </div>
          </div>
        </template>
        <template v-if="message.type === 'accountInfo'">
          <div v-if="message.senderId === currentUser.uid" class="message-content">
            계좌 정보를 보냈습니다
          </div>
          <div v-else class="message-content">
            {{ otherUser.bankName }} {{ otherUser.accountNumber }} {{ otherUser.accountHolder }} {{ otherUser.reservationFee }}
            <button class="copy-button2" @click="copyAccountInfo">계좌 정보 복사</button>
          </div>
        </template>
        <!-- 일반 메시지 - 발신자인 경우 -->
        <template v-if="message.type !== 'consultation' && message.type !== 'consultationButton' && message.type !== 'estimate' && message.type !== 'accountInfo'">
          <div :class="messageClasses(message)">
            <template v-if="message.senderId === currentUser.uid">
              <span class="message-time">{{ formatTime(message.timestamp) }}</span>
              <!-- 이미지 메시지와 텍스트 메시지 분리 -->
              <div v-if="message.type === 'image'" class="image-message">
                <div class="images-grid">
                  <div v-for="(imageUrl, index) in message.images" 
                       :key="index" 
                       class="image-preview"
                       @click="openImageViewer(imageUrl)">
                    <img :src="imageUrl" :alt="'전송된 이미지'" />
                  </div>
                </div>
              </div>
              <div v-else class="message-content" style="white-space: pre-line">
                {{ message.content }}
              </div>
            </template>
            <!-- 일반 메시지 - 수신자인 경우 -->
            <template v-else>
              <!-- 이미지 메시지와 텍스트 메시지 분리 -->
              <div v-if="message.type === 'image'" class="image-message">
                <div class="images-grid">
                  <div v-for="(imageUrl, index) in message.images" 
                       :key="index" 
                       class="image-preview"
                       @click="openImageViewer(imageUrl)">
                    <img :src="imageUrl" :alt="'전송된 이미지'" />
                  </div>
                </div>
              </div>
              <div v-else class="message-content" style="white-space: pre-line">
                {{ message.content }}
              </div>
              <span class="message-time">{{ formatTime(message.timestamp) }}</span>
            </template>
          </div>
        </template>
      </div>
    </div>

    <div class="chat-input">
      <div class="add-menu-container">
        <button class="add-btn" @click.stop="toggleAddMenu">
          <i class="fas fa-plus"></i>
        </button>
        
        <div v-if="showAddMenu" class="add-menu">
          <template v-if="currentUserData?.userType === 'tattooist'">
            <button @click="sendConsultationButton">
              <i class="fas fa-clipboard-list"></i>
              문의서 작성 요청
            </button>
            <button @click="sendAccountInfoButton">
              <i class="fas fa-wallet"></i>
              계좌 정보 전송
            </button>
            <button @click="openForm('reservation')">
              <i class="fas fa-calendar-plus"></i>
              예약서 전송
            </button>
          </template>
          <button @click="openImageUpload">
            <i class="fas fa-image"></i>
            이미지 전송
          </button>
        </div>
      </div>

      <input 
        type="text" 
        v-model="newMessage" 
        @keyup.enter="sendMessage"
        placeholder="메시지를 입력하세요..."
        autofocus
      >
      <button @click="sendMessage" :disabled="!newMessage.trim()">
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
  </div>

  <div v-if="selectedImage" class="image-viewer-modal" @click="closeImageViewer">
    <img :src="selectedImage" alt="확대된 이미지" class="enlarged-image" />
  </div>

  <div v-if="showConsultationForm" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <h3>문의서 작성</h3>
        <button class="close-btn" @click="closeForm">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <!-- 문의서 폼 내용 -->
      </div>
    </div>
  </div>

  <div v-if="showReservationForm" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <h3>예약서 작성</h3>
        <button class="close-btn" @click="closeForm">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <!-- 예약서 폼 내용 -->
      </div>
    </div>
  </div>

  <div v-if="showImageUpload" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <h3>이미지 전송</h3>
        <button class="close-btn" @click="closeForm">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <input 
          type="file" 
          @change="handleImageUpload" 
          accept="image/*" 
          multiple
        >
        <div class="image-preview" v-if="selectedImages.length">
          <div v-for="(image, index) in selectedImages" 
               :key="index" 
               class="preview-item">
            <img :src="image.url" alt="Preview">
            <button @click="removeImage(index)" class="remove-btn">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <button 
          @click="sendImages" 
          class="submit-btn" 
          :disabled="!selectedImages.length"
        >
          이미지 전송
        </button>
      </div>
    </div>
  </div>

  <!-- 견적 입력 모달 -->
  <div v-if="showEstimateModal" class="modal" @click.self="showEstimateModal = false">
    <div class="modal-content">
      <h3>예약서 작성</h3>
      
      <!-- 상담 내용 입력 -->
      <div class="input-group">
        <label>상담 내용</label>
        <textarea 
          v-model="estimateForm.content"
          placeholder="상담 내용을 입력해주세요"
          rows="4"
        ></textarea>
      </div>

      <!-- 작업 비용 입력 -->
      <div class="input-group">
        <label>작업 비용</label>
        <input 
          type="number" 
          v-model="estimateForm.price"
          placeholder="작업 비용을 입력해주세요"
          @input="formatDepositAmount"
        />
      </div>

      <!-- 작업 날짜 선택 -->
      <div class="input-group">
        <label>작업 날짜</label>
        <input 
          type="date" 
          v-model="estimateForm.date"
          :min="getCurrentDate()"
        />
      </div>

      <!-- 작업 시간 선택 -->
      <div class="input-group">
        <label>작업 시간</label>
        <select 
          v-model="estimateForm.time"
          class="time-select"
        >
          <option value="">시간 선택</option>
          <option 
            v-for="time in timeOptions" 
            :key="time.value" 
            :value="time.value"
          >
            {{ time.label }}
          </option>
        </select>
      </div>

      <!-- 작업 시간 선택 다음에 추가 -->
      <div class="input-group">
        <label>예상 소요시간</label>
        <input 
          type="number" 
          v-model="estimateForm.duration"
          min="0"
          placeholder="예상 소요시간 (시간)"
          class="duration-input"
        />
      </div>

      <!-- 이미지 업로드 -->
      <div class="input-group">
        <label>참고 이미지</label>
        <div class="image-upload">
          <input 
            type="file" 
            @change="handleEstimateImageUpload" 
            accept="image/*" 
            multiple
            ref="fileInput"
          />
          <button type="button" @click="$refs.fileInput.click()">
            이미지 선택
          </button>
        </div>
        
        <!-- 이미지 미리보기 -->
        <div v-if="estimateForm.images.length > 0" class="image-preview">
          <div v-for="(image, index) in estimateForm.images" 
               :key="index" 
               class="preview-item">
            <img :src="image.url" alt="Preview" />
            <button class="remove-btn" @click="removeEstimateImage(index)">×</button>
          </div>
        </div>
      </div>

      <!-- 예약금 필요 여부 체크박스 추가 -->
      <div class="input-group checkbox-group">
        <label class="checkbox-label">
          <input 
            type="checkbox" 
            v-model="estimateForm.requiresDeposit"
          >
          예약금이 필요한가요?
        </label>
        <!-- 예약금이 필요한 경우에만 보이는 입력 필드 -->
        <div v-if="estimateForm.requiresDeposit" class="deposit-input">
          <input 
            type="number" 
            v-model="estimateForm.depositAmount"
            placeholder="예약금 금액을 입력하세요"
            @input="formatDepositAmount"
          >
          <span class="currency">원</span>
        </div>
      </div>

      <!-- 전송 버튼 -->
      <div class="button-group">
        <button 
          class="submit-btn" 
          @click="submitEstimate"
          :disabled="!isEstimateFormValid"
        >
          예약서 전송
        </button>
        <button 
          class="cancel-btn" 
          @click="showEstimateModal = false"
        >
          취소
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick, computed, watch } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { db, auth, storage } from '@/firebase';
import { collection, doc, getDoc, addDoc, query, orderBy, onSnapshot, serverTimestamp, updateDoc, limit, getDocs } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { getAuth } from 'firebase/auth';

export default {
  name: 'ChatRoom',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const messages = ref([]);
    const newMessage = ref('');
    const otherUser = ref({});
    const messageContainer = ref(null);
    const currentUserData = ref(null);
    const currentUser = ref(null);
    let unsubscribe = null;
    const selectedImage = ref(null);
    const activeTab = ref('front');
    const showAddMenu = ref(false);
    const showConsultationForm = ref(false);
    const showReservationForm = ref(false);
    const showImageUpload = ref(false);
    const selectedImages = ref([]);
    const showEstimateModal = ref(false);
    const estimateForm = ref({
      content: '',
      price: '',
      date: '',
      time: '',
      duration: '',
      images: [],
      requiresDeposit: false,
      depositAmount: ''
    });
    const isSending = ref(false);
    const senderBankInfo = ref({});

    const goBack = () => {
      router.push('/chat');
    };

    const goToUserProfile = () => {
      if (otherUser.value.userType === 'guest') {
        alert('게스트 회원의 프로필은 확인할 수 없습니다.');
        return;
      }
      router.push(`/tattooist/${otherUser.value.id}`);
    };

    const formatTime = (timestamp) => {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      return date.toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    };

    const scrollToBottom = async () => {
      await nextTick();
      if (messageContainer.value) {
        messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
      }
    };

    const sendMessage = async () => {
      if (!newMessage.value.trim() || isSending.value) return;

      isSending.value = true;
      console.log('메시지 전송 시작');

      const roomId = route.params.id;
      const messagesRef = collection(db, 'chatRooms', roomId, 'messages');
      const roomRef = doc(db, 'chatRooms', roomId);
      
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.value.uid));
        const userData = userDoc.data();
        console.log('발신자 정보:', userData);

        // 메시지 저장
        await addDoc(messagesRef, {
          content: newMessage.value,
          senderId: currentUser.value.uid,
          senderName: userData?.nickname || userData?.name || '알 수 없음',
          timestamp: serverTimestamp()
        });
        console.log('메시지 저장 완료');

        // 채팅방 마지막 메시지 업데이트
        await updateDoc(roomRef, {
          lastMessage: {
            content: newMessage.value,
            timestamp: serverTimestamp()
          }
        });
        console.log('채팅방 마지막 메시지 업데이트 완료');

        // 상대방의 FCM 토큰 가져오기
        const receiverDoc = await getDoc(doc(db, 'users', otherUser.value.id));
        const receiverData = receiverDoc.data();
        const fcmToken = receiverData?.fcmToken;
        console.log('수신자 FCM 토큰:', fcmToken);

        if (fcmToken) {
          try {
            const response = await fetch('https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              mode: 'cors',
              body: JSON.stringify({
                token: fcmToken,
                title: userData?.nickname || userData?.name || '새로운 메시지',
                body: newMessage.value
              })
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.details || errorData.error || '알림 전송 실패');
            }

            const data = await response.json();
            console.log('알림 전송 성공:', data);
          } catch (error) {
            console.error('알림 전송 실패:', error);
          }
        } else {
          console.log('수신자의 FCM 토큰이 없습니다.');
        }

        newMessage.value = '';
        await scrollToBottom();
        console.log('메시지 전송 프로세스 완료');

      } catch (error) {
        console.error('메시지 전송 실패:', error);
        console.error('에러 상세:', {
          message: error.message,
          code: error.code,
          stack: error.stack
        });
      } finally {
        isSending.value = false;
      }
    };

    const openImageViewer = (imageUrl) => {
      selectedImage.value = imageUrl;
    };

    const closeImageViewer = () => {
      selectedImage.value = null;
    };

    const handleClickOutside = (event) => {
      const addMenuContainer = document.querySelector('.add-menu-container');
      if (showAddMenu.value && addMenuContainer && !addMenuContainer.contains(event.target)) {
        showAddMenu.value = false;
      }
    };

    const toggleAddMenu = () => {
      showAddMenu.value = !showAddMenu.value;
      
      if (showAddMenu.value) {
        nextTick(() => {
          document.addEventListener('click', handleClickOutside);
        });
      } else {
        document.removeEventListener('click', handleClickOutside);
      }
    };

    const openForm = (type) => {
      showAddMenu.value = false;
      if (type === 'consultation') {
        showConsultationForm.value = true;
      } else if (type === 'reservation') {
        showEstimateModal.value = true;
      }
    };

    const openImageUpload = () => {
      showAddMenu.value = false;
      showImageUpload.value = true;
    };

    const closeForm = () => {
      showConsultationForm.value = false;
      showReservationForm.value = false;
      showImageUpload.value = false;
      selectedImages.value = [];
    };

    const handleImageUpload = (event) => {
      const files = event.target.files;
      for (let file of files) {
        const url = URL.createObjectURL(file);
        selectedImages.value.push({
          file,
          url
        });
      }
    };

    const removeImage = (index) => {
      URL.revokeObjectURL(selectedImages.value[index].url);
      selectedImages.value.splice(index, 1);
    };

    const sendImages = async () => {
      if (!selectedImages.value.length) return;

      try {
        const roomId = route.params.id;
        const messagesRef = collection(db, 'chatRooms', roomId, 'messages');
        const roomRef = doc(db, 'chatRooms', roomId);
        
        // 이미지 업로드 및 URL 수집
        const uploadedUrls = [];
        for (const image of selectedImages.value) {
          const fileName = `chat_images/${roomId}/${Date.now()}-${image.file.name}`;
          const imageRef = storageRef(storage, fileName);
          
          await uploadBytes(imageRef, image.file);
          const downloadUrl = await getDownloadURL(imageRef);
          uploadedUrls.push(downloadUrl);
        }

        // 메시지 문서 생성
        await addDoc(messagesRef, {
          type: 'image',
          images: uploadedUrls,
          content: '',
          senderId: currentUser.value.uid,
          timestamp: serverTimestamp()
        });

        // 마지막 메시지 업데이트
        await updateDoc(roomRef, {
          lastMessage: {
            content: '이미지를 보냈습니다.',
            timestamp: serverTimestamp()
          }
        });

        // 초기화
        selectedImages.value = [];
        showImageUpload.value = false;

      } catch (error) {
        console.error('이미지 전송 실패:', error);
        alert('이미지 전송에 실패했습니다. 다시 시도해주세요.');
      }
    };

    const messageClasses = (message) => {
      return ['message', message.senderId === currentUser.value?.uid ? 'sent' : 'received'];
    };

    const sendConsultationButton = async () => {
      const roomId = route.params.id;
      const messagesRef = collection(db, 'chatRooms', roomId, 'messages');

      try {
        await addDoc(messagesRef, {
          content: '타투 문의서 작성하기',
          type: 'consultationButton',
          senderId: currentUser.value.uid,
          timestamp: serverTimestamp(),
          tattooistId: currentUser.value.uid
        });

        showAddMenu.value = false;
      } catch (error) {
        console.error('문의서 버튼 전송 실패:', error);
      }
    };

    const sendAccountInfoButton = async () => {
      const roomId = route.params.id;
      const messagesRef = collection(db, 'chatRooms', roomId, 'messages');

      try {
        await addDoc(messagesRef, {
          type: 'accountInfo',
          content: `은행명: ${currentUserData.value.bankName}\n계좌번호: ${currentUserData.value.accountNumber}\n예금주: ${currentUserData.value.accountHolder}`,
          senderId: currentUser.value.uid,
          timestamp: serverTimestamp()
        });

        showAddMenu.value = false;
      } catch (error) {
        console.error('계좌 정보 전송 실패:', error);
      }
    };

    const sendEstimate = async () => {
      showEstimateModal.value = true;
    };

    // 견적서 폼 유효성 검사
    const isEstimateFormValid = computed(() => {
      return estimateForm.value.content && 
             estimateForm.value.price && 
             estimateForm.value.date && 
             estimateForm.value.time &&
             estimateForm.value.duration;
    });

    // 현재 날짜 가져오기
    const getCurrentDate = () => {
      return new Date().toISOString().split('T')[0];
    };

    // 견적서 이미지 업로드 처리
    const handleEstimateImageUpload = async (event) => {
      const files = Array.from(event.target.files);
      
      for (const file of files) {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          };

          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();

          reader.onload = (e) => {
            estimateForm.value.images.push({
              file: compressedFile,
              url: e.target.result
            });
          };

          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('이미지 압축 실패:', error);
        }
      }
    };

    // 견적서 이미지 제거
    const removeEstimateImage = (index) => {
      estimateForm.value.images.splice(index, 1);
    };

    // 예약금 금액 포맷팅
    const formatDepositAmount = (event) => {
      let value = event.target.value.replace(/[^0-9]/g, '');
      estimateForm.value.depositAmount = value;
    };

    // 견적서 전송
    const submitEstimate = async () => {
      try {
        const roomId = route.params.id;
        const messagesRef = collection(db, 'chatRooms', roomId, 'messages');
        const roomRef = doc(db, 'chatRooms', roomId);

        // 이미지 업로드 처리
        const uploadedImageUrls = [];
        for (const image of estimateForm.value.images) {
          if (image.file) {
            const fileName = `chat_images/${roomId}/${Date.now()}-${image.file.name}`;
            const imageRef = storageRef(storage, fileName);
            
            await uploadBytes(imageRef, image.file);
            const downloadUrl = await getDownloadURL(imageRef);
            uploadedImageUrls.push(downloadUrl);
          }
        }

        // 견적서 메시지 생성 - 수정된 부분
        const estimateMessage = {
          type: 'estimate',
          content: {  // content를 객체 형태로 변경
            price: Number(estimateForm.value.price),
            duration: Number(estimateForm.value.duration),
            availableDate: `${estimateForm.value.date} ${estimateForm.value.time}`,
            description: estimateForm.value.content,
            images: uploadedImageUrls,
            requiresDeposit: estimateForm.value.requiresDeposit,
            depositAmount: estimateForm.value.requiresDeposit ? Number(estimateForm.value.depositAmount) : 0
          },
          senderId: currentUser.value.uid,
          senderName: currentUserData.value?.nickname || currentUserData.value?.name || '알 수 없음',
          timestamp: serverTimestamp(),
          tattooistConfirmed: currentUserData.value?.userType === 'tattooist', // 타투이스트가 보낸 경우 자동 확정
          customerConfirmed: false,
          confirmed: false
        };

        // 메시지 전송
        await addDoc(messagesRef, estimateMessage);

        // 채팅방 마지막 메시지 업데이트
        await updateDoc(roomRef, {
          lastMessage: {
            content: '예약서가 도착했습니다.',
            timestamp: serverTimestamp()
          }
        });

        // 모달 닫기 및 폼 초기화
        showEstimateModal.value = false;
        estimateForm.value = {
          content: '',
          price: '',
          date: '',
          time: '',
          duration: '',
          images: [],
          requiresDeposit: false,
          depositAmount: ''
        };

      } catch (error) {
        console.error('견적서 전송 실패:', error);
        alert('견적서 전송에 실패했습니다. 다시 시도해주세요.');
      }
    };

    // formatPrice 함수 수정
    const formatPrice = (price) => {
      if (price === undefined || price === null) return '0';
      return Number(price).toLocaleString();
    };

    const copyAccountInfo = async () => {
      const accountInfo = `${senderBankInfo.value.bankName} ${senderBankInfo.value.accountNumber} ${senderBankInfo.value.accountHolder} ${senderBankInfo.value.reservationFee}`;
      
      try {
        await navigator.clipboard.writeText(accountInfo);
        alert('계좌 정보가 복사되었습니다.');
      } catch (err) {
        // fallback for older browsers
        const textarea = document.createElement('textarea');
        textarea.value = accountInfo;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          alert('계좌 정보가 복사되었습니다.');
        } catch (err) {
          alert('계좌 정보 복사에 실패했습니다.');
        }
        document.body.removeChild(textarea);
      }
    };

    // 30분 단위 시간 옵션 생성
    const timeOptions = computed(() => {
      const options = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const hourStr = hour.toString().padStart(2, '0');
          const minuteStr = minute.toString().padStart(2, '0');
          const value = `${hourStr}:${minuteStr}`;
          const label = `${hourStr}:${minuteStr}`;
          options.push({ value, label });
        }
      }
      return options;
    });

    // 사용자의 예약 확정 여부 확인
    const isUserConfirmed = (message) => {
      const isTattooist = currentUserData.value?.userType === 'tattooist';
      return isTattooist ? message.tattooistConfirmed : message.customerConfirmed;
    };

    // 예약 확정 처리
    const confirmReservation = async (message) => {
      try {
        // 예약 확정 상태 업데이트
        const messageRef = doc(db, 'chatRooms', route.params.id, 'messages', message.id);
        const messageDoc = await getDoc(messageRef);
        const messageData = messageDoc.data();

        // 현재 상태 확인을 위한 로그
        console.log('현재 확정 상태:', {
          tattooistConfirmed: messageData.tattooistConfirmed,
          customerConfirmed: messageData.customerConfirmed,
          userType: currentUserData.value?.userType
        });

        // 상태 업데이트
        if (currentUserData.value?.userType === 'tattooist') {
          await updateDoc(messageRef, {
            tattooistConfirmed: true
          });
        } else {
          await updateDoc(messageRef, {
            customerConfirmed: true
          });
        }

        // 업데이트 후 최신 상태 가져오기
        const updatedMessageDoc = await getDoc(messageRef);
        const updatedMessageData = updatedMessageDoc.data();

        console.log('업데이트 후 상태:', {
          tattooistConfirmed: updatedMessageData.tattooistConfirmed,
          customerConfirmed: updatedMessageData.customerConfirmed
        });

        // 양쪽 모두 확정했는지 확인
        if (updatedMessageData.tattooistConfirmed && updatedMessageData.customerConfirmed) {
          // 완전히 확정된 경우
          await updateDoc(messageRef, {
            confirmed: true,
            status: 'confirmed'
          });

          // 고객 정보 가져오기
          const customerId = currentUserData.value?.userType === 'tattooist' ? otherUser.value.uid : currentUser.value.uid;
          const customerDoc = await getDoc(doc(db, 'users', customerId));
          const customerData = customerDoc.data();
          const customerName = customerData?.nickname || customerData?.name || '고객';

          // 날짜와 시간 파싱
          const [date, time] = message.content.availableDate.split(' ');
          const duration = parseInt(message.content.duration);

          // 시작 시간과 종료 시간 계산
          const startDateTime = new Date(`${date}T${time}`);
          const endDateTime = new Date(startDateTime.getTime() + (duration * 60 * 60 * 1000));

          // 이벤트 데이터 구성
          const eventData = {
            // 이벤트 타입을 'booking'으로 설정
            type: 'booking',
            // 이벤트 제목을 '예약: 고객이름' 형식으로 설정
            title: `예약: ${customerName}`,
            // 이벤트 시작 시간을 설정
            start: startDateTime,
            // 이벤트 종료 시간을 설정
            end: endDateTime,
            // 고객 이름을 설정
            customerName: customerName,
            // 고객 ID를 설정
            customerId: customerId,
            // 채팅방 ID를 설정
            chatRoomId: route.params.id,
            // 가격을 설정, 가격이 없으면 0으로 설정
            price: parseInt(message.content.price) || 0,
            // 이벤트 설명을 설정, 설명이 없으면 빈 문자열로 설정
            content: message.content.description || '',
            // 이벤트 설명을 설정, 설명이 없으면 빈 문자열로 설정 (중복 필드)
            description: message.content.description || '',
            // 이미지 배열을 설정, 이미지가 없으면 빈 배열로 설정
            images: message.content.images || [],
            // 견적서 ID를 설정
            estimateId: message.id,
            // 보증금 필요 여부를 설정, 필요 여부가 없으면 false로 설정
            requiresDeposit: message.content.requiresDeposit || false,
            // 보증금 금액을 설정, 금액이 없으면 0으로 설정
            depositAmount: parseInt(message.content.depositAmount) || 0,
            // 이벤트 상태를 'confirmed'로 설정
            status: 'confirmed',
            // 이벤트 생성 시간을 서버 타임스탬프로 설정
            createdAt: serverTimestamp(),
            // 타투이스트 ID를 설정, 현재 사용자가 타투이스트이면 현재 사용자 ID를, 아니면 메시지 발신자 ID를 설정
            tattooistId: currentUserData.value?.userType === 'tattooist' ? currentUser.value.uid : message.senderId
          };

          // 디버깅을 위한 로그 추가
          console.log('견적서 내용:', message.content);
          console.log('저장할 이벤트 데이터:', eventData);

          // 타투이스트의 스케줄에 이벤트 추가
          const tattooistId = currentUserData.value?.userType === 'tattooist' ? currentUser.value.uid : message.senderId;
          const eventsRef = collection(db, 'users', tattooistId, 'events');
          await addDoc(eventsRef, eventData);

          console.log('예약이 확정되고 스케줄에 추가되었습니다.');
        }

      } catch (error) {
        console.error('예약 확정 실패:', error);
        alert('예약 확정에 실패했습니다. 다시 시도해주세요.');
      }
    };

    // 스케줄에 예약 추가
    const addToSchedule = async (message) => {
      try {
        const auth = getAuth();
        if (!auth.currentUser) return;

        // 날짜와 시간 파싱
        const [date, time] = message.content.availableDate.split(' ');
        const duration = parseInt(message.content.duration);

        // 시작 시간과 종료 시간 계산
        const startDateTime = new Date(`${date}T${time}`);
        const endDateTime = new Date(startDateTime.getTime() + (duration * 60 * 60 * 1000));

        // Firestore에 저장할 이벤트 데이터 객체를 생성합니다.
        const eventData = {
          // 이벤트 유형을 'booking'으로 설정합니다.
          type: 'booking',
          // 이벤트 제목을 '예약: 발신자 이름' 형식으로 설정합니다.
          title: `예약: ${message.senderName}`,
          // 이벤트 시작 시간을 설정합니다.
          start: startDateTime,
          // 이벤트 종료 시간을 설정합니다.
          end: endDateTime,
          // 고객 이름을 설정합니다.
          customerName: message.senderName,
          // 고객 ID를 설정합니다.
          customerId: message.senderId,
          // 채팅방 ID를 설정합니다.
          chatRoomId: route.params.id,
          // 가격을 설정합니다. 가격이 없으면 0으로 설정합니다.
          price: parseInt(message.content.price) || 0,
          // 설명을 설정합니다. 설명이 없으면 빈 문자열로 설정합니다.
          description: message.content.content || '',
          // 내용 필드를 설정합니다. 내용이 없으면 빈 문자열로 설정합니다.
          content: message.content.content || '',
          // 이미지 배열을 설정합니다. 이미지가 없으면 빈 배열로 설정합니다.
          images: message.content.images || [],
          // 견적서 ID를 설정합니다.
          estimateId: message.id,
          // 보증금 필요 여부를 설정합니다. 필요 여부가 없으면 false로 설정합니다.
          requiresDeposit: message.content.requiresDeposit || false,
          // 보증금 금액을 설정합니다. 금액이 없으면 0으로 설정합니다.
          depositAmount: parseInt(message.content.depositAmount) || 0,
          // 이벤트 상태를 'confirmed'로 설정합니다.
          status: 'confirmed',
          // 이벤트 생성 시간을 서버 타임스탬프로 설정합니다.
          createdAt: serverTimestamp(),
          // 타투이스트 ID를 현재 사용자 ID로 설정합니다.
          tattooistId: auth.currentUser.uid
        };

        // 타투이스트의 스케줄에 이벤트 추가
        const eventsRef = collection(db, 'users', auth.currentUser.uid, 'events');
        await addDoc(eventsRef, eventData);

      } catch (error) {
        console.error('스케줄 추가 실패:', error);
        throw error;
      }
    };

    // 예약 확정 관련 함수들
    const getConfirmationStatus = (message) => {
      if (message.confirmed) return '예약 확정됨';
      
      const isTattooist = currentUserData.value?.userType === 'tattooist';
      const tattooistConfirmed = message.tattooistConfirmed;
      const customerConfirmed = message.customerConfirmed;

      if (isTattooist) {
        if (tattooistConfirmed) return '타투이스트 확인 완료 (고객 확인 대기중)';
        return '타투이스트 확인 필요';
      } else {
        if (customerConfirmed) return '고객 확인 완료 (타투이스트 확인 대기중)';
        return '고객 확인 필요';
      }
    };

    const fetchSenderBankInfo = async (senderId) => {
      try {
        const userDoc = await getDoc(doc(db, 'users', senderId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          senderBankInfo.value = {
            bankName: userData.bankName,
            accountNumber: userData.accountNumber,
            accountHolder: userData.accountHolder
          };
        }
      } catch (error) {
        console.error('발신자 정보 가져오기 실패:', error);
      }
    };

    watch(() => messages.value, async (newMessages) => {
      for (const message of newMessages) {
        if (message.type === 'estimate' && message.content?.depositAmount) {
          await fetchSenderBankInfo(message.senderId);
        }
      }
    }, { deep: true });

    onMounted(async () => {
      if (auth.currentUser) {
        currentUser.value = auth.currentUser;
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        currentUserData.value = userDoc.data();
      }
      const roomId = route.params.id;
      const roomDoc = await getDoc(doc(db, 'chatRooms', roomId));
      
      if (!roomDoc.exists()) {
        router.push('/chat');
        return;
      }

      const roomData = roomDoc.data();
      const otherUserId = roomData.participants.find(id => id !== currentUser.value.uid);
      const userDoc = await getDoc(doc(db, 'users', otherUserId));
      otherUser.value = { id: otherUserId, ...userDoc.data() };

      // 디버깅 로그 추가
      console.log('현재 사용자 타입:', currentUserData.value?.userType);
      console.log('상대방 타입:', otherUser.value?.userType);
      
      const messagesRef = collection(db, 'chatRooms', roomId, 'messages');
      const firstMessageQuery = query(messagesRef, orderBy('timestamp'), limit(1));
      const firstMessageSnapshot = await getDocs(firstMessageQuery);
      
      // 디버깅 로그 추가
      console.log('첫 메시지 존재 여부:', !firstMessageSnapshot.empty);
      
      // 조건 수정: 메시지가 없고 현재 사용자가 guest이며 상대방이 tattooist인 경우
      if (firstMessageSnapshot.empty && 
          currentUserData.value?.userType === 'guest' && 
          otherUser.value?.userType === 'tattooist') {
        console.log('환영 메시지 전송 시도');
        
        try {
          // 타투이스트의 환영 메시지 전송
          await addDoc(messagesRef, {
            content: `안녕하세요! ${otherUser.value.nickname || otherUser.value.name}입니다. 
원활한 상담을 위해 문의서를 작성해주시면 감사하겠습니다.`,
            senderId: otherUser.value.id,
            senderName: otherUser.value.nickname || otherUser.value.name,
            timestamp: serverTimestamp(),
            type: 'text'
          });

          console.log('환영 메시지 전송 성공');

          // 문의서 작성 버튼 메시지 전송
          await addDoc(messagesRef, {
            content: '타투 문의서 작성하기',
            type: 'consultationButton',
            senderId: otherUser.value.id,
            timestamp: serverTimestamp(),
            tattooistId: otherUser.value.id
          });

          console.log('문의서 버튼 전송 성공');
          
          // 채팅방 마지막 메시지 업데이트
          await updateDoc(doc(db, 'chatRooms', roomId), {
            lastMessage: {
              content: '안녕하세요!',
              timestamp: serverTimestamp()
            }
          });
          
        } catch (error) {
          console.error('환영 메시지 전송 실패:', error);
        }
      }

      // 실시간 메시지 구독 설정
      const q = query(messagesRef, orderBy('timestamp'));
      unsubscribe = onSnapshot(q, (snapshot) => {
        const newMessages = [];
        snapshot.forEach((doc) => {
          newMessages.push({
            id: doc.id,
            ...doc.data()
          });
        });
        messages.value = newMessages;
        scrollToBottom();
      });
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
      document.removeEventListener('click', handleClickOutside);
    });

    onBeforeRouteLeave((to, from, next) => {
      if (selectedImage.value) {
        selectedImage.value = null
        next(false)
        return
      }
      next()
    })

    return {
      messages,
      newMessage,
      otherUser,
      messageContainer,
      formatTime,
      sendMessage,
      goBack,
      goToUserProfile,
      selectedImage,
      openImageViewer,
      closeImageViewer,
      activeTab,
      showAddMenu,
      showConsultationForm,
      showReservationForm,
      showImageUpload,
      selectedImages,
      toggleAddMenu,
      openForm,
      openImageUpload,
      closeForm,
      handleImageUpload,
      removeImage,
      sendImages,
      currentUserData,
      currentUser,
      messageClasses,
      sendConsultationButton,
      sendAccountInfoButton,
      sendEstimate,
      showEstimateModal,
      estimateForm,
      isEstimateFormValid,
      getCurrentDate,
      handleEstimateImageUpload,
      removeEstimateImage,
      submitEstimate,
      formatPrice,
      timeOptions,
      copyAccountInfo,
      isUserConfirmed,
      confirmReservation,
      addToSchedule,
      formatDepositAmount,
      getConfirmationStatus,
      isSending,
      senderBankInfo
    };
  }
};
</script>

<style scoped>
.chat-room {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  margin: 0 auto;
  background: white;
  margin-top: 60px;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
  background: white;
}

.back-button {
  background: none;
  border: none;
  font-size: 19px;
  cursor: pointer;
  padding: 8px;
  margin-right: 16px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.message {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 8px 0;
  width: 100%;
}

.message.sent {
  justify-content: flex-end;
}

.message.received {
  justify-content: flex-start;
}

.message.sent .message-content {
  background: #339af0;
  color: white;
}

.message.received .message-content {
  background: #f1f3f5;
}

.message-content {
  padding: 10px 12px;
  border-radius: 16px;
  max-width: 70%;
  word-break: break-word;
}

.copy-button {
  background-color: #1a1a1a;
  color: #ffffff;
  border: none;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
}

.copy-button2 {
  background-color: #1a1a1a;
  color: #ffffff;
  border: none;
  padding: 20px 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
}

.message-time {
  font-size: 12px;
  color: #868e96;
  align-self: flex-end;
  margin-bottom: 4px;
}

.profile-image {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin-right: 8px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.image-message {
  max-width: 300px;
  margin: 4px 0;
  background: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.image-message .images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 4px;
  margin-top: 13px;
}

.image-message .image-preview {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.image-message .image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.chat-input {
  display: flex;
  padding: 16px 0;
  gap: 8px;
  border-top: 1px solid #eee;
  background: white;
}

.chat-input input {
  width: 100%;
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #dee2e6;
  border-radius: 24px;
  outline: none;
  font-size: 15px;
}

.chat-input button {
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  background: #339af0;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.chat-input button:disabled {
  background: #adb5bd;
  cursor: not-allowed;
}

.consultation-message {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 12px;
  padding: 13px;
  margin: 8px 0;
  font-size: 14px;
}

.consultation-content {
  white-space: pre-wrap;
  font-family: inherit;
  margin: 0;
  color: #495057;
  font-size: 14px;
  line-height: 1.5;
}

.consultation-images {
  margin-top: 16px;
  border-top: 1px solid #e9ecef;
  padding-top: 16px;
}

.consultation-images h4 {
  margin-bottom: 13px;
  font-size: 14px;
  color: #495057;
}

.images-grid {
  display: grid;
  grid-template-columns: 150px;
  gap: 8px;
  margin-top: 13px;
}

.image-preview {
  position: relative;
  aspect-ratio: 1;
  cursor: pointer;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;
}

.image-preview:hover img {
  transform: scale(1.05);
}

.image-viewer-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.enlarged-image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

.body-images-container {
  margin-bottom: 24px;
  padding: 16px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.body-images-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 8px;
}

.body-image-preview {
  position: relative;
  aspect-ratio: 1;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  background: #f8f9fa;
  padding: 8px;
}

.body-image-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.reference-images {
  margin-top: 32px;
  border-top: 1px solid #e9ecef;
  padding-top: 16px;
}

/* 신체 부위 선택 UI 스타일 */
.body-map {
  margin: 13px 0;
  background: white;
  border-radius: 8px;
  padding: 13px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.body-tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 13px;
}

.tab-button {
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  background: #f1f3f5;
  color: #495057;
  cursor: pointer;
  transition: all 0.2s;
}

.tab-button.active {
  background: #339af0;
  color: white;
}

.body-image {
  position: relative;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.body-front,
.body-back {
  width: 100%;
}

.body-front svg,
.body-back svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
  background: #f8f9fa;
}

.body-part {
  fill: #e0e0e0;
  transition: fill 0.3s ease;
}

.body-part[data-selected="true"] {
  fill: #339af0;
}

.selected-parts {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid #e9ecef;
  font-size: 14px;
  color: #495057;
  word-break: keep-all;
  line-height: 1.4;
}

.add-menu-container {
  position: relative;
}

.add-btn {
  background: none;
  border: none;
  font-size: 19px;
  color: #339af0;
  cursor: pointer;
  padding: 8px;
}

.add-menu {
  position: absolute;
  bottom: 120%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 8px;
  width: 150px;
  z-index: 1000;
}

.add-menu button {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  color: #495057;
}

.add-menu button i {
  width: 20px;
  font-size: 18px;
  text-align: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #343a40;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.25rem;
  color: #868e96;
  cursor: pointer;
  padding: 5px;
  transition: color 0.2s;
}

.close-btn:hover {
  color: #495057;
}

.modal-body {
  padding: 10px 0;
}

.image-preview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  margin: 10px 0;
}

.preview-item {
  position: relative;
  aspect-ratio: 1;
}

.preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.preview-item .remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-btn {
  width: 100%;
  padding: 8px;
  border: none;
  background: #339af0;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

/* 문의서 버튼 메시지 스타일 */
.consultation-button {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 12px;
  padding: 16px;
  margin: 8px 0;
  text-align: center;
}

.consultation-button a {
  display: inline-block;
  padding: 13px 24px;
  background: #339af0;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.2s;
}

.estimate-button-container {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.estimate-button {
  width: 100%;
  padding: 14px;
  background-color: #4371ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal-content {
  background: white;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.input-group input,
.input-group textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.image-upload {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image-upload input[type="file"] {
  display: none;
}

.image-upload button {
  padding: 8px 16px;
  background: #f1f3f5;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.submit-btn,
.cancel-btn {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}

.submit-btn {
  background: #1a1a1a;
  color: white;
}

.submit-btn:disabled {
  background: #adb5bd;
  cursor: not-allowed;
}

.cancel-btn {
  background: #f1f3f5;
  color: #495057;
}

.duration-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.message.sent .consultation-message {
  background-color: #e7f5ff;
  border-color: #d0ebff;
}

.message.sent .consultation-content {
  color: #1971c2;
}

.time-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  background-color: white;
  cursor: pointer;
}

.time-select:focus {
  outline: none;
  border-color: #4371ff;
}

.reservation-status {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.confirmation-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.confirmation-status {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #666;
  gap: 10px;
  padding: 10px;
}

.confirmation-status i {
  margin-left: 4px;
}

.confirmation-status .fa-check {
  color: #40c057;
}

.confirmation-status .fa-clock {
  color: #868e96;
}

.confirm-button {
  width: 100%;
  padding: 12px;
  background-color: #4371ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.confirm-button:hover {
  background: #333;
}

.confirmation-complete {
  text-align: center;
  color: #2ecc71;
  font-weight: 500;
  padding: 8px;
}

.checkbox-group {
  margin: 16px 0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.deposit-input {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.deposit-input input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.currency {
  color: #666;
  font-size: 15px;
}

/* 이미지 메시지는 배경색과 패딩 제거 */
.message.sent .image-message .message-content,
.message.received .image-message .message-content {
  background: none;
  padding: 0;
}

/* 견적서 스타일 */
.estimate-message {
  width: 100%;
  max-width: 500px;
  margin: 16px 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.estimate-header {
  background: #1a1a1a;
  color: white;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-header h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.estimate-date {
  font-size: 0.9rem;
  opacity: 0.8;
}

.estimate-content {
  padding: 20px;
}

.estimate-section {
  margin-bottom: 24px;
}

.estimate-section:last-child {
  margin-bottom: 0;
}

.estimate-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
}

.estimate-row:last-child {
  border-bottom: none;
}

.estimate-row .label {
  color: #666;
  font-size: 0.95rem;
}

.estimate-row .value {
  font-weight: 500;
}

.estimate-row .price {
  color: #1a1a1a;
  font-size: 1.1rem;
  font-weight: 600;
}

.description h4 {
  margin: 0 0 12px 0;
  color: #1a1a1a;
  font-size: 1rem;
}

.description p {
  margin: 0;
  color: #444;
  line-height: 1.6;
  white-space: pre-wrap;
}

.estimate-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  margin-top: 12px;
}

.estimate-image {
  aspect-ratio: 1;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}

.estimate-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;
}

.estimate-image:hover img {
  transform: scale(1.05);
}

.estimate-footer {
  margin-top: 20px;
  border-top: 1px solid #eee;
}

.confirmation-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirm-button {
  background: #1a1a1a;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background 0.2s;
}

.confirm-button:hover {
  background: #333;
}

.confirmation-complete {
  text-align: center;
  color: #2ecc71;
  font-weight: 500;
}

.user-info.clickable {
  cursor: pointer;
}

.user-info:not(.clickable) {
  cursor: default;
}
</style> 

