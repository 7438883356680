<template>
  <div class="login-wrap">
    <div class="login-container">
      <h2>로그인 / 회원가입</h2>
      <form @submit.prevent="login" class="login-form">
        <!-- 국가 코드 선택 -->
        <div class="country-code-wrapper">
          <select v-model="countryCode" required>
            <option value="+82">대한민국 (+82)</option>
            <option value="+1">United States/Canada (+1)</option>
            <option value="+81">日本 (+81)</option>
            <option value="+86">中国 (+86)</option>
            <option value="+852">香港 (+852)</option>
            <option value="+84">ViệtNam (+84)</option>
            <option value="+66">ประเทศไทย (+66)</option>
            <option value="+63">Pilipinas (+63)</option>
            <option value="+65">Singapura (+65)</option>
            <option value="+44">United Kingdom (+44)</option>
            <option value="+49">Deutschland (+49)</option>
            <option value="+33">France (+33)</option>
            <option value="+39">Italia (+39)</option>
            <option value="+34">España (+34)</option>
            <option value="+61">Australia (+61)</option>
            <option value="+64">New Zealand (+64)</option>
            <option value="+7">Россия (+7)</option>
            <option value="+55">Brasil (+55)</option>
            <option value="+91">भारत (+91)</option>
            <option value="+62">Indonesia (+62)</option>
          </select>
        </div>
        <!-- 전화번호 입력 -->
        <div class="input-wrapper">
          <input 
            type="text" 
            v-model="phone" 
            id="phone" 
            required 
            :class="{'has-value': phone}"
            @input="formatPhoneNumber"
            maxlength="13"
          />
          <label for="phone">전화번호</label>
        </div>
        <!-- 인증번호 입력 (코드 전송 후에만 표시) -->
        <div v-if="isCodeSent" class="input-wrapper">
          <input 
            type="text" 
            v-model="verificationCode" 
            id="verificationCode" 
            required 
            :class="{'has-value': verificationCode}"
          />
          <label for="verificationCode">인증번호</label>
        </div>

        <!-- 닉네임 입력 (신규 회원이고 인증번호 입력 후에만 표시) -->
        <div v-if="isNewUser && isCodeVerified" class="input-wrapper">
          <input 
            type="text" 
            v-model="nickname" 
            id="nickname" 
            required 
            :class="{'has-value': nickname}"
          />
          <label for="nickname">이름</label>
        </div>

        <!-- 버튼 그룹 -->
        <div v-if="!isCodeSent" class="button-group">
          <button type="button" @click="sendVerificationCode" class="send-code-button">
            인증번호 전송
          </button>
        </div>
        <div v-else-if="!isCodeVerified" class="button-group">
          <button type="button" @click="verifyCode" class="verify-code-button">
            인증번호 확인
          </button>
        </div>
        <div v-else-if="isNewUser" class="button-group">
          <button type="button" @click="completeSignUp" class="signup-button">
            가입완료
          </button>
        </div>

        <!-- 에러 메시지 -->
        <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>
      </form>

      <!-- 항상 존재하는 reCAPTCHA 컨테이너 -->
      <div id="recaptcha-container" class="recaptcha-container"></div>

      <!-- 타투이스트 회원가입 섹션 (인증번호 전송 전에만 표시) -->
      <div v-if="!isCodeSent" class="signup-section">
        <button class="signup-button artist" @click="goToArtistSignUp">
          <i class="fas fa-palette"></i>
          타투이스트 회원가입
        </button>
      </div>
    </div>
    <div class="recaptcha-terms" style="font-size: 12px; color: #666; text-align: center; margin-top: 20px;">
      이 사이트는 reCAPTCHA로 보호되며 Google의
      <a href="https://policies.google.com/privacy">개인정보처리방침</a> 및
      <a href="https://policies.google.com/terms">서비스 약관</a>이 적용됩니다.
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { auth, db } from "@/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { 
  doc, 
  setDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  getDoc 
} from "firebase/firestore";

export default {
  name: "TattooMapAuth",
  setup() {
    const phone = ref("");
    const countryCode = ref("+82");
    const verificationCode = ref("");
    const nickname = ref("");
    const errorMessage = ref("");
    const isCodeSent = ref(false);
    const isNewUser = ref(false);
    const isLoading = ref(false);
    const retryTimeoutId = ref(null);
    const retryCount = ref(0);
    let confirmationResult = null;
    const router = useRouter();
    const isRecaptchaVisible = ref(false);
    const isCodeVerified = ref(false);

    // 전화번호 형식 통일화 함수
    const formatPhoneForDB = (phoneNumber) => {
      phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
      return `${countryCode.value}${phoneNumber}`;
    };

    // 전화번호 형식 통일화 함수
    const formatPhone = (phoneNumber) => {
      let numbers = phoneNumber.replace(/[^0-9]/g, '');
      
      if (numbers.length !== 11) {
        return phoneNumber;
      }

      return numbers.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    };

    // 전화번호로 사용자 조회
    const checkUserExists = async (phoneNumber) => {
      try {
        const formattedPhone = formatPhoneForDB(phoneNumber);
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("phone", "==", formattedPhone));
        const querySnapshot = await getDocs(q);
        return querySnapshot.size > 0;  // 사용자가 존재하면 true 반환
      } catch (error) {
        console.error("사용자 조회 실패:", error);
        return false;
      }
    };

    const sendVerificationCode = async () => {
      console.log("sendVerificationCode 호출됨");
      if (isLoading.value) return; // 다중 클릭 방지
      isLoading.value = true;
      
      try {
        errorMessage.value = "";
        if (!phone.value) {
          errorMessage.value = "전화번호를 입력해주세요.";
          return;
        }

        // 기존 회원 여부 확인
        const userExists = await checkUserExists(phone.value);
        isNewUser.value = !userExists;
        console.log("기존 회원 여부:", userExists);  // 디버깅용

        // reCAPTCHA 초기화 및 인증번호 전송
        initializeRecaptcha();
        if (window.recaptchaVerifier) {
          await window.recaptchaVerifier.render();
          console.log("reCAPTCHA 초기화 및 렌더링 완료");
        }
      } catch (error) {
        console.error("인증 초기화 실패:", error);
        errorMessage.value = "인증 초기화에 실패했습니다.";
        isRecaptchaVisible.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    const verifyCode = async () => {
      try {
        errorMessage.value = "";
        if (!verificationCode.value) {
          errorMessage.value = "인증번호를 입력해주세요.";
          return;
        }

        const { user } = await confirmationResult.confirm(verificationCode.value);

        // 사용자 문서 조회
        const userDoc = await getDoc(doc(db, "users", user.uid));
        
        if (userDoc.exists()) {
          // 기존 회원인 경우
          console.log("로그인 성공:", user.uid);
          router.push('/');
        } else {
          // 신규 회원인 경우
          isNewUser.value = true;
          isCodeVerified.value = true;
        }
      } catch (error) {
        console.error("인증 실패:", error);
        errorMessage.value = "인증번호가 올바르지 않습니다.";
      }
    };

    const initializeRecaptcha = () => {
      try {
        if (window.recaptchaVerifier) {
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
          console.log("기존 reCAPTCHA 제거됨");
        }
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          'size': 'normal',
          'callback': () => {
            console.log("reCAPTCHA 콜백 출력됨");
            sendVerificationCodeInternal();
          },
          'expired-callback': () => {
            console.log("reCAPTCHA 만료됨");
            errorMessage.value = "reCAPTCHA가 만료되었습니다. 다시 시도해주세요.";
            if (window.recaptchaVerifier) {
              window.recaptchaVerifier.clear();
              window.recaptchaVerifier = null;
            }
          }
        });
        console.log("reCAPTCHA 초기화 완료");
      } catch (error) {
        console.error("RecaptchaVerifier 초기화 실패:", error);
        errorMessage.value = "인증 초기화에 실패했습니다.";
      }
    };

    const sendVerificationCodeInternal = async () => {
      console.log("sendVerificationCodeInternal 호출됨");
      if (isLoading.value) return;
      
      try {
        isLoading.value = true;
        const formattedPhone = formatPhoneForDB(phone.value);  // 형식화된 전화번호 사용
        console.log("Formatted Phone:", formattedPhone);

        console.log("signInWithPhoneNumber 호출 전");
        confirmationResult = await signInWithPhoneNumber(
          auth, 
          formattedPhone,  // 형식화된 전화번호 사용
          window.recaptchaVerifier
        );
        console.log("signInWithPhoneNumber 호출 후");
        
        isCodeSent.value = true;
        alert("인증번호가 전송되었습니다.");
        retryCount.value = 0;
        await window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
        console.log("인증번호 전송 완료");
      } catch (error) {
        console.error("인증번호 전송 실패:", error);
        
        if (error.code === 'auth/too-many-requests') {
          errorMessage.value = "너무 많은 요청이 발생했습니다. 잠시 후 다시 시도해주세요.";
          const waitTime = Math.min(30000 * (retryCount.value + 1), 300000);
          retryCount.value++;
          
          if (retryTimeoutId.value) {
            clearTimeout(retryTimeoutId.value);
          }
          
          retryTimeoutId.value = setTimeout(() => {
            errorMessage.value = "";
          }, waitTime);
        } else {
          errorMessage.value = "인증번호 전송에 실패했습니다. 다시 시도해주세요.";
        }

        if (window.recaptchaVerifier) {
          try {
            await window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
          } catch (e) {
            console.error("reCAPTCHA clear 실패:", e);
          }
        }
      } finally {
        isLoading.value = false;
      }
    };

    const formatPhoneNumber = () => {
      let formattedPhone = phone.value.replace(/[^0-9]/g, '');
      if (formattedPhone.length > 3 && formattedPhone.length <= 7) {
        formattedPhone = formattedPhone.replace(/(\d{3})(\d{1,4})/, '$1-$2');
      } else if (formattedPhone.length > 7) {
        formattedPhone = formattedPhone.replace(/(\d{3})(\d{4})(\d{1,4})/, '$1-$2-$3');
      }
      phone.value = formattedPhone;
    };

    const goToArtistSignUp = () => {
      router.push({ name: 'SignUpArtist' });
    };

    const completeSignUp = async () => {
      try {
        if (!nickname.value) {
          errorMessage.value = "닉네임을 입력해주세요.";
          return;
        }

        // 신규 회원 정보 저장 시 전화번호 형식 통일
        const formattedPhone = formatPhone(phone.value);
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          phone: formattedPhone,  // 형식화된 전화번호 저장
          name: nickname.value,
          createdAt: new Date(),
          userType: 'guest'
        });

        alert("회원가입이 완료되었습니다!");
        router.push('/');
      } catch (error) {
        console.error("회원가입 실패:", error);
        errorMessage.value = "회원가입에 실패했습니다. 다시 시도해주세요.";
      }
    };

    return {
      phone,
      countryCode,
      verificationCode,
      nickname,
      errorMessage,
      isCodeSent,
      isNewUser,
      isRecaptchaVisible,
      sendVerificationCode,
      verifyCode,
      formatPhoneNumber,
      goToArtistSignUp,
      isCodeVerified,
      completeSignUp
    };
  }
};
</script>

<style scoped>
.login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100% - 20px);
  min-height: 100vh;
  background-color: #fff;
  padding: 10px;
}

.recaptcha-terms {
  width: 80%;
}

.login-container {
  width: 90%;
  max-width: 500px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.login-container h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input {
  width: calc(100% - 30px);
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.input-wrapper label {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  transition: all 0.3s;
  pointer-events: none;
}

.input-wrapper input:focus,
.input-wrapper input.has-value {
  border-color: #4371ff;
}

.input-wrapper input:focus + label,
.input-wrapper input.has-value + label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  background-color: white;
  padding: 0 5px;
  color: #4371ff;
}

.login-button {
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-code-button {
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-code-button {
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.error-msg {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.signup-section {
  margin-top: 30px;
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.signup-section p {
  color: #666;
  margin-bottom: 15px;
  font-size: 14px;
}

.signup-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.signup-button {
  padding: 12px 20px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: white;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.signup-button:hover {
  background-color: #333;
  color: white;
}

.signup-button.artist {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 15px;
  background-color: white;
  border: 2px solid #4371ff;
  color: #4371ff;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s;
}

.signup-button.artist:hover {
  background-color: #4371ff;
  color: white;
}

.signup-button.artist i {
  font-size: 18px;
}

@media (prefers-color-scheme: dark) {
  .signup-section {
    border-top-color: #333;
  }

  .signup-section p {
    color: #999;
  }
}

.recaptcha-container {
  margin-top: 20px;
  display: block;
}

.country-code-wrapper select {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: white;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 15px;
}

.country-code-wrapper select:focus {
  border-color: #4371ff;
}
</style>