<template>
  <div class="create-quotation">
    <div class="container">
      <h2 class="title">견적서 작성</h2>

      <!-- 의뢰서 정보 요약 -->
      <div v-if="request" class="request-summary">
        <h3>의뢰서 정보</h3>
        <div class="summary-content">
          <p><strong>희망 지역:</strong> {{ request.city }} {{ request.district }}</p>
          <p><strong>희망 일자:</strong> {{ request.preferredDate }}</p>
          <p><strong>희망 시간:</strong> {{ request.preferredTime }}</p>
          <p><strong>예산:</strong> {{ formatPrice(request.budget) }}</p>
          <p><strong>스타일:</strong> {{ request.tattooStyle }}</p>
          <p><strong>사이즈:</strong> {{ request.size }}</p>
          <p><strong>부위:</strong> {{ request.location }}</p>
          <p><strong>상세 설명:</strong> {{ request.description }}</p> 
        </div>
      </div>

      <!-- 견적서 폼 -->
      <form @submit.prevent="submitQuotation" class="quotation-form">
        <!-- 가격 입력 -->
        <div class="form-group">
          <label for="price">견적 가격</label>
          <div class="price-input">
            <input
              type="number"
              id="price"
              v-model="quotation.price"
              required
              min="0"
              step="10000"
            />
            <span class="currency">원</span>
          </div>
        </div>

        <!-- 작업 시간 입력 -->
        <div class="form-group">
          <label for="duration">예상 작업 시간</label>
          <div class="duration-input">
            <input
              type="number"
              id="duration"
              v-model="quotation.duration"
              required
              min="1"
            />
            <span class="unit">시간</span>
          </div>
        </div>

        <!-- 작업 가능 날짜 -->
        <div class="form-group">
          <label for="availableDates">작업 가능 날짜</label>
          <input
            type="date"
            id="availableDates"
            v-model="quotation.availableDate"
            required
            :min="today"
          />
        </div>

        <!-- 작업가능 시간 -->
        <div class="form-group">
          <label for="availableTime">작업가능 시간</label>
          <input type="time" id="availableTime" v-model="quotation.availableTime" required />
        </div>  

        <!-- 상세 설명 -->
        <div class="form-group">
          <label for="description">상세 설명</label>
          <textarea
            id="description"
            v-model="quotation.description"
            rows="5"
            required
            placeholder="작업 방식, 소요 시간, 주의사항 등을 상세히 설명해주세요."
          ></textarea>
        </div>

        <!-- 작업 예시 이미지 -->
        <div class="form-group">
          <label>작업 예시 이미지</label>
          <div class="image-upload">
            <input
              type="file"
              @change="handleImageUpload"
              accept="image/*"
              multiple
              ref="fileInput"
            />
            <div class="preview-images" v-if="quotation.images.length">
              <div v-for="(image, index) in quotation.images" :key="index" class="preview-image">
                <img :src="image" alt="예시 이미지" />
                <button type="button" @click="removeImage(index)" class="remove-image">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 버튼 그룹 -->
        <div class="button-group">
          <button type="button" @click="goBack" class="cancel-button">취소</button>
          <button type="submit" class="submit-button">견적서 제출</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { doc, getDoc, addDoc, collection, serverTimestamp, query, where, getDocs, updateDoc } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { db, storage } from '@/firebase'
import { getAuth } from 'firebase/auth'

export default {
  name: 'CreateQuotation',
  props: {
    requestId: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const router = useRouter()
    const auth = getAuth()
    const request = ref(null)
    const fileInput = ref(null)

    const quotation = ref({
      price: '',
      duration: '',
      availableDate: '',
      availableTime: '',
      description: '',
      images: []
    })

    const today = computed(() => {
      const date = new Date()
      return date.toISOString().split('T')[0]
    })

    // 의뢰서 정보 가져오기
    const fetchRequest = async () => {
      try {
        const docRef = doc(db, 'tattooRequests', props.requestId)
        const docSnap = await getDoc(docRef)
        
        if (docSnap.exists()) {
          request.value = { id: docSnap.id, ...docSnap.data() }
        } else {
          console.error('의뢰서를 찾을 수 없습니다')
          router.push('/requests')
        }
      } catch (error) {
        console.error('의뢰서 조회 실패:', error)
      }
    }

    // 이미지 업로드 처리
    const handleImageUpload = (event) => {
      const files = event.target.files
      Array.from(files).forEach(file => {
        const reader = new FileReader()
        reader.onload = (e) => {
          quotation.value.images.push(e.target.result)
        }
        reader.readAsDataURL(file)
      })
    }

    // 이미지 제거
    const removeImage = (index) => {
      quotation.value.images.splice(index, 1)
    }

    // 가격 포맷팅
    const formatPrice = (price) => {
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW'
      }).format(price)
    }

    // 견적서 제출
    const submitQuotation = async () => {
      try {
        console.log('견적서 제출 시작:', quotation.value); // 디버깅용

        // 이미지 업로드 및 URL 가져오기
        const imageUrls = await Promise.all(
          quotation.value.images.map(async (image) => {
            if (image.startsWith('data:')) {
              const response = await fetch(image);
              const blob = await response.blob();
              const imageRef = storageRef(storage, `quotations/${Date.now()}-${Math.random().toString(36).substring(7)}`);
              const snapshot = await uploadBytes(imageRef, blob);
              return getDownloadURL(snapshot.ref);
            }
            return image;
          })
        );

        console.log('업로드된 이미지 URLs:', imageUrls); // 디버깅용

        // 견적서 데이터 준비
        const estimateContent = {
          price: Number(quotation.value.price),
          duration: Number(quotation.value.duration),
          availableDate: `${quotation.value.availableDate} ${quotation.value.availableTime}`,
          description: quotation.value.description,
          images: imageUrls
        };

        console.log('전송할 견적서 내용:', estimateContent); // 디버깅용

        // Firestore에 견적서 저장
        const quotationRef = await addDoc(collection(db, 'quotations'), {
          ...estimateContent,
          requestId: props.requestId,
          tattooistId: auth.currentUser.uid,
          customerId: request.value.userId,
          createdAt: serverTimestamp(),
          status: 'pending'
        });

        // 채팅방 찾기 또는 생성
        const chatRoomRef = await findOrCreateChatRoom(request.value.userId);

        // 견적서 메시지 전송
        const messageData = {
          type: 'estimate',
          quotationId: quotationRef.id,
          content: estimateContent,  // 견적서 내용을 content 필드에 포함
          senderId: auth.currentUser.uid,
          timestamp: serverTimestamp(),
          tattooistConfirmed: true, // 타투이스트가 보낼 때 자동으로 확정
          customerConfirmed: false,
          confirmed: false
        };

        console.log('전송할 메시지 데이터:', messageData); // 디버깅용

        await addDoc(collection(db, 'chatRooms', chatRoomRef.id, 'messages'), messageData);

        // 채팅방 마지막 메시지 업데이트
        await updateDoc(doc(db, 'chatRooms', chatRoomRef.id), {
          lastMessage: {
            content: '견적서가 도착했습니다.',
            timestamp: serverTimestamp()
          }
        });

        // 채팅방으로 이동
        router.push(`/chat/${chatRoomRef.id}`);
      } catch (error) {
        console.error('견적서 제출 실패:', error);
        alert('견적서 제출에 실패했습니다. 다시 시도해주세요.');
      }
    };

    // 채팅방 찾기 또는 생성하는 함수
    const findOrCreateChatRoom = async (customerId) => {
      try {
        const chatRoomsRef = collection(db, 'chatRooms');
        
        // 참가자 배열에 두 사용자 ID가 모두 포함된 채팅방 찾기
        const q = query(
          chatRoomsRef,
          where('participants', 'array-contains', auth.currentUser.uid)
        );
        
        const querySnapshot = await getDocs(q);
        const existingRoom = querySnapshot.docs.find(doc => 
          doc.data().participants.includes(customerId)
        );
        
        if (existingRoom) {
          return existingRoom;
        }

        // 채팅방이 없으면 새로 생성
        const newChatRoom = await addDoc(chatRoomsRef, {
          participants: [auth.currentUser.uid, customerId],
          createdAt: serverTimestamp(),
          lastMessage: {
            content: '채팅방이 생성되었습니다.',
            timestamp: serverTimestamp()
          }
        });

        return newChatRoom;
      } catch (error) {
        console.error('채팅방 찾기/생성 실패:', error);
        throw error;
      }
    };

    // 뒤로 가기
    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      fetchRequest()
    })

    return {
      request,
      quotation,
      fileInput,
      today,
      handleImageUpload,
      removeImage,
      formatPrice,
      submitQuotation,
      goBack
    }
  }
}
</script>

<style scoped>
.create-quotation {
  margin-top: 60px;
}

.container {
  padding: 16px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 20px;
  color: #1a1a1a;
}

/* 의뢰서 요약 섹션 */
.request-summary {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 32px;
}

.request-summary h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  margin-bottom: 16px;
}

.summary-content p {
  margin: 8px 0;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
}

.summary-content strong {
  color: #1a1a1a;
  min-width: 72px;
}

/* 폼 스타일링 */
.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

input, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 12px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #666;
}

.price-input,
.duration-input {
  display: flex;
  align-items: center;
  gap: 8px;
}

.currency,
.unit {
  position: absolute;
  right: 40px;
  color: #666;
  font-size: 14px;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

/* 이미지 업로드 영역 */
.image-upload {
  margin-top: 8px;
}

.preview-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.preview-image {
  position: relative;
}

.preview-image img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 12px;
}

.remove-image {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ff6b6b;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: all 0.2s ease;
}

.remove-image:hover {
  transform: scale(1.1);
}

/* 버튼 그룹 */
.button-group {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.cancel-button,
.submit-button {
  flex: 1;
  padding: 14px;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background: #f5f5f5;
  color: #666;
}

.submit-button {
  background: #1a1a1a;
  color: #ffffff;
}

.cancel-button:hover,
.submit-button:hover {
  transform: translateY(-1px);
}

</style> 