//(유저)  공개 타투 의뢰 페이지
<template>
  <div class="tattoo-request">
    <h2 class="title">타투 견적요청</h2>

    <!-- 진행 상태 표시 -->
    <div class="progress-bar">
      <div class="progress" :style="{ width: `${(currentStep / totalSteps) * 100}%` }"></div>
      <div class="step-text">STEP {{ currentStep }}/{{ totalSteps }}</div>
    </div>

    <!-- 스텝별 질문 폼 -->
    <div class="step-container">
      <!-- 장르 선택 스텝 -->
      <div v-if="currentStep === 1" class="step">
        <h3>어떤 스타일의 타투를 원하시나요?</h3>
        <p class="step-description">타투 스타일을 선택해보세요. 처음이라면 부담 갖지 마시고, 마음에 드는 걸 골라보세요!</p>
        <div class="genre-grid">
          <button
            v-for="genre in genres"
            :key="genre"
            type="button"
            :class="['genre-item', { active: form.genre === genre }]"
            @click="toggleGenre(genre)"
          >
            {{ genre }}
          </button>
        </div>
      </div>

      <!-- 사이즈 선택 스텝 -->
      <div v-if="currentStep === 2" class="step">
        <h3>타투 사이즈를 선택해주세요</h3>
        <p class="step-description">타투의 크기를 선택해보세요. 처음이라면 작은 사이즈부터 시작해보는 것도 좋아요!</p>
        <div class="size-grid">
          <button
            v-for="size in ['신용카드크기', '주먹크기', '손바닥크기', '반팔크기', '그이상']"
            :key="size"
            type="button"
            :class="['size-item', { active: form.size === size }]"
            @click="selectSize(size)"
          >
            {{ size }}
          </button>
        </div>
      </div>

      <!-- 나머지 스텝들... -->
      <div v-if="currentStep === 3" class="step">
        <h3>어느 부위에 타투를 하고 싶으신가요?</h3>
        <p class="step-description">타투를 하고 싶은 부위를 선택해보세요. 편안한 부위를 선택하시면 좋아요!</p>
        <div class="body-map">
          <div class="body-tabs">
            <button 
              :class="['tab-button', { active: activeTab === 'front' }]"
              @click="activeTab = 'front'"
            >
              앞면
            </button>
            <button 
              :class="['tab-button', { active: activeTab === 'back' }]"
              @click="activeTab = 'back'"
            >
              뒷면
            </button>
          </div>
          <!-- 기존 body-image 내용 유지 -->
          <div class="body-image">
            <!-- 기존 svg 내용 유지 -->
            <div v-show="activeTab === 'front'" class="body-front">
              <svg viewBox="0 0 226 790" xmlns="http://www.w3.org/2000/svg">
                <path @click="selectBodyPart('얼굴')" :data-selected="selectedBodyParts.includes('얼굴')"
                d="M137.63,93.19c7.08-5.27,7.81-20.71,7.81-20.71,0,0,.55,2.72,3.27.91,2.72-1.81,3.64-20.16,2.91-21.8s-3.82-1.09-3.82-1.09c0,0,4.35-23.98-3.09-36.33C137.26,1.81,119.64,0,112.73,0s-24.52,1.81-31.97,14.17c-7.44,12.36-3.09,36.33-3.09,36.33,0,0-3.09-.55-3.82,1.09-.73,1.63.18,19.98,2.91,21.8,2.72,1.81,3.27-.91,3.27-.91,0,0,.73,15.45,7.81,20.71,0,0,1.4,17.65-.72,25.51h51.22c-2.12-7.87-.72-25.51-.72-25.51h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('쇄골')" :data-selected="selectedBodyParts.includes('쇄골')"
                d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 팔뚝')" :data-selected="selectedBodyParts.includes('오른쪽 팔뚝')"
                d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 팔목')" :data-selected="selectedBodyParts.includes('오른쪽 팔목')"
                d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 손')" :data-selected="selectedBodyParts.includes('오른쪽 손')"
                d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 팔뚝')" :data-selected="selectedBodyParts.includes('왼쪽 팔뚝')"
                d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽  팔목')" :data-selected="selectedBodyParts.includes('왼쪽  팔목')"
                d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 손')" :data-selected="selectedBodyParts.includes('왼쪽 손')"
                d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z"
                class="body-part" />

                <path @click="selectBodyPart('가슴')" :data-selected="selectedBodyParts.includes('가슴')"
                d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('배')" :data-selected="selectedBodyParts.includes('배')"
                d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 허벅지')" :data-selected="selectedBodyParts.includes('왼쪽 허벅지')"
                d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 종아리')" :data-selected="selectedBodyParts.includes('왼쪽 종아리')"
                d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 발')" :data-selected="selectedBodyParts.includes('왼쪽 발')"
                d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 허벅지')" :data-selected="selectedBodyParts.includes('오른쪽 허벅지')"
                d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 종아리')" :data-selected="selectedBodyParts.includes('오른쪽 종아리')"
                d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 발')" :data-selected="selectedBodyParts.includes('오른쪽 발')"
                d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z"
                class="body-part" />
              </svg>
            </div>
            <!-- 뒷면 -->
            <div v-show="activeTab === 'back'" class="body-back">
              <svg viewBox="0 0 226 790" xmlns="http://www.w3.org/2000/svg">
                <path @click="selectBodyPart('(뒤)어깨')" :data-selected="selectedBodyParts.includes('(뒤)어깨')"
                d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 팔뚝')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 팔뚝')"
                d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 팔목')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 팔목')"
                d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 손')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 손')"
                d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 팔뚝')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 팔뚝')"
                d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 팔목')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 팔목')"
                d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 손')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 손')"
                d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)윗등')" :data-selected="selectedBodyParts.includes('(뒤)윗등')"
                d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)아랫등')" :data-selected="selectedBodyParts.includes('(뒤)아랫등')"
                d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 허벅지')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 허벅지')"
                d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 종아리')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 종아리')"
                d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 발')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 발')"
                d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 허벅지')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 허벅지')"
                d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 종아리')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 종아리')"
                d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 발')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 발')"
                d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z"
                class="body-part" />
              </svg>
            </div>
          </div>
          <p class="selected-part">선택된 부위: {{ selectedBodyParts.join(', ') || '선택되지 않음' }}</p>
        </div>
      </div>

      <!-- 예산 입력 스텝 -->
      <div v-if="currentStep === 4" class="step">
        <h3>예산은 어느 정도로 생각하시나요?</h3>
        <p>타투에 투자할 예산을 정해보세요. 부담 없이 설정해보세요!</p>
        <div class="budget-slider-container">
          <input 
            type="range" 
            v-model="form.budget"
            class="budget-slider"
            min="50000"
            max="3000000"
            step="50000"
            @input="validateBudget"
          />
          <div class="budget-info">
            <div class="min-budget">최소 예산: 50,000원</div>
            <div class="selected-budget">
              선택된 예산: {{ formatPrice(form.budget) }}
            </div>
          </div>
        </div>
      </div>

      <!-- 작업 지역 및 희망일시 선택 스텝 -->
      <div v-if="currentStep === 5" class="step">
        <h3>작업 받고 싶은 지역과 희망 일시를 선택해주세요</h3>
        <p>타투를 받을 지역과 시간을 선택해보세요. 편한 시간대를 선택하시면 됩니다!</p>
        
        <!-- 지역 선택 -->
        <div class="region-selects">
          <div class="location-controls">
            <button @click="getCurrentLocation" class="location-button">
              <i class="fas fa-location-arrow"></i>
              내 위치로 찾기
            </button>
          </div>
          <div class="location-select-group">
            <select v-model="form.city" class="input-field" @change="form.district = ''">
              <option value="">시/도 선택</option>
              <option v-for="(districts, city) in districts" :key="city" :value="city">
                {{ city }}
              </option>
            </select>
            <select v-model="form.district" class="input-field">
              <option value="">구/군 선택</option>
              <option v-for="district in districts[form.city]" 
                      :key="district" 
                      :value="district">
                {{ district }}
              </option>
            </select>
          </div>
        </div>

        <!-- 희망 일시 선택 -->
        <div class="datetime-selects">
          <input 
            type="date" 
            v-model="form.preferredDate"
            class="input-field"
            :min="minDate"
          />
          <select v-model="form.preferredTime" class="input-field">
            <option value="">희망 시간대 선택</option>
            <option value="새벽(00:00 ~ 06:00)">새벽 (00:00 ~ 06:00)</option>
            <option value="아침(06:00 ~ 09:00)">아침 (06:00 ~ 09:00)</option>
            <option value="오전(09:00 ~ 12:00)">오전 (09:00 ~ 12:00)</option>
            <option value="이른 오후(12:00 ~ 15:00)">이른 오후 (12:00 ~ 15:00)</option>
            <option value="늦은 오후(15:00 ~ 18:00)">늦은 오후 (15:00 ~ 18:00)</option>
            <option value="저녁(18:00 ~ 21:00)">저녁 (18:00 ~ 21:00)</option>
            <option value="밤(21:00 ~ 24:00)">밤 (21:00 ~ 24:00)</option>
          </select>
        </div>
      </div>

      <!-- 설명 입력 스텝 -->
      <div v-if="currentStep === 6" class="step">
        <h3>원하시는 타투에 대해 자세히 설명해주세요</h3>
        <p>타투에 대한 자세한 설명을 적어주세요. 어떤 디자인을 원하시는지 알려주시면 더 좋습니다!</p>
        <textarea
          v-model="form.description"
          class="textarea-field"
          placeholder="원하시는 타투의 디자인, 특징, 크기 등에 대해 자세히 설명해주세요."
        ></textarea>
      </div>

      <!-- 연락처 입력 스텝 -->
      <div v-if="currentStep === 7" class="step">
        <h3>연락처 제공 동의</h3>
        <p>타투이스트와의 원활한 소통을 위해 회원님의 연락처가 필요합니다.</p>
        
        <div class="phone-consent-container">
          <div class="phone-display">
            <span class="label">나의 연락처:</span>
            <span class="phone-number">{{ userPhone }}</span>
          </div>
          
          <div class="consent-checkbox">
            <input
              type="checkbox"
              id="phoneConsent"
              v-model="form.phoneConsent"
              class="consent-input"
            />
            <label for="phoneConsent" class="consent-label">
              타투이스트에게 나의 연락처를 제공하는 것에 동의합니다(예약 확정시에만 연락처 노출)
            </label>
          </div>
          
          <p v-if="!form.phoneConsent && isSubmitAttempted" class="error-message">
            연락처 제공에 동의해주세요
          </p>
        </div>
      </div>

      <!-- 참고 이미지 업로드 스텝 -->
      <div v-if="currentStep === 8" class="step">
        <h3>참고하실 이미지가 있다면 업로드해주세요</h3>
        <p>참고할 이미지를 업로드해보세요. 디자이너에게 큰 도움이 됩니다!</p>
        <input
          type="file"
          @change="handleImageUpload"
          accept="image/*"
          multiple
          class="input-field"
        />
        <div v-if="uploadedImages.length > 0" class="uploaded-images-summary">
          <div class="images-grid">
            <div v-for="(image, index) in uploadedImages" :key="index" class="image-preview">
              <img :src="image.url" alt="참고 이미지" />
              <button @click="removeImage(index)" class="remove-image">삭제</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 최종 확인 스텝 -->
      <div v-if="currentStep === 9" class="step">
        <h3>3일간 맞춤견적을 받을 수 있습니다.</h3>
        <p>모든 정보를 확인해주세요. 잘못된 부분이 있다면 수정하실 수 있습니다!</p>
        <div class="summary">
          <p>선택한 스타일: {{ form.genre }}</p>
          <p>사이즈: {{ form.size }}</p>
          <p>위치: {{ form.location }}</p>
          <p>예산: {{ formatPrice(form.budget) }}</p>
          <p>희망 지역: {{ form.city }} {{ form.district }}</p>
          <p>희망 일시: {{ form.preferredDate }} {{ form.preferredTime }}</p>
          <p>연락처: {{ userPhone }}</p>
          <p>상세 설명: {{ form.description }}</p>
          <div class="reference-images">
            <p>참고 이미지: {{ uploadedImages.length }}장</p>
            <div v-if="uploadedImages.length > 0" class="images-grid">
              <div v-for="(image, index) in uploadedImages" :key="index" class="image-preview">
                <img :src="image.url" :alt="`참고 이미지 ${index + 1}`" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 네비게이션 버튼 -->
    <div class="navigation-buttons">
      <button 
        v-if="currentStep === 1" 
        @click="startStep" 
        class="nav-button secondary"
      >
        처음
      </button>
      <button 
        v-if="currentStep > 1" 
        @click="prevStep" 
        class="nav-button secondary"
      >
        이전
      </button>
      <button 
        v-if="currentStep < totalSteps" 
        @click="handleStep" 
        class="nav-button primary"
        :disabled="!canProceed"
      >
        다음
      </button>
      <button 
        v-if="currentStep === totalSteps" 
        @click="submitRequest" 
        class="nav-button submit"
      >
        견적 요청
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { auth, storage } from '@/firebase'
import { addDoc, collection, serverTimestamp, getDoc, doc } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { db } from '@/firebase'


    const router = useRouter()
    const currentStep = ref(1)
    const totalSteps = 9
    const activeTab = ref('front')

// genres 데이터 추가
const genres = [
  '이레즈미',
  '치카노',
  '라인워크',
  '블랙앤그레이',
  '올드스쿨',
  '뉴스쿨',
  '블랙워크',
  '트라이벌',
  '컬러타투',
  '일러스트',
  '커버업',
  '수채화',
  '미니타투',
  '감성타투',
  '레터링',
  '동양화',
  '터치업',
  '만화/애니'
]

    const selectedBodyParts = ref([])
    const uploadedImages = ref([])

// computed 속성 사용
    const canProceed = computed(() => {
      switch (currentStep.value) {
        case 1:
          return form.value.genre
        case 2:
          return form.value.size
        case 3:
          return form.value.location
        case 4:
          return form.value.budget >= 50000
        case 5:
          return form.value.city && form.value.district && form.value.preferredDate && form.value.preferredTime
        case 6:
          return form.value.description.length >= 2
        case 7:
          return form.value.phoneConsent
        case 8:
          return true
        case 9:
          return true
        default:
          return true
      }
    })

const form = ref({
  tattooType: '',
  size: '',
  location: '',
  budget: 50000,
  city: '',
  district: '',
  preferredDate: '',
  preferredTime: '',
  description: '',
  phone: '',
  phoneConsent: false,
  status: 'unread'
})

// 이미지 압축 함수 추가
const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    const maxWidth = 1920;  // 최대 너비
    const maxHeight = 1080; // 최대 높이
    const maxSizeMB = 1;    // 최대 파일 크기 (MB)
    const quality = 0.7;    // 초기 품질 설정

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        // 이미지 크기 계산
        let width = img.width;
        let height = img.height;
        
        // 비율 유지하면서 크기 조정
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }

        // Canvas 생성 및 이미지 그리기
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // 압축된 이미지 생성
        const compressAndCheck = (currentQuality) => {
          const compressed = canvas.toDataURL('image/jpeg', currentQuality);
          
          // Base64 문자열을 Blob으로 변환
          const binaryString = atob(compressed.split(',')[1]);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes], { type: 'image/jpeg' });

          // 파일 크기 확인 (MB)
          const sizeMB = blob.size / (1024 * 1024);

          if (sizeMB > maxSizeMB && currentQuality > 0.1) {
            // 파일 크기가 여전히 크다면 품질을 더 낮춰서 재시도
            compressAndCheck(currentQuality - 0.1);
          } else {
            // 최종 압축된 파일 생성
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(compressedFile);
          }
        };

        // 압축 시작
        compressAndCheck(quality);
      };
      img.onerror = (error) => reject(error);
    };
    reader.onerror = (error) => reject(error);
  });
};

// handleImageUpload 함수 수정
const handleImageUpload = async (event) => {
  const files = Array.from(event.target.files);
  
  // 파일 타입 검증
  const validFiles = files.filter(file => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    
    if (!validTypes.includes(file.type)) {
      alert('JPG, PNG, GIF 형식의 이미지만 업로드 가능합니다.');
      return false;
    }
    return true;
  });

  // 각 파일 압축 및 처리
  for (const file of validFiles) {
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        uploadedImages.value.push({
          url: e.target.result,
          file: compressedFile
        });
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('이미지 압축 중 오류 발생:', error);
      alert('이미지 처리 중 오류가 발생했습니다.');
    }
  }
};

const submitRequest = async () => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      router.push('/TattooAuth');
      return;
    }

    // 이미지 업로드 및 데이터 저장 로직...
    const imageUrls = await Promise.all(
      uploadedImages.value.map(async (image) => {
        const imageRef = storageRef(
          storage,
          `tattooRequests/${userId}/${Date.now()}-${image.file.name}`
        );
        await uploadBytes(imageRef, image.file);
        return await getDownloadURL(imageRef);
      })
    );

    const requestData = {
      userId,
      createdAt: serverTimestamp(),
      status: 'unread',
      tattooStyle: form.value.genre,
      size: form.value.size,
      location: form.value.location,
      budget: form.value.budget,
      city: form.value.city,
      district: form.value.district,
      preferredDate: form.value.preferredDate,
      preferredTime: form.value.preferredTime,
      description: form.value.description,
      phone: userPhone.value,
      referenceImages: imageUrls
    };

    await addDoc(collection(db, 'tattooRequests'), requestData);

    // 성공 메시지 표시
    alert('견적서가 성공적으로 제출되었습니다!');

    // 목록 페이지로 이동
    router.push('/guest-requests');

  } catch (error) {
    console.error('견적서 제출 중 오류 발생:', error);
    alert('견적서 제출 중 오류가 발생했습니다.');
  }
};

// 장르 선택 토글 함수
const toggleGenre = (genre) => {
  form.value.genre = genre
}

// 사이즈 선택 함수
const selectSize = (size) => {
  form.value.size = size
}

// 신체 부위 선택 함수
const selectBodyPart = (part) => {
  const index = selectedBodyParts.value.indexOf(part)
  if (index === -1) {
    selectedBodyParts.value.push(part)
  } else {
    selectedBodyParts.value.splice(index, 1)
  }
  form.value.location = selectedBodyParts.value.join(', ')
}

// 예산 유효성 검사
const validateBudget = () => {
  if (form.value.budget < 50000) {
    form.value.budget = 50000
  }
}

// 이미지 제거
const removeImage = (index) => {
  uploadedImages.value.splice(index, 1)
}

// 다음 스텝으로 이동
const nextStep = () => {
  if (currentStep.value < totalSteps) {
    currentStep.value++
  }
}

// 이전 스텝으로 이동
const prevStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--
  }
}

// 처음으로 이동
const startStep = () => {
  router.push('/guest-requests')
}

// 가격 포맷팅 함수 추가
const formatPrice = (price) => {
  return new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW'
  }).format(price)
}

// 시/도별 구/군 데이터 추가
const districts = {
  서울: [
    '강남구', '강동구', '강북구', '강서구', '관악구', '광진구', '구로구', '금천구', 
    '노원구', '도봉구', '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구',
    '성북구', '송파구', '양천구', '영등포구', '용산구', '은평구', '종로구', '중구', '중랑구'
  ],
  부산: [
    '강서구', '금정구', '남구', '동구', '동래구', '부산진구', '북구', '사상구',
    '사하구', '서구', '수영구', '연제구', '영도구', '중구', '해운대구', '기장군'
  ],
  대구: ['중구', '동구', '서구', '남구', '북구', '수성구', '달서구', '달성군'],
  인천: ['중구', '동구', '미추홀구', '연수구', '남동구', '부평구', '계양구', '서구', '강화군', '옹진군'],
  광주: ['동구', '서구', '남구', '북구', '광산구'],
  대전: ['동구', '중구', '서구', '유성구', '대덕구'],
  울산: ['중구', '남구', '동구', '북구', '울주군'],
  세종: ['세종시'],
  경기: [
    '수원시', '성남시', '의정부시', '안양시', '부천시', '광명시', '평택시', '동두천시',
    '안산시', '고양시', '과천시', '구리시', '남양주시', '오산시', '시흥시', '군포시',
    '의왕시', '하남시', '용인시', '파주시', '이천시', '안성시', '김포시', '화성시',
    '광주시', '양주시', '포천시', '여주시', '연천군', '가평군', '양평군'
  ],
  강원: [
    '춘천시', '원주시', '강릉시', '동해시', '태백시', '속초시', '삼척시',
    '홍천군', '횡성군', '영월군', '평창군', '정선군', '철원군', '화천군',
    '양구군', '인제군', '고성군', '양양군'
  ],
  충북: [
    '청주시', '충주시', '제천시', '보은군', '옥천군', '영동군', '증평군',
    '진천군', '괴산군', '음성군', '단양군'
  ],
  충남: [
    '천안시', '공주시', '보령시', '아산시', '서산시', '논산시', '계룡시',
    '당진시', '금산군', '부여군', '서천군', '청양군', '홍성군', '예산군',
    '태안군'
  ],
  전북: [
    '전주시', '군산시', '익산시', '정읍시', '남원시', '김제시', '완주군',
    '진안군', '무주군', '장수군', '임실군', '순창군', '고창군', '부안군'
  ],
  전남: [
    '목포시', '여수시', '순천시', '나주시', '광양시', '담양군', '곡성군',
    '구례군', '고흥군', '보성군', '화순군', '장흥군', '강진군', '해남군',
    '영암군', '무안군', '함평군', '영광군', '장성군', '완도군', '진도군',
    '신안군'
  ],
  경북: [
    '포항시', '경주시', '김천시', '안동시', '구미시', '영주시', '영천시',
    '상주시', '문경시', '경산시', '군위군', '의성군', '청송군', '영양군',
    '영덕군', '청도군', '고령군', '성주군', '칠곡군', '예천군', '봉화군',
    '울진군', '울릉군'
  ],
  경남: [
    '창원시', '진주시', '통영시', '사천시', '김해시', '밀양시', '거제시',
    '양산시', '의령군', '함안군', '창녕군', '고성군', '남해군', '하동군',
    '산청군', '함양군', '거창군', '합천군'
  ],
  제주: ['제주시', '서귀포시']
}

// 현재 위치 가져오기 함수
const getCurrentLocation = () => {
  if (!navigator.geolocation) {
    alert('브라우저가 위치 정보를 지원하지 않습니다.');
    return;
  }

  navigator.geolocation.getCurrentPosition(async (position) => {
    try {
      const { latitude, longitude } = position.coords;
      
      // 네이버 지도 API를 통한 주소 변환
      naver.maps.Service.reverseGeocode({
        coords: new naver.maps.LatLng(latitude, longitude),
        orders: [
          naver.maps.Service.OrderType.ADDR,
          naver.maps.Service.OrderType.ROAD_ADDR
        ].join(',')
      }, (status, response) => {
        if (status === naver.maps.Service.Status.ERROR) {
          alert('위치 정보를 가져오는데 실패했습니다.');
          return;
        }

        if (response.v2.results && response.v2.results.length > 0) {
          const result = response.v2.results[0];
          const region = result.region;

          // 시/도 설정
          let city = region.area1.name;
          city = city.replace(/특별시|광역시|특별자치시|도|특별자치도/g, '');
          form.value.city = city;

          // 시/군/구 설정
          const area2Name = region.area2.name; // 예: "안산시 단원구" 또는 "안산시"
          
          // districts 배열에서 해당 시/군/구 찾기
          const matchedDistrict = districts[form.value.city]?.find(d => 
            area2Name.includes(d) || d.includes(area2Name)
          );
          
          if (matchedDistrict) {
            form.value.district = matchedDistrict;
          }

          console.log('Current Location:', {
            city: form.value.city,
            district: form.value.district,
            original: {
              area1: region.area1.name,
              area2: region.area2.name,
              area3: region.area3.name
            },
            matchedDistrict
          });
        }
      });
    } catch (error) {
      console.error('위치 정보 변환 중 오류:', error);
      alert('위치 정보를 가져오는데 실패했습니다.');
    }
  }, (error) => {
    let errorMessage = '위치 정보를 가져오는데 실패했습니다.';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = '위치 정보 접근 권한이 거부되었습니다.';
        break;
      case error.POSITION_UNAVAILABLE:
        errorMessage = '위치 정보를 사용할 수 없습니다.';
        break;
      case error.TIMEOUT:
        errorMessage = '위치 정보 요청이 시간 초과되었습니다.';
        break;
    }
    alert(errorMessage);
  });
};

// 사용자 전화번호 상태 추가
const userPhone = ref('')
const isSubmitAttempted = ref(false)

// 컴포넌트 마운트 시 사용자 전화번호 가져오기
onMounted(async () => {
  try {
    const user = auth.currentUser
    if (user) {
      const userDoc = await getDoc(doc(db, 'users', user.uid))
      if (userDoc.exists()) {
        userPhone.value = userDoc.data().phone
      }
    }
  } catch (error) {
    console.error('사용자 정보 로드 실패:', error)
  }
})

// nextStep 함수 수정 방식 변경
const handleStep = () => {
  if (currentStep.value === 7) {
    isSubmitAttempted.value = true
    if (!form.value.phoneConsent) return
  }
  nextStep()
}
</script>

<style scoped>
.tattoo-request {
  margin-top: 50px;
  padding: 10px;
  background: #ffffff;
  max-height: 100vh;
}

.title {
  font-size: 32px;
  color: #000;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 6px;
  background: #f0f0f0;
  border-radius: 100px;
  margin-bottom: 48px;
}

.progress {
  height: 100%;
  background: #000;
  border-radius: 100px;
  transition: width 0.3s ease;
}

.step-text {
  position: absolute;
  top: -25px;
  right: 0;
  font-size: 14px;
  color: #666;
}

.step h3 {
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
}

.step-description {
  color: #666;
  margin-bottom: 32px;
  line-height: 1.6;
}

.genre-grid, .size-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.genre-item, .size-item {
  color: #495057;
  padding: 16px;
  border: 2px solid #eee;
  border-radius: 12px;
  background: white;
  font-size: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  font-weight: 600;
}

.genre-item.active, .size-item.active {
  background: #1a1a1a;
  color: white;
  border-color: #1a1a1a;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  gap: 16px;
  padding-bottom: 20px;
}

.nav-button {
  padding: 16px 32px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  border: none;
  gap: 8px;
  transition: all 0.2s ease;
}

.nav-button.primary {
  background: linear-gradient(90deg, #2d3436 0%, #636e72 100%);
  color: white;
}

.nav-button.secondary {
  background: #eee;
  color: #000;
}

.nav-button.submit {
  background: #000;
  color: white;
  width: 50%;
  justify-content: center;
}

/* 기존 스타일 중 필요한 부분 유지 */
.body-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.body-tabs {
  display: flex;
  gap: 13px;
  margin-bottom: 24px;
  width: 100%;
}

.tab-button {
  flex: 1;
  padding: 13px;
  border: 1px solid #eee;
  border-radius: 12px;
  background: white;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tab-button.active {
  background: #000;
  color: white;
  border-color: #000;
}

.body-image {
  width: 100%;
  max-width: 100px;
  margin: 0 auto;
}

.body-part {
  fill: #eee;
  cursor: pointer;
  transition: fill 0.2s ease;
}

.body-part:hover {
  fill: #ccc;
}

.body-part[data-selected="true"] {
  fill: #1a1a1a;
}

/* 예산 슬라이더 */
.budget-slider-container {
  padding: 16px 0;
}

.budget-slider {
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 100px;
  appearance: none;
}

.budget-slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
}

.budget-info {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 14px;
  color: #666;
}

/* 이미지 업로드 */
.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.image-preview {
  position: relative;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 6px 13px;
  font-size: 13px;
}

.region-selects,
.datetime-selects {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
}

.location-controls {
  width: 100%;
  margin-bottom: 8px;
}

.location-button {
  width: 100%;
  padding: 12px;
  background-color: #1a1a1a;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  color: #eee;
  transition: all 0.2s ease;
}

.location-button i {
  font-size: 16px;
}

.location-select-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  color: #495057;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
}

input[type="date"].input-field {
  padding-right: 12px;
  box-sizing: border-box;
}

.textarea-field {
  width: calc(100% - 40px);
  padding: 16px 19px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 17px;
  margin-top: 19px;
  resize: vertical;
  min-height: 140px;
  transition: border-color 0.2s ease;
}

.textarea-field:focus {
  border-color: #2d3436;
  outline: none;
}

.phone-consent-container {
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.phone-display {
  margin-bottom: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  color: #666;
  font-size: 14px;
}

.phone-number {
  font-size: 16px;
  font-weight: 500;
}

.consent-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 15px 0;
}

.consent-input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.consent-label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.error-message {
  color: #dc3545;
  font-size: 13px;
  margin-top: 5px;
}
</style>